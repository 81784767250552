import React, { Component } from "react";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const styles = theme => ({
  root: {
    // justifyContent: "center",
    float: "right"
    // display: "inline-flex",
    // flexWrap: "wrap"
  },
  selectbox: {
    display: "flex",
    flexWrap: "wrap"
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  formControl: {
    margin: "0px 10px",
    minWidth: 100
  },
  font: {
    fontSize: "14px",
    fontFamily: '"Open Sans", sans-serif'
  }
});

@observer
class DateSelectBox extends Component {
  state = {
    value: "day"
  };

  handleChange = event => {
    this.setState({
      value: event.target.value
    });
    this.props.onChangeDate(event.target.value);
  };

  render() {

    // const inputLabel = React.useRef(null);
    const { classes } = this.props;
    return (
      <form className={classes.root} autoComplete="off">
        <FormControl variant="outlined" className={classes.formControl}>
          <NativeSelect
            value={this.state.value}
            onChange={this.handleChange}
            className={classes.font}
          >
            <option value="time" name="time">
              Time
            </option>
            <option value="day" name="day">
              Day
            </option>
            <option value="week" name="week">
              Week
            </option>
            <option value="month" name="month">
              Month
            </option>
            {/*<option value="year" name="year">*/}
            {/*Year*/}
            {/*</option>*/}

            ))}
          </NativeSelect>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(DateSelectBox);
