import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ReactComponent as Logo } from "assets/images/header/logo.svg";
import TopNav from "components/molecules/TopNav";
import CustomBtn from "components/atoms/CustomBtn";
import { withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
// import CustomPopover from "components/atoms/CustomPopover";
import Cart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import Exit from "@material-ui/icons/ExitToApp";
// import CommentIcon from '@material-ui/icons/Comment';
// import CustomDialog from "components/atoms/CustomDialog";
// import Question from "components/atoms/CustomDialog/Question";
// import CheckPermission from "components/lib/CheckPermission";

const styles = theme => ({
  header: {
    borderBottom: "0px"
  },
  centered: {
    display: "flex",
    // padding: "0px 11vw 0px 11vw",
    alignItems: "center"
  },
  headerMargin: {
    width: "100%",
    padding: "0px 10px",
    margin: "auto",
    display: "flex",
    height: "50px",
    backgroundColor: "#ffffff"
    // "@media (max-width: 1500px)": {
    //   // width: '1240px',
    //   margin: 'auto',
    //   padding: "0px"
    // },
  },
  logo: {
    width: "210px",
    height: "31.7px"
  },
  btns: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "10px"
  },
  btnmargin: {
    width: "120px",
    height: "25px",
    margin: "3px"
  },

  btnname: {
    minWidth: "120px",
    height: "25px",
    margin: "3px"
  },
  myPage: {
    minWidth: "120px",
    height: "25px",
    margin: "3px",
    backgroundColor: "#26a1a4",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#26a1a4"
    }
  },
  textdeco: {
    textDecoration: "none!important"
  },
  cart: {
    width: "120px",
    height: "25px",
    margin: "3px",
    backgroundColor: "#26a1a4",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#26a1a4"
    }
  },
  carticon: {
    color: "#ffffff"
  },
  headerContact: {
    paddingTop: "10px",
    paddingRight: "10px",
    fontSize: "10px",
    color: "#b4b4b4",
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: "#868686"
    }
  }
});

@inject("comm", "auth")
@observer
class HeaderContainer extends Component {
  state = {
    open: false
  };

  popoverListClick = ({ path }) => {
    const { auth } = this.props;
    alert("Sign out");
    auth.logout();
    // history.push(path);
  };

  onClickOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const {
      classes,
      comm: { loggedIn, originUser },
      pathname
    } = this.props;
    const { open } = this.state;
    return (
      <>
        <header>
          <div className={classes.header}>
            <div className={classes.centered}>
              <div className={classes.headerMargin}>
                <Link to="/" style={{ paddingTop: "10px" }}>
                  <Logo className={classes.logo} />
                </Link>
                <TopNav pathname={pathname} />
                <div className={classes.btns}>
                  {/* <CheckPermission
                    key={"report"}
                    permission={"ROLE_DEFAULT_USER"}
                  >
                    <div style={{ color: "#b4b4b4", paddingRight: "5px", paddingTop: "10px" }}> <CommentIcon style={{ fontSize: "14px" }} /></div>
                    <div className={classes.headerContact} onClick={this.onClickOpen}> Q&A</div>
                  </CheckPermission> */}
                  <Link to="/cart" className={classes.textdeco}>
                    <CustomBtn
                      text={"DataSet List"}
                      img={Cart}
                      stylebtn={
                        pathname.match("/cart")
                          ? classes.cart
                          : classes.btnmargin
                      }
                      styleicon={
                        pathname.match("/cart") ? classes.carticon : null
                      }
                      isBadge={true}
                    />
                  </Link>
                  {!loggedIn ? (
                    <Link to="/signin" className={classes.textdeco}>
                      <CustomBtn
                        text={"Login"}
                        img={Person}
                        stylebtn={classes.btnmargin}
                      />
                    </Link>
                  ) : (
                    // <CustomPopover
                    //   user={originUser}
                    //   popoverList={[
                    //     {
                    //       name: "EDIT PROFILE",
                    //       path: "/my-page",
                    //       permission: "ROLE_KM_USER"
                    //     },
                    //     {
                    //       name: "LOGOUT",
                    //       path: "/",
                    //       permission: "ROLE_KM_USER"
                    //     }
                    //   ]}
                    //   popoverListClick={this.popoverListClick}
                    // />
                    <>
                      <Link to="/my-page" className={classes.textdeco}>
                        <CustomBtn
                          text={
                            originUser
                              ? ` ${originUser.firstName} ${
                                  originUser.lastName
                                }(${originUser.roleName || "ROLENAME"})`
                              : "null"
                          }
                          img={Person}
                          stylebtn={
                            pathname.match("/my-page")
                              ? classes.myPage
                              : classes.btnname
                          }
                          styleicon={
                            pathname.match("/my-page") ? classes.carticon : null
                          }
                        />
                      </Link>
                      <Link
                        to="/"
                        onClick={this.popoverListClick}
                        className={classes.textdeco}
                      >
                        <CustomBtn
                          text={"Logout"}
                          img={Exit}
                          stylebtn={classes.btnmargin}
                        />
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* <CustomDialog
          open={open}
          onClickOpen={this.onClickOpen}
          type={'QA'}
          CustomDialogContent={Question}
        /> */}
      </>
    );
  }
}

export default withStyles(styles)(HeaderContainer);
