import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TestTable from "components/atoms/TestTable";
import CartDownLoad from "components/atoms/CartDownLoad";
import TSVDownLoad from "components/atoms/TSVDownLoad";
import CustomDialog from "components/atoms/CustomDialog";
import TableContentList from "components/atoms/CustomDialog/TableContentList";
// const util = require("util");

// const fs = require('fs');
// import CustomDialog from "components/atoms/CustomDialog";
// import DetailCartSet from "components/atoms/CustomDialog/DetailCartSet";
// import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";
// import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import Button from "@material-ui/core/Button";

const styles = theme => ({
  chartMargin: {
    // padding: "15px",
    padding: "0px"
  },
  nav: {
    height: "35px",
    marginTop: "15px"
  },
  person: {
    lineHeight: 'normal',
    float: "left",
    paddingLeft: "2px",
    marginTop: "10px",
    fontSize: "14px"
  },
  openbutton: {
    minWidth: "0px",
    width: '25px',
    marginLeft: "5px",
    marginBottom: "8px",
    height: "17px",
    padding: "0px",
    border: "solid 1px #c6c6c6"
  },
  arrowIcon: {
    height: "16px"
  },
  cancerlsitmark: {
    lineHeight: 'normal',
    float: "right",
    paddingRight: "2px",
    marginTop: "15px",
    fontSize: "8px"
  }
})

class DetailBoard extends Component {

  state = {
    selected: {},
    open: false,
    tableType: 'Cancer'
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.parentEl = React.createRef();

    this.contentList = "";
    this.totalString = "";
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    // console.log(this.props.detailType.data, nextProps.detailType.data, this.props.detailType.data.toString() !== nextProps.detailType.data.toString())
    return JSON.stringify(this.props.detailType) !== JSON.stringify(nextProps.detailType) || this.state !== nextState || this.props.chartOpen !== nextProps.chartOpen
    // return true;
  }

  handlePagination = s => {
    const { handlePagination, detailType } = this.props
    handlePagination(s, detailType.title)

  };

  handleSort = ({ order, orderBy }) => {
    console.log(order, orderBy);
  };

  handleClickCheckbox = value => {
  };

  setSelect = selected => {
    this.setState({ selected });
    console.log(this.state.selected)
  };

    
  // TSV file download 구현 by jdy 2020-11
  // onTSVDownload, onUpdateData(), handleColumnList(), downloadTxtFile()

  onUpdateData = (data) => {

    this.contentList = "";

    data.map((d, i) => {

      this.totalString = this.handleColumnList(d);

      this.contentList += this.totalString;

    });

    return this.contentList;
  } 

  handleColumnList = (d) => {
    const {  detailType } = this.props;

    const columns = detailType.columns;

    this.totalString = "";

    let length = columns.length - 1;

    columns.map((c, i) => {
      const { property } = c;
      
      let addString = "";

     if (i == length) {
      addString = d[property] + "\n";
     } else {
      addString = d[property] + "\t";
     }

      this.totalString += addString;
    });

    return this.totalString;
  }

  onTSVDownload = (tableType) => {

    const {  detailType } = this.props;
    
    let textContent = "";
  
    if (tableType === 'Cancer' && detailType.data)
    {
      textContent = this.onUpdateData(detailType.data);
    }
    else if (tableType === 'Gene')
    {
      textContent = this.onUpdateData(detailType.data.content)
    }    
    else if (tableType === 'SNV')
    {
      textContent = this.onUpdateData(detailType.data.content)
    }    
    else if (tableType === 'CNV')
    {
      textContent = this.onUpdateData(detailType.data.content)
    }    
    else if (tableType === 'SV')
    {
      textContent = this.onUpdateData(detailType.data.content)
    }
    else if (tableType === 'Drug')
    {
      textContent = this.onUpdateData(detailType.data.content)
    }

    this.downloadTxtFile(textContent, tableType);

  }

  downloadTxtFile = (textContent, tableType) => {
    const element = document.createElement("a");
    // const file = new Blob([document.getElementById('myInput').value], {type: 'text/plain'});
    const file = new Blob([textContent], {type: 'text/plain'});
    const fileName = tableType + 'List.txt';
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  onClose = () => {
    this.setState({
      open: false
    });
  };

  // Table list 에서 선택시 event 가능하도록 TestTable 에 onClickEvent = {onClickMainFilter} 추가 by jdy 2020-12 
  // TSV file download 추가 by jdy 2020-11

  render() {
    const { onClickMainFilter, detailType, classes, isNotBox, isSubject } = this.props;


    if (isNotBox && detailType.data) {
      return (
        <div className={classes.chartMargin}>
          <div >
            <div className={classes.person}>{detailType.title}</div>
            {isSubject ? <div className={classes.cancerlsitmark}>
              {detailType.data.content && detailType.data.content[0] && detailType.data.content[0].checkTotal !== "" ?
                "(Selected DataSet / Selected DataSet Total / K-MASTER Total), unit:#Subjects" : "(DataSet Total / K-MASTER Total), unit:#Subjects"}
            </div> : null}
          </div>

          <TestTable
            ref={this.node}
            id={"alllist"}

            columns={detailType.columns}
            data={detailType.data}
            defaultSort={{
              order: "asc",
              orderBy: "geneName",
              handleSort: this.handleSort
            }}
            defaultPagination={{
              page: 0,
              rowsPerPage: 5,
              handlePatination: this.handlePagination
            }}
            headerIcon={true}

            onClickEvent = {onClickMainFilter} 
            {...this.props}
          />
        </div>
      )
    }
    if (detailType.type === 'Cancer' && detailType.data) { // cancer의 경우 sub list 
      return (
        <div className={classes.chartMargin}>
          <div className={classes.nav}>
            {/* TSV 다운로드버튼 추가 : 2020-11-09 박남준 */}
            {/* <TSVDownLoad downloadData={{ content: detailType.data, totalElements: 1 }} /> */}
            <TSVDownLoad tableType={detailType.type} onClickTSV={this.onTSVDownload} /> {/* modidy by jdy 2020-11 */}
            <div className={classes.person}>{detailType.title.toUpperCase()}</div>
            {isSubject ? <div className={classes.cancerlsitmark}>
              {detailType.data[0] && detailType.data[0].checkTotal !== "" ? "(Selected DataSet / Selected DataSet Total / K-MASTER Total), unit:#Subjects" : "(DataSet Total / K-MASTER Total), unit:#Subjects"}
            </div> : null}
          </div>
          <div id='alllist' ref={this.parentEl}>
            <TestTable
              ref={this.node}
              parentEl={this.parentEl.current}
              id={"alllist"}

              columns={detailType.columns}
              data={{ content: detailType.data, totalElements: 1 }}

              defaultSort={{
                order: "asc",
                orderBy: "geneName",
                handleSort: this.handleSort
              }}

              onClickEvent = {onClickMainFilter}

              {...this.props}
            />
          </div>
          <CustomDialog
            open={this.state.open}
            onClickOpen={this.onClose}
            type={detailType.type}
            columns={detailType.columns}
            data={{ content: detailType.data, totalElements: 1 }}
            CustomDialogContent={TableContentList}
          />
        </div>
      )
    }
    if (detailType.data) {
      return (
        <div className={classes.chartMargin}>
          <div className={classes.nav}>
            {/* TSV 다운로드버튼 추가 : 2020-11-09 박남준 */}
            {/* <TSVDownLoad downloadData={detailType.data} />             */}
            <TSVDownLoad tableType={detailType.type} onClickTSV={this.onTSVDownload}  /> {/* modidy by jdy 2020-11 */}
            <div className={classes.person}>{detailType.title}</div>
            {isSubject ? 
              <div className={classes.cancerlsitmark}>
              {detailType.data.content && 
               detailType.data.content.length > 0 && detailType.data.content[0] && detailType.data.content[0].checkTotal !== "" ?
                "(Selected DataSet / Selected DataSet Total / K-MASTER Total), unit:#Subjects" : 
                "(DataSet Total / K-MASTER Total), unit:#Subjects"
              }
              </div> : 
              null
            }
          </div>
          <TestTable
            ref={this.node}
            id={"alllist"}

            columns={detailType.columns}
            data={detailType.data}

            defaultSort={{
              order: "asc",
              orderBy: "geneName",
              handleSort: this.handleSort
            }}
            defaultPagination={{
              page: 0,
              rowsPerPage: 5,
              handlePatination: this.handlePagination
            }}

            onClickEvent = {onClickMainFilter}

            {...this.props}
          />
          <CustomDialog
            open={this.state.open}
            onClickOpen={this.onClose}
            type={detailType.type}
            columns={detailType.columns}
            data={{ content: detailType.data.content, totalElements: 1 }}
            CustomDialogContent={TableContentList}
          />          
        </div>

      )
    } else {
      return null
    }

  }
}


export default withStyles(styles)(DetailBoard);
