import React from "react";
import { computed } from "mobx";
import dateFormat from "dateformat";
// import EditRegisterButton from "pages/admin/EditRegisterUser";
// import DeleteUser from "pages/admin/DeleteUser";
class UserModel {
  constructor(d, i) {
    this.id = d.id
    this.idx = d.id
    this.name = `${d.firstName} ${d.lastName}`;
    this.email = d.email;
    this.title = `[ ${d.type} ] ${d.title}`;
    this.contents = d.contents;
    this.commented = d.commented;
    this.createdDateTime = dateFormat(d.createdDateTime, "yyyy-mm-dd  HH:MM:ss");
  }

  @computed
  get userstatus() {
    if (this.commented) {
      return ("Complete");
    } else {
      return ("Waiting");
    }
  }

  @computed
  get control() {
    if (this.commented) {
      return (
        <div style={{ height: "15px", backgroundColor: "#00c8a3", fontSize: "12px", color: "#ffffff", padding: "3px 8px" }}>Complete</div>
      );
    } else {
      return ("Waiting");
    }
  }

}

export default UserModel;
