import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
// import cancertypes from "assets/images/home/cancertypes.svg";
// import cases from "assets/images/home/cases.svg";
// import genes from "assets/images/home/genes.svg";
// import mutations from "assets/images/home/mutations.svg";

const styles = theme => ({
  table: {
    margin: "0px 18px 0px 0px",
    width: "230px",
    bordeRadius: "2px",
    backgroundColor: "rgba(99, 99, 99, 0.63)"
  },
  tableHead: {
    height: "35px"
  },
  headSize: {
    textAlign: "center",
    fontSize: "14px"
  },
  tableBody: {
    height: "42px"
  },
  talbeCell: {
    borderBottom: "0px",
    width: "80%",
    padding: "0px",
    paddingLeft: "14px",
    color: "#ffffff",
    fontSize: "15px"
  },
  number: {
    borderBottom: "0px",
    borderLeft: "solid 1px #cbcbcb",
    borderRadius: "2px",
    width: "20%",
    color: "#ffffff",
    fontSize: "14px",
    textAlign: "center",
    padding: "0px 10px "
  }
});

// const tableImage = {
//   CASES: {
//     img: cases
//   },
//   GENES: {
//     img: genes
//   },
//   MUTATIONS: {
//     img: mutations
//   },
//   "CANCER TYPES": {
//     img: cancertypes
//   }
// };

class HomeCaseTable extends Component {
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.categoryTotalCounts) !== JSON.stringify(this.props.categoryTotalCounts);
  }

  render() {
    const { categoryTotalCounts, name } = this.props;
    const { classes } = this.props;
    return (
      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.tableHead}>
            <TableCell className={classes.talbeCell}>
              {/* <img src={tableImage[name].img} alt="" /> */}
              <span className={classes.headSize}> {name} </span>
            </TableCell>
            <TableCell className={classes.number}>
              {new Intl.NumberFormat("en-IN").format(categoryTotalCounts)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(HomeCaseTable);
