import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

let query = ''

const styles = theme => ({
  contain: {
    height: "600px",
    width: "650px",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  nousercontain: {
    height: "450px",
    width: "650px",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "30px",
    lineHeight: '2',
    fontSize: "16px",
    padding: "0px 25px",
    fontWeight: "500"
  },
  titlename: {
    float: 'left'
  },
  date: {
    float: 'right'
  },
  main: {
    padding: "20px 25px 0px 25px"
  },
  mainPaddingTop: {
    paddingTop: "20px",
    paddingBottom: "4px",
    color: '#434343'
  },
  set: {
    width: "600px",
    height: "27px",
    fontSize: "14px",
    border: 'solid 1px #979797'

  },
  description: {
    width: "600px",
    height: "88px",
    fontSize: "14px",
    border: 'solid 1px #979797'

  },
  button: {
    width: "296px",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "8px"
  },
  query: {
    width: '590px',
    minHeight: '38px',
    border: 'solid 1px #c7c7c7',
    backgroundColor: '#f6f6f6',
    opacity: "0.4",
    padding: "5px",
    fontSize: "14px"
  },
  textInput: {
    paddingLeft: "5px"
  },
  select: {
    width: "600px",
    border: 'solid 1px #979797',
  }
})
let localStorage = window.localStorage;

@inject('analysis')
@observer
class DetailCartSet extends Component {

  state = {
    group: "",
    title: "",
    main: ""
  }
  groupChange = event => {
    this.setState({
      group: event.target.value
    });
  };

  titleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  mainChange = event => {
    this.setState({
      main: event.target.value
    });
  };
  onClickEvent = async (e) => {
    const { analysis, originUser, close, POSTQUERY, valueData } = this.props
    const { title, main, group } = this.state
    let data = {
      setsGroup: group,
      description: main,
      title: title,
      filter: POSTQUERY,
      setsQuery: query,
      setsPersonNum: valueData ? valueData[3].value : 0,
      // setsOwner: originUser.login,
      setsOwner: originUser ? originUser.login : "admin"
    }
    let value
    if (originUser) {
      value = await analysis.postSetData(data)
    } else {
      localStorage.setItem(`${title}||${main}||${query}||${valueData ? valueData[3].value : 0}`, JSON.stringify(POSTQUERY))
    }
    analysis.insertCartSet()
    close(e)
    if (value) {
      alert("Saved to Set List.");
    }
  }



  render() {
    const { classes, list, close, originUser, manageSet, cancerTypeData } = this.props
    const { title, main, group } = this.state
    query = ''
    let length
    let detaillength
    let today = new Date().toISOString().slice(0, 10);

    if (list.reportedFilter) {
      query += `Reported : ${list.reportedFilter} or `
    }

    if (list.gender) {
      query += `Sample : ${list.sample} or `
    }

    if (list.gender) {
      query += `Primary : ${list.primary} or `
    }

    if (list.gender) {
      query += `Gender : ${list.gender} or `
    }

    if (list.startAge) {
      query += `Age : ${list.startAge} / ${list.endAge} or `
    }

    if (list.detailcancer && Object.keys(list.detailcancer).length) {
      length = Object.keys(list.detailcancer).length
      let check = 0
      for (let i of cancerTypeData) {
        if (list.detailcancer[i.name]) {
          if (check !== 0) {
            query += ` or `
          }
          if (list.detailcancer[i.name].length >= i.details.length) {
            query += `${i.name} ( ALL )`
          } else {
            query += `${i.name} ( `
            for (let j in list.detailcancer[i.name]) {
              query += `${list.detailcancer[i.name][j].name} `
              if (list.detailcancer[i.name].length - 1 > j) {
                query += `or `
              }
            }
            query += ` )`
          }
          check += 1
        }
      }
    }

    if (list.gene && list.gene.length) {
      length = list.gene.length
      for (let i in list.gene) {
        query += list.gene[i]
        if (length - 1 > i) {
          query += ` or `
        }
      }
    }

    if (list.snv && list.snv.length) {
      length = list.snv.length
      for (let i in list.snv) {
        query += list.snv[i].replace(/\^/g, ', ')
        if (length - 1 > i) {
          query += ` or `
        }
      }
    }
    if (list.snv && list.snv.length && list.cnv && list.cnv.length) {
      query += ` or `
    }
    if (list.cnv && list.cnv.length) {
      length = list.cnv.length
      for (let i in list.cnv) {
        query += list.cnv[i].replace(/\^/g, ', ')
        if (length - 1 > i) {
          query += ` or `
        }
      }
    }
    if (list.sv && list.sv.length && list.cnv && list.cnv.length) {
      query += ` or `
    } else if (list.snv && list.snv.length && list.sv && list.sv.length) {
      query += ` or `
    }
    if (list.sv && list.sv.length) {
      length = list.sv.length
      for (let i in list.sv) {
        query += list.sv[i].replace(/\^/g, ', ')
        if (length - 1 > i) {
          query += ` or `
        }
      }
    }

    if (list.variantType && Object.keys(list.variantType).length) {
      length = Object.keys(list.variantType).length
      for (let i in Object.keys(list.variantType)) {
        let cancer = Object.keys(list.variantType)[i]
        query += `${cancer} (`
        detaillength = list.variantType[cancer].length
        for (let j in list.variantType[cancer]) {
          query += `${list.variantType[cancer][j]} `
          if (detaillength - 1 > j) {
            query += `or `
          }
        }
        query += `)`
        if (length - 1 > i) {
          query += ` or `
        }
      }
    }
    if (list.chr) {
      query += `Chromosome : chr${list.chr.replace('chr ', '').replace('chr', '')} / ${list.start_position} ~ ${list.end_position}`
    }

    let options = []
    if (originUser && manageSet) {
      for (let i of manageSet) {
        options.push(
          <option value={i.groupName} key={i.groupId}>{i.groupName}</option>
        )
      }
    }

    return (
      <>
        <DialogContent className={originUser ? classes.contain : classes.nousercontain}>
          <div className={classes.title}>
            <div className={classes.titlename}>{`New Cart Set`}</div>
            <div className={classes.date}>{`${today}`}</div>
          </div>
          <div className={classes.main}>
            {originUser ? <><div className={classes.mainPaddingTop}>Group Name</div>
              <Input
                className={classes.set}
                type="text"
                disableUnderline={true}
                placeholder={"Set1"}
                value={group}
                onChange={this.groupChange}
                classes={{ input: classes.textInput }}
                autoComplete='groupname'
              />
              <datalist id='groupname'>
                {options}
              </datalist>
              <div className={classes.mainPaddingTop}>Group Change</div>
              <Select native value={group} disableUnderline={true} displayEmpty
                onChange={(e) => this.groupChange(e)}
                className={classes.select} >
                <option value={''} >Direct Input</option>
                {options}
              </Select>
            </> : null}
            <div className={classes.mainPaddingTop}>Title</div>
            <Input
              className={classes.set}
              type="text"
              disableUnderline={true}
              placeholder="Set1"
              value={this.state.title}
              onChange={this.titleChange}
              classes={{ input: classes.textInput }}
            />
            <div className={classes.mainPaddingTop}>Query</div>
            <div className={classes.query}>
              {query}
            </div>
            {/* <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="논문 참고용."
              value={this.state.main}
              onChange={this.mainChange}
            /> */}
            <div className={classes.mainPaddingTop}>Description</div>
            <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="For thesis reference."
              value={this.state.main}
              onChange={this.mainChange}
              multiline={true}
              rows={4}
              classes={{ input: classes.textInput }}
            />
            <div className={classes.mainPaddingTop} style={{ width: "600px" }}>

              <Button className={`${classes.button} ${classes.buttonpadding}`} type="button" onClick={close}>
                CANCEL
              </Button>
              <Button className={classes.button}
                type="button"
                disabled={title === '' || main === '' || group === '' ? true : false}
                onClick={this.onClickEvent} >
                ADD CART
              </Button>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(DetailCartSet);
