import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import AdminQABoard from "components/organisms/AdminQABoard";
import queryString from "query-string";

const styles = theme => ({
  main: {
    height: "calc(100% - 52px)",
    border: "solid 1px #dbdbdb",
    backgroundColor: "#ffffff",
    margin: "10px 5px 0px 10px",
    // padding: "10px"
  },
  title: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#3a3a3a"
  },
  tableHeader: {
    border: "solid 1px #dedede",
    backgroundColor: "#f3f3f3",
    fontSize: "11px",
    color: "#3a3a3a"
  },
  headerdiv: {
    padding: "4px 0px",
    textAlign: "center"
  }
})

const QAColumns = [
  { column: "No.", property: "idx", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 100 } } },
  { column: "TITLE", property: "title", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 800 } } },
  { column: "E-MAIL", property: "email", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "TIME", property: "createdDateTime", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "STATUS", property: "control", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false, }, style: { head: { width: 120 } } },
];

@inject("system", "comm")
@observer
class QAList extends Component {

  state = {
    type: "type",
    keyword: "",
    page: 0,
    size: 10,
  };

  componentDidMount() {
    const { system } = this.props;
    system.getQAList("", "?size=10");
  }

  componentDidUpdate() {
    const { system, system: { qaList }, comm: { originUser } } = this.props;
    if (originUser && (!qaList)) {
      system.getQAList("", "?size=10");
    }
  }

  searchUser = (type, keyword) => {
    const { system } = this.props;
    const { page, size } = this.state
    this.setState({
      type: type,
      keyword: keyword
    })
    let query = {
      page: page,
      size: size,
      type: type,
      keyword: keyword
    };
    const queryList = queryString.stringify(query);
    system.getQAList("", `?${queryList}`)
  }

  handlePagination = (s, target) => {
    const { system } = this.props;
    const { type, keyword } = this.state
    this.setState({
      page: s.page,
      size: s.rowsPerPage,
    })
    let query = {
      page: s.page,
      size: s.rowsPerPage,
      type: type,
      keyword: keyword
    };
    const queryList = queryString.stringify(query);
    system.getQAList("", `?${queryList}`);
  };

  tableClick = (e) => {
    const { history } = this.props
    history.push(`/admin/qaboard/${e}`)
  }

  render() {
    const { classes, system: { qaList } } = this.props;
    let accessLogType = { title: "Q&A BOARD", columns: QAColumns, data: qaList }
    return (
      <div className={classes.main}>
        {qaList ? <AdminQABoard detailType={accessLogType} handlePagination={this.handlePagination} tableClick={this.tableClick} searchUser={this.searchUser} isSearch={true} /> : null}
      </div>
    );
  }
}

export default withStyles(styles)(QAList);;
