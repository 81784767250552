import React, { Component } from "react";
import { observer } from "mobx-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { lightBlue, deepPurple } from "@material-ui/core/colors";

@observer
class LineChart extends Component {
  componentDidMount() { }

  render() {
    const { data, statics, len } = this.props;
    let x = [];
    let y = [];
    //국내, 국외 방문자 수 
    let cntKr = [];
    let cntFo = [];
    let z = [];
    let resourcedata;
    if (data.length && !statics) {
      for (let i = 0; i < data.length; i++) {
        x[i] = data[i]["x"];
        y[i] = data[i]["y"];
        cntKr[i] = data[i]["cntKr"];
        cntFo[i] = data[i]["cntFo"];
      }
    } else if (len === 2) {
      resourcedata = data[0].series;
      for (let i = 0; i < resourcedata.length; i++) {
        x[i] = resourcedata[i]["name"];
        y[i] = resourcedata[i]["value"];
      }
      resourcedata = data[1].series;
      for (let i = 0; i < resourcedata.length; i++) {
        z[i] = resourcedata[i]["value"];
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        x[i] = data[i]["name"];
        y[i] = data[i]["value"];
      }
    }
    const options = {
      chart: {
        type: !statics ? "area" : null,
        height: 300
        // width: "300"
      },
      title: {
        text: null
      },
      xAxis: {
        gridLineWidth: 2,
        categories: x,
        tickmarkPlacement: "on",
        crosshair: true,
        // gridLineWidth: 1,
        gridZIndex: 4,
        min: 0.45,
        max: x.length - 1.45
        // startOnTick: false,
        // endOnTick: false,
        // minPadding: 0,
        // maxPadding: 0,
        // align: "left"
      },
      yAxis: {
        min: 0,
        softMax: 20,
        gridLineWidth: 1,
        gridZIndex: 4,
        title: {
          text: null
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        crosshairs: true,
        shared: true,

        formatter: function () {
          let additionalString = '';
          // let index = this.point.index;
          // let data = this.series.userOptions.data;
          // console.log('index :', index);
          additionalString = `<br> 국내  : ` + cntKr[this.points[0].point.x];
          additionalString += `<br> 국외  : ` + cntFo[this.points[0].point.x];
          
          return `${this.x} <br> Visitor: ${this.y} ${additionalString}`;
        }
      },
      legend: statics
        ? false
        : {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          floating: true,
        },
      plotOptions: {
        line: {
          softThreshold: false
        },
        series: {
          marker: {
            enabled: false
          }
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, lightBlue[200]], [1, lightBlue[50]]]
          }
        }
      },
      series: statics
        ? [
          {
            name: len === 2 ? data[0].name : "Disk",
            data: y,
            color: deepPurple[400]
          },
          {
            name: data[1] ? data[1].name : null,
            data: z,
            color: lightBlue[200]
          }
        ]
        : [
          {
            name: "Visitor",
            data: y,
            color: lightBlue[200]
          }
        ]
    };

    return (
      <>
        {
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            redraw={true}
            animation={true}
          />
        }
      </>
    );
  }
}
export default LineChart;
