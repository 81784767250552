import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AnalysisChart from "components/atoms/AnalysisChart";
// import { Element } from "react-scroll";
import AgeGenderChart from "components/atoms/AgeGenderChart";
import AgeGenderPieChart from "components/atoms/AgeGenderChart/AgeGenderPieChart";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

const styles = theme => ({
  // chartTitle: {
  //   fontSize: "14px",
  //   borderBottom: "solid 1px #c6c6c6",
  //   padding: "3px 7px 3px 12px",
  //   fontWeight: "500",
  //   lineHeight: '23px'
  // },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "6.5px 0px 6.5px 12px",
    fontWeight: "500"
  },
  board: {
    width: "32%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "7.5px",
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
    // marginBottom: "0px"
  },
  chartwidth: {
    width: '95%',
    margin: 'auto'
  },
  list: {
    display: "flex",
    float: "right",
    border: 'solid 1px #d4d4d5',
    fontSize: '12px',
    color: '#434343',
    textAlign: 'center',
    alignItems: 'center',
    height: '25px',
    marginTop: "2px"
  },
  type: {
    width: '65px',
    height: '25px',
    backgroundColor: ' #a5a5a5',
    color: "#ffffff"
  },
  icon: {
    width: '27px',
    height: '25px',
    backgroundColor: '#f3f3f3'
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  value: {
    width: '70px',
    fontWeight: 'normal'
  },
  barchart: {
    width: "32%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "7.5px",
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  chartContainer: {
    // paddingTop: "15px",
    display: "flex"
  },
  ageGender: {
    width: "50%",
    padding: "0px 7.5px"
  },
})

const chartList = ['cancer', 'gene', 'snv', 'cnv', 'sv'];
const helpList = [3, 4, 5, 6, 7];
class AnalysisChartTable extends Component {

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.mainDataType !== nextProps.mainDataType
    // return true;
  }

  handleMainHelp = (id) => {
    const { clickMainHelp } = this.props;

    if (id === 'demography') {
      clickMainHelp (2);
    }
    else if (id == 'cancer') {
      clickMainHelp (3);
    }
    else if (id == 'gene') {
      clickMainHelp (4);
    }    
    else if (id == 'snv') {
      clickMainHelp (5);
    }    
    else if (id == 'cnv') {
      clickMainHelp (6);
    }    
    else if (id == 'sv') {
      clickMainHelp (7);
    }
  };

  render() {
    const { mainDataType, classes, select, PersonGraphicsData, dxAge, operationSet } = this.props;
    let mainChart = []
    for (let i of chartList) {
      mainChart.push(
        <div className={classes.board} key={i}>
          <div className={classes.chartTitle}>
            <span>{mainDataType[i].type}</span>
            <span>
              <IconButton color="primary" className={classes.helpIconButton} onClick={()=>{this.handleMainHelp(i);}}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </span>
          </div>
          <div className={classes.chartwidth}>
            <AnalysisChart type={mainDataType[i].type} list={mainDataType[i].chart} select={select} operationSet={operationSet} />
          </div>
        </div>
      )
    }

    return (
      <>
        <div className={classes.barchart}>
          <div className={classes.chartTitle}>
            <span>Demography</span>
            <span>
              <IconButton color="primary" className={classes.helpIconButton} onClick={()=>{this.handleMainHelp('demography');}}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </span>
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.ageGender}>
              {/* <AgeGenderChart list={PersonGraphicsData} /> */}
              <AgeGenderPieChart list={PersonGraphicsData} />
            </div>
            <div className={classes.ageGender}>
              {/* <AgeGenderChart list={dxAge} isAge={true} /> */}
              <AgeGenderChart list={dxAge} />
            </div>
          </div>
        </div>
        {mainChart}
      </>

    )
  }
}


export default withStyles(styles)(AnalysisChartTable);
