import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  contain: {
    height: "600px",
    width: "650px",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "30px",
    lineHeight: '2',
    fontSize: "16px",
    padding: "0px 25px",
    fontWeight: "500"
  },
  titlename: {
    float: 'left'
  },
  date: {
    float: 'right'
  },
  main: {
    padding: "20px 25px 0px 25px"
  },
  mainPaddingTop: {
    paddingTop: "20px",
    paddingBottom: "4px",
    color: '#434343'
  },
  notUse: {
    paddingTop: "20px",
    paddingBottom: "4px",
    color: '#434343',
    opacity: "0.2"
  },
  set: {
    width: "600px",
    height: "27px",
    fontSize: "14px",
    border: 'solid 1px #979797'

  },
  description: {
    width: "600px",
    height: "88px",
    fontSize: "14px",
    border: 'solid 1px #979797'

  },
  button: {
    width: "296px",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "8px"
  },
  query: {
    width: '590px',
    minHeight: '38px',
    border: 'solid 1px #c7c7c7',
    backgroundColor: '#f6f6f6',
    opacity: "0.4",
    padding: "5px",
    fontSize: "14px"
  },
  textInput: {
    paddingLeft: "5px"
  },
  select: {
    width: "600px",
    border: 'solid 1px #979797',
  }
})

@inject('analysis')
@observer
class changeCartSet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      group: props.modifyData.setsGroup,
      title: props.modifyData.title,
      main: props.modifyData.description,
      query: props.modifyData.setsQuery
    };
  }

  groupChange = event => {
    this.setState({
      group: event.target.value
    });
  };

  titleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  mainChange = event => {
    this.setState({
      main: event.target.value
    });
  };

  onClickEvent = async (e) => {
    const { analysis, originUser, modifyData, close } = this.props
    const { title, main, group, query } = this.state
    const data = {
      setsGroup: group,
      description: main,
      title: title,
      setDataId: modifyData.setDataId,
      filter: modifyData.filter,
      setsQuery: query,
      setsPersonNum: modifyData.setsPersonNum,
      setsOwner: modifyData.setsOwner,
      // modifiedDate: new Date()
    }
    if (originUser) {
      await analysis.changeSetData(group, data)
    }
    let value = await analysis.getSetListData("");
    close()
    if (value) {
      alert("Modification success.");
    }
  }

  render() {
    const { classes, close, manageSet } = this.props
    const { title, main, query } = this.state
    let options = []
    let today = new Date().toISOString().slice(0, 10);
    for (let i of manageSet) {
      options.push(
        <option value={i.groupName} key={i.groupId}>{i.groupName}</option>
      )
    }
    return (
      <>
        <DialogContent className={classes.contain}>
          <div className={classes.title}>
            <div className={classes.titlename}>{`Change Cart Set`}</div>
            <div className={classes.date}>{`${today}`}</div>
          </div>
          <div className={classes.main}>
            <div className={classes.mainPaddingTop}>Group Name</div>
            <Input
              className={classes.set}
              type="text"
              disableUnderline={true}
              disabled={true}
              placeholder={"Set1"}
              value={this.state.group}
              onChange={this.groupChange}
              classes={{ input: classes.textInput }}
            />
            <div className={classes.mainPaddingTop}>Group Change</div>
            <Select native value={this.state.group} disableUnderline={true} displayEmpty
              onChange={(e) => this.groupChange(e)}
              className={classes.select} >
              {options}
            </Select>



            <div className={classes.mainPaddingTop}>Title</div>
            <Input
              className={classes.set}
              type="text"
              disableUnderline={true}
              placeholder="Set1"
              value={this.state.title}
              onChange={this.titleChange}
              classes={{ input: classes.textInput }}
            />
            <div className={classes.mainPaddingTop}>Query</div>
            <div className={classes.query}>
              {query}
            </div>
            <div className={classes.mainPaddingTop}>Description</div>
            <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="논문 참고용."
              value={this.state.main}
              onChange={this.mainChange}
              multiline={true}
              rows={4}
              classes={{ input: classes.textInput }}
            />
            <div className={classes.mainPaddingTop} style={{ width: "600px" }}>

              <Button className={`${classes.button} ${classes.buttonpadding}`} type="button" onClick={close}>
                CANCEL
              </Button>
              <Button className={classes.button}
                type="button"
                disabled={title === '' || main === '' ? true : false}
                onClick={this.onClickEvent} >
                CHANGE CART
              </Button>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(changeCartSet);
