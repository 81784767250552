import React, { Component } from "react";
import { observer } from "mobx-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highMore from 'highcharts/highcharts-more';
import highDumbbell from 'highcharts/modules/dumbbell';
import highLollipop from 'highcharts/modules/lollipop';

import { lightBlue, deepPurple } from "@material-ui/core/colors";

// Gene Protein Chart (lollipop) 부 , added by jdy 2020-12

// init the module

highMore(Highcharts);
highDumbbell(Highcharts);
highLollipop(Highcharts);

const basicColorList = [
  "#929db0",
  "#e6e0d1",
  "#869594",
  "#c9ccbf",
  "#84bed3",
  "#dadada",
  "#8d9bab",
  "#bda0a0",
  "#d7d2b1",
  "#bfbfbf",
];

const cancerColorList = {
  "Colon/rectal": "#d7d2b1",
  "Breast": "#bfbfbf",
  "Other": "#929db0",
  "Stomach": "#e6e0d1",
  "Lung": "#869594",
  "Sarcoma": "#c9ccbf",
  "Bile duct": "#84bed3",
  "Ovarian": "#dadada",
  "Head and neck": "#8d9bab",
  "Pancreas": "#bda0a0",

  "Cervix": "#b1d0d7",
  "Endometrial": "#9e9d9d",
  "Liver": "#92b0b0",
  "Melanoma": "#d1d5e6",
  "Multiple primary": "#e7eacb",
  "Prostate": "#cbded9",
  "Renal": "#b3bc9f",
  "Urothelial": "#c6c5c5"
}


class LollipopChart extends Component {

  state = {
    fooKey: 1
  };


  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.proteinData !== nextProps.proteinData || 
      this.props.iActiveNum !== nextProps.iActiveNum
    // return this.props.TESTDATA !== nextProps.TESTDATA
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // modify by jdy 2020-12-05
    if (nextProps.proteinData && nextProps.proteinData.length) {
    //if (nextProps.proteinData !== prevState.proteinData) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
   
    const { iActiveNum, geneName, proteinData } = this.props;

    let name = [];
    let options;
    let titleName;

    let activeCategory = [];
    let activeSeries = [];

    // console.log('lollippop iActiveNum : ', iActiveNum);

    if (iActiveNum == 0) {
      activeCategory =  proteinData.categories;
      activeSeries = proteinData.allSeries;
    }
    else {
      activeCategory = proteinData.positionCategory[iActiveNum - 1];
      activeSeries = proteinData.series[iActiveNum - 1];
    }

    let delX = 0;

    if (activeSeries && activeSeries.length > 0) {
      if (activeSeries[0].data.length < 2)
      {
        delX = -180;
      }
      else if (activeSeries[0].data.length < 3)
      {
        delX = -120;
      }
      else if (activeSeries[0].data.length < 4)
      {
        delX = -80;
      }
      else if (activeSeries[0].data.length < 5)
      {
        delX = -30;
      }
      else if (activeSeries[0].data.length < 10)
      {
        delX = -15;
      }
      else if (activeSeries[0].data.length < 20)
      {
        delX = -10;
      }
      else
      {
        delX = 0;
      }
    }   

    titleName = `Gene (total : ${activeCategory.length} positions)`;

    options = {
      chart: {
        type: "lollipop",
        marginLeft: 95,
        marginTop: 30,
        height: 200
      },

      title: { text: null },

      subtitle: { text: titleName },

      credits: {
        enabled: false
      },
      
      tooltip: {
        //pointFormat: 'Subjects : {this.series.data.name} <b>{point.y}</b><br/>',
        formatter: function () {
          let additionalString = '';
          let index = this.point.index;
          let data = this.series.userOptions.data;
          // console.log('index :', index);
          additionalString = '<br> ' + data[index].name;
          
          return `Position : ${this.x}, Total : ${this.y} ${additionalString}`;
        }
      },
    
      
      xAxis: {
        categories: activeCategory,

        labels: {
          formatter: function () {
              return this.value;
          },
          align: "right",
          rotation: -45,
          x: delX
        }
    },
      
      yAxis: {
        title: {
            text: 'Subjects'
        }
      },
      accessibility: {
        point: {
            valueDescriptionFormat: '{index}. {xDescription}, {point.y}.'
        }
      },
  
      legend: {
        enable: true,
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        layout: 'horizontal',
    
        align: 'right',
        verticalAlign: 'top',
        floating: true,

        labelFormatter: function () {
          if (this.name.length > 15) {
            return `${this.name.slice(0, 12)}...`
          }
          else {
            return this.name
          }
        }
      },    

      plotOptions : {
        lollipop : {
           marker : {
             enabled : true,
             radius : 4
           }
        },
        series: {
          events: {
              legendItemClick: function(e) {
                  e.preventDefault();
              }
          }
        },
        line : {
          lineWidth : 2,
          color: "04FF00"
        }
      },


      series: activeSeries
    };

    return (
      <div>


        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          oneToOne={true}
          key={this.state.fooKey}
        />
      </div>

    );
  }

}
export default LollipopChart;
