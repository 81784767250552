class SnvTableModel {
  constructor(d, i) {
    let select = ""
    let cancerStr = ""
    let cancerStrOrg = ""
    let cancerStrRet = ""
    this.cancerList = [];
    let cancerObj = {};

    if (d.selectedTotalSubjectCnt !== d.totalSubjectCnt) {
      select = `/ ${d.selectedTotalSubjectCnt.toLocaleString()}`
    }
    this.id = d.id
    this.name = d.name
    this.hasDetails = d.hasDetails
    this.selectedCancer = d.selectedCancer
    // this.subjectTotal = `${d.subjectCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    this.subjectTotal = `${d.subjectCnt.toLocaleString()}`
    this.subjectCnt = d.subjectCnt
    this.sequenceAlteration = d.sequenceAlteration
    this.targetGene1Id = `${d.targetGene1Id.toLocaleString()}`
    this.chromosomeId = d.chromosomeId
    this.hgvsC = d.hgvsC
    this.hgvsP = d.hgvsP
    this.hgvsP1digit = `${d.hgvsP1digit}`
    this.checkTotal = select
    
    // added by jdy 2020-11
    // SNV Table의 Cancer column에 표시할 cancer list를 생성하기 위하여 cancerList 추가

    if (d.selectedCancer)
    {
      for (let j of d.selectedCancer)
      {
        cancerStrOrg = `${j.toLocaleString()}`

        cancerStrRet = cancerStrOrg.replace(" cancer", "").replace(' carcinoma', '').replace(' Cancer', '');

        if (cancerStrOrg.length > 2) {

          cancerStr += `"${cancerStrRet}"`;
          cancerStr += ',';

          this.cancerList.push(cancerStrOrg);

        }
        
      }
    }
    else
    {
        cancerStr += '-,';
    }

    this.selectedCancerLabel = cancerStr.slice(0,-1); // 현재는 TSV download에 사용된다. cancelList로 display , 
    // 2020-12-21 see CustomCellContent if (detailType && detailType.type == 'SNV' && property == 'selectedCancerLabel') {

    this.targetGVId = `${d.targetGene1Id.toLocaleString()},${d.hgvsP1digit.toLocaleString()}`;
  }
}

export default SnvTableModel;
