import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select, { components } from "react-select";
import Search from "@material-ui/icons/Search";
const styles = theme => ({
  title: {
    // width: "100%",
    height: "25px",
    border: "solid 1px #dddddd",
    backgroundColor: "#ffffff",
    display: "flex"
  },
  input: {
    height: "100%",
    // width: "90%",
    fontSize: "12px",
    textAlign: "center"
  },
  icon: {
    height: "20px"
  }
});
const cancerarray = ['cancerResults', 'detailCancerResults']
const genearray = ['geneResults']
const variantarray = ['sequenceAlteration', 'hgvsp1digitResults', 'cnvResults', 'svResult']

const test = {
  control: (base) => ({
    ...base,
    minHeight: '25px',
    height: '25px',
    // border: "0px"
  }),
  menuPortal: (base) => ({
    ...base
  }),
  // clearIndicator: (base) => ({
  //   ...base,
  //   paddingTop: 0,
  //   paddingBottom: 0,
  // }),
  option: (base) => ({
    ...base,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: '#2cbec1',
      color: "#fff",
      borderColor: "#fff"
    }
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "12px"

  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: "12px"
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: "12px"
  }),
  indicatorsContainer: (base) => ({
    ...base,
    width: '35px',
    height: "23px",
    // backgroundColor: "#f6f6f6"

  }),
  container: (base) => ({
    ...base
  }),
  indicatorSeparator: (base) => ({
    ...base,
    // backgroundColor: "#919191",
    // marginTop: "0px",
    // marginBottom: "0px",
    width: '0px'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0px',
    paddingLeft: '8px'
  }),
};

const formatOptionLabel = ({ value, label }) => (
  <div >
    <div style={{ color: "#ccc", paddingRight: "10px" }}>
      {label}
    </div>
    <div>{value}</div>
  </div>
);

const DropdownIndicator = props => {
  return (
    <div >
      <components.DropdownIndicator {...props} >
        <Search style={{ height: "16px" }} />
      </components.DropdownIndicator>
    </div>
  );
};

// const SingleValue = ({
//   cx,
//   getStyles,
//   selectProps,
//   data,
//   isDisabled,
//   className,
//   ...props
// }) => {
//   return (
//     <div style={{ width: "10vw" }}>{selectProps.getOptionValue(data)}</div>
//   );
// };

class SearchKeyword extends Component {
  state = {
    Patient: null,
  };

  componentDidMount() {
    this.onClickClear();
  }


  handleGeneInputChange = input => {
    const { getPatientAutoComplete } = this.props;
    if (input.length >= 1) {
      getPatientAutoComplete(input);
    }
  };

  handleGeneChange = Patient => {
    const { insertFilterKeyword, getPatientAutoComplete } = this.props
    this.setState({ Patient: null });
    insertFilterKeyword(Patient)
    // this.onClickClear()
    getPatientAutoComplete('');
  };

  onGeneMenuOpen = () => {
    this.setState({ Patient: null });
  };

  onClickClear = () => {
    this.setState({ Patient: null });
  };

  render() {
    const { Patient } = this.state;
    const { patientAutoCompleteList, tab } = this.props;
    let count = []
    let array
    let holder = ''
    switch (tab) {
      case 0:
        array = cancerarray
        holder = "ex. Colon/rectal cancer"
        break;
      case 1:
        array = genearray
        holder = "ex.TP53"
        break;
      case 2:
        array = variantarray
        holder = "ex.G12D"
        break;
      default:
        break;
    }
    for (let e of array) {
      if (patientAutoCompleteList) {
        let label = e.replace('Results', '')
        for (let k of patientAutoCompleteList[e]) {
          count.push({ value: k.name, label: label, name: k.id, parentCancer: k.parentCancer, detailCancers: k.detailCancers })

        }
      }
    }
    // array.map((e, i) => {
    //   if (patientAutoCompleteList) {
    //     let label = e.replace('Results', '')
    //     patientAutoCompleteList[e].map((k, j) => {
    //       count.push({ value: k.name, label: label, name: k.id, parentCancer: k.parentCancer, detailCancers: k.detailCancers })
    //     })

    //   }
    // })
    return (
      <div className="inner-content-container">
        <div className="cancer-summary-outline">
          <div className="cancer-summary-container">
            <div
              style={{
                width: "100%"
              }}
            >
              <Select
                value={Patient}
                onChange={this.handleGeneChange}
                onInputChange={this.handleGeneInputChange}
                onMenuOpen={this.onGeneMenuOpen}
                options={count}
                placeholder={holder}
                onMenuClose
                formatOptionLabel={formatOptionLabel}
                components={{ DropdownIndicator }}
                styles={test}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#fff',
                    primary: '#26a1a4',
                    neutral50: "#B4B4B4"
                  },
                })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SearchKeyword);
