import React from "react";

const CustomButton = ({
  type,
  onClick,
  children,
  useCustomStyle,
  value,
  style,
  selected,
  disabled,
  title
}) => {
  // const classes = useCustomStyle && useCustomStyle();

  return (
    <button
      type={type}
      onClick={onClick}
      // className={`${classes && classes.button} ${selected && classes.selected}`}
      value={value}
      style={style}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  );
};

export default CustomButton;
