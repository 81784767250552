import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const styles = theme => ({
  nousercontain: {
    // height: "650px",
    width: "750px",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "dashed 1px #d8d8d8",
    height: "20px",
    fontSize: "25px",
    padding: "20px 25px",
    fontWeight: "bold"
  },
  titlename: {
    float: "left"
  },
  main: {
    padding: "40px 50px 30px 50px"
  },
  mainPaddingTop: {
    paddingTop: "20px",
    paddingBottom: "4px",
    color: "#707070",
    fontSize: "13px",
    fontWeight: "bold"
  },
  set: {
    width: "100%",
    height: "48px",
    fontSize: "14px",
    border: "solid 1px #979797"
  },
  description: {
    width: "100%",
    height: "250px",
    fontSize: "14px",
    border: "solid 1px #979797"
  },

  button: {
    width: "130px",
    height: "30px",
    borderRadius: "0px",
    backgroundColor: "#ffffff",
    border: "solid 1px #979797",
    color: "#d3d3d3",
    fontSize: "10px",
    "&:hover": {
      backgroundColor: "#00c8a3",
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "8px"
  },
  textInput: {
    padding: "10px",
    height: "100%"
  },
  select: {
    width: "100%",
    border: "solid 1px #979797",
    height: "50px"
  },
  selectinput: {
    height: "35px",
    fontSize: "13px",
    paddingLeft: "10px"
  },
  warming: {
    paddingTop: "10px",
    fontSize: "10px",
    color: "#ff4e5f"
  },
  alert: {
    fontSize: "16px"
  },
  alertAherf: {
    fontSize: "16px",
    paddingLeft: "5px"
  }
});

const questionList = [
  { name: "Bug Report", id: "Bug Report" },
  { name: "Suggestions", id: "Suggestions" },
  { name: "Data error", id: "Data error" },
  { name: "Etc", id: "Etc" }
];


class TableContentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: "",
      title: "",
      main: ""
    }

  
    this.contentList = "";
    this.totalString = "";
  }



  listChange = event => {
    this.setState({
      list: event.target.value
    });
  };

  titleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  mainChange = event => {
    this.setState({
      main: event.target.value
    });
  };

  onClickEvent = () => {
    const {
      close
    } = this.props;
    const { list, title, main } = this.state;
    let message;
    // save file
    close();
  };

  onUpdateData = (data) => {

    const {type} = this.props;

    this.contentList = "";

    data.map((d, i) => {

      this.totalString = this.handleColumnList(d);

      this.contentList += this.totalString;
      // this.contentList += " \n ";    

      // if (type == 'Cancer' && d.hasDetails) {
      //   d.detail.map((detailItem, j) => {

      //     this.totalString = this.handleColumnList(detailItem);
    
      //     this.contentList += "\t";
      //     this.contentList += this.totalString;
      //   });
      // }

    });

    return this.contentList;
  } 

  handleColumnList = (d) => {
    const {columns} = this.props;
    this.totalString = "";

    let length = columns.length - 1;

    columns.map((c, i) => {
      const { property } = c;
      
      let addString = "";

     if (i == length) {
      addString = d[property] + "\n";
     } else {
      addString = d[property] + "\t";
     }

      this.totalString += addString;
    });

    return this.totalString;
  }


  render() {
    const { classes, close, type, columns, data} = this.props;
    const { list, title, main } = this.state;

    let tableList = "";
    let rowMax = 6;

    tableList = "test Cancer <br> html <br>";

    if (type == 'Cancer') {
      rowMax = 18;
    }
    else {
      rowMax = 6
    }
      
    for (let key in data)
    {
      if (key == 'content')
      {
        tableList = this.onUpdateData(data[key]);
      }
    }


    return (
      <>
        <DialogContent className={classes.nousercontain}>
          <div className={classes.title}>
            <div className={classes.titlename}>The {type} Table List</div>
          </div>
          <div className={classes.main}>
            <div style={{ display: "flex", paddingBottom: "15px" }}>
              {/* <div className={classes.alert}>The registered questions can be found in </div> <a href="/my-page" className={classes.alertAherf}>{" Q&A Board Page."}</a> */}
              <div className={classes.alert}>
                This is {type} Table List.
              </div>
            </div>

            <div className={classes.mainPaddingTop}>CONTENT</div>
            {/* <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="Enter a content."
              value={this.state.main}
              onChange={this.mainChange}
              multiline={true}
              rows={4}
              classes={{ input: classes.textInput }}
            /> */}
            <TextareaAutosize
              rowsMax={rowMax}
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue={tableList}
            />
            <div
              className={classes.mainPaddingTop}
              style={{ textAlign: "right" }}
            >
              <Button
                className={`${classes.button} ${classes.buttonpadding}`}
                type="button"
                disabled={false}
                onClick={close}
              >
                CANCEL
              </Button>
              <Button
                className={classes.button}
                type="button"
                disabled={
                  list === "" || title === "" || main === "" ? true : false
                }
                onClick={this.onClickEvent}
              >
                SAVE
              </Button>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(TableContentList);
