import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotFoundLogo from "assets/images/notFound.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "-webkit-fill-available"
  },
  h1: {
    fontSize: "5rem",
    margin: 0
  }
});

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <Link to="/">home</Link>
      <img src={NotFoundLogo} alt="" />
    </div>
  );
}

export default NotFound;
