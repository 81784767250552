import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CustomBtn from "components/atoms/CustomBtn";
// import Cart from "@material-ui/icons/ShoppingCart";
import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";

const styles = theme => ({
  nav: {
    paddingTop: "0px",
  },
  iconDiv: {
    float: "right",
    display: 'flex',
    margin: "5px 0px"
  },
  custom: {
    margin: "5px 3px 0px 3px"
  },
  menuitem: {
    borderRadius: "1px",
    backgroundColor: "#fcfcfc",
    boxShadow: "1px 1px 3px 0 rgba(121, 121, 121, 0.8)",
    border: "0px",
    color: "#3e3e3e",
    textTransform: "none",
    height: "38px",
    width: "4.25vw",
    padding: "0px 0vw 0vw 1.75vw",
    margin: "0px 3px",
    fontSize: '12px',
    "&:hover": {
      backgroundColor: "#efefef",
    }
  },
  list: {
    paddingTop: "0px",
  },
  size: {
    width: "110px",
    height: "25px"
  },
  listBorder: {
    borderTop: "solid 1px #c6c6c6 "

  }
});

class CartDownLoad extends Component {

  state = {
    open: false
  };

  // openList = () => {
  //   this.setState({
  //     open: !this.state.open
  //   });
  // }

  // closeList = () => {
  //   if (this.state.open === true) {
  //     this.setState({
  //       open: false
  //     });
  //   }
  // }

  render() {
    // const { open } = this.state
    const { classes, onClick } = this.props;
    // let data = []
    // if (downloadData && downloadData.content) {
    //   for (let dat of downloadData.content) {
    //     data.push(dat)
    //   }
    //   // downloadData.content.map((da, e) => {
    //   //   data.push(da)
    //   // });
    // }
    return (
      <div className={classes.nav}>
        <div className={classes.iconDiv}>
          <CustomBtn
            text={"Download"}
            img={VerticalAlignBottom}
            stylebtn={`${classes.custom} ${classes.size}`}
            onClick={onClick}
          // isDown={true}
          />
          {/* <ClickAwayListener onClickAway={this.closeList}>
            <div>

              <Popper
                open={open}
                placement={'bottom-start'}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <MenuList className={classes.list}>
                      <CSVLink data={data} headers={headers}
                        filename="PatientList.csv"
                        target="_blank">
                        <MenuItem className={`${classes.menuitem} ${classes.listBorder}`}>
                          CSV
                          <VerticalAlignBottom />
                        </MenuItem>
                      </CSVLink>
                      <CSVLink data={data} headers={headers}
                        filename="PatientList.tsv"
                        separator={"\t"}
                        target="_blank" >
                        <MenuItem className={classes.menuitem} >
                          TSV
                          <VerticalAlignBottom />
                        </MenuItem>
                      </CSVLink>
                    </MenuList>
                  </Fade>
                )}
              </Popper>
            </div>
          </ClickAwayListener> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CartDownLoad);
