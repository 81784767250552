import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import HelpTree from "./helpTree";
import HelpMenu from "./helpMenu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { helpList } from './helpList';

// import png2_3 from './images/2_3.png';



// 2020-03-30 add this file by jdy : for help menu

const styles = theme => ({
  container: {
    display: "flex"
  },
  helpContents: {
    zIndex: 1
  },
  helpTitle: {
    backgroundColor: "#97f797",
    color: '#2651a4',
    padding: '10px',
    fontSize: "15px",
    height: "26px"
  },
  nav: {
    padding: "15px 15px 0px 100px",
    width: '450px',
    // verticalAlign: 'top',
    // width: 'auto',
    // bottom: '10px',
    // left: '11vw',
    // overflowY: 'auto'
    "@media (max-width: 1500px)": {
      padding: "15px 15px 0px 0px",
      //   margin: 'auto'
    },
  },
});
// let patientData



export const menu = [
  {
    icon: <HomeOutlinedIcon />,
    title: "MAIN",
    to: "/2_3.png",
    htmlId: 0,
    items: []
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "1. DATA EXPLORER",
    to: "/2_4.png",
    htmlId: -1,
    items: [
      {
        title: "1.1 OVERVIEW",
        to: "/2_4.png",
        htmlId: 1
      },
      {
        title: "1.2 DATASET FILTER",
        to: "/2_4_1.png",
        htmlId: -1,
        items: [
          {
            title: "1.2.1 OVERVIEW",
            to: "/2_4_1.png",
            htmlId: 2
          },
          {
            title: "1.2.2 CANCER",
            to: "/2_4_1_1.png",
            htmlId: 3
          },
          {
            title: "1.2.3 GENE",
            to: "/2_4_1_2.png",
            htmlId: 4
          },
          {
            title: "1.2.4 VARIANT",
            to: "/2_4_1_3.png",
            htmlId: 5
          }
        ]
      },
      {
        title: "1.3 DATA STATISTICS",
        to: "/2_4_2.png",
        htmlId: -1,
        items: [
          {
            title: "1.3.1 OVERVIEW",
            to: "/2_4_2.png",
            htmlId: 6
          },
          {
            title: "1.3.2 DEMOGRAPHY",
            to: "/2_4_2_1.png",
            htmlId: 7
          },
          {
            title: "1.3.3 CANCER - Chart",
            to: "/2_4_2_2.png",
            htmlId: 8
          },
          {
            title: "1.3.4 CANCER – Primary Site",
            to: "/2_4_2_3.png",
            htmlId: 9
          },
          {
            title: "1.3.5 CANCER – Sample Type",
            to: "/2_4_2_4.png",
            htmlId: 10
          },
          {
            title: "1.3.6 GENE - Protein",
            to: "/2_4_3_1.png",
            htmlId: 11
          },
          {
            title: "1.3.7 GENE - Chart",
            to: "/2_4_3_2.png",
            htmlId: 12
          },
          {
            title: "1.3.8 GENE – Drug Actionable",
            to: "/2_4_3_3.png",
            htmlId: 13
          },
          {
            title: "1.3.9 VARIANT – Annotation DATA",
            to: "/2_4_4_1.png",
            htmlId: 14
          },
          {
            title: "1.3.10 VARIANT – Interpretation Criteria",
            to: "/2_4_4_2.png",
            htmlId: 15
          },
          {
            title: "1.3.11 SNV - Chart",
            to: "/2_4_4_3.png",
            htmlId: 16
          },
          {
            title: "1.3.12 CNV - Chart",
            to: "/2_4_4_4.png",
            htmlId: 17
          },
          {
            title: "1.3.13 SV - Chart",
            to: "/2_4_4_5.png",
            htmlId: 18
          }
        ]
      }
    ]
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "2. ANALYSIS",
    to: "/2_5.png",
            htmlId: -1,
    items: [
      {
        title: "2.1 SET OPERATIONS",
        to: "/2_5_1.png",
            htmlId: 19
      },
      {
        title: "2.2 SET OPERATIONS RESULTS",
        to: "/2_5_2.png",
        htmlId: -1,
        items: [
          {
            title: "2.3.1 OVERVIEW",
            to: "/2_5_2.png",
            htmlId: 20
          },
          {
            title: "2.3.2 VEN DIAGRAM",
            to: "/2_5_2_1.png",
            htmlId: 21
          },
          {
            title: "2.3.3 DATA STATISTICS",
            to: "/2_5_2_2.png",
            htmlId: -1,
            items: [
              {
                title: "2.3.3.1 OVERVIEW",
                to: "/2_5_2_2.png",
                htmlId: 22
              },
              {
                title: "2.3.3.2 DEMOGRAPHY",
                to: "/2_5_2_2_1.png",
                htmlId: 23
              },
              {
                title: "2.3.3.3 CANCER",
                to: "/2_5_2_2_2.png",
                htmlId: 24
              },
              {
                title: "2.3.3.4 GENE",
                to: "/2_5_2_2_3.png",
                htmlId: 25
              },
              {
                title: "2.3.3.5 SNV",
                to: "/2_5_2_2_4.png",
                htmlId: 26
              },
              {
                title: "2.3.3.6 CNV",
                to: "/2_5_2_2_5.png",
                htmlId: 27
              },
              {
                title: "2.3.3.7 SV",
                to: "/2_5_2_2_6.png",
                htmlId: 28
              }
            ]
          }
        ]
      }
    ]
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "3. ADMIN",
    to: "/2_6_1.png",
            htmlId: -1,
    items: [
      {
        title: "3.1 OVERVIEW",
        to: "/2_6_1.png",
            htmlId: 29
      },
      {
        title: "3.2 USER ACCOUNT",
        to: "/2_6_2.png",
            htmlId: 30
      },
      {
        title: "3.3 Q&A BOARD",
        to: "/2_6_3.png",
            htmlId: 31
      },
      {
        title: "3.4 Q&A BOARD Detail",
        to: "/2_6_4.png",
            htmlId: 32
      },
      {
        title: "3.4.1 Q&A BOARD Detail Answer",
        to: "/2_6_4_1.png",
            htmlId: 33
      },
      {
        title: "3.5 DATA SYNC",
        to: "/2_6_5.png",
            htmlId: 34
      }
    ]
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "4. SYSTEM",
    to: "/2_7_1.png",
            htmlId: -1,
    items: [
      {
        title: "4.1 OVERVIEW",
        to: "/2_7_1.png",
            htmlId: 35
      },
      {
        title: "4.2 SYSTEM STATISTICS",
        to: "/2_7_2.png",
        htmlId: -1,
        items: [
          {
            title: "4.2.1 OVERVIEW",
            to: "/2_7_2.png",
            htmlId: 36
          },
          {
            title: "4.2.2 COMPUTING RESOURCE STATISTICS",
            to: "/2_7_2_1.png",
            htmlId: 37
          },
          {
            title: "4.2.3 STORAGE RESOURCE STATISTICS",
            to: "/2_7_2_2.png",
            htmlId: 38
          },
          {
            title: "4.2.4 NETWORKING RESOURCE STATISTICS",
            to: "/2_7_2_3.png",
            htmlId: 39
          }
        ]
      }
    ]
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "5. USER CONTENTS",
    to: "/2_8_1.png",
            htmlId: -1,
    items: [
      {
        title: "5.1 DATASET LIST",
        to: "/2_8_1.png",
            htmlId: 40
      },
      {
        title: "5.2 BASIC INFORMATION",
        to: "/2_8_2.png",
            htmlId: 41
      },
      {
        title: "5.3 Q&A BOARD",
        to: "/2_8_3.png",
            htmlId: 42
      }
    ]
  }
  // {
  //   icon: <TrendingUpOutlinedIcon />,
  //   title: "Options",
  //   to: "/2-3_0.png",
            // htmlId: -1
  // },
  // {
  //   icon: <DescriptionOutlinedIcon />,
  //   title: "Blog",
  //   to: "/2-3_0.png",
            // htmlId: -1
  // }
];



class Help extends Component {

  constructor(props) {
    super(props);

    // for multi-threading and / or filterTab, presentTab 선택에 능동적으로 대처하기 위해 add by jdy (정대열) 2021-01
    // this.basename = process.env.PUBLIC_URL;
    this.urlHome = "help"; //this.basename + "/help";
    this.imageUrl = "help/2_3.png";// this.basename + "/help/2_3.png";
  }
  
  state = {
    srcImage:"help/2_3.png",
    err : "not found",
    imageUrl: "help/2_3.png",
    htmlId: 0,
    helpTitle: "MAIN"
  }

  componentDidMount = () => {
    this.loadImage();
  }

  loadImage = async () => {
    const { imageUrl } = this.state;
    try {
      const { default: srcImage } = await import(/* webpackMode: "lazy" */ `../../../public/${imageUrl}`);
      this.setState({ srcImage });
    } catch (err) {
      this.setState({ err: err.toString() });
    }
  }

  onClickMenu = async (url, htmlId, title) => {

    let imageUrl = this.urlHome + url;

    try {
      const { default: srcImage } = await import(/* webpackMode: "lazy" */ `../../../public/${imageUrl}`);
      this.setState({
        srcImage: srcImage,
        imageUrl: imageUrl,
        htmlId: htmlId,
        helpTitle: title
      });
    } 
    catch (err) {
      this.setState({ err: err.toString() });
    }

  }

  render() {

    const {srcImage, imageUrl, htmlId, helpTitle} = this.state;
    const {classes} = this.props;

    let listTable = [];
    let iSeq = 0;

    listTable.length = 0;

    if (htmlId != -1) {
      helpList.map(row => {
        if (row.id === htmlId) {
          iSeq ++;
          row.items.map(col => {
            listTable.push(
              <TableRow>
                <TableCell align="center" style={{minWidth:20}} >{iSeq++}</TableCell>
                <TableCell align="center" style={{minWidth:60}} >{col.conti}</TableCell>
                <TableCell style={{minWidth:300}} >{col.desc}</TableCell>
              </TableRow>
            );
          });
        }
      });
    }
    else
    {
      listTable = [];
      listTable.length = 0;
    }

    return (
      <div className={classes.container}>
        <div className={classes.nav}>
          <HelpMenu items={menu} onClickMenu={this.onClickMenu} />
        </div>
        
        <div style={{ width: 'calc(100% - 450px)', padding: "19px 50px 0px 10px"}}>                  
          <div className={classes.helpContents}>
            <br />
            <div align="center" className={classes.helpTitle} > {helpTitle} </div>
            <br />
            <img src={srcImage} alt="Not found ${imageUrl}" />
            {/* <Table>
              <TableHead style={{fontSize:"12px"}}>
                <TableRow>
                  <TableCell align="center" style={{minWidth:20, backgroundColor:"#00c8a3"}}>
                    인덱스</TableCell>
                  <TableCell align="center" style={{minWidth:60, backgroundColor:"#a001b1"}}>
                    항 목</TableCell>
                  <TableCell align="center" style={{ minWidth:300, backgroundColor:"#b0c803"}}>
                    설 명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listTable}
              </TableBody>
            </Table> */}
            <br />
            <table width='100%' border='0' cellspacing='2' cellpadding='2'>
              <tr align='center' bgcolor='#d0aab0'>
                <td width='10%'><font color='#ffffff' size='-1'>인덱스</font></td>
                <td width='20%'><font color='#ffffff' size='-1'>항 목</font></td>
                <td width='70%'><font color='#ffffff' size='-1'>설 명</font></td>
              </tr>

              {listTable}
            </table>
            <br />
          </div>
        </div>
      </div>
    );
  }
}


export default withStyles(styles)(Help);
