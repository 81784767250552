import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TestTable from "components/atoms/TestTable";
// import CartDownLoad from "components/atoms/CartDownLoad";
// import CustomDialog from "components/atoms/CustomDialog";
// import DetailCartSet from "components/atoms/CustomDialog/DetailCartSet";
// import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";
// import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import Button from "@material-ui/core/Button";

const styles = theme => ({
  chartMargin: {
    padding: "15px"
  },
  nav: {
    // paddingTop: "15px",
    // minHeight: '1px'
  },
  person: {
    lineHeight: 'normal',
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "14px"
  },
  openbutton: {
    minWidth: "0px",
    width: '25px',
    marginBottom: "8px",
    height: "17px",
    padding: "0px"
  },
  arrowIcon: {
    height: "16px"
  },
  cancerlsitmark: {
    lineHeight: 'normal',
    float: "right",
    paddingRight: "2px",
    fontSize: "8px"
  }
})

class DetailAnalysis extends Component {

  state = {
    selected: {},
    open: false
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.parentEl = React.createRef();

  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    // console.log(this.props.detailType.data, nextProps.detailType.data, this.props.detailType.data.toString() !== nextProps.detailType.data.toString())
    return JSON.stringify(this.props.detailType) !== JSON.stringify(nextProps.detailType) || this.state !== nextState || this.props.chartOpen !== nextProps.chartOpen
    // return true;
  }

  handlePagination = s => {
    const { handlePagination, detailType } = this.props
    handlePagination(s, detailType.title)

  };

  handleSort = ({ order, orderBy }) => {
    console.log(order, orderBy);
  };

  handleClickCheckbox = value => {
  };

  setSelect = selected => {
    this.setState({ selected });
    console.log(this.state.selected)
  };

  render() {
    const { detailType, classes, isNotBox, type } = this.props;


    if (isNotBox && detailType.data) {
      return (
        <div className={classes.chartMargin}>
          <div className={classes.nav}>
            <div className={classes.person}>{detailType.title}</div>
            <div className={classes.cancerlsitmark}>(#Subject) </div>
          </div>
          <TestTable
            ref={this.node}
            id={"alllist"}
            columns={detailType.columns}
            data={detailType.data}
            defaultSort={{
              order: "asc",
              orderBy: "geneName",
              handleSort: this.handleSort
            }}
            defaultPagination={{
              page: 0,
              rowsPerPage: 5,
              handlePatination: this.handlePagination
            }}
            headerIcon={true}

            {...this.props}
          />
        </div>
      )
    }
    if (type === 'Cancer' && detailType.data) {
      return (
        <div className={classes.chartMargin}>
          <div>
            {/* <CartDownLoad downloadData={{ content: detailType.data, totalElements: 1 }} /> */}
            <div className={classes.nav}>
              <div className={classes.person}>{detailType.title}</div>
              <div className={classes.cancerlsitmark}>(#Subject) </div>
            </div>
          </div>
          <div id='alllist' ref={this.parentEl}>
            <TestTable
              ref={this.node}
              parentEl={this.parentEl.current}
              id={"alllist"}
              columns={detailType.columns}
              data={{ content: detailType.data, totalElements: 1 }}
              // selectRow={{
              //   mode: "checkbox",
              //   // handleClickCheckbox: this.handleClickCheckbox,
              //   setSelect: this.setSelect,
              //   selected: this.state.selected
              // }}
              defaultSort={{
                order: "asc",
                orderBy: "geneName",
                handleSort: this.handleSort
              }}
              // headerIcon={<VerticalAlignBottom style={{ width: "30px", height: "20px", color: "#ababab" }} />}
              {...this.props}
            />
          </div>
        </div>
      )
    }
    if (detailType.data) {
      return (
        <div className={classes.chartMargin}>
          <div>
            {/* <CartDownLoad downloadData={detailType.data} /> */}
            <div className={classes.nav}>
              <div className={classes.person}>{detailType.title}</div>
              <div className={classes.cancerlsitmark}>(#Subject) </div>
            </div>
          </div>
          <TestTable
            ref={this.node}
            id={"alllist"}
            columns={detailType.columns}
            data={detailType.data}
            // selectRow={{
            //   mode: "checkbox",
            //   handleClickCheckbox: this.handleClickCheckbox,
            //   // setSelect: this.setSelect,
            //   // selected: this.state.selected
            // }}
            defaultSort={{
              order: "asc",
              orderBy: "geneName",
              handleSort: this.handleSort
            }}
            defaultPagination={{
              page: 0,
              rowsPerPage: 5,
              handlePatination: this.handlePagination
            }}
            // headerIcon={<VerticalAlignBottom style={{ width: "30px", height: "20px", color: "#ababab" }} />}
            {...this.props}
          />
        </div>
      )
    } else {
      return (
        <div className={classes.chartMargin}>
          <div>
            <div className={classes.nav}>
              <div className={classes.person}>{detailType.title}</div>
            </div>
          </div>
        </div>
      )

    }

  }
}


export default withStyles(styles)(DetailAnalysis);
