import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import VennDiagramChart from "components/atoms/VennDiagramChart";
// import Link from "@material-ui/core/Link";
// import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  querytable: {
    width: '100%',
    height: '25px',
    display: "flex",
    border: 'solid 1px #dedede',
    backgroundColor: ' #f3f3f3',
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 'bold'
  },
  setheader: {
    lineHeight: "2.5",
    width: "5%",
    borderRight: "solid 1px #dedede",
  },
  forheader: {
    lineHeight: "2.5",
    width: "85%",
    borderRight: "solid 1px #dedede",
  },
  setsubject: {
    lineHeight: "2.5",
    width: "10%",
  },
  querylistmain: {
    width: '100%',
    height: '25px',
    display: "flex",
    border: 'solid 1px #dedede',
    borderTop: "0px",
    backgroundColor: ' #ffffff',
    textAlign: "center",
    fontSize: "12px",
    color: "#3a3a3a"
  },
  set: {
    lineHeight: "2.5",
    width: "5%",
    borderRight: "solid 1px #dedede",
    // fontStyle: 'italic'
  },
  formula: {
    lineHeight: "2.5",
    width: "85%",
    borderRight: "solid 1px #dedede",
    fontStyle: 'italic'
  },
  subject: {
    lineHeight: "2.5",
    width: "10%",
    textAlign: "right",
    // marginRight: "10px"
  },
});
const TRIPLE = [{ name: ["S1", "S2", "S3"], insert: ["∪", "∪"], key: "unionAAndBAndC", id: "unionAAndBAndCSerial", set: "U" },
{ name: ["S1", "S2", "S3"], insert: ["∩", "∩"], key: 'intersectionABC', id: "intersectionABCSerial", set: "A" },
{ name: ["S1", "S2", "S3"], insert: ["∩", "-"], key: 'intersectionAAndB', id: "intersectionAAndBSerial", set: "B" },
{ name: ["S2", "S3", "S1"], insert: ["∩", "-"], key: "intersectionBAndC", id: "intersectionBAndCSerial", set: "C" },
{ name: ["S1", "S3", "S2"], insert: ["∩", "-"], key: "intersectionAAndC", id: "intersectionAAndCSerial", set: "D" },
{ name: ["S1", "S2", "S3"], insert: ["-", "∪"], key: 'differenceAAndBC', id: "differenceAAndBCSerial", set: "E" },
{ name: ["S2", "S1", "S3"], insert: ["-", "∪"], key: "differenceBAndAC", id: "differenceBAndACSerial", set: "F" },
{ name: ["S3", "S1", "S2"], insert: ["-", "∪"], key: "differenceCAndAB", id: "differenceCAndABSerial", set: "G" }
]
const DOUBLE = [{ name: ["S1", "S2"], insert: ["∪"], key: "unionAAndBAndC", id: "unionAAndBAndCSerial", set: "U" },
{ name: ["S1", "S2"], insert: ["∩"], key: "intersection", id: "intersectionSerial", set: "A" },
{ name: ["S1", "S2"], insert: ["-"], key: "differenceBAndA", id: "differenceBAndASerial", set: "B" },
{ name: ["S2", "S1"], insert: ["-"], key: "differenceAAndB", id: "differenceAAndBSerial", set: "C" }
]
class TopOperationSetList extends Component {

  // shouldComponentUpdate(nextProps) {
  //   // return false 하면 업데이트를 안함
  //   return this.props.isDouble !== nextProps.isDouble || this.props.select !== nextProps.select || this.props.operationSet !== nextProps.operationSet || this.props.setListData !== nextProps.setListData
  //   // return true;
  // }

  runSubject = (e) => {
    const { onClick } = this.props
    onClick(e)
  }

  render() {
    const { select, isDouble, setListData, operationSet, classes } = this.props;
    let SetName = ''
    let SetData = ''
    let SetNum = ''
    if (isDouble && setListData && operationSet) {
      for (let i of DOUBLE) {
        if (i.id === select && setListData[i.name[0]]) {
          SetName = i.set
          SetData = setListData[i.name[0]] && setListData[i.name[1]] ? `${i.name[0]}(${setListData[i.name[0]].title}) ${i.insert[0]} ${i.name[1]}(${setListData[i.name[1]].title})` : ""
          SetNum = operationSet[i.key] ? operationSet[i.key].toLocaleString() : 0
        }
      }
    } else if (setListData && operationSet) {
      for (let i of TRIPLE) {
        if (i.id === select && setListData[i.name[0]]) {
          SetName = i.set
          if (i.set === "E" || i.set === "F" || i.set === "G") {
            SetData = setListData[i.name[0]] && setListData[i.name[1]] && setListData[i.name[2]] ?
              `${i.name[0]}(${setListData[i.name[0]].title}) ${i.insert[0]} ( ${i.name[1]}(${setListData[i.name[1]].title}) ${i.insert[1]} ${i.name[2]}(${setListData[i.name[2]].title}) )`
              : ""
          } else {
            SetData = setListData[i.name[0]] && setListData[i.name[1]] && setListData[i.name[2]] ?
              `${i.name[0]}(${setListData[i.name[0]].title}) ${i.insert[0]} ${i.name[1]}(${setListData[i.name[1]].title}) ${i.insert[1]} ${i.name[2]}(${setListData[i.name[2]].title})`
              : ""
          }
          SetNum = operationSet[i.key] ? operationSet[i.key].toLocaleString() : 0
        }
      }
    }
    return (
      <>
        <div className={classes.querytable}>
          <div className={classes.setheader}>Set</div>
          <div className={classes.forheader}>Selected Formula</div>
          <div className={classes.setsubject}>Subjects</div>
        </div>
        <div className={classes.querylistmain}>
          <div className={classes.set}>{SetName}</div>
          <div className={classes.formula}>{SetData}</div>
          <div className={classes.subject}>{SetNum}</div>

        </div>
      </>
    );
  }
}

export default withStyles(styles)(TopOperationSetList);
