import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// import Cached from "@material-ui/icons/Cached";
import Cart from "assets/images/dataexplorer/cartAdd.svg";
import cartAddWhite from "assets/images/dataexplorer/cartAddWhite.svg";
import CustomBtn from "components/atoms/CustomBtn";
// import CustomDialog from "components/atoms/CustomDialog";
// import DetailCartSet from "components/atoms/CustomDialog/DetailCartSet";

// 각 항목에 따라 색상 지정 , by jdy 2020-12

const styles = theme => ({
  box: {
    height: "26px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#26a1a4",
    display: "inline",
    fontSize: "12px",
    color: "#ffffff !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  detailbox: {
    height: "26px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#7d8686",
    display: "inline",
    fontSize: "12px",
    color: "#ffffff !important",
    margin: "0px 1px",
    padding: "0px 2px"
  },
  margin: {
    margin: "2px 5px",
    lineHeight: "2.5",
    fontSize: "12px"
  },
  custom: {
    width: '100px',
    height: "25px",
    margin: '0px 2px',
    color: "#ffffff",
    // float: "right",
    backgroundColor: "#26a1a4",
    "&:hover": {
      backgroundColor: "#26a1a4"
    }
  },
  disabledCustom: {
    width: '100px',
    height: "25px",
    // float: "right",
    margin: '0px 2px',
  },
  seperatorSymbol: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#030303',
    padding: "3px"
  },
  mainlist: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#f3f3f3',
    padding: "3px"
  },
  sublist: {
    fontSize: '12px',
    color: '#757575',
    fontWeight: 'normal',
    padding: "3px"
  },
  oricon: {
    // fontFamily: 'AppleSDGothicNeo',
    fontWeight: 'normal',
    color: '#757575'
  },
  cancerbox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#2c82be",
    display: "inline",
    fontSize: "12px",
    textColor: "#fafbfc",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  genebox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#af8019",
    display: "inline",
    fontSize: "12px",
    color: "#ffff00 !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  snvcnvbox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#5F904F",
    display: "inline",
    fontSize: "12px",
    color: "#ff00ff !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  variantbox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#896e77",
    display: "inline",
    fontSize: "12px",
    color: "#ff00ff !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  reportedbox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#c6c6c6",
    display: "inline",
    fontSize: "12px",
    color: "#ff00ff !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  samplebox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#c64626",
    display: "inline",
    fontSize: "12px",
    color: "#ff00ff !important",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  },
  otherbox: {
    height: "36px",
    // width: "10%",
    borderRadius: "1px",
    backgroundColor: "#263656",
    display: "inline",
    fontSize: "12px",
    fontColor: "#ffffff",
    margin: "0px 1px ",
    padding: "0px 5px",
    textTransform: 'none'
  }
});

const mainList = ['gene', 'snv', 'cnv', 'sv']

class QueryList extends Component {
  render() {
    const { seq, name, classes, detail } = this.props;
    if (detail) {
      return (
        <span className={classes.sublist}>{name}</span>
      );
    } 
    else {
      if (seq === 'cancer') {
        return (
          <div className={classes.cancerbox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else if (seq === 'gene') {
        return (
          <div className={classes.genebox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else if (seq === 'snv' || seq === 'cnv' || seq === 'sv') {
        return (
          <div className={classes.snvcnvbox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else if (seq === 'variant') {
        return (
          <div className={classes.variantbox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else if (seq === 'reported') {
        return (
          <div className={classes.reportedbox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else if (seq === 'sample' || seq === 'primary') {
        return (
          <div className={classes.samplebox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }
      else {
        return (
          <div className={classes.otherbox}>
            <span className={classes.mainlist}>
              {name.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(' cancer', '').replace(' carcinoma', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.')}</span>
          </div>
        );
      }

    }
  }
}

class AppliedFilter extends Component {

  // by jdy 2020-12
  // shouldComponentUpdate(nextProps) {
  //   // return false 하면 업데이트를 안함
  //   return this.props.dxAge !== nextProps.dxAge
  //   // return true;
  // }

  clickOpen = (e) => {
    const { posQuery } = this.props
    posQuery(e)
  }
  render() { 
    const { classes, mainFilterObject, cancerTypeData, originUser } = this.props;
    let keyWord = [];
    //Cancer 탭에 cancer 선택시 
    if (mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer)) {
      let check = 0
      for (let a of cancerTypeData) {
        if (mainFilterObject.detailcancer[a.name]) {
          if (check !== 0) {
            keyWord.push(
              <span key={`${check}`} className={classes.seperatorSymbol}>or</span>
            );
          }
          if (mainFilterObject.detailcancer[a.name].length >= a.details.length) {
            keyWord.push(
              <span key={`${a.name}`}>
                <QueryList seq={'cancer'} name={a.name} classes={classes} />
                <span className={classes.oricon}>( ALL )</span>
              </span>
            );
          }
          else {
            keyWord.push(
              <span key={`${a.name}`}>
                <QueryList seq={'cancer'} name={a.name} classes={classes} />
                <span className={classes.oricon}>(</span>
              </span>
            );
            for (let j in mainFilterObject.detailcancer[a.name]) {
              let d = mainFilterObject.detailcancer[a.name][j]
              keyWord.push(
                <QueryList seq={'cancer'} name={d.name} classes={classes} key={d.id} detail={true} />
              );
              if (mainFilterObject.detailcancer[a.name].length - 1 > j) {
                keyWord.push(
                  <span key={`${d.name}${d.id}`} className={classes.seperatorSymbol}>or</span>
                );
              }
            }
            keyWord.push(
              <span className={classes.oricon} key={a.variant}>)</span>
            );
          }
          check += 1
        }
      }
    }

    //Gene 필터 체크시 실행
    let variantFlag = false; // jdy 2020-12-30
    let geneFlag = false;

    for (let i of mainList) {
      if (mainFilterObject[i] && mainFilterObject[i].length) {
        let cancerT = JSON.stringify(mainFilterObject.detailcancer);
        
        if (i === 'gene') {
          if (mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer) && cancerT != "{}") {
            keyWord.push(
              <span key={i} className={classes.seperatorSymbol}>and</span>
            );
          }

          geneFlag = true;
        }
        else if (variantFlag === false && (i === 'snv' || i === 'cnv' || i === 'sv'))
        {
          if ((mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer) && cancerT != "{}") || geneFlag === true) {
            keyWord.push(
              <span key={i} className={classes.seperatorSymbol}>and</span>
            );
            variantFlag = true;
          }

        }

        for (let j in mainFilterObject[i]) {
          // console.log("J " + j);
          // console.log("mainFilterObject[i].length  " + mainFilterObject[i].length );
          if (mainFilterObject[i].length  === 1) {
            keyWord.push(
              <QueryList key={`${j}${mainFilterObject[i][j]}`} seq={i} name={`${mainFilterObject[i][j]}`} classes={classes} />
            );
          }
          else{
            if(j == 0){
              keyWord.push(
                <QueryList key={`${j}${mainFilterObject[i][j]}`} seq={i} name={`(${mainFilterObject[i][j]}`} classes={classes} />
              );
            }else{
              if(j == mainFilterObject[i].length - 1){
                keyWord.push(
                  <QueryList key={`${j}${mainFilterObject[i][j]}`} seq={i} name={`${mainFilterObject[i][j]})`} classes={classes} />
                );
              }else{
                keyWord.push(
                  <QueryList key={`${j}${mainFilterObject[i][j]}`} seq={i} name={`${mainFilterObject[i][j]}`} classes={classes} />
                );
              }          
            }
          }
          
          if (mainFilterObject[i].length - 1 > j) {
            keyWord.push(
              <span key={`${mainFilterObject[i][j]}${j}`} className={classes.seperatorSymbol}>or</span>
            );
          }
        }
        if (i === 'snv' && mainFilterObject['cnv'] && mainFilterObject['cnv'].length) {
          keyWord.push(
            <span key={`snvcnv`} className={classes.seperatorSymbol}>or</span>
          );
        } else if (i === 'snv' && mainFilterObject['sv'] && mainFilterObject['sv'].length) {
          keyWord.push(
            <span key={`snvsv`} className={classes.seperatorSymbol}>or</span>
          );
        }
        if (i === 'cnv' && mainFilterObject['sv'] && mainFilterObject['sv'].length) {
          keyWord.push(
            <span key={`svcnv`} className={classes.seperatorSymbol}>or</span>
          );
        }
      }
    }
    
    // by jdy 2020-11-09 Variant에 Variant Type
    if (mainFilterObject.variantType) {
      for (let i in Object.keys(mainFilterObject.variantType)) {
        let c = Object.keys(mainFilterObject.variantType)[i]
        keyWord.push(
          <span key={`${c}${i}`}>
            <QueryList seq={'variant'} name={c} classes={classes} />
            <span className={classes.oricon}>(</span>
          </span>
        );
        for (let j in mainFilterObject.variantType[c]) {
          let d = mainFilterObject.variantType[c][j]
          keyWord.push(
            <QueryList seq={'variant'} name={d} classes={classes} key={`${c}${j}${d}`} detail={true} />
          );
          if (mainFilterObject.variantType[c].length - 1 > j) {
            keyWord.push(
              <span key={`${d}${c}${j}`} className={classes.seperatorSymbol}>or</span>
            );
          }
        }
        keyWord.push(
          <span className={classes.oricon} key={c}>)</span>
        );
        if (Object.keys(mainFilterObject.variantType).length - 1 > i) {
          keyWord.push(
            <span key={`${i}${c}`} className={classes.seperatorSymbol}>or</span>
          );
        }
      }
    }
    
    if (mainFilterObject.chr) {
      keyWord.push(
        <span key={`chr`} className={classes.mainlist}>
          Chromosome : chr{mainFilterObject.chr.replace('chr ', '').replace('chr', '')} / {mainFilterObject.start_position} ~ {mainFilterObject.end_position}
        </span>
      );
    }
    let cardDisable = true
    if (originUser && keyWord.length !== 0) {
      cardDisable = false
    }
    return (
      <div className={classes.margin}>
        <CustomBtn
          text={"DataSet"}
          img={cardDisable ? Cart : cartAddWhite}
          isSVG={true}
          disabled={cardDisable}
          stylebtn={cardDisable ? classes.disabledCustom : classes.custom}
          onClick={this.clickOpen}
        />
        {/* {' Query :'} */}
        {/* <CustomBtn
          text={"Clear"}
          // img={Cached}
          stylebtn={classes.custom}
          onClick={this.clickReset}
        /> */}


        {mainFilterObject.reportedFilter ? (
          <QueryList seq={'reported'} name={mainFilterObject.reportedFilter} classes={classes} />
        ) : null}
        {mainFilterObject.reportedFilter && mainFilterObject.gender ? <span className={classes.seperatorSymbol}>or</span> : null}

        {mainFilterObject.sample ? (
          <QueryList seq={'sample'} name={`sample :${mainFilterObject.sample}`} classes={classes} />
        ) : null}

        {mainFilterObject.primary ? (
          <QueryList seq={'primary'} name={`primary :${mainFilterObject.primary}`} classes={classes} />
        ) : null}

        {mainFilterObject.gender ? (
          <QueryList seq={'gender'} name={`Gender :${mainFilterObject.gender}`} classes={classes} />
        ) : null}
        {/* bug fix by jdy 2021-01 : */}
        {/* {mainFilterObject.startAge && mainFilterObject.gender ? <span className={classes.seperatorSymbol}>or</span> : null} */}

        {mainFilterObject.startAge ? (
          <QueryList
            seq={'age'} 
            name={`Age :${mainFilterObject.startAge}/${mainFilterObject.endAge}`}
            classes={classes}
          />
        ) : null}
        {keyWord}

      </div>
    );
  }
}

export default withStyles(styles)(AppliedFilter);

