import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);
// const MaleColorList = [
//   "#236898",
//   "#2775ab",
//   "#2c82be",
//   "#428ec4",
//   "#569bcb",
//   "#6ba7d1",
//   "#80b4d8",
//   "#95c0de",
//   "#aacde5",
//   "#bfd9e6",
// ]
// const FeMaleColorList = [
//   "#cc645f",
//   "#e5716b",
//   "#ff7e77",
//   "#ff8a84",
//   "#ff9792",
//   "#ffa49f",
//   "#ffb1ad",
//   "#ffbebb",
//   "#ffcbc8",
//   "#ffd8d6",
// ]
// const gendercategories = [
//   "0-9",
//   "10-19",
//   "20-29",
//   "30-39",
//   "40-49",
//   "50-59",
//   "60-69",
//   "70-79",
//   "80-89",
//   "90<"
// ];
const categories = ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100<"];

class AgeGenderChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.list !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { list } = this.props;

    let options;

  

    options = {
      chart: {
        // marginTop: 75,
        backgroundColor: "#00ff0000",
        type: "column",
        height: 200
      },
      title: {
        text: null
      },
      subtitle: {
        text: "Diagnosis Age",
        y: 15
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          gridLineDashStyle: "longdash",
          gridLineWidth: 1,
          categories: categories,
          tickmarkPlacement: "on",
          reversed: false,
          title: {
            text: "(Age)",
            align: "low",
            rotation: 0,
            offset: 0,
            y: 10,
            x: -30,
            style: {
              fontSize: "10px",
              fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
              color: "#434343"
            }
          }
        }
      ],
      yAxis: {
        gridLineDashStyle: "longdash",
        gridLineWidth: 0.5,
        // softMax: 1000,
        tickAmount: 6,
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          }
        },
        title: {
          text: "(Patient)",
          align: "high",
          rotation: 0,
          offset: 0,
          y: -20,
          x: -5,
          style: {
            fontSize: "10px",
            fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
            color: "#434343"
          }
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        // series: {
        //   stacking: "normal"
        // },
        column: {
          pointPlacement: "between",
          stacking: "normal",
          grouping: false,
        }
      },
      series: list ? list.series : null
    };


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default AgeGenderChart;
