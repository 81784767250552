import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

// Gene Protein Chart의 Gene type 및 Position 선택 부 , added by jdy 2020-12

const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: 30
  },
  container: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    fontSize : 15,
    //backgroundColor : "#aabbaa"
  },
  selectEmpty: {
    marginTop: 0, //theme.spacing(1),
    fontSize : "14px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold"
  },
  buttonSelect: {
    //background: "#5f06a0",
    marginTop: 10,
    textAlign: 'center',
    border: "1px solid silver",
    boderRadius: '5px',
    cursor: "pointer",
    height: '25px',
    width: 80,
    color: '#0f000f',
    backgroundColor: '#afc6c0',
    //padding: '8px 20px'
  },
  button: {
    //background: "#f0f0f0",
    marginTop: 10,
    textAlign: 'center',
    border: "1px solid silver",
    boderRadius: '5px',
    cursor: "pointer",
    height: '25px',
    width: 80,
    color: '#f5ff5f6',
    backgroundColor: '#f0f0f0',
    //padding: '8px 20px'
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 0px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  paper: {
    marginTop: 10,
    height: '15px',
    width: 80,
    border:'none'
  },
  paperSelect : {
    height: '15px',
    width: 80,
    border:'none'

  },
  control: {
    padding: theme.spacing(2),
  },
});



class ChooseChartRange extends Component {

  state = {
    gene : '',
    spacing : 2
  }

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.proteinData !== nextProps.proteinData || 
      this.props.geneName !== nextProps.geneName ||
      this.props.iActiveNum !== nextProps.iActiveNum ||
      this.props.selectedGeneList !== nextProps.selectedGeneList
  }

  handlePageChange = (event) => {
    const { onHandlePageChoice } = this.props;
    onHandlePageChoice(Number(event.target.value));
  };

  handleGeneChange = (event) => {
    const { onHandleGeneChoice } = this.props;

    onHandleGeneChoice(event.target.value);
  };

  proteinHelp = () => {
    const { onProteinHelp } = this.props;
    
    onProteinHelp();
  };

  render() 
  {
    const {gene, spacing} = this.state;

    const { iActiveNum, geneName, proteinData, selectedGeneList, classes } = this.props;

    let cartstyle;

    let numPages = proteinData.numPages;

    let iG = 0;
    let allLabel;
    let title;

    let resultButton = [];

    let menuItem = [];
  
    menuItem.length = 0;

    if (selectedGeneList.nNames > 0) {
      menuItem.push(
        <MenuItem key={"-1"} value=""><em>{geneName}</em></MenuItem>
      );    
      for (let i=0; i<selectedGeneList.nNames; i++)
      {
        if (selectedGeneList.geneNames[i] != geneName) {

          menuItem.push(
            <MenuItem key={i.toString()} value={selectedGeneList.geneNames[i]}>{selectedGeneList.geneNames[i]}</MenuItem>
          );
        }

      }
    }
    else {
      menuItem.push(
        <MenuItem key={"-1"} value=""><em>{geneName}</em></MenuItem>
      );    
    }


    resultButton.length = 0;


    // allLabel = `All: ${proteinData.length}s`;
    allLabel = "All";

    title = 'Position : ';


    if (iActiveNum == 0) 
    {
      cartstyle = classes.buttonSelect;
    }
    else
    {
      cartstyle = classes.button;
    }
    resultButton.push(
      <span>
        <button onClick={this.handlePageChange} className={cartstyle} value={0}> {allLabel} </button>
      </span>  
    );



    for (let i=0; i<numPages; i++) 
    {
      let j;
      j = i + 1;
      if (iActiveNum == (i + 1)) 
      {
        cartstyle = classes.buttonSelect;
      }
      else
      {
        cartstyle = classes.button;
      }
      resultButton.push(
        <span>
          <button onClick={this.handlePageChange} className={cartstyle} value={j}> {proteinData.choiceText[i]} </button>
        </span>  
      );
    }
    
    return (
      <div>
         <div className={classes.container}>

      
          <span>
        
         
            <FormControl className={classes.formControl}>
              <Select
                  value={gene}
                  onChange={this.handleGeneChange}
                  displayEmpty
                  className={classes.selectEmpty}
              >
                {menuItem}
              </Select>

            </FormControl>
            
          </span>

          <span >
           <div className={classes.paper}> 
            {title}
           </div>
          </span>
          {resultButton}

          <span >
            <div style={{margin:'1px 0px 2px 2px'}}>
                    <IconButton color="primary" className={classes.helpIconButton} onClick={this.proteinHelp}>
                      <HelpIcon className={classes.helpIcon} />
                    </IconButton>
            </div>
          </span>
        
        </div> 
      </div>
    );

  }
  
}


export default withStyles(styles)(ChooseChartRange);
