import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);

// Cancer presentTab의 Primary Site Chart, added by jdy 2020-12

const basicColorList = [
  "#ff7e77",  
  "#2c82be",
  "#7F505F",
  "#5FB04F"
];

class PrimarySiteChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.list !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { list, isAge } = this.props;
    let primary = 0
    let meta = 0
    let total = 10;
    let desc0 = "";
    let desc1 = "";
    let portion = [];

    if (list) {
      primary = list.primary;
      meta = list.meta;
      total = primary + meta;

      if (total == 0) {
        total = 10;
      }

      portion[0] = Math.ceil(10000 * primary / total) / 100;
      portion[1] = Math.floor(10000 * meta / total) / 100;

      desc0 = portion[0] + "%";
      desc1 = portion[1] + "%";
    }
    // if (list && !isAge) {
    //   for (let i in AgeList) {
    //     male += list[AgeList[i]].male;
    //     female += list[AgeList[i]].female;
    //   }
    // }
    let options;
    let Sum = [
      {
        name: "Primary",
        y: primary,
        description: desc0,
        color: basicColorList[0]
      },
      {
        name: "Metastasis",
        y: meta,
        description: desc1,
        color: basicColorList[1]
      },
    ]
    

    options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "pie",
        height: 200,
      },
      title: {
        text: null
      },
      subtitle: {
        text: "Primary / Metastasis",
        y: 15,
        zIndex: 10,
      },
      credits: {
        enabled: false
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        layout: 'vertical',
    
        align: 'right',
        verticalAlign: 'center',
        floating: true,
        labelFormatter: function () {
          if (this.name.length > 17) {
            return `${this.name.slice(0, 5)}...`
          }
          else {
            return this.name
          }
        }
      },
      // tooltip: {
      //   formatter: function () {
      //     return (
      //       "<b><span >" +
      //       this.point.description +
      //       "</span></b>"
      //     )
      //   }
      // },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 10,
            style: {
              fontSize: "10px"
            },
            formatter: function () {
              return (
                "<b><span >" +
                this.point.name +
                "</span></b><br/><span>" +
                "Subject: " +
                this.y.toLocaleString() + "</span>"
              )
            }
          },
          showInLegend: true
        }
      },
      series: [{ name: 'Share', data: Sum }],
      // drilldown: {
      //   series: [{
      //     name: "Male",
      //     id: "Male",
      //     // innerSize: '20%',
      //     data: Male
      //   }, {
      //     name: "Female",
      //     id: "Female",
      //     // innerSize: '20%',
      //     data: FeMale
      //   }]
      // }
    };


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default PrimarySiteChart;
