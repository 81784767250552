import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge.js";

import { orange, red } from "@material-ui/core/colors";

HighchartsMore(Highcharts);
solidGauge(Highcharts);

let options = {
  chart: {
    type: "solidgauge",
    height: 123
    // width: 200
  },
  title: {
    text: null
  },
  credits: {
    enabled: false
  },
}
class Gauge extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.data !== nextProps.data
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && nextProps.data.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }



  render() {
    const { data } = this.props;
    let color = "#26a1a4"
    if (data) {
      if (data.usage > 70 || data.percentage > 70) {
        color = red[400]
      } else if (data.usage > 40 || data.percentage > 40) {
        color = orange[400]

      }

      options = {
        chart: {
          type: "solidgauge",
          height: 123,
          // width: 200
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false,
          reversed: true
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [
            {
              // Track for Move
              outerRadius: "112%",
              innerRadius: "70%",
              borderWidth: 0
            }
          ]
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              borderWidth: 0,
              y: -10,
              color: color,
              format: '<span style="font-size:1.5em;stroke-width:0px;">{point.y}%</span>'
            },
            stickyTracking: false
            // rounded: true
          }
        },
        series: [
          {
            name: data.type,
            data: [
              {
                color: color,
                radius: "112%",
                innerRadius: "70%",
                y: data.usage || data.percentage
              }
            ]
          }
        ]
      };
    }


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      key={this.state.fooKey}
    />
  }
}
export default Gauge;
