import { axios } from "dependencyLibrary";

class SystemRepository {
  URL = "/api/kmaster";

  systemDashboard = (type, current) => {
    return axios.get(`${this.URL}/system-dashboard/${type}${current}`);
  };

  systemTool = (type, filter) => {
    return axios.get(`${this.URL}/systems${type}${filter}`);
  };

  getAccounts = filter => {
    return axios.get(`${this.URL}/accounts${filter}`);
  };
    
  // 2021.09.17 add getToken by jdy : for api-token 
  getToken = data => {
    return axios.post(`${this.URL}/get_token`, data);
  };

  changePasswordUser = data => {
    return axios.post(`${this.URL}/accounts/me/change_password`, data);
  };

  systemStatistics = (type, statistics, filter) => {
    return axios.get(
      `${this.URL}/system-statistics${type}${statistics}${filter}`
    );
  };

  getUsersCount = () => {
    return axios.get(`${this.URL}/users/counts`);
  };

  getAdminUsers = queryParams => {
    return axios.get(`${this.URL}/users/keyword${queryParams}`);
  };

  deleteuser = queryParams => {
    return axios.delete(`${this.URL}/users${queryParams}`);
  };

  changeUser = query => {
    return axios.put(`${this.URL}/users`, query);
  };

  getNotification = query => {
    return axios.get(`${this.URL}/notifications${query}`);
  };

  putNotification = query => {
    return axios.put(`${this.URL}/notifications`, query);
  };

  postQA = data => {
    return axios.post(`${this.URL}/qaboard`, data);
  };

  postComment = query => {
    return axios.post(`${this.URL}/qaboard/comment`, query);
  };

  putComment = query => {
    return axios.put(`${this.URL}/qaboard/comment`, query);
  };

  getQaboard = (url, query) => {
    return axios.get(`${this.URL}/qaboard${url}${query}`);
  };

  getUserAccessLog = (type, query) => {
    return axios.get(`${this.URL}/${type}${query}`);
  };

  fetchSyncLog = query => {
    return axios.get(`${this.URL}/updateHistory${query}`);
  };

  fetchSyncUpdateLog = query => {
    return axios.get(`${this.URL}/updateHistory/portal${query}`);
  };

  updateCdm = data => {
    return axios.put(`${this.URL}/cdm/update`, data);
  };

  fetchApiKey = type => {
    return axios.get(`${this.URL}/key-store/${type}`);
  };

  modifyApiKey = ({ type, id, payload }) => {
    return axios.put(`${this.URL}/key-store/${type}/${id}`, payload);
  };
}
export default new SystemRepository();
