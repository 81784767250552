import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { withStyles } from "@material-ui/core/styles";
// import { teal } from "@material-ui/core/colors";

const styles = theme => ({
  td: {
    fontSize: "1.1rem",
    border: "1px solid #c0bebe",
    color: "#6a6a6a",
    padding: "10px 10px",
    wordBreak: "break-all"
  },
  rtd: {
    padding: "0px",
    border: "1px solid #c0bebe",
    fontSize: "1.1rem",
    "&:last-child": {
      paddingRight: "0px"
    }
  },
  rtc: {
    fontSize: "1.1rem",
    color: "#6a6a6a",
    padding: "10px 10px"
  },
  table: {
    "& tr:nth-last-child(1)": {
      "& td": {
        borderBottom: "0px"
      }
    }
  },
  selected: {
    fontSize: "1.1rem",
    border: "1px solid #c0bebe",
    color: "#fff",
    padding: "10px 10px",
    backgroundColor: '#26a1a4',
    wordBreak: "break-all"
  },
  srtc: {
    fontSize: "1.1rem",
    color: "#fff",
    backgroundColor: '#26a1a4',
    padding: "10px 10px"
  }
});

class DefaultCell extends Component {
  render() {
    const {
      data,
      column: { key, align, scroll },
      selected
    } = this.props;

    const value = data[key];

    return scroll ? (
      <TableCell align={align} className={selected}>
        <Element name={value}>{value || key}</Element>
      </TableCell>
    ) : (
        <TableCell align={align} className={selected}>
          <>{typeof value !== "string" ? String(value || key) : value || key}</>
          {/* {value || key} */}
        </TableCell>
      );
  }
}

class NestedTableCell extends Component {
  render() {
    const {
      data,
      column: { key, align },
      classes,
      selected
    } = this.props;

    const value = data[key];

    return (
      <TableCell className={classes.rtd}>
        <Table className={classes.table}>
          <TableBody className={classes.table}>
            {value.map((v, i) => (
              <TableRow key={i}>
                <TableCell align={align} className={selected}>
                  {v || key}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableCell>
    );
  }
}

class LinkCell extends Component {
  render() {
    const {
      data,
      column: { key, align },
      classes
    } = this.props;

    const value = data[key];

    return (
      <TableCell align={align} className={classes.td}>
        <Link
          to={{
            pathname: "/patientDetail",
            search: "?sID=" + value
          }}
        >
          {value || key}
        </Link>
      </TableCell>
    );
  }
}

class MultiCell extends Component {
  render() {
    const {
      data,
      column: { key, link },
      isRender,
      classes
    } = this.props;
    const selected = isRender ? classes.selected : classes.td;
    const scrollSelected = isRender ? classes.srtc : classes.rtc;
    return !Array.isArray(data[key]) ? (
      link ? (
        <LinkCell {...this.props} selected={selected} />
      ) : (
          <DefaultCell {...this.props} selected={selected} />
        )
    ) : (
        <NestedTableCell {...this.props} selected={scrollSelected} />
      );
  }
}

export default withStyles(styles)(MultiCell);
