import React, { Component } from "react";
// import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
// import DataPageTable from "components/organisms/DataPageTable";
// import PageTemplate from "pages/template/pageTemplate";
// import LeftMenu from "components/organisms/LeftMenu";
// import queryString from "query-string";
import AnalysisRouter from "shared/AnalysisRouter";
// import { observer, inject } from "mobx-react";
import PageTemplate from "pages/template/pageTemplate";

const styles = theme => ({
  backcolor: {
    backgroundColor: "#c7c7c7",
    padding: "22px 11vw 145px 11vw",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif'
  },
  mainback: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff"
  },
  display: {
    // width: "68vw",
    height: "100%",
    // margin: "auto",
    // paddingTop: "50px"
  }
});


// @inject("analysis")
// @observer
class Analysis extends Component {
  // componentDidMount() {
  //   const { analysis } = this.props
  //   analysis.getOncoPrint()
  // }
  render() {
    const { classes, match } = this.props;
    return (
      <PageTemplate
        content={
          <div className={classes.display}>
            <AnalysisRouter match={match} />
          </div>
        } />
    );
  }
}
export default withStyles(styles)(Analysis);
