import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import CartTabHeader from "components/atoms/CartTabHeader";
import PageTemplate from "pages/template/pageTemplate";
import OperationBoard from "components/organisms/OperationBoard";
import CustomDialog from "components/atoms/CustomDialog";
import isDelete from "components/atoms/CustomDialog/isDelete";
import changeCartSet from "components/atoms/CustomDialog/ChangeCartSet";
import GoLogin from "components/atoms/CustomDialog/GoLogin";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

let localStorage = window.localStorage;

const OperationColumns = [
  { column: "Title", property: "title", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "Query", property: "setsQuery", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Description", property: "description", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 500 } } },
  { column: "Subject", property: "setsPersonNum", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 100 } } },
];

const styles = theme => ({
  container: {
    display: 'flex'
  },
  maintitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#4a4a4a",
    display: "flex"
  },
  smalltitle: {
    paddingTop: "8px",
    fontSize: "15px",
    textAlign: "center",
    color: "#4a4a4a",
    paddingBottom: "50px",
    display: "flex"
  },
  display: {
    padding: "50px 100px",
    borderTop: '1px solid #c6c6c6',
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  tableborder: {
    background: "#f6f6f6",
    border: "solid 1px #c6c6c6",
    padding: "15px",
  },
  maintable: {
    minHeight: "550px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    borderTop: "0px"
  },
  chartPadding: {
    padding: "5px 20px"
  },
  tabhead: {
    height: "50px",
    backgroundColor: "#ffffff",
    border: 'solid 1px #c6c6c6',
    borderBottom: "0px",
    paddingTop: "14px"
  }
});

let Data = []
let tabList = []
let NouserSetData = []

@inject("comm", 'analysis')
@observer
class CartPage extends Component {
  state = {
    tab: 0,
    deleteOpen: false,
    modifyopen: false,
    target: undefined,
    isAll: false,
    isChange: false,
    open: false,
    helpOpen: false,
    imageUrl: "help/2_8_1.png",
    htmlId: 40
  };

  async componentDidMount() {
    const { analysis, comm: { originUser } } = this.props;
    analysis.resetCartSet()
    if (originUser) {
      analysis.getSetListData("");
    } else {
      this.setState({
        open: true
      })
    }
  }

  componentDidUpdate() {
    const { analysis, analysis: { manageSet }, comm: { originUser } } = this.props;
    if (originUser && !manageSet) {
      analysis.getSetListData("");
    }
    if (originUser && this.state.open) {
      this.setState({
        open: false
      })
    }
  }

  // 20310409 add help function : clickMainHelp, onClose by jdy
  clickMainHelp = (id) => {
    const { tab } = this.state;

    let url;
    let htmlId;

    if (0 == id) { // Overview
      url = "/2_8_1.png";
      htmlId = 40;
    }
    else {
      url = "/2_8_1.png";
      htmlId = 40;
    }

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  onClickOpen = () => {
    this.setState({
      open: true
    })
  }

  onDeleteClose = () => {
    this.setState({
      deleteOpen: false,
      modifyopen: false
    });
  }

  onChangeTab = value => {
    this.setState({
      tab: value,
      isChange: false
    });
  };

  onChangeName = () => {
    this.setState({
      isChange: true
    });
  }
  onSubmitGroupName = async (id, name) => {
    const { analysis } = this.props
    if (name === "") {
      alert("You need to write.")
    } else {
      await analysis.getChangeGroupName(id, name)
      await analysis.getSetListData("");
      this.onChangeTab(0)
    }
  }

  deleteSetlist = (value, isAll) => {
    this.setState({
      deleteOpen: true,
      target: value,
      isAll: isAll
    });
  }

  modify = async (value) => {
    const { analysis } = this.props
    await analysis.getModifyData(`/${value}`)
    this.setState({
      modifyopen: true
    });
  }

  deleteItem = async () => {
    const { comm: { originUser }, analysis } = this.props;
    const { target, isAll } = this.state
    let name = ''
    if (originUser) {
      if (isAll) {
        name = `groups/${target}`
      } else {
        name = `${target}`
      }
      let message = await analysis.deleteSetData(name)
      if (message) {
        alert(`${message.data}\n`);
      }

      analysis.getSetListData("");
    } else {
      if (isAll) {
        localStorage.clear();
        alert('Complete Deleting Group and related Sets data')
      } else {
        localStorage.removeItem(target);
        alert('Completed Deletion')
      }
    }
    this.onDeleteClose()
    this.setState({
      tab: 0
    });
  }

  render() {
    const { classes, comm: { originUser }, analysis: { manageSet, modifyData } } = this.props;
    const { tab, isChange } = this.state;
    if (!originUser) {
      NouserSetData = []
      tabList = [{ name: 'Set List', value: 0 }]
      for (let i of Object.entries(localStorage)) {
        let j = i[0].split('||')
        NouserSetData.push({
          id: i[0],
          title: j[0] ? j[0] : '',
          description: j[1] ? j[1] : '',
          setsQuery: j[2] ? j[2] : '',
          setsPersonNum: j[3] ? Number.parseInt(j[3]) : 0
        })
      }
    } else {
      tabList = []
      if (manageSet && manageSet[tab]) {
        for (let i of manageSet) {
          tabList.push({ name: i.groupName, id: i.groupId })
        }
        Data = []
        for (let i of manageSet[tab].setsDataList) {
          Data.push({
            groupId: manageSet[tab].groupId,
            id: i.setDataId,
            title: i.title,
            description: i.description,
            setsQuery: i.setsQuery,
            setsPersonNum: i.setsPersonNum
          })
        }
      }
    }

    let detailType = { title: '', columns: OperationColumns, data: originUser ? Data : NouserSetData }
    return (
      <>
        <PageTemplate
          content={
            <div className={classes.display}>
              <div className={classes.container}> 
                <div className={classes.maintitle}>Saved Set List</div>

                <div style={{margin:'0px 0px 2px 2px'}}>
                  <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(0);}}>
                    <HelpIcon className={classes.helpIcon} />
                  </IconButton>
                </div>

                <CustomDialog
                  open={this.state.helpOpen}
                  onClickOpen={this.onClose}
                  imageUrl={this.state.imageUrl}
                  htmlId={this.state.htmlId}
                  CustomDialogContent={HelpContentList}
                />
              </div>

              <div className={classes.smalltitle}>
                Set List is your save interest data kind of cancertype, detail, gene,
                 variant and you can manage set operation from this page
            </div>
              <div className={classes.table}>
                <div className={classes.tableborder}>
                  <div className={classes.tabhead}>
                    <CartTabHeader
                      tabList={tabList}
                      value={tab}
                      isChange={isChange}
                      setListData={Data}
                      onChangeTab={this.onChangeTab}
                      onChangeName={this.onChangeName}
                      deleteTab={this.deleteSetlist}
                      onSubmitGroupName={this.onSubmitGroupName}
                      originUser={originUser}
                    />
                  </div>
                  <div className={classes.maintable} >
                    <div className={classes.chartPadding}>
                      <OperationBoard detailType={detailType} detailtab={tab} deleteSetlist={this.deleteSetlist}
                        modify={this.modify} setListData={Data[tab]} originUser={originUser} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }
        />
        <CustomDialog
          open={this.state.deleteOpen}
          onClickOpen={this.onDeleteClose}
          CustomDialogContent={isDelete}
          delete={this.deleteItem}
        />
        <CustomDialog
          open={this.state.modifyopen}
          onClickOpen={this.onDeleteClose}
          CustomDialogContent={changeCartSet}
          modifyData={modifyData}
          manageSet={manageSet}
          originUser={originUser}
        />
        <CustomDialog
          open={this.state.open}
          onClickOpen={this.onClickOpen}
          type={'Alert'}
          CustomDialogContent={GoLogin}
        />
      </>
    );
  }
}

export default withStyles(styles)(CartPage);
