import React from "react";
import { Router } from "react-router-dom";
import { history } from "dependencyLibrary";
import { Provider } from "mobx-react";
import Store from "modules/store/Store";
import App from "shared/App";

const root = new Store();

const Root = () => {
  return (
    <Provider {...root}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};

export default Root;
