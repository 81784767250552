import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);

// Cancer presentTab의 Sample Type Chart, added by jdy 2020-12

const basicColorList = [
  "#ff7e77",  
  "#2c82be",
  "#7F505F",
  "#5FB04F"
];

class SampleTypeChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.list !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { list, isAge } = this.props;
    let blood = 0;
    let tissue = 0;
    let total = 10;
    let tissueDesc = "";
    let bloodDesc = "";
    let descrip = [];
    
    let portion = [];
    if (list) {
 
      tissue = list.tissue;
      blood = list.blood;
      total = tissue + blood;

      if (total == 0) {
        total = 10;
      }

      portion[0] = Math.ceil(10000* tissue / total) / 100;
      portion[1] = Math.floor(10000 * blood / total) / 100;

      tissueDesc = portion[0] + "%";
      bloodDesc = portion[1] + "%";

      // descrip[0] = portion[0].toString + "%";
      // descrip[1] = portion[1].toString + "%";

    }
    // blood = list.blood;
    // tissue = list.tissue;
    let options;
    // if (list && !isAge) {
    //   for (let i in AgeList) {
    //     male += list[AgeList[i]].male;
    //     female += list[AgeList[i]].female;
    //   }
    // }
    // let Sum = [
    //   {
    //     name: "Tissue",
    //     data: [['Tissue', tissue]],
    //     color: basicColorList[0]
    //   },
    //   {
    //     name: "Blood",
    //     data: [['Blood', blood]],
    //     color: basicColorList[1]
    //   },
    // ]
    let Sum = [
      {
        name: "Tissue",
        y: tissue,
        description: tissueDesc,
        color: basicColorList[0]
      },
      {
        name: "Blood",
        y: blood,
        description: bloodDesc,
        color: basicColorList[1]
      },
    ]
    options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "pie",
        height: 200,
      },
      title: {
        text: null
      },
      subtitle: {
        text: "Tissue / Blood",
        y: 15,
        zIndex: 10,
      },
      credits: {
        enabled: false
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        layout: 'vertical',
    
        align: 'right',
        verticalAlign: 'center',
        floating: true,
        labelFormatter: function () {
          if (this.name.length > 17) {
            return `${this.name.slice(0, 5)}...`
          }
          else {
            return this.name
          }
        }
      },

      // tooltip: {
      //   formatter: function () {
      //     return (
      //       "<b><span >" +
      //       this.point.description +
      //       "</span></b>"
      //     )
      //   }
      // },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 10,
            style: {
              fontSize: "10px"
            },
            formatter: function () {
              return (
                "<b><span >" +
                this.point.name +
                "</span></b><br/><span>" +
                "Subject: " +
                this.y.toLocaleString() + "</span>"
              )
            }
          },
          showInLegend: true
        }
      },
      series: [{ name: 'Share', data: Sum }],
      // drilldown: {
      //   series: [{
      //     name: "Male",
      //     id: "Male",
      //     // innerSize: '20%',
      //     data: Male
      //   }, {
      //     name: "Female",
      //     id: "Female",
      //     // innerSize: '20%',
      //     data: FeMale
      //   }]
      // }
    };


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default SampleTypeChart;
