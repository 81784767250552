class NetWorkModel {
  constructor(d) {

    this.pck = {
      name: ["rxpck", "txpck"],
      value: [
        {
          name: "rxpck",
          data: [d.rxpck, 0],
          color: "#26a1a4"
        },
        {
          name: "txpck",
          data: [0, d.txpck],
          color: "#26a1a4"
        }
      ]
    }

    this.cmp = {
      name: ["rxcmp", "txcmp"],
      value: [
        {
          name: "rxcmp",
          data: [d.rxcmp, 0],
          color: "#26a1a4"
        },
        {
          name: "txcmp",
          data: [0, d.txcmp],
          color: "#26a1a4"
        }
      ]
    }

    this.rxmcst = {
      name: ["rxmcst"],
      value: [
        {
          name: "rxmcst",
          data: [d.rxmcst],
          color: "#26a1a4"
        }
      ]
    }

    this.kB = {
      name: ["rxkB", "txkB"],
      value: [
        {
          name: "rxkB",
          data: [d.rxkB, 0],
          color: "#26a1a4"
        },
        {
          name: "txkB",
          data: [0, d.txkB],
          color: "#26a1a4"
        },
      ]
    }
  }


}

export default NetWorkModel;
