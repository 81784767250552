import {
  axios
} from 'dependencyLibrary';

class LoginRepository {
  URL = "/api/kmaster";
  USER_URL = `${this.URL}/accounts`;

  requestLogin = data => {
    return axios.post(`${this.URL}/authenticate`, data);
  };

  loadUser = () => {
    return axios.get(`${this.USER_URL}`);
  };

  // rigisterUser = data => {
  //   return axios.post(`${this.USER_URL}`, data);
  // };
  rigisterUser = data => {
    return axios.post(`${this.USER_URL}/register`, data);
  };

  activateUser = data => {
    return axios.get(`${this.URL}/activate${data}`);
  };

  updateUser = data => {
    return axios.put(`${this.USER_URL}/me`, data);
  };

  // setAuthInHeader = token => {
  //   axios.defaults.headers.common["Authorization"] = token
  //     ? `Bearer ${token}`
  //     : null;
  // };

  postSendEmail = data => {
    return axios.post(`${this.USER_URL}/reset_password/init`, data);
  }

  postChangePassword = data => {
    return axios.post(`${this.USER_URL}/reset_password/finish`, data);
  }
}

export default new LoginRepository();