import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { AnalysisMain, Operation, OncoGrid, OncoPrint } from "pages/analysis";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

class AnalysisdRouter extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          {/* <Route path={`${match.url}/operation/0`} component={Operation} /> */}
          <PrivateRoute path={`${match.url}/operation/:id`} component={Operation} />
          <Route path={`${match.url}/oncogrid`} component={OncoGrid} />
          <Route path={`${match.url}/oncoprint`} component={OncoPrint} />
          <Route path={`${match.url}`} component={AnalysisMain} />
        </Switch>
      </>
    );
  }
}

export default AnalysisdRouter;
