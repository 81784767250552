import React, { Component } from "react";
import { Link } from "react-router-dom";
import "pages/signin/signin.scss";
// import * as FontAwesome from "react-icons/lib/fa";
import { inject } from "mobx-react";
import CustomDialog from "components/atoms/CustomDialog";
import CustomDialogContent from "components/atoms/CustomDialog/CustomDialogContent";
import CustomBtn from "components/atoms/CustomBtn";
import Home from "@material-ui/icons/Home";
import BorderColor from "@material-ui/icons/BorderColor";
import Search from "@material-ui/icons/Search";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"

const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
const checkedIcon = <img src={CheckBoxIcon} alt="" />

@inject("auth", "comm")
class Signin extends Component {
  state = {
    login: "", //admin
    password: "",  //Kmaster1004!!
    rememberMe: false,
    open: false
  };

  componentDidMount() {
    this.props.comm.setCurPage("Signin");
  }

  changeInput = e => {
    if (e.target.type === "text") {
      this.setState({
        login: e.target.value
      });
    } else if (e.target.type === "password") {
      this.setState({
        password: e.target.value
      });
    } else if (e.target.type === "checkbox") {
      this.setState({
        rememberMe: !this.state.rememberMe
      });
    }
  };

  onSubmit = async e => {
    const { auth, history } = this.props;
    let message
    e.preventDefault();

    // 2021.09.27 add 1 line by jdy : jwt_token
    auth.setLoginInfo(this.state);

    message = await auth.login(this.state);
    if (message) {
      history.push("/");
    }
  };

  onClickOpen = e => {
    e.preventDefault();
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { login, password } = this.state;
    return (
      <div className="backcolor-sign">
        <div className="row display-table">
          <div className="container display-table-cell">
            <div className="signin">
              {/* <div> */}
              <div className="logintitle">
                <p className="logintitle-bold">LOGIN</p>
                <p className="logintitle-small">
                  Please use it after joining the membership
                </p>
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="loginRow">
                  <div>
                    <input
                      type="text"
                      className="input-top"
                      placeholder="Enter Your E-mail"
                      onChange={this.changeInput}
                      value={login}
                    />
                    {/*[(ngModel)]="credential.login">*/}
                    <input
                      name="password"
                      type="password"
                      className="input"
                      placeholder="Enter Your Password"
                      onChange={this.changeInput}
                      value={password}
                    />
                  </div>
                  <button
                    type="submit"
                    className="logintext"
                    onClick={this.onSubmit}
                    disabled={login.length <= 0 || password.length <= 0}
                  >
                    Login
                  </button>
                </div>
                {/*[(ngModel)]="credential.password"
     (keyup.enter)="signin()" autocomplete="off">*/}
                <div className="checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        type="checkbox"
                        checked={this.state.rememberMe}
                        onChange={this.changeInput}
                        icon={icon}
                        checkedIcon={checkedIcon}
                      />
                    }
                    label="Stay logged in"
                  />
                  {/* <button
                  className="forgot-password"
                  // role="button"
                  onClick={this.onClickOpen}
                  type="button"
                >
                  <FontAwesome.FaArrowRight /> Forgot password?
                </button> */}
                </div>
              </form>

              <div className="addbtn">
                {/* <Link
                  to="/registration"
                  className="btn btn-default common-btn-default no-outline"
                >
                  REGISTRATION
                </Link> */}
                <CustomBtn
                  text={"Find Password"}
                  img={Search}
                  onClick={this.onClickOpen}
                  stylebtn={"btnsize"}
                />
                <Link to="/registration" className="deco">
                  <CustomBtn
                    text={"Join"}
                    img={BorderColor}
                    stylebtn={"btnsize"}
                  />
                </Link>
                <Link to="/" className="deco">
                  <CustomBtn
                    text={"Home"}
                    img={Home}
                    stylebtn={"btnsize-last"}
                  />
                </Link>
              </div>
            </div>
          </div>
          <CustomDialog
            open={this.state.open}
            onClickOpen={this.onClickOpen}
            CustomDialogContent={CustomDialogContent}
          />
          {/* <div className="activate" style={{ display: "none" }}> */}
          {/* *ngSwitchCase="'ACTIVATE'">*/}
          {/* <p>인증이 완료되었습니다.</p> */}
          {/* <Link to="/signin"> */}
          {/* <FontAwesome.FaArrowRight /> Go to Login account */}
          {/* </Link> */}
          {/*<a routerLink="/signin" routerLinkActive="active" (click)="currentPageReset()"><i className="fas fa-arrow-right"></i> Go to Login</a>*/}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default Signin;
