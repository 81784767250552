import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import GaugeTable from "components/organisms/GaugeTable";
import PersonIcon from '@material-ui/icons/Person';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';


const gaugeList = ['cpu', 'memory', 'filesystem', 'network/total', 'network/rxtx']

const styles = theme => ({
  displayFlex: {
    margin: "0px 5px",
    display: "flex"
  },
  test: {
    width: "25%",
    margin: "10px 5px",
  },
  test2: {
    width: "40%",
    margin: "10px 5px",
  }
});


@inject("system", "comm")
@observer
class OverView extends Component {
  constructor(props) {
    super(props);
    this.state = { number: 1 };
  }

  componentDidMount() {
    const { system } = this.props
    for (let name of gaugeList) {
      system.getCurrentUsage(name)
    }
    this.interval = setInterval(() => {
      this.setState({ number: this.state.number + 1 });
      if (this.state.number > 5) {
        for (let name of gaugeList) {
          system.getCurrentUsage(name)
        }
        this.setState({ number: 0 });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      comm: { user },
      system: {
        currentUsageCpu,
        currentUsageFile,
        currentUsageMemory,
        currentUsageNetwork,
        currentUsagerxtx
      },
      classes
    } = this.props;
    return (
      <>
        {user && (
          <>
            <div className={classes.displayFlex}>
              <div style={{ width: "60%", display: "flex" }}>
                <div className={classes.test}>
                  <GaugeTable name={'cpu'} currentUsage={currentUsageCpu} isChart={true} Icon={<PersonIcon style={{ color: "#d4d2d2" }} />} />
                </div>
                <div className={classes.test}>
                  <GaugeTable name={'memory'} currentUsage={currentUsageMemory} isChart={true} Icon={<SettingsApplicationsIcon style={{ color: "#d4d2d2" }} />} />
                </div>
                <div className={classes.test}>
                  <GaugeTable name={'filesystem'} currentUsage={currentUsageFile} isChart={true} Icon={<PersonIcon style={{ color: "#d4d2d2" }} />} />
                </div>
                <div className={classes.test}>
                  <GaugeTable name={'network'} currentUsage={currentUsageNetwork} Icon={<SettingsApplicationsIcon style={{ color: "#d4d2d2" }} />} />
                </div>
              </div>
              <div className={classes.test2}><GaugeTable name={"Transaction"} currentUsage={currentUsagerxtx} isBar={true} Icon={<SettingsApplicationsIcon style={{ color: "#d4d2d2" }} />} /></div>




            </div>
          </>
        )}
      </>
    );
  }
}
export default withStyles(styles)(OverView);
