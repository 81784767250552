import React, { Component } from "react";
import Footer from "components/organisms/Footer";
import PresentHeader from "./PresentHeader";
import Router from "./Router";
import "styles/style.scss";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

@inject("comm", "auth", "home")
@observer
class App extends Component {
  async componentDidMount() {
    const { comm, auth, home } = this.props;
    home.getLastUpdateDate();
    const id_token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (!!id_token) {
      await auth.setAuthInHeader(id_token);
      comm.setLoggedIn(!!id_token);
      auth.loadUser();
    }
  }

  render() {
    const {
      location: { pathname },
      home: { lastUpdateDate },
      // comm: { originUser }
    } = this.props; // from jdy : @inject 하면 props로 들어온다

    return (
      <div style={{ minWidth: "1280px" }}>
        {pathname === '/data-explorer' ? null : <PresentHeader />}
        <Router />
        {pathname === '/data-explorer' || pathname === '/analysis/operation' ? null :
          <Footer lastUpdateDate={lastUpdateDate} />
        }
        {/* <Footer /> */}
      </div>
    );

  }
}

export default withRouter(App);
