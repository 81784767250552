import { observable, action, configure } from "mobx";
import SearchRepository from "../repository/SearchRepository";
import TopFiveVariantModel from "modules/model/TopFiveVariantModel";

configure({ enforceAction: "true" });
class SearchStore {
  @observable.shallow searchConditionList = [];
  @observable.shallow leftNavigationAutoCompleteGeneData = [];
  @observable.shallow leftNavigationAutoCompleteVariantData = [];
  @observable.shallow leftNavigationGeneQuickSearch = [];
  @observable.shallow leftNavigationVariantQuickSearch = [];
  @observable.shallow topFiveGene = [];
  @observable.shallow topFiveVariant = [];

  constructor(root) {
    this.root = root;
  }

  @action
  async loadSearchCondition() {
    const { data } = await SearchRepository.getSearchCondition();
    this.searchConditionList = data.filter(
      d => d.fieldId === "observation.value_as_concept_id"
    );
  }

  @action
  async loadSearchConditionAutoComplete(query) {
    const {
      data: [{ series }, { variantRankedList }]
    } = await SearchRepository.getSearchConditionAutoComplete(query);
    this.leftNavigationAutoCompleteGeneData = series.map(s => {
      return { name: s };
    });
    this.leftNavigationAutoCompleteVariantData = variantRankedList.map(v => {
      const { gene, hgvsp1digit } = v;
      return { name: `${gene}, ${hgvsp1digit}` };
    });
  }

  @action
  async loadGeneRankedData(query) {
    const { data } = await SearchRepository.getGeneRankedData(query);
    this.topFiveGene = data;
    this.leftNavigationGeneQuickSearch = data.splice(0, 15);
  }

  @action
  async loadVariantRankedData(query) {
    const { data } = await SearchRepository.getVariantRankedData(query);
    this.topFiveVariant = data.map((d, i) => new TopFiveVariantModel(d, i));
    this.leftNavigationVariantQuickSearch = data.splice(0, 15);
  }

  // @action
  // async getSubFilterData(dat) {
  //   const { data } = await SearchRepository.return(dat);
  //   this.getSubFilterData = data;
  // }



}

export default SearchStore;
