import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import {
  Home,
  Registration,
  Signin,
  MyPage,
  DataPage,
  // DetailViewPage,
  // DataExplorerPage,
  // AllListPage,
  SystemPage,
  AdminPage,
  Cart,
  ChangePassword,
  InternalServerError,
  NotFound
} from "pages"; 

import { Analysis } from "pages/analysis"; 

// // 2020-03-30 add 1 line by jdy : for help menu
import Help from "pages/help/help"; 


function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

class Router extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/data-explorer" component={DataPage} />
        <Route path="/analysis" component={Analysis} />
        {/* // // 2020-03-30 add 1 line by jdy : for help menu */}
        <Route path="/help" component={Help} />
        <Route path="/cart" component={Cart} />
        <PrivateRoute path="/my-page" component={MyPage} />
        <PrivateRoute path="/system" component={SystemPage} />
        <PrivateRoute path="/admin" component={AdminPage} />
        <Route path="/signin" component={Signin} />
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/registration" component={Registration} />
        <Route path="/500" component={InternalServerError} />
        <Route component={NotFound} />
        {/* <Route path="/home" component={Home} />
        <Route path="/detailed-view" component={DetailViewPage} />
        <Route path="/data-explorer-search" component={DataExplorerPage} />
        <Route path="/all-list" component={AllListPage} /> */}
      </Switch>
    );
  }
}

export default Router;
