import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import SystemStaticTable from "components/organisms/SystemStaticTable";

@inject("system")
@observer
class HardwareResoureceStatistics extends Component {

  async componentDidMount() {
    const { system } = this.props;
    system.getHardwareResource("memory-cpu", "usage", "day");
    system.getHardwareResource("network", "rxtxbytes", "day");
    system.getHardwareResource("filesystem", "usage", "day");
    system.getCurrentUsage('cpu')
    system.getCurrentUsage('filesystem')

  }

  onchangeDate = (name, usage, value) => {
    const { system } = this.props;
    system.getHardwareResource(name, usage, value);
  };

  render() {
    const { system: {
      currentUsageCpu,
      currentUsageFile,
    } } = this.props

    return (
      <>
        <SystemStaticTable name={"memory-cpu"} gauge={"cpu"} usage={"usage"} onchangeDate={this.onchangeDate} data={currentUsageCpu} />
        <SystemStaticTable name={"network"} usage={"rxtxbytes"} onchangeDate={this.onchangeDate} />
        <SystemStaticTable name={"filesystem"} gauge={"filesystem"} usage={"usage"} onchangeDate={this.onchangeDate} data={currentUsageFile} />
      </>
    );
  }
}

export default HardwareResoureceStatistics;
