import { action, observable, configure } from "mobx";
import SystemRepository from "../repository/SystemRepository";
import LineModel from "modules/model/LineModel";
import UserModel from "modules/model/UserModel";
import QAListModel from "modules/model/QAListModel";
import AccessLogModel from "modules/model/AccessLogModel";
import NetworkModel from "modules/model/NetworkModel";
import queryString from "query-string";
import DateFormatModel from "../model/DateFormatModel";
import SyncLogModel from "../model/SyncLogModel";
import TableFormatModel from "../model/TableFormatModel";

const defaultSearch = {
  size: 5,
  page: 0
};

configure({ enforceAction: "true" });
class SystemStore {
  @observable.shallow visitorNumber = {};
  @observable.shallow accountList = [];
  @observable.shallow accessLogs = [];
  @observable.shallow hardwareResource = observable.map();
  @observable.shallow getAdminUsers = {};
  @observable.shallow currentUsageCpu = {};
  @observable.shallow currentUsageFile = {};
  @observable.shallow currentUsageMemory = {};
  @observable.shallow currentUsageNetwork = {};
  @observable currentUsagerxtx;
  @observable.shallow notificationsList = {};

  @observable.shallow qaList = {};
  @observable QACount = 0;
  @observable visitorsCount = 0;
  @observable usersCount = 0;
  @observable.shallow details = [];
  @observable.shallow questionAnswer = [];
  @observable syncData = [];
  @observable syncUpdateData = [];
  @observable syncSearch = {
    ...defaultSearch
  };

  @observable syncUpdateSearch = {
    ...defaultSearch
  };

  constructor(root) {
    this.root = root;
  }

  @action
  async getCurrentUsage(some) {
    const { data } = await SystemRepository.systemDashboard(
      some,
      "/current-usage"
    );
    switch (some) {
      case "cpu":
        this.currentUsageCpu = data;
        break;
      case "filesystem":
        this.currentUsageFile = data;
        break;
      case "memory":
        this.currentUsageMemory = data;
        break;
      case "network/total":
        this.currentUsageNetwork = data;
        break;
      case "network/rxtx":
        this.currentUsagerxtx = new NetworkModel(data);
        break;
      default:
        break;
    }
  }

  @action
  async getVisitorsNumber(day) {
    const { data } = await SystemRepository.systemTool(
      "/access-log/visitors-numbers",
      `?periodFilter=${day}`
    );
    this.visitorNumber = data.map((d, i) => new LineModel(d, i));
  }

  @action
  async getVisitorsCount() {
    const { data } = await SystemRepository.systemTool(
      "/access-log/visitors-number",
      ""
    );
    this.visitorsCount = data.value;
  }

  @action
  async getAccessLog(filter) {
    const { data } = await SystemRepository.systemTool("/access-log", filter);
    this.accessLogs = data;
  }

  @action
  async getUserAccessLog(filter) {
    const { data } = await SystemRepository.getUserAccessLog("audits", filter);
    this.accessLogs = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new AccessLogModel(d, i))
    };
  }
  @action
  async getUsersCount() {
    const { data } = await SystemRepository.getUsersCount();
    this.usersCount = data;
  }

  @action
  async getAccounts(role) {
    const { data } = await SystemRepository.getAccounts(`?filter=${role}`);
    this.accountList = data;
  }
  @action
  async getHardwareResource(type, usage, day) {
    const { data } = await SystemRepository.systemStatistics(
      `/${type}`,
      `/${usage}-statistics`,
      `?filter=${day}`
    );
    this.hardwareResource.set(type, data);
  }

  @action
  async getAdminUser(queryParams) {
    const { data } = await SystemRepository.getAdminUsers(queryParams);
    this.getAdminUsers = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new UserModel(d, i))
    };
  }

  @action
  async deleteUser(queryParams) {
    const { data } = await SystemRepository.deleteuser(queryParams);
    return data;
  }

  @action
  async changeUser(query) {
    const { data } = await SystemRepository.changeUser(query);
    return data;
  }
  
  // 2021.09.17 add getToken by jdy : for api-token 
  @action
  async getToken(queryParams) {

    console.log("Debug loginInfo : ", queryParams);
    const data = await SystemRepository.getToken(queryParams);
    return data.id_token;
  }

  @action
  async changePasswordUser(queryParams) {
    const data = await SystemRepository.changePasswordUser(queryParams);
    return data;
  }

  @action
  async getNotification(query) {
    const { data } = await SystemRepository.getNotification(query);
    this.notificationsList = data;
  }

  @action
  async putNotification(query) {
    const { data } = await SystemRepository.putNotification(query);
    return data;
  }

  @action
  async postQA(query) {
    const { data } = await SystemRepository.postQA(query);
    return data;
  }

  @action
  async postComment(query) {
    const { data } = await SystemRepository.postComment(query);
    return data;
  }

  @action
  async putComment(query) {
    const { data } = await SystemRepository.putComment(query);
    return data;
  }

  @action
  async getQAList(url, query) {
    const { data } = await SystemRepository.getQaboard(url, query);
    this.qaList = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new QAListModel(d, i))
    };
  }

  @action
  async getQACount() {
    const { data } = await SystemRepository.getQaboard("/counts", "");
    this.QACount = data;
  }

  @action
  async getQuestion(query) {
    const { data } = await SystemRepository.getQaboard("/detail", query);
    this.details = data;
  }

  @action
  async getQuestionAnswer(query) {
    const { data } = await SystemRepository.getQaboard("/comment", query);
    this.questionAnswer = data;
  }

  @action
  async loadSync() {
    const search = queryString.stringify(this.syncSearch);
    const { data } = await SystemRepository.fetchSyncLog(`?${search}`);
    this.syncData = new TableFormatModel(
      data.content.map(
        m => new SyncLogModel(new DateFormatModel(m, "updateDatetime"))
      ),
      data.totalElements
    );
  }

  @action
  setSyncSearch(obj) {
    this.syncSearch = {
      ...this.syncSearch,
      ...obj
    };
  }

  @action
  resetSyncSearch() {
    this.syncSearch = {
      ...defaultSearch
    };
  }

  @action
  async loadUpdateSync() {
    const search = queryString.stringify(this.syncUpdateSearch);
    const { data } = await SystemRepository.fetchSyncUpdateLog(`?${search}`);
    this.syncUpdateData = new TableFormatModel(
      data.content.map(
        m => new SyncLogModel(new DateFormatModel(m, "updateDatetime"))
      ),
      data.totalElements
    );
  }

  @action
  setSyncUpdateSearch(obj) {
    this.syncUpdateSearch = {
      ...this.syncUpdateSearch,
      ...obj
    };
  }

  @action
  resetSyncUpdateSearch() {
    this.syncUpdateSearch = {
      ...defaultSearch
    };
  }

  @action
  async updateCdm(data) {
    const retValue = await SystemRepository.updateCdm(data);
    return retValue;
  }

  @observable apiKey = {
    receive: {
      serverName: "",
      apiKey: "",
      apiSig: ""
    },
    publish: {
      serverName: "",
      apiKey: "",
      apiSig: ""
    }
  };

  @action
  async loadApiKey(type) {
    const { data } = await SystemRepository.fetchApiKey(type);
    this.apiKey = {
      ...this.apiKey,
      [type]: data.pop()
    };
  }

  @action
  async changeApiKey(type, { id, ...payload }) {
    const data = await SystemRepository.modifyApiKey({
      type,
      id,
      payload
    });
    return data;
  }
}
export default SystemStore;
