import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import div from "@material-ui/core/div";
// import div from "@material-ui/core/div";
import LineChart from "components/atoms/LineChart";
import Gauge from "components/atoms/Gauge";
import DateSelectBox from "components/atoms/DateSelectBox";

import { orange, red, grey } from "@material-ui/core/colors";

const styles = theme => ({
  head: {
    width: "auto",
    border: "solid 1px #c6c6c6 ",
    // padding: "1px",
    // borderColor: "white",
    // boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    margin: "20px",
    // padding: "20px 20px 40px 20px",
    background: "#ffffff",
    "&:hover": {
      // padding: "0px",
      border: "solid 1px #26a1a4 ",
    }
  },
  boxgreen: {
    backgroundColor: '#26a1a4',
    height: "12px",
    width: "12px",
    float: "left",
    marginRight: "12px"
  },
  boxorange: {
    backgroundColor: orange[400],
    height: "12px",
    width: "12px",
    float: "left",
    marginRight: "12px"
  },
  boxred: {
    backgroundColor: red[400],
    height: "12px",
    width: "12px",
    float: "left",
    marginRight: "12px"
  },
  relativepos: {
    display: 'flow-root',
    padding: " 0px 5px"
  },
  gauge: {
    margin: "auto",
    width: "20%"
  },
  menu: {
    width: "15%",
    fontSize: "12px",
    padding: "3px",
    margin: "auto"
  },
  linechart: {
    width: "65%"
  },
  capacity: {
    fontSize: "14px",
    textAlign: "center",
    color: grey[400]
  },
  title: {
    float: "left",
    margin: "0px",
    padding: "10px 10px",
    fontSize: "13px",
    color: "#7d7d7d"
  },
  subtitle: {
    float: "right",
    margin: "0px",
    padding: "10px 10px",
    fontSize: "11px",
    color: "#7d7d7d"
  },
  display: {
    display: "flex",
    borderTop: "1px solid #cbcbcb",
  },
  nogauge: {
    width: "100%"
  }
});

@inject("system")
@observer
class SystemStaticTable extends Component {

  onchangeDate = value => {
    const { onchangeDate, name, usage } = this.props;
    onchangeDate(name, usage, value)
  };

  render() {
    const {
      system: { hardwareResource },
      name,
      gauge
    } = this.props;
    const { classes, data } = this.props;
    const headName = {
      "memory-cpu": "Computing ",
      network: "Networking ",
      filesystem: "Storage "
    };
    const detailNmae = {
      "memory-cpu": "annual, montly, daily usage for CPU, memory(%)",
      network: "annual, montly, daily usage for rxBytes, txBytes(Mb) ",
      filesystem: "annual, montly, daily usage for  Disk(%) "
    }
    let resource = hardwareResource.get(name);
    return resource ? (
      <div className={classes.head}>
        <div>
          <div className={classes.relativepos}>
            <div className={classes.title}>{headName[name]}resource statistics</div>
            <DateSelectBox onChangeDate={this.onchangeDate} />
            <div className={classes.subtitle}>{detailNmae[name]}</div>
          </div>
          <div className={classes.display}>
            {gauge ? (
              <>
                <div className={classes.gauge}>
                  <Gauge data={data} />
                  <div className={classes.capacity}>CAPACITY 2.00CHZ/8C </div>
                </div>
                <div className={classes.menu}>
                  <div>
                    <div className={classes.boxgreen} />
                    <span>0~40% ( Good )</span>
                  </div>
                  <div>
                    <div className={classes.boxorange}> </div>
                    <span>40~70% ( Warning )</span>
                  </div>
                  <div>
                    <div className={classes.boxred} />
                    <span>70~100% ( Danger )</span>
                  </div>
                </div>
                <div className={classes.linechart}>
                  <LineChart data={resource} statics={true} len={resource.length} />
                </div>
              </>
            ) :
              <div className={classes.nogauge}>
                <LineChart data={resource} statics={true} len={resource.length} />
              </div>}

          </div>
        </div>
      </div>
    ) : null;
  }
}
export default withStyles(styles)(SystemStaticTable);
