import React, { Component } from "react";
import Highcharts from "highcharts/highcharts.src.js";
import VennModule from "highcharts/modules/venn.js";
import HighchartsReact from "highcharts-react-official";
VennModule(Highcharts);
// require("highcharts/modules/venn")(Highcharts);
// let titleStyle = {
//   textAlign: 'center'
// }

class VennChart extends Component {

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.operationSet !== nextProps.operationSet || this.props.isDouble !== nextProps.isDouble || this.props.select !== nextProps.select
    // return true;
  }
  operation = (e) => {
    const { operationSet, runSubject } = this.props;
    runSubject({ target: { value: operationSet[e.point.key], name: e.point.key } })
  }

  render() {
    const { operationSet, isDouble, select } = this.props;
    let seriesData = []
    let tooltipData = {}
    let color = {
      differenceAAndB: "#e6e6e6", differenceBAndA: "#e6e6e6", intersection: "#e6e6e6",
      differenceAAndBC: "#e6e6e6", differenceBAndAC: "#e6e6e6", differenceCAndAB: "#e6e6e6", intersectionAAndB: "#e6e6e6",
      intersectionBAndC: "#e6e6e6", intersectionAAndC: "#e6e6e6", intersectionABC: "#e6e6e6"
    }
    if (select === 'unionAAndBAndCSerial') {
      color = {
        differenceAAndB: "#26a1a4", differenceBAndA: "#26a1a4", intersection: "#26a1a4",
        differenceAAndBC: "#26a1a4", differenceBAndAC: "#26a1a4", differenceCAndAB: "#26a1a4", intersectionAAndB: "#26a1a4",
        intersectionBAndC: "#26a1a4", intersectionAAndC: "#26a1a4", intersectionABC: "#26a1a4"
      }
    }
    if (select) {
      color[select.replace('Serial', '')] = '#26a1a4'
    }
    if (operationSet && isDouble) {
      let adifb = operationSet.differenceBAndA
      let bdifa = operationSet.differenceAAndB
      let ab = operationSet.intersection
      tooltipData = { 'B': adifb, 'C': bdifa, 'A': ab }

      seriesData = [
        // {
        //   name: "B",
        //   sets: ["A"],
        //   value: 3,
        //   color: color.differenceAAndB,
        //   key: 'differenceAAndBSerial'
        // },
        // {
        //   name: "C",
        //   sets: ["B"],
        //   value: 3,
        //   color: color.differenceBAndA,
        //   key: 'differenceBAndASerial'
        // },
        {
          name: "B",
          sets: ["A"],
          value: 3,
          color: color.differenceBAndA,
          key: 'differenceBAndASerial'
        },
        {
          name: "C",
          sets: ["B"],
          value: 3,
          color: color.differenceAAndB,
          key: 'differenceAAndBSerial'
        },
        {
          name: "A",
          sets: ["A", "B"],
          value: 1,
          color: color.intersection,
          key: 'intersectionSerial'
        }
      ]

    } else if (operationSet) {
      let adifbc = operationSet.differenceAAndBC
      let bdifac = operationSet.differenceBAndAC
      let cdifab = operationSet.differenceCAndAB
      let aintb = operationSet.intersectionAAndB
      let bintc = operationSet.intersectionBAndC
      let cinta = operationSet.intersectionAAndC
      let abc = operationSet.intersectionABC

      tooltipData = {
        'E': adifbc, 'F': bdifac, 'G': cdifab,
        'B': aintb, 'C': bintc, 'D': cinta, 'A': abc
      }

      seriesData = [
        {
          name: "E",
          sets: ["A"],
          value: 5,
          color: color.differenceAAndBC,
          key: "differenceAAndBCSerial"
        },
        {
          name: "F",
          sets: ["B"],
          value: 5,
          color: color.differenceBAndAC,
          key: "differenceBAndACSerial"
        },
        {
          name: "G",
          sets: ["C"],
          value: 5,
          color: color.differenceCAndAB,
          key: "differenceCAndABSerial"
        },
        {
          name: "B",
          sets: ["A", "B"],
          value: 2,
          color: color.intersectionAAndB,
          key: "intersectionAAndBSerial"
        },
        {
          name: "C",
          sets: ["B", "C"],
          value: 2,
          color: color.intersectionBAndC,
          key: "intersectionBAndCSerial"
        },
        {
          name: "D",
          sets: ["A", "C"],
          value: 2,
          color: color.intersectionAAndC,
          key: "intersectionAAndCSerial"
        },
        {
          name: "A",
          sets: ["A", "B", "C"],
          value: 1,
          color: color.intersectionABC,
          key: "intersectionABCSerial"
        }
      ]
    }
    let options = {
      chart: {
        type: "venn",
        // height: 380
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return '<div>' + this.point.name +
            ' : ' + tooltipData[this.point.name] + '</div>';
        },
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderColor: '#ffffff',
          borderWidth: "3px "
        },
        venn: {
          events: {
            click: (e) => { this.operation(e) }
          },
          dataLabels: {
            useHTML: true,
            formatter: function () {
              if (this.point.key === select || select === 'unionAAndBAndCSerial') {
                return `<div style="text-align: center;margin-bottom: 3px;color:#ffffff">` + this.point.name + '</div>' +
                  ' <div style="background-color: #ffffff;border-radius: 20px;width:35px;height:20px;text-align: center;line-height: 1.8;font-weight: normal;"> '
                  + (tooltipData[this.point.name] ? tooltipData[this.point.name].toLocaleString() : 0) + '</div>';
              } else {
                return `<div style="text-align: center;margin-bottom: 3px">` + this.point.name + '</div>' +
                  ' <div style="background-color: #ffffff;border-radius: 20px;width:35px;height:20px;text-align: center;line-height: 1.8;font-weight: normal;"> '
                  + (tooltipData[this.point.name] ? tooltipData[this.point.name].toLocaleString() : 0) + '</div>';
              }
            }
          }
        }
      },
      series: [
        {
          type: "venn",
          data: seriesData,
          opacity: 1,
          states: { hover: { borderColor: '#ffffff', color: "#d3d3d3" }, inactive: { opacity: 1 } }
        }
      ]
    };
    if (isDouble) {
      options.chart.height = 200
    } else {
      options.chart.height = 380
    }
    return (
      <HighchartsReact
        ref={"chartComponent"}
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
        key={"0"}
      />
    );
  }
}

export default VennChart;
