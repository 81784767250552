import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  btn: {
    minWidth: "110px",
    height: "30px",
    margin: "5px",
    border: "1px solid #dedede",
    color: "#ffffff",
    borderRadius: "2px",
    fontSize: "12px",
    padding: "2px 5px",
    textTransform: "none",
    fontWeight: "normal",
    "&:hover": {
      border: "2px solid #ffffff"
    }
  }
});

class CustomButton extends Component {
  hadleClick = () => {
    const { onClick } = this.props;
    if (onClick) onClick();
  };

  render() {
    const { text, img: ICON, style, classes } = this.props;
    // console.log(img)
    return (
      <Button
        variant="outlined"
        onClick={this.hadleClick}
        className={classes.btn}
      >
        <ICON className={style} />
        {text}
      </Button>
    );
  }
}

export default withStyles(styles)(CustomButton);
