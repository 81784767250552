import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  backcolor: {
    backgroundColor: "#ffffff",
    // paddingTop: '22px',
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif'
  },
  mainback: {
    width: "calc(100% - 0px)",
    margin: '0px 0px',
    backgroundColor: "#ffffff",
    // "@media (max-width: 1500px)": {
    //   width: '1240px'
    // }
  },
  display: {
    // width: "100%",
    height: "100%",
    // paddingTop: "19px"
  }
});

class pageTemplate extends Component {

  render() {
    const { classes, content } = this.props;
    return (
      <div className={classes.backcolor}>
        <div className={classes.mainback}>
          <div className={classes.display}>
            <div>{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(pageTemplate);
