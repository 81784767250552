import { observable, action, configure } from "mobx";
import SummaryRepository from "../repository/SummaryRepository";
import PieModel from "modules/model/PieModel";
import DetailPieModel from "modules/model/DetailPieModel";
// import CancerModel from "modules/model/CancerModel";

configure({ enforceAction: "true" });
class SummaryStore {
  top5MenuList = [
    "variant-top5-snv",
    "variant-top5-cnv-amp",
    "variant-top5-cnv-del",
    "variant-top5-sv"
  ];
  mutatedList = ["snv", "cnv", "sv"];
  @observable.shallow searchCancerList = [];
  @observable.shallow searchOriginCancerList = [];
  @observable.shallow searchCancerTypeDetailList = [];
  @observable.shallow top5MenuMap = observable.map();
  @observable.shallow GeneTopByCancerList = [];
  @observable.shallow rowDataSearchMap = observable.map();
  @observable.shallow geneCardList = [];
  @observable.shallow allList = [];
  @observable allListTableMap = observable.map();
  @observable.shallow makeGetAllListData = [];
  @observable.shallow allListCnt = {};
  @observable.shallow firstIndex = [];

  constructor(root) {
    this.root = root;
  }

  @action
  async loadSearchCancer(search, type, query, isGeneTop5Query) {
    this.clearSearchCancerTypeDetailList();
    const { data } = await SummaryRepository.getSearchCancer(
      search,
      type,
      query
    );
    if (type === "type-detail") {
      this.firstIndex = data[0];
      this.searchCancerTypeDetailList = data.map((d, i) => new PieModel(d, i));
    } else {
      this.firstIndex = data[0];
      this.searchCancerList = await data.map((d, i) => new PieModel(d, i));
      if (this.searchCancerList.length > 0) {
        this.root.comm.setCancerType(
          this.searchCancerList[0].id,
          this.searchCancerList[0].name
        );
        if (isGeneTop5Query) {
          const addQuery = `&cancerType=${this.searchCancerList[0].id}`;
          console.log(addQuery);
          this.loadGeneTopByCancerData(isGeneTop5Query + addQuery);
        }
      }
    }
  }

  @action
  resetSearchCancer() {
    this.searchCancerList = [];
    this.GeneTopByCancerList = [];
  }

  @action
  resetTop5Map() {
    this.top5MenuMap.clear();
  }

  @action
  async loadSearchCancerOrigin(search, type, query) {
    const { data } = await SummaryRepository.getSearchCancer(
      search,
      type,
      query
    );
    this.searchOriginCancerList = data;
  }
  // @action
  // loadCancerAll(m, type, query) {
  //   this.clearTableMap(this.top5MenuMap);
  //   if(m)this.loadCancerGeneTop5(m, type, query);
  //   else this.loadCancer(type, query);
  // }

  @action
  async loadCancerGeneTop5(m, type, query) {
    const { data } = await SummaryRepository.getCancer(m, type, query);
    this.top5MenuMap.set(m, data);
  }

  @action
  async loadCancer(type, query) {
    // this.top5MenuMap.clear();

    const list = this.top5MenuList.map(
      async m => await SummaryRepository.getCancer(m, type, query)
    );

    Promise.all(list).then(res => {
      this.setPromiseList(this.top5MenuMap, this.top5MenuList, res);
    });
  }

  @action
  setPromiseList(observableMap, list, resList) {
    for (let [i, v] of resList.entries()) {
      const { data } = v;
      observableMap.set(list[i], data);
    }
  }

  @action
  async loadGeneTopByCancerData(query) {
    const { data } = await SummaryRepository.getGeneTopByCancerData(query);
    this.GeneTopByCancerList = data.map((d, i) => new DetailPieModel(d, i));
  }

  // @action
  // async loadRowDataPerson(query) {
  //   const { data } = await SummaryRepository.getRowDataSearchPerson(query);
  //   // this.rowDataPersonList = data;
  //   this.rowDataSearchMap.set("person", data);
  // }

  @action
  async loadRowDataSearch(query) {
    const { data } = await SummaryRepository.getRowDataSearchPerson(query);
    this.rowDataSearchMap.set("person", data);

    const list = this.mutatedList.map(
      async mutated => await SummaryRepository.getRowDataSearch(mutated, query)
    );
    Promise.all(list).then(res => {
      this.setPromiseList(this.rowDataSearchMap, this.mutatedList, res);
    });
  }

  @action
  async loadAllList(options) {
    const { filter, type, query } = options;
    const { data } = await SummaryRepository.getAllList(filter, type, query);
    this.allList = data;

    if (data.length > 0) {
      const {
        personTotalCount,
        specimenTotalCount,
        variantTotalCount
      } = data[0];
      this.allListCnt = {
        personTotalCount,
        specimenTotalCount,
        variantTotalCount
      };
    }

    if (filter === "variant-all-snv") {
      this.allListTableMap = this.makeTableData({
        data,
        map: this.allListTableMap,
        // model: CancerModel,
        propertyOfKey: "hgvsp"
      });

      this.geneCardList = data.filter((item, i) => {
        return (
          data.findIndex((item2, j) => {
            return item.hgvsp === item2.hgvsp;
          }) === i
        );
      });
    } else {
      this.allListTableMap = this.makeTableData({
        data,
        map: this.allListTableMap,
        // model: CancerModel,
        propertyOfKey: "gene"
      });

      this.geneCardList = data.filter((item, i) => {
        return (
          data.findIndex((item2, j) => {
            return item.gene === item2.gene;
          }) === i
        );
      });
    }
  }

  @action
  clearSearchCancerTypeDetailList() {
    this.searchCancerTypeDetailList = [];
  }

  @action
  clearTableMap(map) {
    map.clear();
  }

  @action
  makeTableData({ data, map, model, propertyOfKey }) {
    this.clearTableMap(map);
    const retMap = new Map();

    for (let i = 0; i < data.length; i++) {
      let m = new model(data[i]);
      if (retMap.has(data[i][propertyOfKey])) {
        !Array.isArray(retMap.get(data[i][propertyOfKey]))
          ? (m = [retMap.get(data[i][propertyOfKey])])
          : (m = retMap.get(data[i][propertyOfKey]));
        m.push(new model(data[i]));
      }
      retMap.set(data[i][propertyOfKey], m);
    }

    return retMap;
  }
}

export default SummaryStore;
