import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import OverView from "pages/systemPage/overView"
import systemStatistics from "pages/systemPage/systemStatistics"

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

class SystemdRouter extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          <PrivateRoute path={`${match.url}/static`} component={systemStatistics} />
          <PrivateRoute path={`${match.url}`} component={OverView} />
        </Switch>
      </>
    );
  }
}

export default SystemdRouter;
