import 'core-js/es';
import "react-app-polyfill/ie9";
import 'normalize.css'

import React from "react";
import ReactDOM from "react-dom";
import Root from "./client/Root";
import * as serviceWorker from "./serviceWorker";
// import "bootstrap/dist/css/bootstrap.min.css";
///
ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
