import React, { useMemo } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import useCustomContext from "../useCustomContext";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    padding: 0
  },
  checked: {
    color: "#26a1a4"
  }
});

const CustomCheckBox = ({ checkType, injectContext, rowCount, d, detail, selectRow, id, style }) => {
  const {
    state: { selected },
    actions
  } = useCustomContext(injectContext);

  const classes = useStyles();
  const selectNum = selected.length;
  const indeterminate = checkType && selectNum > 0 && selectNum < rowCount;
  const checkBoxClass = { root: classes.root, checked: classes.checked };

  let checked = false;
  if (checkType === "head") checked = selectNum === rowCount;
  else if (checkType === "body") {
    checked = actions.isSelected(d);
  } else checked = actions.isDetailsSelected(detail);

  const isDetailDisable = detail && actions.isSelected(d);
  const maxSelectCount = selectRow && selectRow.maxSelectCount;
  const hide = selectRow && selectRow.hide;
  let Test = null
  if (Object.keys(selectRow.selected)[0] === id.toString()) {
    Test = d.setsType
  }
  const tt = Test ? Test === d.setsType : true
  const disabled = !tt || (!isDetailDisable && !checked && maxSelectCount <= actions.getSelectedCount());

  const checkBox = useMemo(() => {
    return (
      !hide && (
        <Checkbox
          style={style}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="small" />}
          classes={checkBoxClass}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          color="default"
        />
      )
    );
  }, [checked, indeterminate, style, disabled]);

  return <>{checkBox}</>;
};

export default CustomCheckBox;
