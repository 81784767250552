import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";

const styles = theme => ({
  title: {
    height: '20px',
    marginTop: '15px',
    float: 'left',
    fontSize: '14px',
    lineHeight: 'normal',
    paddingLeft: '2px'
  },
  table: {
    border: "1px solid #dedede",
    margin: "0px",
    width: "100%"
  },
  tableHead: {
    height: "35px",
    backgroundColor: "#f3f3f3"
  },
  tablebody: {
    height: "35px",
    backgroundColor: "#ffffff"
  },
  headSize: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 600
  },
  null: {
    width: "5%",
    padding: "0px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#3a3a3a",
    textAlign: "center",
    backgroundColor: "#f3f3f3"
  },
  number: {
    borderLeft: "solid 1px #cbcbcb",
    width: "9.5%",
    textAlign: "center",
    padding: "0px !important",
    fontSize: "12px",
    fontWeight: 600,
    color: "#3a3a3a"
  },
  numlength: {
    borderLeft: "solid 1px #cbcbcb",
    width: "9.5%",
    textAlign: "right",
    padding: "0px 5px !important",
    fontSize: "12px",
    fontWeight: 'normal',
  }
});

const List = {
  "age10": "0~9",
  "age20": "10~19",
  "age30": "20~29",
  "age40": "30~39",
  "age50": "40~49",
  "age60": "50~59",
  "age70": "60~69",
  "age80": "70~79",
  "age90": "80~89",
  "age100": "90~99",
}

class DxTable extends Component {
  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return JSON.stringify(this.props.dxAge) !== JSON.stringify(nextProps.dxAge)
    // return true;
  }

  render() {
    const { dxAge, classes } = this.props;
    let head = []
    let female = []
    let male = []
    let listName = Object.keys(List)
    if (dxAge && dxAge.isAll) {
      for (let i in Object.keys(List)) {
        head.push(<TableCell key={i} className={classes.number}>{List[listName[i]]}</TableCell>)
        male.push(<TableCell key={`${i}male`} className={classes.numlength}>{dxAge.totalMale[i]}</TableCell>)
        female.push(<TableCell key={`${i}female`} className={classes.numlength}>{dxAge.totalFemale[i]}</TableCell>)
      }
    } else if (dxAge) {
      for (let i in Object.keys(List)) {
        head.push(<TableCell key={i} className={classes.number}>{List[listName[i]]}</TableCell>)
        male.push(<TableCell key={`${i}male`} className={classes.numlength}>{dxAge.male[i]}</TableCell>)
        female.push(<TableCell key={`${i}female`} className={classes.numlength}>{dxAge.female[i]}</TableCell>)
      }
    }
    return (
      <div>
        <div className={classes.title}>DEMOGRAPHY LIST</div>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.tableHead}>
              <TableCell className={classes.null}>Dx Age</TableCell>
              {head}
            </TableRow>
            <TableRow className={classes.tablebody}>
              <TableCell className={classes.null}>Male</TableCell>
              {male}
            </TableRow>
            <TableRow className={classes.tablebody}>
              <TableCell className={classes.null}>Female</TableCell>
              {female}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(DxTable);
