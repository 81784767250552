import React from "react";
import CheckPermission from "components/lib/CheckPermission";

const withMenuList = dataList => WrappedComponent => props => (
  <ul className="top-nav">
    {dataList.map((data, i) => (
      <CheckPermission key={i} permission={data.permission} only={data.only}>
        <WrappedComponent {...props} data={data} />
      </CheckPermission>
    ))}
  </ul>
);

export default withMenuList;
