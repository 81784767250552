import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { StickyContainer, Sticky } from "react-sticky";
import { Element } from "react-scroll";
import { withStyles } from "@material-ui/core/styles";
// import queryString from "query-string";
import CheckPermission from "components/lib/CheckPermission";
import PageTemplate from "pages/template/pageTemplate";
import LeftMenu from "components/organisms/LeftMenu";
import DataPageTable from "components/organisms/DataPageTable";
import PatientListTable from "components/organisms/PatientListTable";
import SubjectSample from "components/atoms/SubjectSample";
import dateFormat from "dateformat";
import TypeSubject from "components/atoms/TypeSubject";
import CustomDialog from "components/atoms/CustomDialog";
import RightScroll from "components/atoms/RightScroll";
import ReportedStatus from "components/atoms/ReportedStatus";
import DetailCartSet from "components/atoms/CustomDialog/DetailCartSet";
import DetailBoard from "components/organisms/DetailBoard";
import DxTable from "components/organisms/DxTable";
import Footer from "components/organisms/FooterSmall";
import PresentHeader from "../../shared/PresentHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import XLSX from 'xlsx';
import TabHeaderData from "components/atoms/TabHeaderData";
import AppliedFilter from "components/organisms/AppliedFilter";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

import * as Comlink from "comlink";

// import TestChart from "components/atoms/TestChart";
// import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

// import Download from "assets/images/dataexplorer/download.svg";
//새창 href:'주소' 
//클릭 이벤트 fn:()=>{} 
// TSV 형식으로 다운로드
// CSV 형식으로 다운로드
// const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
// const checkedIcon = <img src={CheckBoxIcon} alt="" />
const ROLE = ["ROLE_ADMIN", "ROLE_KM_USER"]

let tabList = ["Cancer", "Gene", "Variant"];



const detailCancerColumns = [
  { column: "Cancer", property: "name", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1055 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "Samples", property: "samplesTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Genes", property: "gene", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  // { column: "Variants", property: "variant", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "SNV", property: "snvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "CNV", property: "cnvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SV", property: "svTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const PatientColumns = [
  { column: "Person ID", property: "personSourceValue", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 600 } } },
  { column: "Sex", property: "sex", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Age", property: "age", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Cancer", property: "cancer", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Detail Cancer", property: "cancerDetail", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene", property: "geneCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "Variant", property: "variantCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const genecolumns = [
  { column: "Gene", property: "geneName", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "Samples", property: "samplesTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Genes", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Variants", property: "variantCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SNV", property: "snvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "CNV", property: "cnvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SV", property: "svTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];

const geneActioncolumns = [
  { column: "Gene", property: "geneName", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "Samples", property: "samplesTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Genes", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Variants", property: "variantCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SNV", property: "snvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "CNV", property: "cnvTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SV", property: "svTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];

/* modify by jdy(정대열) 2020-11 */
/* SNV LIST 의 column 변경으로 인한 변경 */
const snvcolumns = [
  // { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  // { column: "HGVS.p", property: "hgvsP1digit", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene and HGVS.p", property: "targetGVId", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 500 } } },
  { column: "Cancer", property: "selectedCancerLabel", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1600 } } },
  // { column: "HGVS.c", property: "hgvsC", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  // { column: "HGVS.p", property: "hgvsP", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  //{ column: "HGVS.p", property: "hgvsP1digit", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const cnvcolumns = [
  { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Type", property: "sequenceAlteration", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1100 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const svcolumns = [
  { column: "Gene1", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene2", property: "targetGene2Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1100 } } },
  { column: "Subjects", property: "subjectTotal", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const DetailTableTitle = ['DETAIL CANCER LIST', 'GENE LIST', 'SNV LIST', 'CNV LIST', 'SV LIST', 'DRUG ACTIONABLE GENE LIST']

const styles = theme => ({
  chartTitle: {
    lineHeight: 'normal',
    float: "left",
    paddingLeft: "2px",
    marginTop: "15px",
    marginBottom: "8px",
    fontSize: "14px"
  },
  chartMargin: {
    // padding: "15px",
    padding: "0px"
  },
  Top: {
    position: 'relative',
    zIndex: 1
  },
  selectContainer: {
    verticalAlign: 'top',
    backgroundColor: "#7b7b7b",
    height: '40px',
    right: "0px",
    padding: "0px 85px",
    "@media (max-width: 1500px)": {
      padding: "0px ",
    },
  },
  marginContainger: {
    margin: 'auto',
    padding: '0px 15px ',
    // display: "flex"
    // "@media (max-width: 1500px)": {
    //   width: '1210px !important',
    //   margin: 'auto'
    // },
  },
  container: {
    display: "flex"
  },
  details: {
    borderRadius: "2px",
    // border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    marginTop: " 15px",
    // paddingTop: "15px"
    minHeight: "inherit"
  },
  chartNameTitle: {
    float: "left",
    fontSize: '14px',
    color: '#000000',
    lineHeight: '1.8',
    fontWeight: "normal"
  },
  loading: {
    padding: "16px 20px"
  },
  loadingPaper: {
    backgroundColor: '#00ff0000',
    boxShadow: "none",
    width: "95px"
  },
  circlecolor: {
    color: "#ffffff"
  },
  release: {
    float: 'left',
    lineHeight: '2.8',
    fontSize: '14px',
    fontWeight: '500',
    color: '#ffffff'
  },

  tabsize: {
    width: "200px",
    height: "30px",
    padding: "0px"
  },
  zIndex1: {
    zIndex : 1
  },

  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },

  filterContainerReport: {
    //position: 'absolute',
    //verticalAlign: 'center',
    backgroundColor: '#ffffff',
    top: "20px",
    //right: "0px",
    width: "25%"
  },
  filterContainer: {

    marginBottom : '5px',

    verticalAlign: 'top',
    backgroundColor: '#ffffff',
    right: "0px",
    width: "75%"
  },
  filtermarginContainger: {
    //margin: 'auto',
    //padding: "7.5px",
    backgroundColor: "#f6f6f6",
    "@media (max-width: 1500px)": {
      margin: 'auto'
    },
  },
  filters: {
    minHeight: "36px",
    // width: "1525px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    "@media (max-width: 1500px)": {
      margin: 'auto',
    },
  },
});
// let patientData

@inject("summary", "comm", "home", "analysis")
@observer
class DataPage extends Component {
  pdfExport
  Component;

  constructor(props) {
    super(props);

    // for multi-threading and / or filterTab, presentTab 선택에 능동적으로 대처하기 위해 add by jdy (정대열) 2021-01 
    this.filterLoaded = [false, false, false];
    this.presentLoaded = [false, false, false];

    this.bNeedFilterUpdate = true;

    this.filterWorker = {};
    this.cancerWorker = {};
    this.geneWorker = {};
    this.variantWorker = {};

    // whether the browser support multi-threading or not. by jdy (정대열) 2021-01
    if(window.Worker) {
      this.webWorkerable = true; // multi-threading support
    }
    else {
      this.webWorkerable = false;  // multi-threading not support
    }
    //this.webWorkerable = false;
  }
  
  state = {
    refresh:true,
    open: false,
    filterTab: 0,
    presentTab: 0,
    detailCancerList: [],
    reported: true,
    select: {
      cancerList: [],
      detailCancer: {},
      gene: [],
      snv: [],
      cnv: [],
      sv: [],
      sample:"",
      primary:"",
      proteinGene:"",
      gender: "",
      age: "",
      value: "",
      variantType: {},
      Variant: [],
    },
    scrolling: 'false',
    search: "",
    suggestions: [],
    chartOpen: {
      cancer: true,
      gene: false,
      drug: false,
      snv: false,
      cnv: false,
      sv: false,
      dx: false
    },
    allChart: false,
    absolute: true,
    loading: false,
    personCheck: 0,
    setCheck: 0,
    helpOpen: false,
    imageUrl: "help/2_4_2.png",
    htmlId: 6
  };


  /* 
    add by jdy 2021-01 for multi-threading using web worker
    filterTab (좌측 메뉴 tab)에 관련된 added functions are the following
    setFilterLoaded() // 각 filterTab에 관련된 server api 완료 여부
    backFilterThread(), notCancerFilterWork(), notGeneFilterWork(), notVariantFilterWork // inactive filterTab 에 따른 server api 실행
  */

  setFilterLoaded(value, loaded) {
    if (-1 === value){
      this.filterLoaded[0] = loaded;
      this.filterLoaded[1] = loaded;
      this.filterLoaded[2] = loaded;
    }
    else {
      this.filterLoaded[value] = loaded;
    }
  }

  backFilterThread = () => {

    const { filterTab } = this.state;

    let filterValue = filterTab;

    if (this.webWorkerable) {
      if (0 === filterValue) {
        this.filterWorker.doWork(Comlink.proxy(this.notCancerFilterWork));
      }
      else if (1 === filterValue) {
        this.filterWorker.doWork(Comlink.proxy(this.notGeneFilterWork));
      }
      else {
        this.filterWorker.doWork(Comlink.proxy(this.notVariantFilterWork));
      }      
    }
    else {
      if (0 === filterValue) {
        this.notCancerFilterWork();
      }
      else if (1 === filterValue) {
        this.notGeneFilterWork();
      }
      else {
        this.notVariantFilterWork();
      }
    }
  }

  notCancerFilterWork = () => {

    const { home } = this.props;
        
    let toolsquery1 = this.mainFilterQuery();
    toolsquery1.sort = ["subject,desc"];

    let toolsquery2 = this.mainFilterQuery();
    toolsquery2.sort = ["subjectCnt,desc"];
    toolsquery2.size = 20;

    let toolsquery3 = this.mainFilterQuery();
    toolsquery3.sort = ["subjectCnt,desc"];
    toolsquery3.size = 3;


    let array = [];

    array.push(home.getGeneTypeData(toolsquery2));

    array.push(home.getVariantTypeListData(toolsquery2));

    array.push(home.getVariantTypeData(toolsquery3));

    const promises2 = array.map(item => (item));
 
    Promise.all(promises2).then((values) => {
      this.filterLoaded[1] = true;
      this.filterLoaded[2] = true;
    });
  }

  notGeneFilterWork = () => {

    const { home } = this.props;

    let toolsquery1 = this.mainFilterQuery();
    toolsquery1.sort = ["subject,desc"];

    let toolsquery2 = this.mainFilterQuery();
    toolsquery2.sort = ["subjectCnt,desc"];
    toolsquery2.size = 20;

    let toolsquery3 = this.mainFilterQuery();
    toolsquery3.sort = ["subjectCnt,desc"];
    toolsquery3.size = 3;

    let array = [];
 
    array.push(home.getCancerTypeData(toolsquery1)); //암 검색 필터

    array.push(home.getVariantTypeListData(toolsquery2)); //variant 검색필터 리스트

    array.push(home.getVariantTypeData(toolsquery3)); //interpretation criteria 검색필터

    const promises2 = array.map(item => (item));
 
    Promise.all(promises2).then((values) => {
      this.filterLoaded[0] = true;
      this.filterLoaded[2] = true;
    });
  }

  notVariantFilterWork = () => {

    const { home } = this.props;
            
    let toolsquery1 = this.mainFilterQuery();
    toolsquery1.sort = ["subject,desc"];

    let toolsquery2 = this.mainFilterQuery();
    toolsquery2.sort = ["subjectCnt,desc"];
    toolsquery2.size = 20;

    let toolsquery3 = this.mainFilterQuery();
    toolsquery3.sort = ["subjectCnt,desc"];
    toolsquery3.size = 3;

    let array = [];

    array.push(home.getCancerTypeData(toolsquery1)); //암 검색 필터

    array.push(home.getGeneTypeData(toolsquery2)); //진 검색필터

    const promises2 = array.map(item => (item));
 
    Promise.all(promises2).then((values) => {
      this.filterLoaded[0] = true;
      this.filterLoaded[1] = true;
    });
  }

  /* 
    add by jdy 2021-01 for multi-threading using web worker
    presentTab (우측 작업 tab) 관련된 added functions are the following
    setPresentLoaded() // 각 presentTab에 관련된 server api 완료 여부
    completeWork() // active presentTab 에 따른 cancerWork(), geneWork(), variantWork() 를 call 
    cancerThread(), cancerWork() // cancer 관련 작업 thread, 실제 작업 server api call
    geneThread(), geneWork() // gene 관련 작업 thread, 실제 작업 server api call
    variantThread(), variantWork() // variant 관련 작업 thread, 실제 작업 server api call
  */

  setPresentLoaded(value, loaded) {

    if (-1 === value){
      this.presentLoaded[0] = loaded;
      this.presentLoaded[1] = loaded;
      this.presentLoaded[2] = loaded;
    }
    else {
      this.presentLoaded[value] = loaded;
    }
  }

  completeWork = async (value, bChangePresentTab) => {
    // if onChangePresentTab event -> bChangePresentTab : true
    // if onClickMainFilter event -> bChangePresentTab : false

    const {home} = this.props;

    let tabValue = value;

    if (0 === tabValue) {
      this.cancerWork();
    }
    else if (1 === tabValue) {
      this.geneWork();
    }
    else if (2 === tabValue) {
      this.variantWork();
    }

    if (this.presentLoaded[tabValue] === false)
    {
      if (bChangePresentTab) {
        const intervalTab = setInterval(() => {
          if (this.presentLoaded[tabValue] === true) {
            clearInterval(intervalTab);
            this.handleChangePresentTab(tabValue);
          }
        }, 1000);
      }
      else
      {
        const intervalMain = setInterval(() => {
          if (this.presentLoaded[tabValue] === true) {
            clearInterval(intervalMain);
            this.handleChangePresentTab(tabValue);
            // this.setState({
            //   loading: false
            // });
          }
        }, 1000);
      }
    }
    else{
      this.handleChangePresentTab(tabValue);
    }
  }

  cancerThread = () => {
    
    if (this.webWorkerable) {
      this.cancerWorker.doWork(Comlink.proxy(this.cancerWork));
    }
    else {
      this.cancerWork();
    }
  }

  cancerWork = () => {
    const { home } = this.props;
    const { filterTab, absolute } = this.state;
    let array = [];

    // console.log("cancerWork start!");

    const query = this.mainFilterQuery()
    query.sort = ["subject,desc"]

    // array : 6 sets
    if (absolute) {
      array.push(home.getAbsoluteData('cancers', query, filterTab)); //암 차트
    } else {
      array.push(home.getCancerChartData(query));
    }

    array.push(home.getDxGraphicsData(query, absolute)); //연령 차트
    array.push(home.getPersonGraphicsData(query)); //젠더 차트
    array.push(home.getSampleType(query)); //샘플타입 차트
    array.push(home.getPrimarySite(query)); //프라이머리 차트
    array.push(home.getCancerTableData(query)); //암 Table 데이터

    const promises1 = array.map(item => (item));
 
    Promise.all(promises1).then((values) => {
      this.setPresentLoaded(0, true);
    });
  }

  geneThread = () => {
    
    if (this.webWorkerable) {
      this.geneWorker.doWork(Comlink.proxy(this.geneWork));
    }
    else {
      this.geneWork();
    }
  }

  geneWork = () => {
    const { home } = this.props;
    const { filterTab, absolute } = this.state;
    let array = [];

    //console.log("geneWork start!");

    const query = this.mainFilterQuery()
    query.sort = ["subject,desc"]

    let queryminsize = this.mainFilterQuery()
    queryminsize.size = 5
    queryminsize.sort = ["subjectCnt,desc"]

    let querychartsize = this.mainFilterQuery()
    querychartsize.size = 20
    querychartsize.sort = ["subjectCnt,desc"] 

    // array : 5 sets

    array.push(home.getProteinData(query)); //프로틴 포지션 차트

    if (absolute) {
      array.push(home.getAbsoluteData('genes', querychartsize, filterTab)); //gene 그래프
    } else {
      array.push(home.getAllGeneDate(querychartsize));
    }
    // add by jdy 2020-11-18
    array.push(home.getGeneActionableData(querychartsize)); //드러그 액셔너블 차트
    array.push(home.getSelectedGeneList(querychartsize)); //프로틴 셀렉트 박스 차트 

    array.push(home.getreturnGeneDate(queryminsize)); //Gene Table 데이터

    array.push(home.getreturnGeneActionData(queryminsize)); //GeneDrug Table 데이터

    const promises1 = array.map(item => (item));
 
    Promise.all(promises1).then((values) => {
      this.setPresentLoaded(1, true);
    });
  }

  variantThread = () => {
    
    if (this.webWorkerable) {
      this.variantWorker.doWork(Comlink.proxy(this.variantWork));
    }
    else {
      this.variantWork();
    }
  }

  variantWork = () => {
    const { home } = this.props;
    const { filterTab, absolute } = this.state;
    let array = [];

    //console.log("variantWork start!");

    const query = this.mainFilterQuery()
    query.sort = ["subject,desc"]

    let queryminsize = this.mainFilterQuery()
    queryminsize.size = 5
    queryminsize.sort = ["subjectCnt,desc"]

    let querychartsize = this.mainFilterQuery()
    querychartsize.size = 20
    querychartsize.sort = ["subjectCnt,desc"] 

    // array : 8 sets

    if (absolute) {
      array.push(home.getAbsoluteData('variants/snvs', querychartsize, filterTab)); //SNV 그래프
      array.push(home.getAbsoluteData('variants/cnvs', querychartsize, filterTab)); //CNV 그래프
      array.push(home.getAbsoluteData('variants/svs', querychartsize, filterTab)); //SV 그래프
    } else {
      array.push(home.getAllVarientDate(querychartsize, "snvs"));
      array.push(home.getAllVarientDate(querychartsize, "cnvs"));
      array.push(home.getAllVarientDate(querychartsize, "svs"));
    }

    array.push(home.getVariantCategory(query)); //variant 카테고리 차트 (actionable, pathogenic)

    array.push(home.getAnnotationCategory(query)); //어노테이션 차트

    array.push(home.getreturnVariantDate(queryminsize, "snvs")); //SVN Table 데이터
    array.push(home.getreturnVariantDate(queryminsize, "cnvs")); //CNV Table 데이터
    array.push(home.getreturnVariantDate(queryminsize, "svs")); //SV Table 데이터
        
    const promises1 = array.map(item => (item));
 
    Promise.all(promises1).then((values) => {
      this.setPresentLoaded(2, true);
    });
  }

  async componentDidMount() {
    const { home, comm, comm: { originUser, mainFilterObject } } = this.props;
    const { filterTab, absolute, presentTab } = this.state;

    this.setPresentLoaded(-1, false);
    this.setFilterLoaded(-1, false);

    await this.setState({
      loading: true
    });
    await comm.resetSelectedObj();
    await comm.resetMainFilterObject();
    let total = { reported: mainFilterObject.reported, sort: ["subject,desc"] }
    const query = this.mainFilterQuery()

    // const queryProtein = this.mainFilterQuery();
    // queryProtein.size = 200;

    query.sort = ["subject,desc"]
    
    let queryminsize = this.mainFilterQuery()
    queryminsize.size = 5
    queryminsize.sort = ["subjectCnt,desc"]
    let querychartsize = this.mainFilterQuery()
    querychartsize.size = 20
    querychartsize.sort = ["subjectCnt,desc"]
    let personSize = this.mainFilterQuery()

    window.addEventListener("scroll", this.handleScroll);
    
    // by jdy 2021-01
    // Cancer-filterTab, Cancer-presentTab 관련 api 만 선택
    let array = [
      home.getDxGraphicsData(query, absolute), //연령 차트
      home.getLastUpdateDate(), // 마지막 업데이트 날짜
      home.getCancerTypeData({ sort: ["subject,desc"] }), //암 검색 필터
      home.getPersonGraphicsData(query), //젠더 차트
      home.getAbsoluteData('cancers', query, filterTab), //암 차트
      home.getSampleType(query), //샘플타입 차트
      home.getPrimarySite(query), //프라이머리 차트
      home.TotalData(total), // 상단 바 전체 데이터
      home.getValueData(query), //상단 암, 진 , 배리언트 숫자
      home.getCancerTableData(query) //암 테이블 데이터
      // ,home.getreturnGeneActionData(queryminsize) //GeneDrug Table 데이터
    ];

    if (originUser && ROLE.indexOf(originUser.authorities[0]) > -1 && !this.state.personCheck) {
      await this.setState({
        personCheck: 1
      })
      home.getreturnPersonDate(personSize);
    }

    const promises = array.map(item => (item));
    await Promise.all(promises);

    // by jdy 2021-01
    // Cancer-filterTab, Cancer-presentTab 관련 api 작업 완료 true
    this.setPresentLoaded(0, true);
    this.setFilterLoaded(0, true);
    
    this.setState({
      loading: false
    });

    // by jdy 2021-01
    // multi-threading 지원시에 worker thread 생성
    if(this.webWorkerable) {
      // console.log("web worker is supported !");

      // filterTab 관련 background Worker
      this.filterWorker = Comlink.wrap(new Worker("workers/workerObj.js", {type: 'module'}));

      // Cancer presentTab 관련 background Worker thread
      this.cancerWorker = Comlink.wrap(new Worker("workers/workerObj.js", {type: 'module'}));

      // Gene presentTab 관련 background Worker thread
      this.geneWorker = Comlink.wrap(new Worker("workers/workerObj.js", {type: 'module'}));

      // Varinat presentTab 관련 background Worker thread
      this.variantWorker = Comlink.wrap(new Worker("workers/workerObj.js", {type: 'module'}));
    }
    
    // inactive Filter를 위한 filter Item list용 api call 

    this.backFilterThread();

    this.bNeedFilterUpdate = false; // 이것을 comment 처리하면 select 상태에 따라 Filter Item 들이 항상 update 된다.

    // gene Present를 위한 api call 
    this.geneThread();
    // variant Present를 위한 api call 
    this.variantThread();

  }


  componentWillUnmount() {
    const { home } = this.props
    window.removeEventListener("scroll", this.handleScroll);
    home.resetChartData()
  }

  async componentDidUpdate() {
    setTimeout(async () => {
      const { home, analysis, comm: { originUser } } = this.props;
      const { personCheck, setCheck } = this.state
      if (originUser && ROLE.indexOf(originUser.authorities[0]) > -1 && !personCheck) {
        await this.setState({
          personCheck: 1
        })
        await home.getreturnPersonDate({});
      }
      if (originUser && !setCheck) {
        await this.setState({
          setCheck: 1
        })
        await analysis.getSetListData("");
      }
    }, 5000);
  }

  reStart = async () => {
    const { comm } = this.props;
    await comm.resetSelectedObj();
    await comm.resetMainFilterObject();
    this.onClickCriteriaFilter();
  };

  mainFilterQuery = () => {
    const { comm: { mainFilterObject } } = this.props;
    let list = []
    let detailcancer
    let variantType = [];
    if (mainFilterObject.detailcancer) {
      list = []
      for (let i of Object.keys(mainFilterObject.detailcancer)) {
        for (let j of mainFilterObject.detailcancer[i]) {
          list.push(j.id)
        }
      }
      detailcancer = list
    }
    if (mainFilterObject.variantType) {
      variantType = []
      for (let i of Object.values(mainFilterObject.variantType)) {
        variantType = variantType.concat(i)
      }
    }
    let query = {}

    // query.proteinGene = "";
    // 선택된 gene이 없을 경우에는 아예 보내지 않음
    // gene 선택이 있을 경우에는 
    // gene protein chart list 에서 gene 선택이 아닌 경우에는 query.proteinGene=""
    // gene protein chart list 에서 gene 선택의 경우에는 query.proteinGene = 선택된 gene
    // updated by jdy, 2021-01
    if (mainFilterObject.gene && mainFilterObject.gene.length > 0) { 
      query.majorGene = mainFilterObject.gene;
      query.proteinGene = mainFilterObject.proteinGene;
    }
    
    if (mainFilterObject.snv) {
      query.majorSnv = mainFilterObject.snv
    }
    if (mainFilterObject.cnv) {
      query.majorCnv = mainFilterObject.cnv
    }
    if (mainFilterObject.sv) {
      query.majorSv = mainFilterObject.sv
    }
    if (detailcancer) {
      query.detailCancer = detailcancer
    }
    if (Boolean.toString(mainFilterObject.reported)) {
      query.reported = mainFilterObject.reported
    }
    if (mainFilterObject.sample) {
      query.sample = mainFilterObject.sample
    }
    if (mainFilterObject.primary) {
      query.primary = mainFilterObject.primary
    }
    if (mainFilterObject.gender) {
      query.gender = [mainFilterObject.gender]
    }
    if (mainFilterObject.endAge) {
      query.endAge = mainFilterObject.endAge
    }
    if (mainFilterObject.startAge) {
      query.startAge = mainFilterObject.startAge
    }
    if (variantType.length) {
      query.variantType = variantType
    }
    if (mainFilterObject.start_position && mainFilterObject.chr) {
      query.startPosition = mainFilterObject.start_position
      query.endPosition = mainFilterObject.end_position
      query.chromosome = mainFilterObject.chr.replace('chr ', '').replace('chr', '')
    }
    return query
  }

  setStateFromTableList = () => {
    const { comm: { mainFilterObject } } = this.props;
    let {select} = this.state;

    // select.cancerList = mainFilterObject.cancerList;
    // select.detailcancer = mainFilterObject.detailcancer;
    // select.gene = mainFilterObject.gene;
    // select.snv = mainFilterObject.snv;
    // select.cnv = mainFilterObject.cnv;
    // select.sv = mainFilterObject.sv;


    if (mainFilterObject.cancerList) {
      select.cancerList = mainFilterObject.cancerList;
    }
      
    if (mainFilterObject.detailcancer) {
      select.detailcancer = mainFilterObject.detailcancer;
    }
  
    if (mainFilterObject.gene) { 
      select.gene = mainFilterObject.gene;
    }
    
    if (mainFilterObject.snv) {
      select.snv = mainFilterObject.snv
    }
    if (mainFilterObject.cnv) {
      select.cnv = mainFilterObject.cnv
    }
    if (mainFilterObject.sv) {
      select.sv = mainFilterObject.sv
    }

    if (mainFilterObject.sample) {
      select.sample = mainFilterObject.sample
    }
    if (mainFilterObject.primary) {
      select.primary = mainFilterObject.primary
    }
    if (mainFilterObject.gender) {
      select.gender = mainFilterObject.gender
    }
    if (mainFilterObject.endAge) {
      select.endAge = mainFilterObject.endAge
    }
    if (mainFilterObject.startAge) {
      select.startAge = mainFilterObject.startAge
    }

  }

  onClickMainFilter = async (queryObj) => {

    await this.setState({
      loading: true
    });

    const { home, comm: { originUser, mainFilterObject } } = this.props;
    const { presentTab, absolute, filterTab } = this.state
    
    this.setStateFromTableList();

    const query = this.mainFilterQuery();

    // console.log('query : ', query);
    
    let toolsquery1 = this.mainFilterQuery();
    toolsquery1.sort = ["subject,desc"];

    let toolsquery2 = this.mainFilterQuery();
    toolsquery2.sort = ["subjectCnt,desc"];
    toolsquery2.size = 20;

    let toolsquery3 = this.mainFilterQuery();
    toolsquery3.sort = ["subjectCnt,desc"];
    toolsquery3.size = 3;

    let personSize = query;
    query.sort = ["subject,desc"]

    if (queryObj && queryObj.action === 'protein')
    {
      await home.getProteinData(query);
      setTimeout(function () {
        this.setState({
          loading: false
        });
      }.bind(this), 500);
      return;
    }

    // jdy 2020-12-30
    this.setPresentLoaded(-1, false);

    let array = [];

    // foreground 작업 start
    if (true === this.bNeedFilterUpdate)
    {
      this.setFilterLoaded(-1, false);

      if (filterTab === 0) {
        array.push(home.getCancerTypeData(toolsquery1));
      }
      else if (filterTab === 1) {
        array.push(home.getGeneTypeData(toolsquery2));
      }
      else {
        array.push(home.getVariantTypeListData(toolsquery2));
        array.push(home.getVariantTypeData(toolsquery3));
      }
    }  

    array.push(home.getValueData(query));
    let total = { reported: mainFilterObject.reported, sort: ["subject,desc"] }
    array.push(home.TotalData(total));


    if (originUser && ROLE.indexOf(originUser.authorities[0]) > -1) { // apply all case
      array.push(home.getreturnPersonDate(personSize));
    }

    const promises = array.map(item => (item));
    await Promise.all(promises);

    this.setFilterLoaded(filterTab, true);

    await this.completeWork(presentTab, false);

    this.setState({ refresh: !this.state.refresh });

    console.log('select : ', this.state.select);

    // foreground 작업 end

    // backgrond 작업 시작
    if (true === this.bNeedFilterUpdate)
    {
      this.backFilterThread();
    }

    if (this.webWorkerable) {
      if(presentTab === 0){ //cancer 탭
        this.geneThread();
        this.variantThread();
      }
      else if(presentTab === 1){ //gene 탭 
        this.cancerThread();
        this.variantThread();
      }
      else{ //variant 탭
        this.cancerThread();
        this.geneThread();
      }
    }
  }

  onClickCriteriaFilter = async () => {
    const { home, comm: { mainFilterObject } } = this.props;

    // 현재의 선택 사항들이 유지된 상태에서 update되어야한다. modify by jdy, 2021-01
    // 따라서 onClickMainFilter() 와 동일
  
    this.onClickMainFilter();

  }

  handlePagination = (s, target) => {
    const { home } = this.props;
    const query = this.mainFilterQuery()
    query.page = s.page;
    query.size = s.rowsPerPage

    switch (target) {
      case DetailTableTitle[0]:
        // home.getDetailCancerDate(`?${querylist}`);
        break;
      case DetailTableTitle[1]:
        home.getreturnGeneDate(query);
        break;
      case DetailTableTitle[2]:
        home.getreturnVariantDate(query, 'snvs');
        break;
      case DetailTableTitle[3]:
        home.getreturnVariantDate(query, 'cnvs');
        break;
      case DetailTableTitle[4]:
        home.getreturnVariantDate(query, 'svs');
        break;
      case DetailTableTitle[5]:
        home.getreturnGeneActionData(query); //GeneDrug Table 데이터
        break;
      default:
        home.getreturnPersonDate(query);
        break;
    }
  };

  handleSort = ({ order, orderBy }) => {
    const { home } = this.props;
    home.getreturnPersonDate(`?sort=${orderBy},${order}`);
  };

  onClickOpen = e => {
    e.preventDefault();
    this.setState({
      open: !this.state.open
    });
  };

  clickDataFilterHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_1.png";
    htmlId = 2;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  clickDataStatisticsHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_2.png";
    htmlId = 6;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  // resetState add by jdy 2021-01 : for onClearReset
  resetState = () => {
    // this.setState() 에 의한 rederning 방지 by jdy 2021-01
    this.state.reported = true;
    this.state.detailCancerList = [];
    this.state.select = {
      cancerList: [],
      detailCancer: {},
      gene: [],
      snv: [],
      cnv: [],
      sv: [],
      sample:"",
      primary:"",
      proteinGene:"",
      gender: "",
      age: "",
      value: "",
      variantType: {},
      Variant: [],
    };
    this.state.allChart = false;
    this.state.absolute = true;

    // this.setState({
    //   filterTab: value,
    //   reported: true,
    //   select: {
    //     cancerList: [],
    //     detailCancer: {},
    //     gene: [],
    //     snv: [],
    //     cnv: [],
    //     sv: [],
    //     sample:"",
    //     primary:"",
    //     proteinGene:"",
    //     gender: "",
    //     age: "",
    //     value: "",
    //     variantType: {},
    //     Variant: [],
    //   },
    //   allChart: false,
    //   absolute: true
    // });
    // switch (value) {
    //   case 0:
    //     this.setState({
    //       chartOpen: {
    //         cancer: true,
    //         gene: false,
    //         snv: false,
    //         cnv: false,
    //         sv: false,
    //         dx: false
    //       },
    //     });
    //     break;
    //   case 1:
    //     this.setState({
    //       chartOpen: {
    //         cancer: false,
    //         gene: true,
    //         snv: false,
    //         cnv: false,
    //         sv: false,
    //         dx: false
    //       },
    //     });
    //     break;
    //   case 2:
    //     this.setState({
    //       chartOpen: {
    //         cancer: false,
    //         gene: false,
    //         snv: true,
    //         cnv: true,
    //         sv: true,
    //         dx: false
    //       },
    //     });
    //     break;
    //   default:
    //     break;
    // }
  }

  onClearReset = async (value) => {

    // reStart->onClickCriteriaFilter->onClickMainFilter 에서 onClickMainFilter 함수에서 loading 제어됨

    // await this.setState({
    //   loading: true
    // });

    this.resetState();

    this.getPatientAutoComplete('')

    this.reStart();

    // setTimeout(function () {
    //   this.setState({
    //     loading: false
    //   });
    // }.bind(this), 1500);

  }

  // 왼쪽 탭 메뉴 변경 리스너
  // jdy 2020-12-11
  onChangeFilterTab = async (value) => {

    const {home} = this.props;

    await this.setState({
      loading: true
    });

    let valueTab = value;

    // background 작업 완료여부 check
    if (this.filterLoaded[valueTab] === false) // background 작업 미완시
    {
      const intervalFilter = setInterval(() => {
        if (this.filterLoaded[valueTab] === true) {
          clearInterval(intervalFilter);
          this.setState({
            filterTab: valueTab,
            loading: false
          });
        }
      }, 1000);
    }
    else
    {
      this.setState({
        filterTab: valueTab,
        loading: false,
        //reported: true,
        /* select: {
          ...this.state.select
        }, */
        //allChart: false,
        //absolute: true
      });
    }
    
    this.getPatientAutoComplete('')

  }

  //상단 탭메뉴 추가 리스너 - 권순현
  onChangePresentTab = async (value) => {

    await this.setState({
      loading: true
    });

    if (this.webWorkerable) { // multi-threading 지원시
      // background 작업 완료여부 check
      if (this.presentLoaded[value] === false) // background 작업 미완시
      {
        const interval0 = setInterval(() => {
          if (this.presentLoaded[value] === true) {
            clearInterval(interval0);
            this.handleChangePresentTab(value);
          }
        }, 1000);
      }
      else 
      {
        this.handleChangePresentTab(value);
      }
    }
    else { // multi-threading 미 지원시
      // foreground 작업만 가능
      if (this.presentLoaded[value] === false) // filter item list에 변경이 있는 경우
      { 
        await this.completeWork(value, true); // completeWork 내에서 handleChangePresentTab 이 call 됨.
      } 
      else 
      {
        this.handleChangePresentTab(value);  // filter item list에 변경이 없는 경우
      }

    }
  }

  handleChangePresentTab = (value) => {
    switch (value) {
      case 0:
        this.setState({
          presentTab: value,
          loading: false,
          chartOpen: {  //아래에 데이터셋(테이블) 열기
            cancer: true,
            gene: false,
            drug: false,
            snv: false,
            cnv: false,
            sv: false,
            dx: false
          },
        });
        break;
      case 1:
        this.setState({
          presentTab: value,
          loading: false,
          chartOpen: {
            cancer: false,
            gene: true,
            drug: true,
            snv: false,
            cnv: false,
            sv: false,
            dx: false
          },
        });
        break;
      case 2:
        this.setState({
          presentTab: value,
          loading: false,
          chartOpen: {
            cancer: false,
            gene: false,
            drug: false,
            snv: true,
            cnv: true,
            sv: true,
            dx: false
          },
        });
        break;
      default:
        break;
    }
    this.getPatientAutoComplete('')
  }


  getKeyword = data => {
    const { home, comm: { mainFilterObject } } = this.props;
    const reported = mainFilterObject.reported === false ? "false" : "true"
    home.getKeyWord(`?searchKeyword=${data}&reported=${reported}`)
  };

  insertFilterKeyword = data => {
    const { comm: { mainFilterObject }, } = this.props;
    const { select } = this.state
    let array = []
    let cancer = []
    if (mainFilterObject.gene && mainFilterObject.gene.length >= 5) {
      alert("You can't enter any more")
    } else if (data.label === 'detailCancer' && mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length >= 5) {
      if (mainFilterObject.detailcancer) {
        cancer = mainFilterObject.detailcancer
      }
      if (cancer[data.parentCancer]) {
        const idx = cancer[data.parentCancer].findIndex(function (item) {
          return item.id === data.value
        });
        if (idx === -1) {
          cancer[data.parentCancer].push({ id: data.value, name: data.name })
          mainFilterObject.detailcancer = cancer
          this.setState({
            select: {
              ...select,
              detailCancer: mainFilterObject.detailcancer
            }
          })
          this.onClickMainFilter()
        }
      } else {
        alert("You can't enter any more")
      }
    } else if (data.label === 'cancer' &&
      ((mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length >= 5) || (mainFilterObject.cancerList && mainFilterObject.cancerList.length >= 5))) {
      if (mainFilterObject.detailcancer[data.value] && mainFilterObject.detailcancer[data.value].length <= data.detailCancers.length) {
        for (let i of data.detailCancers) {
          cancer.push({ id: i.id, name: i.name })
        }
        mainFilterObject.detailcancer[data.value] = cancer
        this.setState({
          select: {
            ...select,
            detailCancer: mainFilterObject.detailcancer
          }
        })
        this.onClickMainFilter()
      }
      else {
        alert("You can't enter any more")
      }
    } else {
      switch (data.label) {
        case 'gene':
          if (mainFilterObject.gene) {
            array = mainFilterObject.gene
          }
          if (array.indexOf(data.name) === -1) {
            array.push(data.name)
          }
          mainFilterObject.gene = array
          this.setState({
            select: {
              ...select,
              gene: mainFilterObject.gene
            }
          })
          break;
        case 'cancer':
          for (let i of data.detailCancers) {
            cancer.push({ id: i.id, name: i.name })
          }
          if (mainFilterObject.detailcancer) {
            mainFilterObject.detailcancer[data.value] = cancer
          } else {
            mainFilterObject.detailcancer = { [data.value]: cancer }
          }
          if (mainFilterObject.cancerList) {
            const idx = mainFilterObject.cancerList.findIndex(function (item) {
              return item.name === data.value
            });
            if (idx === -1) {
              mainFilterObject.cancerList.push({ id: Number.parseInt(data.name), name: data.value })
            }
          } else {
            mainFilterObject.cancerList = [{ id: Number.parseInt(data.name), name: data.value }]
          }
          this.setState({
            select: {
              ...select,
              cancerList: mainFilterObject.cancerList,
              detailCancer: mainFilterObject.detailcancer
            }
          })
          break;
        case 'detailCancer':
          if (mainFilterObject.detailcancer) {
            cancer = mainFilterObject.detailcancer
          }
          if (cancer[data.parentCancer]) {
            const idx = cancer[data.parentCancer].findIndex(function (item) {
              return item.id === data.value
            });
            if (idx === -1) {
              cancer[data.parentCancer].push({ id: data.value, name: data.name })
            }
          } else {
            cancer[data.parentCancer] = [{ id: data.value, name: data.name }]
          }
          mainFilterObject.detailcancer = cancer
          this.setState({
            select: {
              ...select,
              detailCancer: mainFilterObject.detailcancer
            }
          })
          break;
        case 'hgvsp1digit':
          //hgvsp1digitResults snv
          if (mainFilterObject.snv) {
            array = mainFilterObject.snv
          }
          if (array.indexOf(data.name) === -1) {
            array.push(data.name)
          }
          mainFilterObject.snv = array
          this.setState({
            select: {
              ...select,
              snv: mainFilterObject.snv
            }
          })
          break;
        case 'cnv':
          if (mainFilterObject.cnv) {
            array = mainFilterObject.cnv
          }
          if (array.indexOf(data.name) === -1) {
            array.push(data.name)
          }
          mainFilterObject.cnv = array
          this.setState({
            select: {
              ...select,
              cnv: mainFilterObject.cnv
            }
          })
          break;
        case 'sequenceAlteration':
          if (mainFilterObject.variantType) {
            array = mainFilterObject.variantType
          }
          if (array.indexOf(data.name) === -1) {
            array.push(data.name)
          }
          mainFilterObject.variantType = array
          //Case 삭제
          break;
        case 'svResult':
          if (mainFilterObject.sv) {
            array = mainFilterObject.sv
          }
          if (array.indexOf(data.name) === -1) {
            array.push(data.name)
          }
          mainFilterObject.sv = array
          this.setState({
            select: {
              ...select,
              sv: mainFilterObject.sv
            }
          })
          break;
        default:
          break;
      }
      this.onClickMainFilter()

    }
  }

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  }

  getPatientAutoComplete = value => {
    this.getKeyword(value)
  };

  handleScroll = (event) => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    }
    else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  }

  // chartOpen = async (type) => {
  //   const { filterTab, chartOpen } = this.state
  //   switch (type) {
  //     case 'cancer':
  //       if (filterTab !== 0) {
  //         await this.setState({
  //           chartOpen: {
  //             ...chartOpen,
  //             cancer: !chartOpen.cancer,
  //           }
  //         });
  //       }
  //       break;
  //     case 'gene':
  //       if (filterTab !== 1) {
  //         await this.setState({
  //           chartOpen: {
  //             ...chartOpen,
  //             gene: !chartOpen.gene,
  //           }
  //         });
  //       }
  //       break;
  //     case 'snv':
  //       if (filterTab !== 2) {
  //         await this.setState({
  //           chartOpen: {
  //             ...chartOpen,
  //             snv: !chartOpen.snv,
  //           }
  //         });
  //       }
  //       break;
  //     case 'cnv':
  //       if (filterTab !== 2) {
  //         await this.setState({
  //           chartOpen: {
  //             ...chartOpen,
  //             cnv: !chartOpen.cnv,
  //           }
  //         });
  //       }
  //       break;
  //     case 'sv':
  //       if (filterTab !== 2) {
  //         await this.setState({
  //           chartOpen: {
  //             ...chartOpen,
  //             sv: !chartOpen.sv
  //           }
  //         });
  //       }
  //       break;
  //     case 'dx':
  //       await this.setState({
  //         chartOpen: {
  //           ...chartOpen,
  //           dx: !chartOpen.dx
  //         }
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  //   const { chartOpen: open } = this.state
  //   if (open.cancer && open.gene && open.snv && open.cnv && open.sv) {
  //     this.setState({
  //       allChart: true
  //     });
  //   } else {
  //     this.setState({
  //       allChart: false
  //     });
  //   }
  // }

  handleChartOpen = (type) => {
    const { presentTab, chartOpen } = this.state
    switch (type) {
      case 'cancer':
        this.setState({
          chartOpen: {
            ...chartOpen,
            cancer: !chartOpen.cancer,
          }
        });
        break;
      case 'gene':
        this.setState({
          chartOpen: {
            ...chartOpen,
            gene: !chartOpen.gene,
          }
        });
        break;
      case 'drug':
      this.setState({
        chartOpen: {
          ...chartOpen,
          drug: !chartOpen.drug,
        }
      });
      break;
      case 'snv':
        this.setState({
          chartOpen: {
            ...chartOpen,
            snv: !chartOpen.snv,
          }
        });
        break;
      case 'cnv':
        this.setState({
          chartOpen: {
            ...chartOpen,
            cnv: !chartOpen.cnv,
          }
        });
        break;
      case 'sv':
        this.setState({
          chartOpen: {
            ...chartOpen,
            sv: !chartOpen.sv,
          }
        });
        break;
      case 'dx':
        this.setState({
          chartOpen: {
            ...chartOpen,
            dx: !chartOpen.dx,
          }
        });
        break;
      default:
        break;
    }
    const { chartOpen: open } = this.state
    if (open.cancer && open.gene && open.snv && open.cnv && open.sv && open.drug) {
      this.setState({
        allChart: true
      });
    } else {
      this.setState({
        allChart: false
      });
    }
  }

  chartAllOpen = async (type) => {
    const { chartOpen } = this.state
    switch (type) {
      case 'cancer':
        await this.setState({
          chartOpen: {
            ...chartOpen,
            cancer: true,
          }
        });
        break;
      case 'gene':
        await this.setState({
          chartOpen: {
            ...chartOpen,
            gene: true,
          }
        });
        break;
      case 'drug':
        await this.setState({
          chartOpen: {
            ...chartOpen,
            drug: true,
          }
        });
        break;
      case 'snv':
        await this.setState({
          chartOpen: {
            ...chartOpen,
            snv: true,
            cnv: true,
            sv: true
          }
        });
        break;
      default:
        break;
    }
  }

  allChartOpen = () => {
    const { allChart } = this.state
    if (!allChart) {
      this.setState({
        chartOpen: {
          cancer: true,
          gene: true,
          drug: true,
          snv: true,
          cnv: true,
          sv: true,
          dx: true
        },
        allChart: true
      });
    } else {
      this.setState({
        chartOpen: {
          cancer: false,
          gene: false,
          drug: false,
          snv: false,
          cnv: false,
          sv: false,
          dx: false
        },
        allChart: false
      });
    }
  }

  isAbsolute = async () => {
  
    const { absolute, filterTab } = this.state
    const { home } = this.props
    await this.setState({
      absolute: !absolute,
      loading: true
    });
    const query = this.mainFilterQuery()
    query.sort = ["subject,desc"]
    let querychartsize = this.mainFilterQuery()
    querychartsize.size = 20
    querychartsize.sort = ["subjectCnt,desc"]
    home.getValueData(query)
    if (!absolute) {
      let absoluteArray = [
        home.getAbsoluteData('cancers', query, filterTab),
        home.getAbsoluteData('genes', querychartsize, filterTab),
        // home.getAbsoluteData('position', querychartsize, filterTab),
        home.getAbsoluteData('variants/snvs', querychartsize, filterTab),
        home.getAbsoluteData('variants/cnvs', querychartsize, filterTab),
        home.getAbsoluteData('variants/svs', querychartsize, filterTab)
                
      ]
      const promises = absoluteArray.map(item => (item));
      await Promise.all(promises);
    } else {
      let array = [
        home.getCancerChartData(query),
        home.getAllGeneDate(querychartsize),
        home.getAllVarientDate(querychartsize, "snvs"),
        home.getAllVarientDate(querychartsize, "cnvs"),
        home.getAllVarientDate(querychartsize, "svs")
      ]
      const promises = array.map(item => (item));
      await Promise.all(promises);
    }
    home.getDxGraphicsData(query, !absolute)
    await this.setState({
      loading: false
    });
  }


  setReport = async () => {
    const { comm: { mainFilterObject } } = this.props;
    const { reported } = this.state

    // onClickCriteriaFilter->onClickMainFilter 에서 onClickMainFilter 함수에서 loading 제어됨

    // await this.setState({
    //   loading: true
    // });
    mainFilterObject.reported = !reported;
    this.setState({
      reported: !reported
    });
    if (!reported === false) {
      mainFilterObject.reportedFilter = `Reported : All`;
    } else {
      mainFilterObject.reportedFilter = '';
    }

    // await this.onClickCriteriaFilter();
    // await this.setState({
    //   loading: false
    // });

    this.onClickCriteriaFilter(); // onClickMainFilter call
  };

  downloadPatient = async () => {
    const { home } = this.props
    await this.setState({
      loading: true
    });
    let personSize = this.mainFilterQuery()
    personSize.size = 10000;
    await home.getRowDataSearchPerson(personSize)
    const { home: { rowDataSearchPerson } } = this.props
    await this.setState({
      loading: false
    });
    let ws = XLSX.utils.json_to_sheet(rowDataSearchPerson.content);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patient");
    XLSX.writeFile(wb, "PERSON_list.xlsx");
  }

  // TSV file dowload 구현 : add by jdy 2020-12
  // downloadTsvPatient(), downloadTxtFile()
  downloadTsvPatient = async () => {
    const { home } = this.props
    await this.setState({
      loading: true
    });
    let personSize = this.mainFilterQuery()
    personSize.size = 10000;
    await home.getRowDataSearchPerson(personSize)
    const { home: { rowDataSearchPerson } } = this.props
    await this.setState({
      loading: false
    });

    let textContent = "";
    
    rowDataSearchPerson.content.map((data, i) => {
      textContent += data.personSourceValue;
      textContent += "\t";
      textContent += data.sex;
      textContent += "\t";
      textContent += data.age;
      textContent += "\t";
      textContent += data.cancer;
      textContent += "\t";
      textContent += data.cancerDetail;
      textContent += "\t";
      textContent += data.geneCount;
      textContent += "\t";
      textContent += data.variantCount;
      textContent += "\n";
    });

    
    this.downloadTxtFile(textContent);
  }
 
  downloadTxtFile = (textContent) => {
    const element = document.createElement("a");
    // const file = new Blob([document.getElementById('myInput').value], {type: 'text/plain'});
    const file = new Blob([textContent], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "patientFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  render() {

    const { refresh, filterTab, select, chartOpen, absolute, loading, reported, presentTab } = this.state;
    const {
      classes,
      home: {
        lastUpdateDate,
        cancerTableData,
        cancerChartData,
        cancerTypeData,
        geneTypeData,
        variantTypeListData,
        variantTypeData,
        returnPersonData,
        returnGeneData,
        returnGeneActionData,
        returnSnvData,
        returnCnvData,
        returnSvData,
        allGeneData,
        allSnvData,
        allCnvData,
        allSvData,
        totalData,
        valueData,
        downloadData,
        returnKeyWord,
        PersonGraphicsData,
        variantCategory,
        SampleType,
        primarySite,
        // proteinData, 여기서 사용하지 않기 때문에 굳이 props로 가지고 있을 필요가 없다. 다만 update를 위한 action은 여기서 한다. Store의 장점
        //GeneGraphicsData, // by jdy 2020-11-27 유전자 포지션 그래프를 위한 추가 data 
        dxAge,
        absoluteData
      },
      comm: { originUser, mainFilterObject },
      analysis: { manageSet },
      location: { pathname } //,
      // onChangeFilterTab
    } = this.props;
    const POSTQUERY = this.mainFilterQuery()
    // tabList[0] = tabList[0] + "(" + (valueData ? valueData[2] : 0 ) + ")";
   
    let mainDataType = {}
    mainDataType.cancer = { type: 'Cancer', title: DetailTableTitle[0], columns: detailCancerColumns, data: cancerTableData, chart: cancerChartData, value: valueData ? valueData[2] : 0 }
    mainDataType.gene = { type: 'Gene', title: DetailTableTitle[1], columns: genecolumns, data: returnGeneData, chart: allGeneData, value: valueData ? valueData[0] : 0 }
    mainDataType.snv = { type: 'SNV', title: DetailTableTitle[2], columns: snvcolumns, data: returnSnvData, chart: allSnvData, value: valueData ? valueData[4] : 0 }
    mainDataType.cnv = { type: 'CNV', title: DetailTableTitle[3], columns: cnvcolumns, data: returnCnvData, chart: allCnvData, value: valueData ? valueData[5] : 0 }
    mainDataType.sv = { type: 'SV', title: DetailTableTitle[4], columns: svcolumns, data: returnSvData, chart: allSvData, value: valueData ? valueData[6] : 0 }
    mainDataType.drug = { type: 'Drug', title: DetailTableTitle[5], columns: geneActioncolumns, data: returnGeneActionData, chart: allGeneData, value: valueData ? valueData[0] : 0 }
    return (
      <>
        <StickyContainer>

          <Sticky  >
            {({ style }) => (
              <div style={style} className={classes.Top}>
                <PresentHeader />
                <div className={classes.selectContainer}>
                  <div className={classes.marginContainger}>
                    <SubjectSample list={cancerTableData} totalData={totalData} />
                    <TypeSubject valueData={valueData} />

                    <div className={classes.release}>
                      Last Data Release - {lastUpdateDate ? dateFormat(lastUpdateDate, "mediumDate") : "Oct 30, 2019"}
                    </div>


                    {/* <CheckPermission
                      key={"report"}
                      permission={"ROLE_KM_USER"}
                    >
                      <div className={classes.panelmargin}>
                        <ReportedStatus onClickEvent={this.onClickCriteriaFilter} mainFilterObject={mainFilterObject} state={select} />
                      </div>
                    </CheckPermission> */}
                  </div>
                </div>
              </div>
            )}
          </Sticky>
          <PageTemplate
            pathname={pathname}
            content={
              <div className={classes.container}>
                <LeftMenu   //왼쪽탭메뉴
                  refresh={refresh}
                  filterTab={filterTab}
                  onClearReset={this.onClearReset}
                  onChangeFilterTab={this.onChangeFilterTab}
                  select={select}
                  cancerTypeData={cancerTypeData}
                  geneTypeData={geneTypeData}
                  variantTypeListData={variantTypeListData}
                  variantTypeData={variantTypeData}
                  returnKeyWord={returnKeyWord}
                  getKeyword={this.getKeyword}
                  insertFilterKeyword={this.insertFilterKeyword}
                  mainFilterObject={mainFilterObject}
                  onClickMainFilter={this.onClickMainFilter}
                  onClickCriteriaFilter={this.onClickCriteriaFilter}
                // posQuery={this.onClickOpen}
                />

                <div style={{ width: 'calc(100% - 405px)', padding: "19px 100px 0px 0px", minHeight: "250px" }}>
                  <div >
                    <div className={classes.container}>
                      <div className={classes.filterContainer}>
                        <div className={classes.filtermarginContainger}>
                          <div className={classes.filters}>
                            <AppliedFilter dxAge={dxAge} mainFilterObject={mainFilterObject}
                              cancerTypeData={cancerTypeData} posQuery={this.onClickOpen}
                              originUser={originUser} />
                          </div>
                        </div>
                      </div>
                      <IconButton color="primary" className={classes.helpIconButton} onClick={this.clickDataStatisticsHelp}>
                        <HelpIcon className={classes.helpIcon} />
                      </IconButton>
                      <CustomDialog
                        open={this.state.helpOpen}
                        onClickOpen={this.onClose}
                        imageUrl={this.state.imageUrl}
                        htmlId={this.state.htmlId}
                        CustomDialogContent={HelpContentList}
                      />
                      <div className={classes.filterContainerReport}>
                      {/* {(presentTab === 0 && select.gene.length === 0 && select.snv.length === 0 &&
                          select.cnv.length === 0 && select.sv.length === 0) ?
                          <ReportedStatus title={"Chart Option"} left={"Relative"} right={"Absolute"} checked={absolute} onchange={this.isAbsolute} />
                          : null} */}
                        <ReportedStatus title={"Chart Option"} left={"Relative"} right={"Absolute"} checked={absolute} onchange={this.isAbsolute} />
                      </div>
                    </div>

                  {/* <div className={classes.chartNameTitle}>STATUS</div> */}
                      <CheckPermission
                        key={"report"}
                        permission={"ROLE_KM_USER"}
                      >
                        <ReportedStatus title={"Data Option"} left={"All"} right={"Reported"} checked={reported} onchange={this.setReport} />
                        {/* {(presentTab === 0 && select.gene.length === 0 && select.snv.length === 0 &&
                          select.cnv.length === 0 && select.sv.length === 0) ? 
                          <span style={{ float: "right", fontSize: "13px", padding: "0px 5px" }}> | </span> : null} */}
                      </CheckPermission>

                    <div style={{ height: "29px" }}>
                      {/* 메인 탭 메뉴 추가 - 권순현 */}
                      <div className={classes.tabhead}>
                        <TabHeaderData
                          tabList={tabList}
                          value={presentTab}
                          onChangeTab={this.onChangePresentTab}
                          tabstyle={classes.tabsize}
                          valueData={valueData}
                          POSTQUERY={POSTQUERY}
                        />
                      </div>

                    </div>

                    <div className={classes.zIndex1}>
                      {/* 차트그래프 */}
                      <DataPageTable mainDataType={mainDataType} mainFilterObject={mainFilterObject} 
                        onClickMainFilter={this.onClickMainFilter}
                        chartOpen={this.handleChartOpen} absolute={absolute} variantCategory={variantCategory}
                        PersonGraphicsData={PersonGraphicsData} SampleType={SampleType} primarySite={primarySite} dxAge={dxAge} filterTab={filterTab} 
                        presentTab={presentTab} 
                        checked={chartOpen} absoluteData={absoluteData}
                        cancerTypeData={cancerTypeData} posQuery={this.onClickOpen} originUser={originUser}
                      />


                    </div>
   

                  </div>
                  {/* 데이터 테이블셋 */}
                  <div className={classes.details}>
                    {chartOpen.dx ? <DxTable dxAge={dxAge} /> : null}
                    <Element name={'cancer'}>
                      {chartOpen.cancer ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.cancer} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    </Element>
                    <Element name={'gene'}>
                      {chartOpen.gene ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.gene} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    </Element>
                    <Element name={'drug'}>
                      {chartOpen.drug ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.drug} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    </Element>
                    <Element name={'snv'}>
                      {chartOpen.snv ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.snv} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    </Element>
                    {chartOpen.cnv ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.cnv} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    {chartOpen.sv ? <DetailBoard onClickMainFilter={this.onClickMainFilter} isLink={true} isSubject={true} detailType={mainDataType.sv} handlePagination={this.handlePagination} stateList={this.state.detailCancerList} /> : null}
                    <Element name={'Patient'}>
                      <CheckPermission
                        key={"patient"}
                        permission={"ROLE_KM_USER"}
                      >
                        <div className={classes.chartMargin}>
                          <div className={classes.chartTitle}>PATIENT LIST ({returnPersonData ? returnPersonData.totalElements.toLocaleString() : 0}) </div>
                          <PatientListTable
                            isLink={false}
                            classes={classes}
                            downloadData={downloadData}
                            returnPersonData={returnPersonData}
                            PatientColumns={PatientColumns}
                            handlePagination={this.handlePagination}
                            downloadPatient={this.downloadPatient}
                            downloadTsvPatient={this.downloadTsvPatient}
                            detailType={mainDataType.cancer}
                          />
                        </div>
                      </CheckPermission>
                    </Element>
                  </div>
                  <Footer lastUpdateDate={lastUpdateDate} />
                </div>
                <CustomDialog
                  open={this.state.open}
                  onClickOpen={this.onClickOpen}
                  type={'Cart'}
                  CustomDialogContent={DetailCartSet}
                  POSTQUERY={POSTQUERY}
                  valueData={valueData}
                  list={mainFilterObject}
                  originUser={originUser}
                  manageSet={manageSet}
                  cancerTypeData={cancerTypeData}
                />
                <Dialog
                  open={loading}
                  classes={{ paper: classes.loadingPaper }}
                  maxWidth={false}
                >
                  <DialogContent className={classes.loading}>
                    <CircularProgress
                      classes={{ colorPrimary: classes.circlecolor }}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            }
          />
        </StickyContainer>
        {this.state.scrolling ? <RightScroll state={chartOpen} chartOpen={this.chartAllOpen} /> : null}

      </>
    );
  }
}

export default withStyles(styles)(DataPage);
