import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/footerlogo.svg";
import TopNav from "components/molecules/TopNav";
// import CustomBtn from "components/atoms/CustomBtn";
import CustomButton from "components/atoms/CustomButton";
import { withStyles } from "@material-ui/core/styles";
// import CustomPopover from "components/atoms/CustomPopover";
import Cart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import Exit from "@material-ui/icons/ExitToApp";
// import CommentIcon from '@material-ui/icons/Comment';
// import CustomDialog from "components/atoms/CustomDialog";
// import Question from "components/atoms/CustomDialog/Question";
// import CheckPermission from "components/lib/CheckPermission";

const styles = theme => ({
  header: {
    borderBottom: "1px solid #868686",
    fontWeight: "normal"
  },
  centered: {
    display: "flex",
    padding: "5px 10px",
    alignItems: "center"

    // "@media (max-width: 1500px)": {
    //   width: '1240px',
    //   margin: 'auto',
    //   padding: "0px"
    // },
  },
  logo: {
    width: "200px",
    height: "34px"
  },
  btns: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end"
  },
  cart: {
    width: "16px",
    height: "16px",
    color: "#ffffff",
    marginRight: "5px"
  },
  person: {
    width: "15px",
    height: "15px",
    color: "#dedede",
    marginRight: "5px"
  },
  headerContact: {
    paddingTop: "15px",
    paddingRight: "10px",
    fontSize: "10px",
    color: "#d1d1d1",
    cursor: "pointer",
    textDecoration: "underline"
  }
});

@inject("comm", "auth")
@observer
class HomeHeaderContainer extends Component {
  state = {
    open: false
  };

  popoverListClick = ({ path }) => {
    const { auth } = this.props;
    alert("Sign out");
    auth.logout();
    // }
    // history.push(path);
  };

  onClickOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const {
      classes,
      comm: { loggedIn, originUser },
      pathname
    } = this.props;
    const { open } = this.state;
    return (
      <>
        <header>
          <div className={classes.header}>
            <div className={classes.centered}>
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
              <TopNav pathname={pathname} />
              <div className={classes.btns}>
                {/* <CheckPermission
                  key={"report"}
                  permission={"ROLE_DEFAULT_USER"}
                >
                  <div style={{ color: "#d1d1d1", paddingRight: "5px", paddingTop: "15px" }}> <CommentIcon style={{ fontSize: "14px" }} /></div>
                  <div className={classes.headerContact} onClick={this.onClickOpen}> Q&A</div>
                </CheckPermission> */}
                <Link to="/cart">
                  <CustomButton
                    text={"DataSet List"}
                    img={Cart}
                    style={classes.cart}
                  />
                </Link>
                {!loggedIn ? (
                  <Link to="/signin">
                    <CustomButton
                      text={"Login"}
                      img={Person}
                      style={classes.person}
                    />
                  </Link>
                ) : (
                  // <CustomPopover
                  //   user={originUser}
                  //   CustomButton={CustomButton}
                  //   popoverList={[
                  //     {
                  //       name: "EDIT PROFILE",
                  //       path: "/my-page",
                  //       permission: "ROLE_KM_USER"
                  //     },
                  //     {
                  //       name: "LOGOUT",
                  //       path: "/",
                  //       permission: "ROLE_KM_USER"
                  //     }
                  //   ]}
                  //   popoverListClick={this.popoverListClick}
                  // />
                  <>
                    <Link to="/my-page">
                      <CustomButton
                        text={
                          originUser
                            ? ` ${originUser.firstName} ${
                                originUser.lastName
                              }(${originUser.roleName || "ROLENAME"})`
                            : "null"
                        }
                        img={Person}
                        style={classes.person}
                      />
                    </Link>
                    <Link to="/" onClick={this.popoverListClick}>
                      <CustomButton
                        text={"Logout"}
                        img={Exit}
                        style={classes.person}
                      />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        {/* <CustomDialog
          open={open}
          onClickOpen={this.onClickOpen}
          type={'QA'}
          CustomDialogContent={Question}
        /> */}
      </>
    );
  }
}

export default withStyles(styles)(HomeHeaderContainer);
