import { observable, action } from "mobx";

class SearchFormStore {
  @observable value = "";
  @observable suggestions = [];

  constructor(root) {
    this.root = root;
  }

  @action
  setValue(value) {
    console.log("store value", value);
    // this.value = value;
  }

  @action
  setSuggestions(suggestions) {
    console.log("store", suggestions);
    // this.suggestions = suggestions;
  }
}

export default SearchFormStore;
