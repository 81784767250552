const basicColorList = [
  // "#206e70",
  // "#26a1a4",
  // "#67babc",
  // "#a0d2d3",
  // "#cae6e6",
  "#5c4306",
  "#8a6c1c",
  "#ca9918",
  "#ffc429",
  "#f6de9f",
];
// let variants = ['snvs', 'cnvs', 'svs']

// Gene presentTab 의 Drug Actionable Chart 연계 model , by jdy 2020-12

class DrugActionableModel {
  constructor(data, select) {
    this.id = []
    this.name = []
    this.totalSubject = []
    this.selectSubject = []
    this.selectedTotalSubject = []
    this.totalSubjectCnt = []
    this.selected = {}
    this.selectedvariant = {}
    this.series = []
    this.tickPositions = []
    this.tickPixelInterval = 30
    this.tickInterval = 20
    this.marginLeft = 60
    this.breakSize = 100
    
    for (let i of data) {
      this.id.push(i.id);
      if (i.geneName.indexOf('|') > -1) {
        this.name.push(`${i.geneName.slice(0, i.geneName.indexOf('|') + 1)}...`);
      } else {
        this.name.push(i.geneName);
      }
      this.selectSubject.push(i[select] ? i[select].subjectCnt : 0); // selected 0
      this.totalSubject.push(i.subjectCnt); // 1
 
      this.selectedTotalSubject.push(i.selectedTotalSubjectCnt); // 2

      this.totalSubjectCnt.push(i.totalSubjectCnt); //3


    }

    if (this.selectedTotalSubject[0] === this.totalSubjectCnt[0]) {
      this.breaksFrom = this.totalSubject.reduce(function (a, b) {
        return Math.max(a, b);
      });
      this.selectedTotalSubject = []
    } else {
      this.breaksFrom = this.totalSubject.reduce(function (a, b) { return Math.max(a, b); }) > this.selectedTotalSubject[0] ?
        this.totalSubject.reduce(function (a, b) { return Math.max(a, b); }) : this.selectedTotalSubject[0]
    }

    this.breaksFrom = this.totalSubject.reduce(function (a, b) {
      return Math.max(a, b);
    });
      
    this.maxTotalSubjectCnt = this.totalSubjectCnt[0];// + this.breaksFrom;
    
    let initPos = 0;
    let intervalPos = 10;

    let act = this.breaksFrom;

    let band = Math.ceil(act / 4);
  
    this.breaksFrom = act + band;
    this.breaksTo = this.maxTotalSubjectCnt - band;

    this.breakSize = 0;

    let interval = act; //Math.ceil(act / 2);

    this.tickPositions.push(0);

    initPos =  interval / 3.0;

    let iPos = 1;
    for (let ip=1; ip<10; ip++)
    {
      iPos = iPos * 10;

      if (initPos < iPos) {
        break;
      }
    }

    iPos /= 10;

    intervalPos = Math.ceil(initPos / iPos) * iPos;
      
    initPos = intervalPos;

    this.tickPositions.push(initPos);

    for (let iLoop=0; iLoop<2; iLoop++)
    {
      if (initPos < interval)
      {
        initPos += intervalPos;
        this.tickPositions.push(initPos);
      }
    }

    this.tickPositions.push(this.totalSubjectCnt[0]);

    // this.tickInterval = Math.ceil(act / 2);

    // Original
    // this.tickPixelInterval = this.totalSubject[0] > 100 ? 5 : 2
    // this.breaksFrom = this.breaksFrom + 70
    // this.breaksTo = this.totalSubjectCnt[0] - 10

    this.series = [
      {
        name: 'K-MASTER Total ',
        data: this.totalSubjectCnt,
        color: "#c6c6c6",
        stack: 2
      },
      {
        name: 'Selected DataSet Total ',
        data: this.selectedTotalSubject,
        color: "#7b7b7b",
        stack: 3
      },
      {
        name: 'DataSet Total ',
        data: this.totalSubject,
        color: "#26a1a4",
        stack: 0
      }
    ]
    if (Object.keys(this.selected).length) {
      Object.keys(this.selected).map((data, k) => {
        this.series.push({
          name: data,
          data: this.selected[data],
          color: basicColorList[k],
          stack: 1
        })
        return null
      })
    } else if (Object.keys(this.selectedvariant).length) {
      Object.keys(this.selectedvariant).map((data, k) => {
        this.series.push({
          name: data,
          data: this.selectedvariant[data],
          color: basicColorList[k],
          stack: 1
        })
        return null
      })
    } else {
      this.series.push(
        {
          name: 'Selected DataSet ',
          data: this.selectSubject,
          color: "#ffc019",
          stack: 1
        }
      )
    }


  }
}

export default DrugActionableModel;
