import React, { Component } from "react";
import CustomTable from "components/atoms/CustomTable";
import MultiCell from "components/atoms/CustomTable/MultiCell";
import { animateScroll as scroll } from "react-scroll";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { withStyles } from "@material-ui/core/styles";

const columnMap = new Map()
  .set("gene-all", [
    { column: "Gene", key: "gene", link: false, align: "left", scroll: true },
    {
      column: "Subjects",
      key: "genePersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Samples",
      key: "geneSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Gene freq (%)",
      key: "percentageVariantOfTotal",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Cancer freq (%)",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer subject",
      key: "geneCancerPersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer samples",
      key: "geneCancerSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    }
  ])
  .set("variant-all-snv", [
    {
      column: "Chromosome",
      key: "chromosome",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Start",
      key: "start",
      link: false,
      align: "right",
      scroll: false
    },
    { column: "End", key: "end", link: false, align: "right", scroll: false },
    { column: "Ref", key: "ref", link: false, align: "left", scroll: false },
    { column: "Var", key: "var", link: false, align: "left", scroll: false },
    {
      column: "Hgvsc",
      key: "hgvsc",
      link: false,
      align: "left",
      scroll: false
    },
    { column: "Hgvsp", key: "hgvsp", link: false, align: "left", scroll: true },
    {
      column: "Variant freq (%)",
      key: "percentageVariantOfTotal",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Subjects",
      key: "genePersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Samples",
      key: "geneSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Cancer freq (%)",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer subject",
      key: "geneCancerPersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer samples",
      key: "geneCancerSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    }
  ])
  .set("variant-all-cnv-amp", [
    { column: "Gene", key: "gene", link: false, align: "left", scroll: false },
    {
      column: "MutType",
      key: "mutType",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Variant freq (%)",
      key: "percentageVariantOfTotal",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Subjects",
      key: "genePersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Samples",
      key: "geneSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Cancer freq (%)",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer subject",
      key: "geneCancerPersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer samples",
      key: "geneCancerSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    }
  ])
  .set("variant-all-cnv-del", [
    { column: "Gene", key: "gene", link: false, align: "left", scroll: false },
    {
      column: "MutType",
      key: "mutType",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Variant freq (%)",
      key: "percentageVariantOfTotal",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Subjects",
      key: "genePersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Samples",
      key: "geneSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Cancer freq (%)",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer subject",
      key: "geneCancerPersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer samples",
      key: "geneCancerSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    }
  ])
  .set("variant-all-sv", [
    {
      column: "Gene11",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "gene12",
      key: "gene12",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Variant freq (%)",
      key: "percentageVariantOfTotal",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Subjects",
      key: "genePersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Samples",
      key: "geneSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Cancer freq (%)",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer subject",
      key: "geneCancerPersonCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Cancer samples",
      key: "geneCancerSpecimenCount",
      link: false,
      align: "right",
      scroll: false
    }
  ])
  .set("gene-top-all", [
    {
      column: "Gene",
      key: "name",
      link: false,
      align: "left",
      scroll: false
    },
    {
      column: "Person",
      key: "value",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Specimen",
      key: "specimenCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Total person",
      key: "personTotalCount",
      link: false,
      align: "right",
      scroll: false
    },
    {
      column: "Total specimen",
      key: "personTotalCount",
      link: false,
      align: "right",
      scroll: false
    }
  ]);

const styles = theme => ({
  rightHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  searchBtn: {
    margin: "5px",
    border: "1px solid transparent",
    backgroundColor: "#fff",
    borderColor: "#ccc",
    color: "#000",
    fontFamily: "Open Sans,sans-serif",
    fontWeight: 300,
    padding: "4px 12px"
  }
});

class AllListTableContainer extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.allListTableMap !== this.props.allListTableMap;
  }

  scrollToTop = e => {
    scroll.scrollToTop();
  };

  render() {
    const {
      isPagination,
      allListTableMap,
      isRowSpan,
      selectedGeneCard,
      filter,
      classes
    } = this.props;

    const thStyle = {
      fontSize: "1.4rem",
      width: `${columnMap.get(filter).length / 100}%`
    };

    return (
      <>
        <div className={classes.rightHeader}>
          <ReactHtmlTableToExcel
            className={classes.searchBtn}
            buttonText="Excel"
            table={`alllist`}
            filename={`all_list_table`}
            sheet="Sheet 1"
          />
        </div>
        <CustomTable
          columnList={columnMap.get(filter)}
          tableList={allListTableMap}
          Component={MultiCell}
          isPagination={filter === "gene-top-all" ? true : isPagination}
          isRowSpan={isRowSpan}
          length={allListTableMap.size}
          selectedValue={selectedGeneCard}
          filter={filter}
          id={`alllist`}
          thStyle={thStyle}
        />
      </>
    );
  }
}

export default withStyles(styles)(AllListTableContainer);
