import DetailCancerTableModel from "modules/model/DetailCancerTableModel";


class CancerTableModel {
  constructor(d, i) {
    let select = ""
    let sampleselect = ""
    if (d.selectedSubjectCnt !== d.totalSubjectCnt) {
      select = `/ ${d.selectedSubjectCnt.toLocaleString()}`
      sampleselect = `/ ${d.selectedSpecimenCnt.toLocaleString()}`
    }
    this.details = {}
    this.id = d.id
    this.name = d.name
    this.parentName = d.parentName
    this.hasDetails = d.hasDetails
    this.details = d.details ? d.details.map((data, m) => new DetailCancerTableModel(data, m)) : []
    // this.details = d.details
    this.selectedCancer = d.selectedCancer
    // this.subjectTotal = `${d.subject.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.samplesTotal = `${d.samples.toLocaleString()} ${sampleselect} / ${d.totalSpecimenCnt.toLocaleString()}`
    // this.geneTotal = `${d.gene.toLocaleString()} ${select} / ${d.totalSubjectCnt}`
    // this.variantTotal = `${d.variant.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.snvTotal = `${d.snv.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.cnvTotal = `${d.cnv.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.svTotal = `${d.sv.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    this.subjectTotal = `${d.subject.toLocaleString()}`
    this.samplesTotal = `${d.samples.toLocaleString()}`
    this.geneTotal = `${d.gene.toLocaleString()} ${select} / ${d.totalSubjectCnt}`
    this.variantTotal = `${d.variant.toLocaleString()} ${select}`
    this.snvTotal = `${d.snv.toLocaleString()}`
    this.cnvTotal = `${d.cnv.toLocaleString()}`
    this.svTotal = `${d.sv.toLocaleString()}`
    this.subject = d.subject
    this.samples = d.samples
    this.primary = d.primary
    this.gene = d.gene
    this.variant = d.variant
    this.snv = d.snv
    this.cnv = d.cnv
    this.sv = d.sv
    this.checkTotal = select
    this.selectSubject = d.selectedSubjectCnt
  }
}


export default CancerTableModel;
