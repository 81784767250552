import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"
import Checkbox from "@material-ui/core/Checkbox";

const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
const checkedIcon = <img src={CheckBoxIcon} alt="" />
const styles = theme => ({
  table: {
    margin: '10px 20px',
    boxShadow: "1px 1px 2px 0 rgba(173, 173, 173, 0.8)",

  },
  maintitle: {
    height: '30px',
    width: "100%",
    border: 'solid 1px #dddddd',
    backgroundColor: '#ffffff',
  },
  button: {
    width: '20px',
    minWidth: "30px",
    float: "left"
  },
  icon: {
    width: "15px",
    height: "15px",
    // border: "1px solid #9d9d9d",
    color: "#9d9d9d"
  },
  groupName: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#212121',
    lineHeight: "2.5",
    float: "left"
  },
  setquery: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#212121',
    lineHeight: "2.5",
    paddingRight: "10px",
    float: "right"
  },
  head: {
    width: "100%",
    height: '30px',
    border: 'solid 1px #dddddd',
    borderTop: "0px",
    fontWeight: '500',
    backgroundColor: ' #f3f3f3',
    display: "flex",
    textAlign: "center",
    fontSize: '12px',
    lineHeight: "2.5",
    color: "#000000"
  },
  query: {
    width: "74%",
    paddingLeft: "10px",
    border: "1px solid #dddddd",
    borderTop: "0px ",
    borderBottom: "0px"
  },
  queryTitle: {
    width: "17%",
    paddingLeft: "10px"
  },
  queryPatient: {
    width: "5%"
  },
  querymain: {
    minHeight: '30px',
    width: "100%",
    border: 'solid 1px #dddddd',
    borderTop: "0px",
    backgroundColor: '#ffffff',
    display: 'flex',
    fontSize: '11px',
    lineHeight: "2.5",
  },
  checkquerymain: {
    minHeight: '30px',
    width: "100%",
    border: 'solid 1px #dddddd',
    borderTop: "0px",
    backgroundColor: '#ecfffe',
    display: 'flex',
    fontSize: '11px',
    lineHeight: "2.5",
  },
  diablequerymain: {
    minHeight: '30px',
    width: "100%",
    border: 'solid 1px #dddddd',
    borderTop: "0px",
    backgroundColor: '#ffffff',
    display: 'flex',
    fontSize: '11px',
    lineHeight: "2.5",
    opacity: "0.4"
  },
  querycheckbox: {
    width: "2%",
    paddingLeft: "20px",
    textAlign: "left",
    borderRight: "solid 1px #dddddd"
  },
  querylist: {
    width: "74%",
    paddingLeft: "10px",
    textAlign: "left",
    border: "1px solid #dddddd",
    borderTop: "0px ",
    borderBottom: "0px"
  },
  querylistTitle: {
    width: "17%",
    textAlign: "left",
    paddingLeft: "10px"
  },
  querylistPatient: {
    width: "5%",
    textAlign: "right",
  },
  checkboxs: {
    padding: "0px"
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
  },
});

class Setquery extends Component {

  check = (e) => {
    let setValue = e.target.value.split('||')
    const { checklist, setCehckList, checkReported } = this.props
    if (checklist.indexOf(setValue[0].toString()) > -1) {
      checklist.splice(checklist.indexOf(setValue[0].toString()), 1);
    } else {
      checklist.push(setValue[0])
    }
    checkReported(setValue[1])
    setCehckList(checklist)
  }

  render() {
    const { classes, detail, checklist, reported } = this.props;

    let check = false
    if (checklist.indexOf(detail.setDataId.toString()) > -1) {
      check = true
    }
    // disable = reported=null ? : detail.filter.reported !== reported
    let disable = (checklist.length > 2 && !check) || (reported !== null && detail.filter.reported !== reported)

    return (
      <div className={disable ? classes.diablequerymain : check ? classes.checkquerymain : classes.querymain}>
        <div className={classes.querycheckbox}>
          <Checkbox
            disabled={disable}
            checked={check}
            className={classes.checkboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.selectCancerreencolor }}
            value={`${detail.setDataId}||${detail.filter.reported}`}
            onChange={this.check}
          />
        </div>
        <div className={classes.querylistTitle}>{detail.title}</div>
        <div className={classes.querylist}>{detail.setsQuery}</div>
        <div className={classes.querylistPatient}><span style={{ paddingRight: "10px" }}>{detail.setsPersonNum.toLocaleString()}</span></div>
      </div>
    )
  }
}

class SetMainTable extends Component {

  state = {
    open: true
  }

  open = () => {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { classes, data, ...rest } = this.props;
    const { open } = this.state
    let detail = []
    for (let i of data.setsDataList) {
      detail.push(
        <Setquery classes={classes} detail={i} key={i.setDataId} {...rest} />
      )
    }
    return (
      <div className={classes.table} >
        <div className={classes.maintitle}>
          <div className={classes.button}>
            <Button className={classes.button} onClick={this.open}>
              {open ? <Remove className={classes.icon} /> : <Add className={classes.icon} />}
            </Button >
          </div>
          <div className={classes.groupName} >{data.groupName}</div>
          <div className={classes.setquery} >(Save Query {data.setsDataList.length}) </div>
        </div>
        {open && detail.length ?
          <div>
            <div className={classes.head}>
              <div className={classes.querycheckbox}></div>
              <div className={classes.queryTitle}>Title</div>
              <div className={classes.query}>Query</div>
              <div className={classes.queryPatient}>Patient</div>
            </div>
            {detail}
          </div>
          : null}
      </div>)
  }
}

class NoUserSetquery extends Component {

  check = (e) => {
    const { checklist, setCehckList } = this.props
    if (checklist.indexOf(e.target.value) > -1) {
      checklist.splice(checklist.indexOf(e.target.value), 1);
    } else {
      checklist.push(e.target.value)
    }
    setCehckList(checklist)
  }

  render() {
    const { classes, detail, checklist } = this.props;

    let check = false
    if (checklist.indexOf(detail[0]) > -1) {
      check = true
    }
    let disable = checklist.length > 2 && !check
    let Text = detail[0].split('||')
    return (
      <div className={disable ? classes.diablequerymain : check ? classes.checkquerymain : classes.querymain}>
        <div className={classes.querycheckbox}>
          <Checkbox
            disabled={disable}
            checked={check}
            className={classes.checkboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.selectCancerreencolor }}
            value={detail[0]}
            onChange={this.check}
          />
        </div>
        <div className={classes.querylistTitle}>{Text[0]}</div>
        <div className={classes.querylist}>{Text[2]}</div>
        <div className={classes.querylistPatient}><span style={{ paddingRight: "10px" }}>{Text[3] ? Text[3] : 0}</span></div>
      </div>
    )
  }
}

class NoUserMainTable extends Component {

  state = {
    open: true
  }

  open = () => {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { classes, data, ...rest } = this.props;
    const { open } = this.state
    let detail = []
    for (let i of data) {
      detail.push(
        <NoUserSetquery classes={classes} detail={i} key={i[0]} {...rest} />
      )
    }
    return (
      <div className={classes.table} >
        <div className={classes.maintitle}>
          <div className={classes.button}>
            <Button className={classes.button} onClick={this.open}>
              {open ? <Remove className={classes.icon} /> : <Add className={classes.icon} />}
            </Button >
          </div>
          <div className={classes.groupName} >Set List </div>
          <div className={classes.setquery} >(Save Query {data.length})</div>
        </div>
        {open && detail.length ?
          <div>
            <div className={classes.head}>
              <div className={classes.querycheckbox}></div>
              <div className={classes.queryTitle}>Title</div>
              <div className={classes.query}>Query</div>
              <div className={classes.queryPatient}>Patient</div>
            </div>
            {detail}
          </div>
          : null}
      </div>)
  }
}


class SetListTable extends Component {

  render() {
    const { classes, originUser, manageSet, ...rest } = this.props;
    let arr = []
    if (manageSet) {
      if (originUser) {
        for (let i of manageSet) {
          arr.push(
            <SetMainTable classes={classes} data={i} key={i.groupName} {...rest} />
          )
        }
      } else {
        arr.push(
          <NoUserMainTable classes={classes} data={manageSet} key={manageSet.length} {...rest} />
        )
      }
    }
    return (
      <>
        {arr}
      </>

    );
  }
}

export default withStyles(styles)(SetListTable);
