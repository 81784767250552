import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Sample from "assets/images/dataexplorer/sample.svg";
import Subject from "assets/images/dataexplorer/subject.svg";
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
  textSize: {
    width: "100px",
    lineHeight: '1.5',
    height: "23px",
    border: "solid 1px #d4d4d5",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    backgroundColor: "#ffffff",
    "@media (max-width: 1500px)": {
      fontSize: "10px",
      lineHeight: '2',
    },

  },
  display: {
    display: "flex",
    zIndex: "1",
  },
  total: {
    width: "250px",
    height: "23px",
    lineHeight: '2',
    border: "solid 1px #d4d4d5",
    backgroundColor: "#a5a5a5",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "12px",
    "@media (max-width: 1500px)": {
      width: "210px",
      fontSize: "10px",
      lineHeight: '2.5',
    },
  },
  icon: {
    width: "25px",
    height: "23px",
    border: "solid 1px #d4d4d5",
    backgroundColor: "#f3f3f3",
    borderLeft: "0px",
    borderRight: "0px",
    textAlign: "center"
  },
  title: {
    padding: '7px 0px 0px 10px',
    float: "right"
  },
  tooltip: {
    fontSize: "12px",
    margin: "0px",
    borderRadius: '0px',
    "@media (max-width: 1500px)": {
      fontSize: "10px",
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  arrowPopper: {
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 20,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `rgba(97, 97, 97, 0.9) transparent transparent transparent`,
      },
    },
  },
  test: {
    top: '-8px !important'
  },
  sample: {
    paddingTop: '4px'
  },
  numberpadding: {
    paddingTop: "3px"
  }
});

class SubjectSample extends Component {
  state = {
    arrowRef: null
  }

  setArrowRef = e => {
    // e.preventDefault();
    this.setState({
      arrowRef: e,
    });
  }

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list || this.props.totalData !== nextProps.totalData
    // return true;
  }

  render() {
    const { list, totalData, classes } = this.props;
    let selectSubject = 0
    let selectSample = 0
    let subject = 0;
    let sample = 0;
    if (list) {
      for (let i of list) {
        // console.log(i.subject);
        // selectSubject += i.subject;
        //상단 바 selected 값 변경
        selectSubject = i.selectSubject;
        selectSample += i.samples;
      }
    }
    if (totalData) {
      for (let i of totalData) {
        //상단 바 selectedtotal  값 변경
        subject = i.selectedSubjectCnt;
        sample += i.samples;
      }
    }
    if (selectSubject === subject) {
      selectSubject = 0
    }
    if (selectSample === sample) {
      selectSample = 0
    }
    return (
      <div className={classes.title}      >
        < div className={classes.display} >
          <div className={classes.total}>{'Selected Total / KM Total, unit:#Subjects'}</div>
          {/* <Tooltip
            title={
              <React.Fragment>
                
              <span className={classes.arrow} ref={this.setArrowRef} />
              </React.Fragment>
            }
            placement="top"
            classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(this.state.arrowRef),
                    element: this.state.arrowRef,
                  },
                },
              },
            }}
          > */}
          <Tooltip title="Subjects" >
            <div className={classes.icon}>
              <img src={Subject} alt="" className={classes.sample} />
            </div>
          </Tooltip>
          <div className={classes.textSize}>
            <div className={classes.numberpadding}>
              {`${selectSubject.toLocaleString()} / ${subject.toLocaleString()}`}
            </div>
          </div>

          {/* <Tooltip
            title={
              <React.Fragment>
                Samples
              <span className={classes.arrow} ref={this.setArrowRef} />
              </React.Fragment>
            }
            placement="top"
            classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(this.state.arrowRef),
                    element: this.state.arrowRef,
                  },
                },
              },
            }}
          > */}
          <Tooltip title="Sample" >
            <div className={classes.icon}>
              <img src={Sample} alt="" className={classes.sample} />
            </div>
          </Tooltip>

          <div className={classes.textSize}>
            <div className={classes.numberpadding}>
              {`${selectSample.toLocaleString()} / ${sample.toLocaleString()}`}
            </div>
          </div>
        </div >
      </div>
    );
  }
}

export default withStyles(styles)(SubjectSample);
