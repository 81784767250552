import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import { teal } from "@material-ui/core/colors";
import LineChart from "components/atoms/LineChart";
import DateSelectBox from "components/atoms/DateSelectBox";
import ShowChartIcon from '@material-ui/icons/ShowChart';
const styles = theme => ({
  head: {
    border: "solid 1px #dbdbdb ",
    // borderColor: "white",
    // boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    // margin: "20px",
    // padding: "20px 20px 40px 20px",
    background: "#ffffff",
    // padding: "1px",
    "&:hover": {
      border: "solid 1px #26a1a4 ",
      // padding: "0px",
    }
  },
  body: {
    padding: "20px 10px"
  },
  title: {
    textAlign: "center",
    display: "flex",
    fontSize: "11px",
    color: "#7d7d7d"
  },
  relativepos: {
    display: 'flow-root',
    padding: "5px"
  },
});

class VisitorTable extends Component {

  render() {
    const { data, statics, onChangeVisitor } = this.props;
    const { classes } = this.props;

    return (
      <div className={classes.head}>
        <div className={classes.body}>
          <div className={classes.relativepos}>
            <div className={classes.title}>
              <div style={{ width: "48%", textAlign: "right" }}><ShowChartIcon style={{ fontSize: "20px" }} /></div>
              <div style={{ width: "52%", textAlign: "left", padding: "5px 0px 0px 5px" }}>VISITOR COUNT</div>
            </div>
            <DateSelectBox onChangeDate={onChangeVisitor} />
          </div>
          <LineChart data={data} statics={statics} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VisitorTable);
