import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import ColumnChart from "components/atoms/ColumnChart";
import Absolute3Chart from "components/atoms/AbsoluteChart/Absolute3Chart";
import Absolute2Chart from "components/atoms/AbsoluteChart/Absolute2Chart";
import Absolute1Chart from "components/atoms/AbsoluteChart/Absolute1Chart";
import NoScrollChart from "components/atoms/NoScrollChart";

import CheckIcon from '@material-ui/icons/Check';

import ColChart from "components/atoms/ColChart";
import Pie3Chart from "components/atoms/Pie3Chart";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

// Variant presentTab 선택시 display 되는 chart, added by jdy 2020-11

const status = ['cancer', 'gene', 'snv', 'cnv', 'sv']

const styles = theme => ({
  container: {
    display: 'flex'
  },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "2px 7px 1px 12px",
    fontWeight: "500",
    lineHeight: '26px',
  },
  board2: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "calc(47.5% + 1px)",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "@media (max-width: 1915px)": {
      width: "calc(47.5% + 1px)",
    },
  },
  boardHover3: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "31%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "31%",
    },
  },
  checkBoard3: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "31%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "31%",
    },
  },
  chartWidth45: {
    width: '45%',
    margin: 'auto',
    height: '200px'
  },
  chartWidth95: {
    width: '95%',
    margin: 'auto',
    height: '200px'
  },
  chartWidth30: {
    width: '30%',
    margin: 'auto'
  },
  chartWidth: {
    width: '100%',
    margin: 'auto',
    height: '200px'
  },
  checkBoard: {
    // width: "48.7%",
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "6.5px",
    cursor: 'pointer',
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  disableBoard: {
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "6.5px",
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },

  //아예 화면상에 보이지 않는 CSS
  disableBoardNone: {
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "6.5px",
    display: "none",
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  chartwidthTemp: {
    width: '95%',
    margin: 'auto',
    height: '200px'
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  tabcontainer: {
    background: "#f6f6f6",
    border: "solid 1px #c6c6c6",
    padding: "7.5px",
    display: "flex",
    flexFlow: "wrap"
  },
  chartContainer: {
    display: "flex",
  },
  ageGenderPie: {
    width: "38%",
    padding: "0px"
  },
  ageGender: {
    width: "62%",
    padding: "0px"
  },
  checkboxlist: {
    float: "right",
    fontSize: "10px",
    fontWeight: "normal"
  },
  filterContainer: {
    verticalAlign: 'top',
    backgroundColor: '#ffffff',
    right: "0px",
    width: "100%"
  },
  filtermarginContainger: {
    margin: 'auto',
    padding: "7.5px",
    backgroundColor: "#f6f6f6",
    "@media (max-width: 1500px)": {
      margin: 'auto'
    },
  },
  filters: {
    minHeight: "36px",
    // width: "1525px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    "@media (max-width: 1500px)": {
      margin: 'auto',
    },
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
  },
})

@inject("home")
@observer
class VariantChartPages extends Component {

  state = {
    helpOpen : false,
    imageUrl: "help/2_4_4_1.png",
    htmlId: 7
  }

  annotationHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_4_1.png";
    htmlId = 14;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  interpretHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_4_2.png";
    htmlId = 15;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  snvChartHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_4_3.png";
    htmlId = 16;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  cnvChartHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_4_4.png";
    htmlId = 17;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  svChartHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_4_5.png";
    htmlId = 18;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };
  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  divOpen = (e) => {
    const { chartOpen } = this.props
    chartOpen(e)
  }

  render() 
  {
    const { mainDataType, mainFilterObject, variantCategory, PersonGraphicsData, dxAge, filterTab, checked, absoluteData, absolute, cancerTypeData, posQuery, originUser, classes, presentTab, home: {annotationCategory} } = this.props;

    let snvChart = [];
    let cnvChart = [];
    let svChart = [];

    snvChart.length = 0;
    cnvChart.length = 0;
    svChart.length = 0;


    for (let i of status) 
    {
 
      if(i === 'snv')
      {
        snvChart.push(
          <div className={checked[i] ? classes.checkBoard3 : classes.boardHover3} key={mainDataType[i].type} >
            <div className={classes.chartTitle} onClick={() => this.divOpen(i)} >
              {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
              <span>{mainDataType[i].type}</span>
              <span>
                <IconButton color="primary" className={classes.helpIconButton} onClick={this.snvChartHelp}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </span>  
            </div>
            <div className={classes.chartwidth}>
  
              {absolute ?
                <Absolute1Chart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
                : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
                  <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
                  <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
              }
  
            </div>
          </div >
        );
      }
      else if(i === 'cnv')
      {
        cnvChart.push(
          <div className={checked[i] ? classes.checkBoard3 : classes.boardHover3} key={mainDataType[i].type} >
            <div className={classes.chartTitle} onClick={() => this.divOpen(i)} >
              {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
              <span>{mainDataType[i].type}</span>
              <span>
                <IconButton color="primary" className={classes.helpIconButton} onClick={this.cnvChartHelp}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </span>     
            </div>
            <div className={classes.chartwidth}>
  
              {absolute ?
                <Absolute2Chart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
                : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
                  <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
                  <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
              }
  
            </div>
          </div >
        );
      } 
      else if(i === 'sv')
      {
        svChart.push(
          <div className={checked[i] ? classes.checkBoard3 : classes.boardHover3} key={mainDataType[i].type} >
            <div className={classes.chartTitle} onClick={() => this.divOpen(i)} >
              {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
              <span>{mainDataType[i].type}</span>   
              <span>
                <IconButton color="primary" className={classes.helpIconButton} onClick={this.svChartHelp}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </span>   
            </div>
            <div className={classes.chartwidth}>
  
              {absolute ?
                <Absolute3Chart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
                : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
                  <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
                  <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
              }
  
            </div>
          </div >
        );
      } else {
        continue;
      }
    }
    
    return (

      <div className={classes.tabcontainer}>   

        {/* <div className={classes.board2}>
          <ColChart list={annotationCategory} />
        </div> */}
        <div className={classes.board2}>
          <div className={classes.chartTitle} >
            <span>Annotation DATA</span>
            <span>
              <IconButton color="primary" className={classes.helpIconButton} onClick={this.annotationHelp}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </span>     
          </div>

          <div className={classes.chartwidth}>
            <ColChart list={annotationCategory} />  
          </div>
        </div >

        <div className={classes.board2}>
          <div className={classes.chartTitle} >
            <span>Interpretation Criteria</span>
            <span>
              <IconButton color="primary" className={classes.helpIconButton} onClick={this.interpretHelp}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </span>     
          </div>

          <div className={classes.chartwidth}>
            <Pie3Chart list={variantCategory} />
          </div>
        </div >


        {/* <div className={classes.board2}>
          <Pie3Chart list={variantCategory} />
        </div> */}
          
        {snvChart}
        
        {cnvChart}
        
        {svChart}
        

        <CustomDialog
          open={this.state.helpOpen}
          onClickOpen={this.onClose}
          imageUrl={this.state.imageUrl}
          htmlId={this.state.htmlId}
          CustomDialogContent={HelpContentList}
        />
      </div>
    );

  } 
}


export default withStyles(styles)(VariantChartPages);