import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";

class CustomDialog extends Component {
  render() {
    const { open, CustomDialogContent, onClickOpen, ...rest } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClickOpen}
          aria-labelledby="form-dialog-title"
          maxWidth={false}
        >
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <CustomDialogContent close={onClickOpen} {...rest} />
          {/* <DialogActions>
            <Button onClick={this.handleClickOpen} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleClickOpen} color="primary">
              Subscribe
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}

export default CustomDialog;
