import React, { Component } from "react";
// import Highcharts from "highcharts";
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";

const baseColor = ['#d8d8d8', '#14a4e7']
const Test = [
  {
    id: 45883769,
    name: "Colon/rectal cancer",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 719,
    reported: 72,
    unreported: 600,
    variant: 4085,
    snv: 4015,
    cnv: 33,
    sv: 37
  },
  {
    id: 45884515,
    name: "Breast cancer",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 472,
    reported: 47,
    unreported: 400,
    variant: 1330,
    snv: 1199,
    cnv: 130,
    sv: 1
  },
  {
    id: 45884515,
    name: "Breast cancer4",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 472,
    reported: 47,
    unreported: 400,
    variant: 1330,
    snv: 1199,
    cnv: 130,
    sv: 1
  },
  {
    id: 45884515,
    name: "Breast cancer3",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 472,
    reported: 47,
    unreported: 400,
    variant: 1330,
    snv: 1199,
    cnv: 130,
    sv: 1
  },
  {
    id: 45884515,
    name: "Breast cancer1",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 472,
    reported: 47,
    unreported: 400,
    variant: 1330,
    snv: 1199,
    cnv: 130,
    sv: 1
  },
  {
    id: 45884515,
    name: "Breast cancer2",
    parentName: null,
    hasDetails: true,
    details: [],
    geneList: [],
    snvList: [],
    subject: 472,
    reported: 47,
    unreported: 400,
    variant: 1330,
    snv: 1199,
    cnv: 130,
    sv: 1
  }
]

let e
(function (H) {
  //internal functions
  function stopEvent(e) {
    if (e) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      e.cancelBubble = true;
    }
  }

  //the wrap
  H.wrap(H.Chart.prototype, 'render', function (proceed) {
    var chart = this,
      mapNavigation = chart.options.mapNavigation;
    proceed.call(chart);
    // Add the mousewheel event
    H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'mousewheel', function (event) {
      var delta, extr, step, newMin, newMax, axis = chart.xAxis[0];
      e = chart.pointer.normalize(event);
      // Firefox uses e.detail, WebKit and IE uses wheelDelta
      delta = e.detail || -(e.wheelDelta / 120);
      delta = delta > 0 ? 1 : -1;
      if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
        extr = axis.getExtremes();
        step = (extr.max - extr.min) / 5 * delta;
        axis.setExtremes(extr.min + step, extr.max + step, true, false);
      }
      stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
      return false;
    });
  });
}(Highcharts));



class ColumnChart extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // return true;
  }
  render() {
    // const { list } = this.props;
    let name = [];
    let allSample = [];
    let reviewSample = []
    // let unReviewSample = []
    let options;
    for (let i of Test) {
      reviewSample.push(i.reported)
      allSample.push(i.subject)
      name.push(i.name)
    }
    let series = [
      {
        name: 'REVIEWED',
        data: reviewSample,
        color: baseColor[1],
      },
      {
        name: 'ALL',
        data: allSample,
        color: baseColor[0]
      },
    ]



    options = {
      chart: {
        type: "bar",
        marginRight: 80,
        marginTop: 60
      },
      title: { text: null },
      subtitle: { text: null },
      credits: {
        enabled: false
      },

      xAxis: {
        scrollbar: {
          enabled: true
        },
        tickLength: 0,
        min: 0,
        max: 3,
        categories: name,
        events: {
          afterSetExtremes: function () {
            var xAxis = this,
              numberOfPoints = xAxis.series[0].points.length - 1,
              minRangeValue = xAxis.getExtremes().min,
              maxRangeValue = xAxis.getExtremes().max;

            if (minRangeValue < 0) {
              xAxis.setExtremes(0.2, xAxis.options.max);
            } else if (maxRangeValue > numberOfPoints) {
              xAxis.setExtremes(numberOfPoints - xAxis.options.max + 1, numberOfPoints);
            }
          }
        }
      },
      yAxis: {
        tickPixelInterval: 50,
        //최대값
        softMax: 800,
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          }
        },
        title: null
      },
      legend: {
        // align: 'left','center','right'
        align: 'left',
        verticalAlign: 'top',
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
        }
      },
      series: series,

    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
      // key={this.state.fooKey}
      />
    );
  }
}
export default ColumnChart;
