
class SnvTableModelAnaly {
  constructor(d, i) {
    let select = ""
    if (d.selectedTotalSubjectCnt !== d.totalSubjectCnt) {
      select = `/ ${d.selectedTotalSubjectCnt.toLocaleString()}`
    }
    this.id = d.id
    this.name = d.name
    this.hasDetails = d.hasDetails
    this.selectedCancer = d.selectedCancer
    this.subjectTotal = `${d.subjectCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    this.subjectCnt = d.subjectCnt
    this.sequenceAlteration = d.sequenceAlteration
    this.targetGene1Id = d.targetGene1Id
    this.chromosomeId = d.chromosomeId
    this.hgvsC = d.hgvsC
    this.hgvsP = d.hgvsP
    this.hgvsP1digit = d.hgvsP1digit
    this.checkTotal = select
  }
}

export default SnvTableModelAnaly;
