import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";

const styles = theme => ({
  subject: {
    float: "right"
  },
  checkboxs: {
    padding: "0px!important",
    width: "0.8vw",
    fontSize: "1.2vw !important",
    margin: "2px 5px",
    "& root": {
      fontSize: "1.2vw !important",
    },
  },
  greencolor: {
    color: "#26a1a4 !important"
  },
  tableRow: {
    height: "30px",
    '&$hover:hover': {
      backgroundColor: "#efefef"
    }
  },
  checkName: {
    height: "30px",
    backgroundColor: "#ecfffe",
    '&$hover:hover': {
      backgroundColor: "#ecfffe"
    }
  },
  subjectDiv: {
    backgroundColor: '#ececec',
    textAlign: "center",
    padding: '2px 4px',
    fontSize: "10px",
    color: "#434343",
    borderRadius: '10px'
  },
  hover: {},
  boxCell: {
    width: "5%",
    padding: "0px!important"
  },
  checkBoxCell: {
    width: "5%",
    padding: "0px 5px 0px 0px !important"
  },
  textCell: {
    width: "80%",
    padding: "0px",
    fontSize: "12px",
  },
  subjectCell: {
    width: "10%",
    padding: "0px",
    fontSize: "12px",
    textAlign: 'end'
  },
  mainTable: {
    borderTop: "1px solid #dddddd"
  },
  icon: {
    marginLeft: "5px",
    width: '15px',
    height: "15px",
    marginTop: "6px",
    color: "#9d9d9d"
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
  }
});

const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
const checkedIcon = <img src={CheckBoxIcon} alt="" />
// const Type = ['snvType', "cnvType", "svType"] 
// const NAME = ['SNV', "CNV", "SV"]
// replace by jdy 2020-11-20
const Type = ['snvType']
const NAME = ['All']
class DetailPanel extends Component {

  state = {
    isCheck : false
  }
  // home.variantTypeData 이 변함에 따라 반영되어야함. delete shouldComponentUpdate by jdy, 2021-01
  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false 하면 업데이트를 안함
    
  //   // console.log("DetailPanel : shouldComponentUpdate");

  //   return this.state.isCheck !== nextState.isCheck;

  // }

  static getDerivedStateFromProps(nextProps, prevState) { // props로  받아온 값을 state로 동기화하는 작업 
    const { detail, parent, state } = nextProps;

    let isCheck = false;
    if (state.variantType.hasOwnProperty(parent) &&
      state.variantType[parent].indexOf(detail.name) > -1
    ) {
        isCheck = true;
    }

    if (isCheck != prevState.isCheck) {
      return {
        isCheck: isCheck
      };
    }

    return null;
  }

  detailVariantTypeCheck = (isCheck, name, parent) => {
    let { onClick, clickEvent } = this.props;
    onClick(isCheck, name, parent);
    clickEvent()
    this.handleCheck(!isCheck);
  };

  handleCheck = (isCheck) => {
    this.setState({
      isCheck : isCheck
    });
  };

  render() {
    const { classes, detail, parent, state } = this.props;
    const { isCheck } = this.state;

    return (
      <>
        <TableCell className={classes.boxCell} />
        <TableCell className={classes.boxCell} />
        <TableCell padding={"checkbox"} className={classes.textCell}>
          <Checkbox
            disabled={!detail.value}
            checked={isCheck}
            className={classes.checkboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.greencolor }}
            value={detail.name}
            onChange={() =>
              this.detailVariantTypeCheck(!isCheck, detail.name, parent)
            }
          />
          {detail.name}
        </TableCell>
        <TableCell className={classes.subjectCell}>
          <div className={classes.subjectDiv}>
            {detail.value ? detail.value.toLocaleString() : 0}
          </div>
        </TableCell>
      </>
    );
  }
}

class VariantPanel extends Component {

  state = {
    isCheck : false,
    detaillist: false,
  };

  // home.variantTypeData 이 변함에 따라 반영되어야함. delete shouldComponentUpdate by jdy, 2021-01-15
  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false 하면 업데이트를 안함
    
  //   // console.log("CancerPanel : shouldComponentUpdate");

  //   return this.state.isCheck !== nextState.isCheck 
  //       || this.state.detaillist !== nextState.detaillist;
  // }

  static getDerivedStateFromProps(nextProps, prevState) { // props로  받아온 값을 state로 동기화하는 작업 
    const { data, state, name  } = nextProps;

    // console.log("CancerPanel : getDerivedStateFromProps");

    let isCheck = false;

    if (state.variantType && state.variantType[name]) {
      if (state.variantType[name].length >= data.details.length) {
        isCheck = true
      }
    }

    if (isCheck != prevState.isCheck) {
      return {
        isCheck: isCheck
      };
    }

    return null;
  }

  onClickArrow = () => {
    const { detaillist } = this.state;
    this.setState({
      detaillist: !detaillist
    });
  };

  variantCheck = (isCheck) => {
    const { data, onClick, name, clickEvent } = this.props;
    if (isCheck) {
      this.setState({
        detaillist: false,
      });
    }
    for (let detail of data.details) {
      onClick(!isCheck, detail.name, name);

    }
    // data.details.map((detail, len) => {
    //   onClick(!isCheck, detail.name, name);
    // });
    clickEvent()

    this.handleCheck(!isCheck);
  };

  handleCheck = (isCheck) => {
    this.setState({
      isCheck : isCheck
    });
  };


  render() {
    const { classes, data, mainFilterObject, detailCancer, state, onClick, clickEvent, name } = this.props;
    const { isCheck } = this.state;

    let arrow;
    let detail;

    if (this.state.detaillist) {
      arrow = <Remove className={classes.icon} />;
      detail = data.details.map((detail, len) => (
        <TableRow key={len} classes={{ hover: classes.hover }} className={isCheck ? classes.checkName : classes.tableRow} hover>
          <DetailPanel
            classes={classes}
            detailCancer={detailCancer}
            state={state}
            parent={name}
            mainFilterObject={mainFilterObject}
            detail={detail}
            onClick={onClick}
            clickEvent={clickEvent}
          />
        </TableRow>
      ));
    } else {
      arrow = <Add className={classes.icon} />;
      detail = null;
    }

    return (
      <>
        <TableRow classes={{ hover: classes.hover }}
          className={isCheck ? classes.checkName : classes.tableRow} hover >
          <TableCell className={classes.boxCell} onClick={this.onClickArrow}>
            {arrow}
          </TableCell>
          <TableCell padding={"checkbox"} className={classes.checkBoxCell}>
            <Checkbox
              disabled={!data.totalSubject}
              checked={isCheck}
              className={classes.checkboxs}
              icon={icon}
              checkedIcon={checkedIcon}
              classes={{ checked: classes.selectCancerreencolor }}
              value={name}
              onChange={() => this.variantCheck(isCheck)}
            />
          </TableCell>
          <TableCell className={classes.textCell}>
            {name}
          </TableCell>
          <TableCell className={classes.subjectCell}>
            <div className={classes.subjectDiv}>
              {data.totalSubject ? data.totalSubject.toLocaleString() : 0}
            </div>
          </TableCell>
        </TableRow>
        {detail}
      </>
    );
  }
}

class VariantTypeList extends Component {

  onClick = (check, detailname, dataname) => {
    const { state, mainFilterObject } = this.props;
    if (!state.variantType.hasOwnProperty(dataname)) {
      state.variantType[dataname] = []
    }
    if (check) {
      state.variantType[dataname].push(detailname);
    } else {
      if (state.variantType[dataname].indexOf(detailname) > -1) {
        state.variantType[dataname].splice(state.variantType[dataname].indexOf(detailname), 1);
      }
    }
    if (state.variantType[dataname].length === 0) {
      delete state.variantType[dataname]
    }
    mainFilterObject.variantType = state.variantType
  }
  clickEvent = () => {
    const { onClickEvent } = this.props
    onClickEvent();

  }

  render() {
    const { classes, list, mainFilterObject, state } = this.props;
    return (
      <Table className={classes.mainTable}>
        <TableBody>
          {list
            ? Type.map((data, length) => (
              <VariantPanel
                classes={classes}
                data={list[data]}
                name={NAME[length]}
                state={state}
                key={length}
                mainFilterObject={mainFilterObject}
                onClick={this.onClick}
                clickEvent={this.clickEvent}
              />
            ))
            : null}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(VariantTypeList);

