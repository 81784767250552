export const helpList = [
  {
    id: 0,
    title: "MAIN",
    items: [
      {
        index: "1",
        conti: "상단 메뉴",
        desc: "사이트에서 제공하는 메뉴 리스트가 출력된다."
      },
      {
        index: "2",
        conti: "사이트 소개",
        desc: "사이트에 대한 설명이 출력된다."
      },
      {
        index: "3",
        conti: "데이터 업데이트 날짜",
        desc: "마지막으로 데이터가 업데이트된 날짜가 출력된다."
      },
      {
        index: "4",
        conti: "데이터 통계",
        desc: "암종, 환자수, 유전자, 변이 통계 정보를 제공한다."
      },
      {
        index: "5",
        conti: "데이터 업데이트 날짜",
        desc: "암종 별 환자 수 정보를 제공한다."
      },
      {
        index: "6",
        conti: "기타 정보",
        desc: "사이트의 Sponsor 및 Family Site 정보 그리고 사이트가 제공하는 추가 기능 목록을 제공한다."
      }
    ]
  },
  {
    id: 1,
    title: "DATA EXPLORER",
    items: [
      {
        index: "1",
        conti: "상단 통계 바",
        desc: "데이터 업데이트 날짜와 암종, 유전자, 변이, 환자 통계정보를 보여준다."
      },
      {
        index: "2",
        conti: "DataSet Filter",
        desc: "특정 암종, 유전자, 변이, 진단나이, 성별에 따른 조건을 설정 하여 Data Statistics에 반영할 수  있다."
      },
      {
        index: "3",
        conti: "Data Statistics",
        desc: "DataSet Filter에서 선택한 조건들에 따른 정보를 시각화와 표로 보여준다."
      }
    ]
  },
  {
    id: 2,
    title: "DATASET FILTER",
    items: [
      {
        index: "1",
        conti: "Dataset Tab",
        desc: "자신이 주로 선택할 부분을 클릭하면 선택한것과 관련됨 필터들이 나타난다."
      },
      {
        index: "2",
        conti: "Clear Button",
        desc: "자신이 선택한 모든 조건을 초기화 하고 처음부터 다시 선택할 수 있도록 한다."
      },
      {
        index: "3",
        conti: "SampleType, PrimarySite",
        desc: "자신이 원하는 Sample Type이나 Primary Site를 선택할 수 있다."
      },
      {
        index: "4",
        conti: "Eligibility Criteria",
        desc: "자신이 원하는 진단 나이나 성별을 선택할 수 있다."
      },
      {
        index: "5",
        conti: "Age Detail Search",
        desc: "자신이 원하는 특정 나이를 입력하여 찾을 수 있다."
      }
    ]
  },
  {
    id: 3,
    title: "DATASET FILTER : CANCER",
    items: [
      {
        index: "1",
        conti: "Cancer Search",
        desc: "2글자 이상 입력할 때 자신이 입력한 글자에 관련된 암종과 세부 암종이 나타나고 원하는 암종을 선택하면 반영된다."
      },
      {
        index: "2",
        conti: "Cancer Type Filter",
        desc: "Cancer 중 환자수가 많은 10가지를 출력하며 검색창으로 찾은 것과 합산하여 1개 암종을 선택할 수 있다."
      }
    ]
  },
  {
    id: 4,
    title: "DATASET FILTER : GENE",
    items: [
      {
        index: "1",
        conti: "Gene Search",
        desc: "2글자 이상 입력할 때 자신이 입력한 글자에 관련된 유전자가 나타나며 선택할 시 반영된다."
      },
      {
        index: "2",
        conti: "Gene Type Filter",
        desc: "유전자 중 환자수가 많은 10가지를 출력하며 검색창으로 찾은 것과 합산하여 최대 5개 유전자 까지 선택할 수 있다."
      },
      {
        index: "3",
        conti: "데이터 업데이트 날짜",
        desc: "마지막으로 데이터가 업데이트된 날짜가 출력된다."
      }
    ]
  },
  {
    id: 5,
    title: "DATASET FILTER : VARIANT",
    items: [
      {
        index: "1",
        conti: "Variant Search",
        desc: "2글자 이상 입력할 때 자신이 입력한 글자에 관련된 변이 목록이 나타나며 선택할 시 반영된다."
      },
      {
        index: "2",
        conti: "Chromosome search",
        desc: "자신이 찾고자 하는 변이의 Chromosome 과 사작점 끝점을 입력하면 그 부분에 관련된 변이들이 반영된다."
      },
      {
        index: "3",
        conti: "Interpretation Criteria",
        desc: "Interpretation Criteria로 ALL, Pathogenic, Actionable, VUS 4가지로 추가 검색이 가능하게 하여 확장검색이 가능함."
      },
      {
        index: "4",
        conti: "Variant Type",
        desc: "각 변이 목록 중 환자수가 많은 3가지를 출력하며 선택할 때 반영된다."
      }
    ]
  },
  {
    id: 6,
    title: "DATA STATISTICS",
    items: [
      {
        index: "1",
        conti: "DataSet Query",
        desc: "DataSet Filter에서 선택한 내용이 나타나며 로그인 한 사용자는 이 내용을 저장할 수 있다."
      },
      {
        index: "2",
        conti: "DataSet Tab",
        desc: "DataSet Filter에서 Cancer, Gene, Variant의 차트를 탭으로 구분하여 나타낸다."
      },
      {
        index: "3",
        conti: "DataSet Graph",
        desc: "DataSet Filter에서 선택한 내용의 결과를 그래프로 시각화 하여 나타낸다."
      },
      {
        index: "4",
        conti: "DataSet Table",
        desc: "DataSet Filter에서 선택한 내용의 결과를 표로 나타내 보여주고, 하이퍼링크를 클릭하여 확장검색이 가능하며, TSV형식 다운로드가 가능하다."
      }
    ]
  },
  {
    id: 7,
    title: "DEMOGRAPHY",
    items: [
      {
        index: "1",
        conti: "Gender Chart",
        desc: "DataSet Filter로 선택된 사람의 성별이 Pie Chart 로 나타난다."
      },
      {
        index: "2",
        conti: "Diagnosis Age Chart",
        desc: "전체 진단나이의 대한 통계치는 녹색으로 DataSet Filter로 선택된 사람의 진단나이는 노랑색 으로 표시된다."
      }
    ]
  },
  {
    id: 8,
    title: "CANCER - Chart",
    items: [
      {
        index: "1",
        conti: "Cancer Chart",
        desc: "총 환자 수는 연한 회색, 해당 암종을 가지고 있는 총 환자는 녹색,  DataSet Filter로 선택된 환자는 짙은 회색, DataSet Filter로 선택된 환자 중 해당 암종을 가지고 있는 환자는 노랑색으로 표시한다. "
      }
    ]
  },
  {
    id: 9,
    title: "CANCER – Primary Site",
    items: [
      {
        index: "1",
        conti: "PrimarySite Chart",
        desc: "DataSet Filter로 선택된 환자 중 원발암인 환자를 빨강색으로 전이암인 환자는 파랑색으로 표시한다."
      }
    ]
  },
  {
    id: 10,
    title: "CANCER – Sample Type",
    items: [
      {
        index: "1",
        conti: "SampleType Chart",
        desc: "DataSet Filter로 선택된 환자 중 검체 타입이 조직으로 구분된 환자는 빨강색으로 표시하고 혈액인 환자는 파랑색으로 표시한다. "
      }
    ]
  },
  {
    id: 11,
    title: "GENE - Protein",
    items: [
      {
        index: "1",
        conti: "Protein Chart",
        desc: "SNV Type의 Protein Position의 분포를 한 눈에 분석 할수 있도록 차트로 표시한다."
      }
    ]
  },
  {
    id: 12,
    title: "GENE - Chart",
    items: [
      {
        index: "1",
        conti: "Gene Chart",
        desc: "총 환자 수는 연한 회색, 해당 유전자를 가지고 있는 총 환자는 녹색,  DataSet Filter로 선택된 환자는 짙은 회색, DataSet Filter로 선택된 환자 중 해당 유전자를 가지고 있는 환자는 노랑색으로 표시한다."
      }
    ]
  },
  {
    id: 13,
    title: "GENE – Drug Actionable",
    items: [
      {
        index: "1",
        conti: "Drug Actionable Chart",
        desc: "총 환자 수는 연한 회색, DataSet Filter로 선택된 환자는 짙은 회색, 해당 유전자를 가지고 있는 약물 반응성이 있는 총 환자는 녹색, DataSet Filter로 약물 반응성이 있는 환자 중 해당 유전자를 가지고 있는 환자는 노랑색으로 표시한다."
      }
    ]
  },
  {
    id: 14,
    title: "VARIANT – Annotation DATA",
    items: [
      {
        index: "1",
        conti: "Annotation DATA Chart",
        desc: "Annotation Data를 확보하여 Actionable환자는 빨강색,  Pathogenic환자는 파랑색,  VUS환자는 갈색, Complex인 환자는 녹색으로 표시한다."
      }
    ]
  },
  {
    id: 15,
    title: "VARIANT – Interpretation Criteria",
    items: [
      {
        index: "1",
        conti: "Interpretation Criteria Chart",
        desc: "Annotation Data로 Actionable환자는 빨강색, Pathogenic환자는 파랑색,  VUS환자는 갈색으로 표시한다."
      }
    ]
  },
  {
    id: 16,
    title: "SNV - Chart",
    items: [
      {
        index: "1",
        conti: "SNV Chart",
        desc: "총 환자 수는 연한 회색, 해당 SNV를 가지고 있는 총 환자는 녹색,  DataSet Filter로 선택된 환자는 짙은 회색, DataSet Filter로 선택된 환자 중 해당 SNV를 가지고 있는 환자는 노랑색으로 표시한다."
      }
    ]
  },
  {
    id: 17,
    title: "CNV - Chart",
    items: [
      {
        index: "1",
        conti: "CNV Chart",
        desc: "총 환자 수는 연한 회색, 해당 CNV를 가지고 있는 총 환자는 녹색,  DataSet Filter로 선택된 환자는 짙은 회색, DataSet Filter로 선택된 환자 중 해당 CNV를 가지고 있는 환자는 노랑색으로 표시한다."
      }
    ]
  },
  {
    id: 18,
    title: "SV - Chart",
    items: [
      {
        index: "1",
        conti: "SV Chart",
        desc: "총 환자 수는 연한 회색, 해당 SV를 가지고 있는 총 환자는 녹색,  DataSet Filter로 선택된 환자는 짙은 회색, DataSet Filter로 선택된 환자 중 해당 SV를 가지고 있는 환자는 노랑색으로 표시한다."
      }
    ]
  },
  {
    id: 19,
    title: "SET OPERATIONS",
    items: [
      {
        index: "1",
        conti: "Operation Run Button",
        desc: "Set List를 2개 이상을 누를시 활성화 되며 선택한 Set들의 관계를 보여주는 Page로 이동한다."
      },
      {
        index: "2",
        conti: "Set List",
        desc: "자신이 Data Page에서 저장했던 목록들을 보여준다."
      }
    ]
  },
  {
    id: 20,
    title: "SET OPERATIONS RESULTS",
    items: [
      {
        index: "1",
        conti: "상단 통계바",
        desc: "현재 선택된 부분의 암종, 유전자, 변이, 환자 정보들의 통계치를 보여준다."
      },
      {
        index: "2",
        conti: "Venn diagram",
        desc: "선택한 Set들의 상관관계를 판단하여 Venn diagram과 List로 보여주고 각 Set들의 환자수와 Title을 표 형식으로 보여준다. "
      },
      {
        index: "3",
        conti: "Data Statistics",
        desc: "Venn diagram에서 선택한 부분의 통계치를 그래프와 표로 보여준다."
      }
    ]
  },
  {
    id: 21,
    title: "VEN DIAGRAM",
    items: [
      {
        index: "1",
        conti: "Venn diagram",
        desc: "자신이 선택한 Set들의 관계를 보기 쉽게 Venn diagram 으로 나타내고 Venn diagram을 선택할 때 선택한 부분에 맞는 통계 정보를 Data Statistics에서 보여준다."
      },
      {
        index: "2",
        conti: "Query Set List",
        desc: "자신이 선택한 Set들의 제목과 환자수를 보여준다."
      },
      {
        index: "3",
        conti: "Set List",
        desc: "자신이 선택한 Set들의 관계 에서 환자 분포를 표로 보여주고 선택한 부분에 맞는 통계 정보를 Data Statistics에서 보여준다."
      }
    ]
  },
  {
    id: 22,
    title: "DATA STATISTICS",
    items: [
      {
        index: "1",
        conti: "Selected Set",
        desc: "자신이 Venn diagram에서 선택한 Set이 무엇인지 보여준다."
      },
      {
        index: "2",
        conti: "Selected Set Graph",
        desc: "자신이 Venn diagram에서 선택한 Set의 대한 통계치를 시각화하여 그래프로 보여준다."
      },
      {
        index: "3",
        conti: "Selected Set Table",
        desc: "자신이 Venn diagram에서 선택한 Set의 대한 정보를 표로 보여준다."
      }
    ]
  },
  {
    id: 23,
    title: "DEMOGRAPHY",
    items: [
      {
        index: "1",
        conti: "Gender Chart",
        desc: "자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의 성별 통계치를 그래프로 보여준다."
      },
      {
        index: "2",
        conti: "Diagnosis Age Chart",
        desc: "자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의 진단 나이들의 통계치를 그래프로 보여준다."
      }
    ]
  },
  {
    id: 24,
    title: "CANCER",
    items: [
      {
        index: "1",
        conti: "Cancer Chart",
        desc: "전체 환자에 대한 암종 통계치는 회색으로 표시하고 자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의  암종 통계치는 녹색으로 표시하여 그래프로 보여준다."
      }
    ]
  },
  {
    id: 25,
    title: "GENE",
    items: [
      {
        index: "1",
        conti: "Gene Chart",
        desc: "전체 환자에 대한 유전자 통계치는 회색으로 표시하고 자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의  유전자 통계치는 녹색으로 표시하여 그래프로 보여준다."
      }
    ]
  },
  {
    id: 26,
    title: "SNV",
    items: [
      {
        index: "1",
        conti: "SNV Chart",
        desc: "전체 환자에 대한 SNV 통계치는 회색으로 표시하고 자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의  SNV 통계치는 녹색으로 표시하여 그래프로 보여준다."
      }
    ]
  },
  {
    id: 27,
    title: "CNV Chart",
    items: [
      {
        index: "1",
        conti: "CNV Chart",
        desc: "전체 환자에 대한 CNV 통계치는 회색으로 표시하고 자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의  CNV 통계치는 녹색으로 표시하여 그래프로 보여준다."
      }
    ]
  },
  {
    id: 28,
    title: "SV",
    items: [
      {
        index: "1",
        conti: "SV Chart",
        desc: "전체 환자에 대한 SV 통계치는 회색으로 표시하고 자신이 Venn diagram에서 선택한 Set에 포함되어 있는 환자들의  SV 통계치는 녹색으로 표시하여 그래프로 보여준다."
      }
    ]
  },
  {
    id: 29,
    title: "ADMIN OVERVIEW",
    items: [
      {
        index: "1",
        conti: "Admin 메뉴",
        desc: "Admin에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "OverView User Count",
        desc: "현재 회원 가입된 사용자들의 숫자를 보여준다."
      },
      {
        index: "3",
        conti: "OverView Visit Count",
        desc: "오늘 하루 방문한 회원의 수를 보여준다."
      },
      {
        index: "4",
        conti: "Q&A Board",
        desc: "현재 등록된 Q&A 수를 보여준다."
      },
      {
        index: "5",
        conti: "OverView Data Release",
        desc: "최근 릴리즈된 데이터의 날짜를 보여준다."
      },
      {
        index: "6",
        conti: "Admin Notification",
        desc: "유저들이 등록한 Q&A의 상황을 알려준다."
      },
      {
        index: "7",
        conti: "OverView Visitor Count",
        desc: "Time, Day, Week, Month별로 방문자의 통계를 국내, 국외로 구분하여 보여준다."
      }
    ]
  },
  {
    id: 30,
    title: "USER ACCOUNT",
    items: [
      {
        index: "1",
        conti: "Admin 메뉴",
        desc: "Admin에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "User Account Search",
        desc: "현재 등록된 유저들을 Name, Institution, E-Mail, Role, Locked 별로 검색할 수 있다."
      },
      {
        index: "3",
        conti: "User Account User List",
        desc: "현재 등록된 유저들의 정보를 볼 수 있다."
      },
      {
        index: "4",
        conti: "Admin Notification",
        desc: "유저들이 등록한 Q&A등의 상황을 알려준다."
      }
    ]
  },
  {
    id: 31,
    title: "Q&A BOARD",
    items: [
      {
        index: "1",
        conti: "Admin 메뉴",
        desc: "Admin에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "Q&A Board Search",
        desc: "유저들이 등록한 Q&A를 Type, Title, E-Mail 별로 검색할 수 있다. "
      },
      {
        index: "3",
        conti: "Q&A Board",
        desc: "유저들이 등록한 Q&A 목록을 보여준다. 답변을 등록한 경우 STATUS가 Complete로 나타난다."
      },
      {
        index: "4",
        conti: "Admin Notification",
        desc: "유저들이 등록한 Q&A등의 상황을 알려준다."
      }
    ]
  },
  {
    id: 32,
    title: "Q&A BOARD 상세페이지",
    items: [
      {
        index: "1",
        conti: "Admin 메뉴",
        desc: "Admin에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "Q&A Board 상세",
        desc: "사용자가 등록한 Q&A의 상세 내용을 볼 수 있다. Q&A의 답변을 확인 또는 등록할 수 있다."
      },
      {
        index: "3",
        conti: "Return to List",
        desc: "Q&A 리스트 화면으로 돌아간다."
      },
      {
        index: "4",
        conti: "EDIT",
        desc: "Q&A에 등록된 답변을 수정할 수 있다."
      },
      {
        index: "5",
        conti: "Admin Notification",
        desc: "유저들이 등록한 Q&A등의 상황을 알려준다."
      }
    ]
  },
  {
    id: 33,
    title: "Q&A BOARD 상세페이지 답변 입력/수정",
    items: [
      {
        index: "1",
        conti: "Comment",
        desc: "사용자 질문에 대한 답변을 적을 수 있다."
      },
      {
        index: "2",
        conti: "Cancel / Confirm Button",
        desc: "답변을 작성 하던 중 취소하거나,  적은 답변을 등록할 수 있다."
      }
    ]
  },
  {
    id: 34,
    title: "SYNC LOG : DATA SYNC",
    items: [
      {
        index: "1",
        conti: "Admin 메뉴",
        desc: "Admin에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "SYNC LOG LIST",
        desc: "SYNC LOG LIST"
      },
      {
        index: "3",
        conti: "Admin Notification",
        desc: "유저들이 등록한 Q&A등의 상황을 알려준다."
      }
    ]
  },
  {
    id: 35,
    title: "SYSTEM OVERVIEW",
    items: [
      {
        index: "1",
        conti: "System 메뉴",
        desc: "System에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "Over View CPU",
        desc: "현재 서버의 CPU 사용량을 보여준다."
      },
      {
        index: "3",
        conti: "Over View Memory",
        desc: "현재 서버의 Memory 사용량을 보여준다."
      },
      {
        index: "4",
        conti: "Over View Disk",
        desc: "현재 서버의 Disk 사용량을 보여준다."
      },
      {
        index: "5",
        conti: "Over View Network",
        desc: "현재 서버의 Network 상태를 보여준다."
      },
      {
        index: "6",
        conti: "Over View Transaction",
        desc: "현재 서버의 Transaction 상태를 보여준다."
      }
    ]
  },
  {
    id: 36,
    title: "SYSTEM STATISTICS",
    items: [
      {
        index: "1",
        conti: "System 메뉴",
        desc: "System에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "Computer resource statistics",
        desc: "현재 CPU의 상태와 Time, Day, Week, Month별 Cpu의 상태를 그래프로 보여준다."
      },
      {
        index: "3",
        conti: "Networking resource statistics",
        desc: "Time, Day, Week, Month별 Network의 사용량을 그래프로 보여준다."
      },
      {
        index: "4",
        conti: "Storage resource statistics",
        desc: "현재 Storage의 사용량과 Time, Day, Week, Month별 Storage의 상태를 그래프로 보여준다."
      }
    ]
  },
  {
    id: 37,
    title: "COMPUTING RESOURCE STATISTICS",
    items: [
      {
        index: "1",
        conti: "CPU 원형 그래프",
        desc: "현재 CPU 사용량을 보여준다."
      },
      {
        index: "2",
        conti: "CPU 선형 그래프",
        desc: "Time, Day, Week, Month별 Cpu의 상태를 그래프로 보여준다."
      }
    ]
  },
  {
    id: 38,
    title: "STORAGE RESOURCE STATISTICS",
    items: [
      {
        index: "1",
        conti: "Storage 원형 그래프",
        desc: "현재 Storage 사용량을 보여준다."
      },
      {
        index: "2",
        conti: "Storage 선형 그래프",
        desc: "Time, Day, Week, Month별 Storage의 상태를 그래프로 보여준다."
      }
    ]
  },
  {
    id: 39,
    title: "NETWORKING RESOURCE STATISTICS",
    items: [
      {
        index: "1",
        conti: "Network 선형 그래프",
        desc: "Time, Day, Week, Month별 Network의 상태를 그래프로 보여준다."
      }
    ]
  },
  {
    id: 40,
    title: "USER CONTENTS : DATASET LIST",
    items: [
      {
        index: "1",
        conti: "Save Set",
        desc: "사용자가 저장한 Set Group의 목록을 보여준다."
      },
      {
        index: "2",
        conti: "Set List",
        desc: "Set에 해당하는 리스트를 보여준다."
      },
      {
        index: "3",
        conti: "Delete",
        desc: "Set List의 항목을 삭제할 수 있다."
      },
      {
        index: "4",
        conti: "Modify",
        desc: "Set List의 항목을 수정할 수 있다. (Group, Title, Description 수정가능)"
      }
    ]
  },
  {
    id: 41,
    title: "BASIC INFORMATION",
    items: [
      {
        index: "1",
        conti: "My Profile 메뉴",
        desc: "My Profile에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "Basic Information",
        desc: "유저 본인의 이름과 E-Mail, Institution을 확인할 수 있다. 비밀번호를 변경할 수 있다."
      },
      {
        index: "3",
        conti: "Get Token for Api",
        desc: "Click하면 Rest API 에 사용할 token을 생성해 준다."
      }
    ]
  },
  {
    id: 42,
    title: "Q&A BOARD",
    items: [
      {
        index: "1",
        conti: "My Profile 메뉴",
        desc: "My Profile에서 제공하는 메뉴 리스트를 보여준다."
      },
      {
        index: "2",
        conti: "My Q&A Board",
        desc: "유저 본인이 등록한 Q&A와 STATUS를 확인할 수 있다."
      }
    ]
  }
];