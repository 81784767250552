import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  registration: {
    textAlign: "center",
    width: "450px",
    margin: "0 auto",
    paddingBottom: "17px",
    "::-webkit-input-placeholder": {
      color: "#c6c6c6"
    }
  },
  body: {
    padding: "48px 58px 38px 58px"
  },
  h5: {
    display: "inline-block"
  },
  title: {
    marginTop: "30px",
    // marginBottom: "30px",
    textAlign: "center"
  },
  titleBold: {
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    fontSize: "32px",
    color: "#1a1a1a",
    margin: 0,
    fontWeight: "bold"
  },
  titleSmall: {
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    fontSize: "15px",
    color: "#434343"
  },
  input: {
    width: "95%",
    height: "36px",
    border: "solid 1px #979797",
    fontSize: "15px",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    paddingLeft: "18px"
  },
  inputnontop: {
    width: "95%",
    height: "36px",
    border: "solid 1px #979797",
    borderTop: "0px",
    fontSize: "15px",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    paddingLeft: "18px"
  },
  emailmargin: {
    marginTop: "18px",

    marginBottom: "20px"
  },
  warming: {
    fontSize: "15px",
    textAlign: "center",
    color: "#ff4343",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    float: "left",
    paddingTop: "8px"
  },
  cleanWarming: {
    fontSize: "15px",
    textAlign: "center",
    color: "#26a1a4",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    float: "left",
    paddingTop: "8px"
  }
});

class JoinTable extends Component {
  render() {
    const {
      classes,
      user,
      changeInput,
      isCharacterSizeConfirm,
      isCharacterMatchConfirm,
      isEmailCheck
    } = this.props;
    return (
      <div className={classes.registration}>
        <div className={classes.title}>
          <p className={classes.titleBold}>JOIN</p>
          <p className={classes.titleSmall}>
            E-mail authentication is required to complete
          </p>
        </div>
        <input
          name="login"
          type="email"
          className={`${classes.input} `}
          id="registrationEmail"
          placeholder="Enter your email"
          value={user.login}
          onChange={changeInput}
        />
        <div className={!isEmailCheck ? classes.warming : classes.cleanWarming}>
          * Must be an email address.
          </div>
        <div style={{ height: "40px" }}></div>
        <input
          name="password"
          type="password"
          className={classes.input}
          id=" registrationPwd"
          placeholder=" Password"
          value={user.password}
          onChange={changeInput}
        />
        <input
          name="passwordConfirm"
          type="password"
          className={classes.inputnontop}
          id=" registrationRepwd"
          placeholder=" Re-type Password"
          value={user.passwordConfirm}
          onChange={changeInput}
        />
        <div className={!isCharacterSizeConfirm ? classes.warming : classes.cleanWarming}>
          * Please enter at least 8 characters for password.
          </div>
        <div className={!isCharacterMatchConfirm ? classes.warming : classes.cleanWarming}>
          * The password and confirmation password do not match.
          </div>
        <input
          name="institute"
          type="text"
          className={`${classes.input} ${classes.emailmargin}`}
          placeholder=" Institute"
          id=" registrationInstitute"
          value={user.institute}
          onChange={changeInput}
        />
        <input
          name="firstName"
          type="text"
          className={classes.input}
          placeholder=" First Name"
          id=" registrationNamefirst"
          value={user.firstName}
          onChange={changeInput}
        />
        <input
          name="lastName"
          type="text"
          className={classes.inputnontop}
          placeholder=" Last Name"
          id=" registrationNamelast"
          value={user.lastName}
          onChange={changeInput}
        />
      </div>
    );
  }
}

export default withStyles(styles)(JoinTable);
