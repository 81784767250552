import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"
// import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
// import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
const styles = theme => ({
  subject: {
    float: "right"
  },
  checkboxs: {
    padding: "0px!important",
    width: "0.8vw",
    fontSize: "1.2vw !important",
    margin: "2px 5px",
    "& root": {
      fontSize: "1.2vw !important",
    },
    // "$hover:hover &": {
    //   color: "#fff "
    // }
  },
  greencolor: {
    color: "#26a1a4 !important"
  },
  tableRow: {
    height: "30px",
    '&$hover:hover': {
      backgroundColor: "#efefef"
      // backgroundColor: '#26a1a4 ',
      // color: "#fff",
      // borderColor: "#26a1a4"
    }
  },
  checkName: {
    height: "30px",
    backgroundColor: "#ecfffe",
    '&$hover:hover': {
      backgroundColor: "#ecfffe"
    }
  },
  hover: {},
  boxCell: {
    width: "5%",
    padding: "0px 20px 0px 0px !important"
  },
  checkBoxCell: {
    width: "5%",
    padding: "0px 5px 0px 0px !important"
  },
  disablecheckbox: {
    opacity: '0.2'
  },
  textCell: {
    width: "80%",
    padding: "0px",
    fontSize: "12px",
    // "$hover:hover &": {
    //   color: "#fff "
    // }
  },
  subjectDiv: {
    backgroundColor: '#ececec',
    textAlign: "center",
    padding: '2px 4px',
    fontSize: "10px",
    color: "#434343",
    borderRadius: '10px'
  },
  disabletextCell: {
    width: "80%",
    padding: "0px",
    fontSize: "12px",
    opacity: '0.2'
  },
  subjectCell: {
    width: "10%",
    padding: "0px",
    fontSize: "12px",
    textAlign: 'end'
    // "$hover:hover &": {
    //   color: "#fff "
    // }
  },
  disablesubjectCell: {
    width: "10%",
    padding: "0px",
    fontSize: "12px",
    textAlign: 'end',
    opacity: '0.2'
  },
  mainTable: {
    borderTop: "1px solid #dddddd"
  },
  icon: {
    marginLeft: "4px"
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
    // "$hover:hover &": {
    //   color: "#fff !important"
    // }
  },
  checkboxsize: {
    fontSize: "20px !importnat"
  },
  scrollY: {
    height: "300px",
    overflowY:"scroll",
  },
});

const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
const checkedIcon = <img src={CheckBoxIcon} alt="" />
class GeneVariantPanel extends Component {

  state = {
    isCheck : false,
    disable : false
  }

  // home.variantTypeListData, geneTypeData 이 변함에 따라 반영되어야함. delete shouldComponentUpdate by jdy, 2021-01-15
  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false 하면 업데이트를 안함
    
  //   // console.log("GeneVariantPanel : shouldComponentUpdate");

  //   return this.state.isCheck !== nextState.isCheck 
  //       || this.state.disable !== nextState.disable ;
  // }

  static getDerivedStateFromProps(nextProps, prevState) { // props로  받아온 값을 state로 동기화하는 작업 
    const { data, state, mainFilterObject } = nextProps;

    // console.log("GeneVariantPanel : getDerivedStateFromProps");

    let isCheck = false;

    if (mainFilterObject.snv) {
      if (mainFilterObject.snv.indexOf(data.id) > -1) {
        isCheck = true;
      }
    }
    if (mainFilterObject.cnv) {
      if (mainFilterObject.cnv.indexOf(data.id) > -1) {
        isCheck = true;
      }
    }
    if (mainFilterObject.sv) {
      if (mainFilterObject.sv.indexOf(data.id) > -1) {
        isCheck = true;
      }
    }
    // let disable = (!data.subjectCnt || (state.gene ? state.gene.length > 3 : false)) && !isCheck
    let disable = false;

    if (isCheck != prevState.isCheck || disable != prevState.disable) {
      return {
        isCheck: isCheck,
        disable: disable
      };
    }

    return null;
  }

  geneCheck = (isCheck, id) => {
    let { onclickGene, } = this.props;
    onclickGene(!isCheck, id);
    this.handleCheck(!isCheck);
  };

  handleCheck = (isCheck) => {
    this.setState({
      isCheck : isCheck
    });
  };

  // detailClick = (checked, id, name, parentName, parentCheck) => {
  //   let { data, onclickDetail, onclickGene } = this.props;
  //   if (parentCheck === false) {
  //     onclickGene(true, data.id, data.name);
  //   }
  //   // onclickDetail(!checked, id, name, parentName, true);
  // };

  render() {

    const { classes, data,  state } = this.props;
    const { isCheck, disable} = this.state;


    return (
      <>
        <TableRow classes={{ hover: classes.hover }}
          className={isCheck ? classes.checkName : classes.tableRow} hover >
          <TableCell padding={"checkbox"} className={classes.checkBoxCell}>
            <Checkbox
              disabled={disable}
              checked={isCheck}
              className={classes.checkboxs}
              icon={icon}
              checkedIcon={checkedIcon}
              classes={{ checked: classes.selectCancerreencolor, disabled: classes.disablecheckbox }}
              value={data.id}
              onChange={() => this.geneCheck(isCheck, data.id)}
            />
          </TableCell>
          <TableCell className={disable ? classes.disabletextCell : classes.textCell}>
            {data.id.replace('^SV', '').replace('^CNV Deletion', '').replace('^CNV Amplification', '').replace('^CNV', '').replace(/\^/g, ', ').replace('Amplification', 'Amp.').replace('Deletion', 'Del.').slice(0, 20)}
          </TableCell>
          <TableCell className={disable ? classes.disablesubjectCell : classes.subjectCell}>
            <div className={classes.subjectDiv}>
              {data.subjectCnt ? data.subjectCnt.toLocaleString() : 0}
            </div>
          </TableCell>

        </TableRow>
        {/* {detail} */}
      </>
    );
  }
}

class GeneVariantTypeList extends Component {
  onclickGene = (check, id) => {
    const { onClickEvent, state, mainFilterObject, title } = this.props;
    if (title === 'SNV Type') {
      if (check) {
        state.snv.push(id);
      } else {
        if (state.snv.indexOf(id) > -1) {
          state.snv.splice(state.snv.indexOf(id), 1);
        }
      }
      mainFilterObject.snv = state.snv
    }
    else if (title === 'CNV Type') {
      if (check) {
        state.cnv.push(id);
      } else {
        if (state.cnv.indexOf(id) > -1) {
          state.cnv.splice(state.cnv.indexOf(id), 1);
        }
      }
      mainFilterObject.cnv = state.cnv
    }
    else if (title === 'SV Type') {
      if (check) {
        state.sv.push(id);
      } else {
        if (state.sv.indexOf(id) > -1) {
          state.sv.splice(state.sv.indexOf(id), 1);
        }
      }
      mainFilterObject.sv = state.sv
    }
    onClickEvent();
  };

  /* 
	    변경일자:   2020-06-25
	    변경자 이름: 지신복 
	    변경 내용:   화면 display 건수 변경
	    변경 사유:   암종추가에 따른 화면 display 건수 변경                            
	*/      
  render() {
    const { classes, list, state, mainFilterObject } = this.props;
    return (
      <div className={classes.scrollY}>
        <Table className={classes.mainTable}>
          <TableBody>
            {list
              ? list.content.map((data, length) => (
                <GeneVariantPanel
                  classes={classes}
                  data={data}
                  key={length}
                  onclickGene={this.onclickGene}
                  state={state}
                  mainFilterObject={mainFilterObject}
                />
              ))
              : null}
          </TableBody>
      </Table>
      </div>
    );
  }
}

export default withStyles(styles)(GeneVariantTypeList);
