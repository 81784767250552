import React, { Component } from "react";
import $ from "jquery";
import Oncoprint from "oncoprintjs";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";

const styles = theme => ({
  maintitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#4a4a4a",
    display: "flex"
  },
  smalltitle: {
    paddingTop: "8px",
    fontSize: "15px",
    textAlign: "center",
    color: "#4a4a4a",
    paddingBottom: "50px",
    display: "flex"
  },
  minHeight: {
    minHeight: "40vw"
  }
})

let ga_data = [
  {
    "gene": "GENE0",
    "desc": "Annotation for GENE0",
    "data": [
      {
        "cnv": "hetloss",
        "disp_mut": "promoter_rec",
        "disp_germ": "true",
        "sample": "TCGA-00"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "sample": "TCGA-01"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-02"
      },
      {
        "sample": "TCGA-03",
        "disp_germ": "true",
        "disp_mut": "trunc"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-04"
      },
      {
        "sample": "TCGA-05"
      },
      {
        "sample": "TCGA-06"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-07",
        "sv": "up"
      },
      {
        "sample": "TCGA-08"
      },
      {
        "sample": "TCGA-09"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-10",
        "sv": "up"
      },
      {
        "sv": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-11"
      },
      {
        "cnv": "homdel",
        "disp_mut": "promoter",
        "sample": "TCGA-12"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-13"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-14"
      },
      {
        "sv": "up",
        "sample": "TCGA-15"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-16"
      },
      {
        "sv": "down",
        "sample": "TCGA-17"
      },
      {
        "sample": "TCGA-18"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-19"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-20"
      },
      {
        "sv": "down",
        "sample": "TCGA-21"
      },
      {
        "cnv": "hetloss",
        "disp_mut": "trunc",
        "sample": "TCGA-22",
        "sv": "up"
      },
      {
        "sample": "TCGA-23"
      },
      {
        "sample": "TCGA-24"
      },
      {
        "sv": "up",
        "sample": "TCGA-25"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-26"
      },
      {
        "sample": "TCGA-27"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-28"
      },
      {
        "sample": "TCGA-29"
      },
      {
        "sample": "TCGA-30"
      },
      {
        "sample": "TCGA-31"
      },
      {
        "cnv": "amp",
        "disp_mut": "inframe",
        "sample": "TCGA-32"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-33"
      },
      {
        "sample": "TCGA-34"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-35"
      },
      {
        "cnv": "gain",
        "disp_prot": "down",
        "sample": "TCGA-36"
      },
      {
        "cnv": "gain",
        "disp_mut": "inframe",
        "sample": "TCGA-37"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-38"
      },
      {
        "sample": "TCGA-39"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-40"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-41"
      },
      {
        "sv": "down",
        "disp_prot": "down",
        "sample": "TCGA-42"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-43"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-44"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-45"
      },
      {
        "cnv": "amp",
        "disp_prot": "up",
        "disp_mut": "trunc",
        "sample": "TCGA-46",
        "sv": "up"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-47"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-48"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-49"
      }
    ]
  },
  {
    "gene": "GENE1",
    "desc": "Annotation for GENE1",
    "data": [
      {
        "sample": "TCGA-00"
      },
      {
        "sample": "TCGA-01"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-02"
      },
      {
        "sv": "down",
        "disp_prot": "up",
        "disp_mut": "trunc",
        "sample": "TCGA-03"
      },
      {
        "disp_mut": "missense",
        "sample": "TCGA-04"
      },
      {
        "sample": "TCGA-05"
      },
      {
        "disp_prot": "up",
        "disp_mut": "missense",
        "sample": "TCGA-06"
      },
      {
        "sample": "TCGA-07"
      },
      {
        "sample": "TCGA-08"
      },
      {
        "sample": "TCGA-09"
      },
      {
        "cnv": "homdel",
        "disp_mut": "missense",
        "sample": "TCGA-10"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-11"
      },
      {
        "sv": "up",
        "sample": "TCGA-12"
      },
      {
        "cnv": "gain",
        "disp_mut": "promoter",
        "sample": "TCGA-13"
      },
      {
        "cnv": "diploid",
        "sample": "TCGA-14"
      },
      {
        "cnv": "gain",
        "disp_mut": "promoter",
        "sample": "TCGA-15"
      },
      {
        "sample": "TCGA-16"
      },
      {
        "sample": "TCGA-17"
      },
      {
        "cnv": "diploid",
        "disp_mut": "trunc",
        "sample": "TCGA-18",
        "sv": "up"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "sample": "TCGA-19"
      },
      {
        "disp_prot": "down",
        "disp_mut": "missense",
        "sample": "TCGA-20"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-21"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-22"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-23"
      },
      {
        "sample": "TCGA-24"
      },
      {
        "sv": "down",
        "sample": "TCGA-25"
      },
      {
        "sv": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-26"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-27"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-28"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-29"
      },
      {
        "sample": "TCGA-30"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-31"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-32"
      },
      {
        "sample": "TCGA-33"
      },
      {
        "sample": "TCGA-34"
      },
      {
        "sample": "TCGA-35"
      },
      {
        "cnv": "amp",
        "disp_prot": "up",
        "sample": "TCGA-36"
      },
      {
        "cnv": "amp",
        "disp_prot": "down",
        "sample": "TCGA-37",
        "sv": "down"
      },
      {
        "sample": "TCGA-38"
      },
      {
        "sample": "TCGA-39"
      },
      {
        "sample": "TCGA-40"
      },
      {
        "cnv": "hetloss",
        "disp_prot": "up",
        "disp_mut": "missense",
        "sample": "TCGA-41",
        "sv": "up"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-42"
      },
      {
        "cnv": "homdel",
        "disp_mut": "promoter",
        "sample": "TCGA-43"
      },
      {
        "sample": "TCGA-44"
      },
      {
        "cnv": "diploid",
        "disp_mut": "inframe",
        "sample": "TCGA-45",
        "sv": "up"
      },
      {
        "sample": "TCGA-46"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-47"
      },
      {
        "sv": "down",
        "sample": "TCGA-48"
      },
      {
        "cnv": "diploid",
        "disp_prot": "up",
        "sample": "TCGA-49"
      }
    ]
  },
  {
    "gene": "GENE2",
    "desc": "Annotation for GENE2",
    "data": [
      {
        "cnv": "homdel",
        "sample": "TCGA-00"
      },
      {
        "sample": "TCGA-01"
      },
      {
        "sample": "TCGA-02"
      },
      {
        "cnv": "homdel",
        "disp_prot": "up",
        "disp_mut": "promoter",
        "sample": "TCGA-03"
      },
      {
        "disp_prot": "up",
        "disp_mut": "promoter",
        "sample": "TCGA-04"
      },
      {
        "disp_prot": "up",
        "disp_mut": "missense",
        "sample": "TCGA-05"
      },
      {
        "sv": "up",
        "sample": "TCGA-06"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-07"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-08"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-09"
      },
      {
        "cnv": "gain",
        "disp_mut": "missense",
        "sample": "TCGA-10"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-11"
      },
      {
        "sv": "up",
        "sample": "TCGA-12"
      },
      {
        "sample": "TCGA-13"
      },
      {
        "cnv": "gain",
        "disp_mut": "missense",
        "sample": "TCGA-14"
      },
      {
        "sample": "TCGA-15"
      },
      {
        "disp_prot": "up",
        "disp_mut": "trunc",
        "sample": "TCGA-16"
      },
      {
        "sv": "up",
        "sample": "TCGA-17"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-18",
        "sv": "up"
      },
      {
        "sample": "TCGA-19"
      },
      {
        "cnv": "diploid",
        "disp_prot": "down",
        "sample": "TCGA-20"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-21"
      },
      {
        "sv": "down",
        "disp_mut": "missense",
        "sample": "TCGA-22"
      },
      {
        "disp_mut": "missense",
        "sample": "TCGA-23"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-24"
      },
      {
        "disp_prot": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-25"
      },
      {
        "cnv": "gain",
        "disp_mut": "missense",
        "sample": "TCGA-26"
      },
      {
        "sv": "down",
        "sample": "TCGA-27"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "sample": "TCGA-28"
      },
      {
        "sample": "TCGA-29"
      },
      {
        "sample": "TCGA-30"
      },
      {
        "cnv": "homdel",
        "disp_mut": "promoter",
        "sample": "TCGA-31",
        "sv": "up"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-32"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-33"
      },
      {
        "disp_prot": "up",
        "disp_mut": "promoter",
        "sample": "TCGA-34"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-35"
      },
      {
        "sample": "TCGA-36"
      },
      {
        "sample": "TCGA-37"
      },
      {
        "sv": "down",
        "disp_mut": "missense",
        "sample": "TCGA-38"
      },
      {
        "sv": "up",
        "sample": "TCGA-39"
      },
      {
        "sample": "TCGA-40"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-41"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-42"
      },
      {
        "sample": "TCGA-43"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-44"
      },
      {
        "cnv": "diploid",
        "sample": "TCGA-45",
        "sv": "down"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-46"
      },
      {
        "sv": "down",
        "sample": "TCGA-47"
      },
      {
        "sample": "TCGA-48"
      },
      {
        "cnv": "amp",
        "disp_mut": "inframe",
        "sample": "TCGA-49",
        "sv": "up"
      }
    ]
  },
  {
    "gene": "GENE3",
    "desc": "Annotation for GENE3",
    "data": [
      {
        "disp_prot": "down",
        "disp_mut": "trunc",
        "sample": "TCGA-00"
      },
      {
        "cnv": "diploid",
        "sample": "TCGA-01"
      },
      {
        "sample": "TCGA-02"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-03"
      },
      {
        "sample": "TCGA-04"
      },
      {
        "sample": "TCGA-05"
      },
      {
        "sample": "TCGA-06"
      },
      {
        "sv": "down",
        "sample": "TCGA-07"
      },
      {
        "sample": "TCGA-08"
      },
      {
        "sample": "TCGA-09"
      },
      {
        "cnv": "amp",
        "disp_prot": "up",
        "disp_mut": "missense",
        "sample": "TCGA-10",
        "sv": "up"
      },
      {
        "sample": "TCGA-11"
      },
      {
        "cnv": "diploid",
        "disp_prot": "down",
        "sample": "TCGA-12",
        "sv": "down"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-13"
      },
      {
        "disp_mut": "missense",
        "sample": "TCGA-14"
      },
      {
        "cnv": "homdel",
        "disp_mut": "missense",
        "sample": "TCGA-15"
      },
      {
        "sample": "TCGA-16"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-17"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-18"
      },
      {
        "sample": "TCGA-19"
      },
      {
        "sample": "TCGA-20"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-21"
      },
      {
        "sv": "up",
        "sample": "TCGA-22"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-23"
      },
      {
        "disp_mut": "trunc",
        "sample": "TCGA-24"
      },
      {
        "sample": "TCGA-25"
      },
      {
        "sample": "TCGA-26"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-27"
      },
      {
        "disp_prot": "up",
        "disp_mut": "trunc",
        "sample": "TCGA-28"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-29",
        "sv": "down"
      },
      {
        "sample": "TCGA-30"
      },
      {
        "cnv": "homdel",
        "disp_mut": "promoter",
        "sample": "TCGA-31"
      },
      {
        "sample": "TCGA-32"
      },
      {
        "sample": "TCGA-33"
      },
      {
        "cnv": "gain",
        "disp_prot": "up",
        "sample": "TCGA-34"
      },
      {
        "sample": "TCGA-35"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-36"
      },
      {
        "sv": "up",
        "disp_mut": "trunc",
        "sample": "TCGA-37"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-38"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-39"
      },
      {
        "sample": "TCGA-40"
      },
      {
        "sv": "up",
        "sample": "TCGA-41"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-42"
      },
      {
        "sample": "TCGA-43"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-44"
      },
      {
        "sample": "TCGA-45"
      },
      {
        "sample": "TCGA-46"
      },
      {
        "disp_prot": "down",
        "disp_mut": "promoter",
        "sample": "TCGA-47"
      },
      {
        "sample": "TCGA-48"
      },
      {
        "disp_prot": "up",
        "disp_mut": "promoter",
        "sample": "TCGA-49"
      }
    ]
  },
  {
    "gene": "GENE4",
    "desc": "Annotation for GENE4",
    "data": [
      {
        "cnv": "diploid",
        "sample": "TCGA-00"
      },
      {
        "sample": "TCGA-01"
      },
      {
        "sample": "TCGA-02"
      },
      {
        "cnv": "amp",
        "disp_mut": "trunc",
        "sample": "TCGA-03"
      },
      {
        "disp_mut": "inframe",
        "sample": "TCGA-04"
      },
      {
        "sample": "TCGA-05"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-06"
      },
      {
        "sample": "TCGA-07"
      },
      {
        "sample": "TCGA-08"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-09"
      },
      {
        "sv": "down",
        "sample": "TCGA-10"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-11"
      },
      {
        "sample": "TCGA-12"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-13"
      },
      {
        "sample": "TCGA-14"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-15"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-16"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-17"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-18"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-19"
      },
      {
        "cnv": "hetloss",
        "sample": "TCGA-20"
      },
      {
        "sample": "TCGA-21"
      },
      {
        "sv": "down",
        "disp_prot": "down",
        "sample": "TCGA-22"
      },
      {
        "sv": "up",
        "sample": "TCGA-23"
      },
      {
        "disp_prot": "down",
        "sample": "TCGA-24"
      },
      {
        "sample": "TCGA-25"
      },
      {
        "cnv": "hetloss",
        "disp_mut": "promoter",
        "sample": "TCGA-26"
      },
      {
        "sample": "TCGA-27"
      },
      {
        "sv": "up",
        "disp_prot": "up",
        "sample": "TCGA-28"
      },
      {
        "sample": "TCGA-29"
      },
      {
        "sample": "TCGA-30"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-31"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-32",
        "sv": "down"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-33"
      },
      {
        "sv": "down",
        "disp_prot": "up",
        "sample": "TCGA-34"
      },
      {
        "cnv": "amp",
        "sample": "TCGA-35"
      },
      {
        "disp_mut": "missense",
        "sample": "TCGA-36"
      },
      {
        "sample": "TCGA-37"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-38"
      },
      {
        "sample": "TCGA-39"
      },
      {
        "sample": "TCGA-40"
      },
      {
        "cnv": "gain",
        "sample": "TCGA-41"
      },
      {
        "cnv": "homdel",
        "sample": "TCGA-42",
        "sv": "down"
      },
      {
        "sv": "down",
        "disp_prot": "up",
        "sample": "TCGA-43"
      },
      {
        "disp_mut": "promoter",
        "sample": "TCGA-44"
      },
      {
        "disp_prot": "up",
        "sample": "TCGA-45"
      },
      {
        "sample": "TCGA-46"
      },
      {
        "sv": "down",
        "disp_prot": "up",
        "disp_mut": "inframe",
        "sample": "TCGA-47"
      },
      {
        "cnv": "diploid",
        "disp_mut": "missense",
        "sample": "TCGA-48"
      },
      {
        "cnv": "homdel",
        "disp_prot": "up",
        "sample": "TCGA-49",
        "sv": "up"
      }
    ]
  }
];
let DEFAULT_GREY = "rgba(190, 190, 190, 1)";

// Mutation colors
let MUT_COLOR_MISSENSE = '#008000';
// let MUT_COLOR_MISSENSE_PASSENGER = '#53D400';
let MUT_COLOR_INFRAME = '#993404';
// let MUT_COLOR_INFRAME_PASSENGER = '#fe9929';
let MUT_COLOR_TRUNC = '#000000';
// let MUT_COLOR_TRUNC_PASSENGER = '#708090';
let MUT_COLOR_FUSION = '#8B00C9';
let MUT_COLOR_PROMOTER = '#FFA942';
let MUT_COLOR_GERMLINE = '#FFFFFF';

let PROT_COLOR_UP = "#9224A6";
let PROT_COLOR_DOWN = "#00BCD4";

// Base mutation rule set parameters
let baseRuleSetParams = {
  'type': 'gene',
  'legend_label': 'Genetic Alteration',
  'legend_base_color': DEFAULT_GREY
};
let non_mutation_rule_params = {
  // Default: gray rectangle
  '*': {
    shapes: [{
      'type': 'rectangle',
      'fill': DEFAULT_GREY,
      'z': 1
    }],
    legend_label: 'No alterations',
  },
  // Copy number alteration
  'cnv': {
    // Red rectangle for amplification
    'CNV Amplification': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(255,0,0,1)',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 2,
      }],
      legend_label: 'CNV Amplification',
    },
    // Light red rectangle for gain
    'gain': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(255,182,193,1)',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 2,
      }],
      legend_label: 'Gain',
    },
    // Blue rectangle for deep deletion 
    'CNV Deletion': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(0,0,255,1)',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 2,
      }],
      legend_label: 'CNV Deletion',
    },
    // Light blue rectangle for shallow deletion
    'CNV': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(143, 216, 216,1)',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 2,
      }],
      legend_label: 'CNV',
    }
  },
  // mRNA regulation
  'sv': {
    // Light red outline for upregulation
    'Translocation': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(0, 0, 0, 0)',
        'stroke': 'rgba(255, 153, 153, 1)',
        'stroke-width': '2',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 3,
      }],
      legend_label: 'SV',
    },
    // Light blue outline for downregulation
    'down': {
      shapes: [{
        'type': 'rectangle',
        'fill': 'rgba(0, 0, 0, 0)',
        'stroke': 'rgba(102, 153, 204, 1)',
        'stroke-width': '2',
        'x': '0%',
        'y': '0%',
        'width': '100%',
        'height': '100%',
        'z': 3,
      }],
      legend_label: 'mRNA Downregulation',
    },
  },
  // protein expression regulation
  'disp_prot': {
    // small top rectangle for upregulated
    'up': {
      shapes: [{
        'type': 'rectangle',
        'fill': PROT_COLOR_UP,
        'x': "0%",
        'y': "0%",
        'width': "100%",
        'height': "20%",
        'z': 4,
      }],
      legend_label: 'Protein Upregulation',
    },
    // small bottom rectangle for upregulated
    'down': {
      shapes: [{
        'type': 'rectangle',
        'fill': PROT_COLOR_DOWN,
        'x': "0%",
        'y': "80%",
        'width': "100%",
        'height': "20%",
        'z': 4,
      }],
      legend_label: 'Protein Downregulation',
    }
  },
  // fusion
  'disp_fusion': {
    // tall inset purple rectangle for fusion
    'true': {
      shapes: [{
        'type': 'rectangle',
        'fill': MUT_COLOR_FUSION,
        'x': '0%',
        'y': '20%',
        'width': '100%',
        'height': '60%',
        'z': 5
      }],
      legend_label: 'Fusion'
    }
  },
  // germline
  'disp_germ': {
    // white stripe in the middle
    'true': {
      shapes: [{
        'type': 'rectangle',
        'fill': MUT_COLOR_GERMLINE,
        'x': '0%',
        'y': '46%',
        'width': '100%',
        'height': '8%',
        'z': 7
      }],
      legend_label: 'Germline Mutation'
    }
  }
};


let geneticrules = {};
geneticrules.genetic_rule_set_different_colors_no_recurrence = $.extend({}, baseRuleSetParams, {
  'rule_params': $.extend({}, non_mutation_rule_params, {
    'snv': {
      'promoter,promoter_rec': {
        shapes: [{
          'type': 'rectangle',
          'fill': MUT_COLOR_PROMOTER,
          'x': '0%',
          'y': '33.33%',
          'width': '100%',
          'height': '33.33%',
          'z': 6,
        }],
        legend_label: 'Promoter Mutation'
      },
      'DEL,trunc_rec': {
        shapes: [{
          'type': 'rectangle',
          'fill': MUT_COLOR_TRUNC,
          'x': '0%',
          'y': '33.33%',
          'width': '100%',
          'height': '33.33%',
          'z': 6,
        }],
        legend_label: 'DEL',
      },
      'INS,inframe_rec': {
        shapes: [{
          'type': 'rectangle',
          'fill': MUT_COLOR_INFRAME,
          'x': '0%',
          'y': '33.33%',
          'width': '100%',
          'height': '33.33%',
          'z': 6,
        }],
        legend_label: 'INS',
      },
      'SNV,missense_rec': {
        shapes: [{
          'type': 'rectangle',
          'fill': MUT_COLOR_MISSENSE,
          'x': '0%',
          'y': '33.33%',
          'width': '100%',
          'height': '33.33%',
          'z': 6,
        }],
        legend_label: 'SNV',
      },
    }
  })
});

@inject("analysis")
@observer
class Onco extends Component {
  componentDidMount() {
    const { analysis: { oncogrid } } = this.props
    console.log(oncogrid)
    if (oncogrid) {
      ga_data = oncogrid.content
    }
    $(document).ready(function () {

      let oncoprint = new Oncoprint("#oncoprint-glyphmap", 800);
      oncoprint.suppressRendering();

      function geneticComparator() {
        let cna_key = 'cnv';
        let cna_order = { 'CNV Amplification': 0, 'CNV Deletion': 1, 'gain': 2, 'hetloss': 3, 'CNV': 4, undefined: 5 };
        let mut_type_key = 'disp_mut';
        let mut_order = { 'DEL': 0, 'INS': 1, 'promoter': 2, 'SNV': 3, undefined: 4 };
        let mrna_key = 'sv';
        let prot_key = 'disp_prot';
        let reg_order = { 'up': 0, 'down': 1, undefined: 2 };
        return function (d1, d2) {
          let keys = [cna_key, mut_type_key, mrna_key, prot_key];
          let orders = [cna_order, mut_order, reg_order, reg_order];
          let diff = 0;
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let order = orders[i];
            if (d1[key] && d2[key]) {
              diff = order[d1[key]] - order[d2[key]];
            } else if (d1[key]) {
              diff = -1;
            } else if (d2[key]) {
              diff = 1;
            }
          }
          return diff;
        }
      }

      let share_id = null;
      for (let i = 0; i < ga_data.length; i++) {
        let track_params = {
          'rule_set_params': geneticrules.genetic_rule_set_different_colors_no_recurrence,
          'label': ga_data[i].gene,
          'target_group': 0,
          'sortCmpFn': geneticComparator(),
          'description': ga_data[i].desc,
          'na_z': 1.1
        };
        let new_ga_id = oncoprint.addTracks([track_params])[0];
        ga_data[i].track_id = new_ga_id;
        if (i === 0) {
          share_id = new_ga_id;
        } else {
          oncoprint.shareRuleSet(share_id, new_ga_id);
        }
      }

      oncoprint.hideIds([], true);
      oncoprint.keepSorted(false);

      for (let i = 0; i < ga_data.length; i++) {
        oncoprint.setTrackData(ga_data[i].track_id, ga_data[i].data, 'sample');
        oncoprint.setTrackInfo(ga_data[i].track_id, "");
        oncoprint.setTrackTooltipFn(ga_data[i].track_id, function (data) {
          return "<b>Sample: " + data[0].sample + "</b>";
        });
      }

      oncoprint.keepSorted(true);
      oncoprint.releaseRendering();

    });
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <div className={classes.maintitle}>OncoPrint</div>
        <div className={classes.smalltitle}>
          {/* Set Operation analyzes the relationship between clinical data sets
          selected by the user. */}
        </div>
        <div id="container" className={classes.minHeight}>
          <div id="oncoprint-glyphmap"></div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Onco);

