import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ColumnChart from "components/atoms/ColumnChart";

import AbsoluteChart from "components/atoms/AbsoluteChart";
import NoScrollChart from "components/atoms/NoScrollChart";
import AgeGenderChart from "components/atoms/AgeGenderChart";
import AgeGenderPieChart from "components/atoms/AgeGenderChart/AgeGenderPieChart";
import SampleTypeChart from "components/atoms/PieChart/SampleTypeChart";
import PrimarySiteChart from "components/atoms/PieChart/PrimarySiteChart";

import CheckIcon from '@material-ui/icons/Check';

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

// import Draggable from 'react-draggable';
// Cancer presentTab 선택시 display 되는 chart, added by jdy 2020-11

const styles = theme => ({
  container: {
    display: 'flex'
  },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "2px 7px 1px 12px",
    fontWeight: "500",
    lineHeight: '26px',
  },
  board2: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  boardHover2: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  checkBoard2: {
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  tabcontainer: {
    background: "#f6f6f6",
    border: "solid 1px #c6c6c6",
    padding: "7.5px",
    display: "flex",
    flexFlow: "wrap"
  },
  chartContainer: {
    display: "flex",
  },
  ageGenderPie: {
    width: "38%",
    padding: "0px"
  },
  ageGender: {
    width: "62%",
    padding: "0px"
  },
  samplePrimaryPie: {
    width: "100%",
    padding: "0px"
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  button: {
    background: "none rgb(252, 252, 252)",
    border: "none",
    cursor: "pointer",
    opacity: 1,
    color: "rgb(62, 62, 62)",
    fontSize: "12px",
    boxShadow: "rgba(121, 121, 121, 0.8) 1px 1px 3px 0px",
    fontWeight: "normal",
    lineHeight: "normal",
    borderRadius: 1,
    textTransform: "none",
    padding: "5px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
})


class CancerChartPages extends Component {

  state = {
    helpOpen : false,
    imageUrl: "help/2_4_2_1.png",
    htmlId: 7
  }

  demographyHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_2_1.png";
    htmlId = 7;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  cancerChartHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_2_2.png";
    htmlId = 8;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  primarySiteHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_2_3.png";
    htmlId = 9;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  sampleTypeHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_2_4.png";
    htmlId = 10;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  divOpen = (e) => {
    const { chartOpen } = this.props
    chartOpen(e)
  }


  render() 
  {
    const { mainDataType, mainFilterObject, PersonGraphicsData, SampleType, primarySite, dxAge, filterTab, checked, absoluteData, absolute, cancerTypeData, posQuery, originUser, classes, presentTab } = this.props;
    const { open } = this.state;

    let resultChart = [];

    let nCharts = [];
    let iNum;

    resultChart.length = 0;

    let i = 'cancer';

    resultChart.push(
      <div className={checked[i] ? classes.checkBoard2 : classes.boardHover2} key={mainDataType[i].type} >
        <div className={classes.chartTitle} onClick={() => this.divOpen(i) }>
          {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
          <span>{mainDataType[i].type}</span>
          <span>
            <IconButton color="primary" className={classes.helpIconButton} onClick={this.cancerChartHelp}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </span>
        </div>
        <div className={classes.chartwidth} >

          {absolute ?
            <AbsoluteChart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
            : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
              <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
              <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
          }

        </div>
      </div >
    );

    resultChart.push(
      <div className={classes.board2} key={'PrimarySite'} >
        <div className={classes.chartTitle}>
          {1 === 0 ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /> </span> : null}
          <span>Primary Site</span>
          <span>
            <IconButton color="primary" className={classes.helpIconButton} onClick={this.primarySiteHelp}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </span>
        </div>
        <div className={classes.chartContainer}>
          <div className={classes.samplePrimaryPie}>
            <PrimarySiteChart list={primarySite} />
          </div>
        </div>
      </div>
    );
    
    resultChart.push(
      <div className={classes.board2} key={'SimpleType'} >
        <div className={classes.chartTitle}>
          {1 === 0 ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /> </span> : null}
          <span>Sample Type</span>
          <span>
            <IconButton color="primary" className={classes.helpIconButton} onClick={this.sampleTypeHelp}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </span>
        </div>
        <div className={classes.chartContainer}>
          <div className={classes.samplePrimaryPie}>
            <SampleTypeChart list={SampleType} />
          </div>
        </div>
      </div>
    );
    
    return (

      <div className={classes.tabcontainer}>

        <div className={checked['dx'] ? classes.checkBoard2 : classes.boardHover2}>

          <div className={classes.chartTitle} onClick={() => this.divOpen('dx')} >
            {checked['dx'] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /> </span> : null}
            <span>Demography</span>
            <span>
              <IconButton color="primary" className={classes.helpIconButton} onClick={this.demographyHelp}>
              <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </span>
          </div>
          <div className={classes.chartContainer} >
            <div className={classes.ageGenderPie}>
              <AgeGenderPieChart list={PersonGraphicsData} />
            </div>
            <div className={classes.ageGender}>
              <AgeGenderChart list={dxAge} />
            </div>
          </div>
        </div>
        {resultChart}

        <CustomDialog
          open={this.state.helpOpen}
          onClickOpen={this.onClose}
          imageUrl={this.state.imageUrl}
          htmlId={this.state.htmlId}
          CustomDialogContent={HelpContentList}
        />

      </div>
    )
  }

}


export default withStyles(styles)(CancerChartPages);
