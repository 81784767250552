import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import CustomBtn from "components/atoms/CustomBtn";
import Cached from "@material-ui/icons/Cached";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";
// import Cart from "@material-ui/icons/ShoppingCart";

import LeftTabPanel from "components/organisms/LeftTabPanel";
// import ReportedStatus from "components/atoms/ReportedStatus";
import CancerTypeList from "components/atoms/CancerTypeList";
import GeneTypeList from "components/atoms/GeneTypeList";
import GeneVariantTypeList from "components/atoms/GeneTypeList/GeneVariantTypeList";
import EligibilityCriteria from "components/atoms/EligibilityCriteria";
import SampleType from "components/atoms/SampleType";
import AgeDetailSearch from "components/atoms/AgeDetailSearch";
import SearchKeyword from "components/atoms/SearchKeyword";
// import CheckPermission from "components/lib/CheckPermission";
import VariantTypeList from "components/atoms/VariantTypeList";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Chromosome from "components/atoms/Chromosome";
import { data } from "jquery";
const variantTitle = ['SNV', 'CNV', 'SV']
const _variantGroupTitle = ['Interpretation', 'Variant', 'Variant']
const styles = theme => ({
  tools: {
    // fontSize: "12px",
    // fontWeight: "500",
    // width: "100%",
    padding: "0px 0.7vw",
    height: "30px",
  },
  mainback: {
    backgroundColor: "#ffffff"
  },
  panelmargin: {
    margin: "3px 0.7vw 0.5vw 0.7vw"
  },
  container: {
    display: "flex"
  },
  search: {
    padding: "0px 0.7vw 0px 0.7vw",
    // width: '270px'
  },
  custom: {
    width: "85px",
    height: '26px',
    lineHeight: '1.5'
  },
  btn: {
    // float: "right",
    width: '30%',
    padding: "8px 9px 10px 5px"
  },
  text: {
    float: "left",
    paddingTop: "8px"
  },
  checkmenu: {
    color: "#26a1a4",
    fontSize: "13px",
    padding: "0.5vw 0vw 0vw 0vw",
    width: "49%"
    // textAlign: "center"
  },
  nocheckmenu: {
    color: "#a9a9a9",
    fontSize: "13px",
    padding: "0.5vw 0vw 0vw 0vw",
    width: "49%"
    // textAlign: "center"
  },
  iconSize: {
    fontSize: "10px"
  },
  dashed: {
    margin: '0px 0.7vw 0px 0.7vw',
    borderTop: '1px dashed #c6c6c6'
  },
  mainHeader: {
    width: "200px",
    padding: "18px 10px 10px 10px",
    // display: "flex",
    fontSize: "12px"
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  helpCustom: {
    width: "25px",
    height: '26px',
    lineHeight: '1.5'
  },
  helpMain: {
    width: "45px",
    padding: "8px 9px 10px 1px",
    // display: "flex",
    fontSize: "12px"
  }
});

@inject("home")
@observer
class TabContainer extends Component {

  state = {
    expandedTitle : "SNV Type",
    open: false,
    imageUrl: "help/2_4_1_1.png",
    htmlId: 3
  }

  clickReset = () => {
    const { clear, tab } = this.props;
    clear(tab);
  };

  clickHelp = () => {
    const { tab } = this.props;
    let url;
    let htmlId;

    // console.log('help tab : ', tab);
    if (0 == tab) {
      url = "/2_4_1_1.png";
      htmlId = 3;
    }
    else if (1 == tab) {
      url = "/2_4_1_2.png";
      htmlId = 4;
    }
    else {
      url = "/2_4_1_3.png";
      htmlId = 5;
    }

    this.setState({
      open: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  // VariantType에 사용되었으나, 불필요하여 삭제, by jdy 2021-01
  // 대신에 LeftTabPanel의 props로 최초 render시에 expand 여부를 설정하는 expandedInit 추가
  // reciveData = (title) => {

  //   this.setState({
  //     expandedTitle : title
  //   });
  // };

  // clickOpen = (e) => {
  //   const { posQuery } = this.props
  //   posQuery(e)
  // }

  setStateValueAndSuggestions = ({ value, suggestions }) => {
    const stateObj = {};
    if (value !== undefined) stateObj.value = value;
    if (suggestions !== undefined) stateObj.suggestions = suggestions;
    this.setState(stateObj);
    this.props.getKeyword(value)
  };

  getPatientAutoComplete = value => {
    this.props.getKeyword(value)
  };

  onUpdateCnt = (data) => {
    const {home: {variantCategory}} = this.props;

    let actionableCnt = variantCategory.actionable;
    let pathogenicCnt = variantCategory.pathogenic;
    let vusCnt = variantCategory.vus;
    let drugTotalCnt = actionableCnt + pathogenicCnt + vusCnt;

    if (data.id == "ALL") {
      data.subjectCnt = drugTotalCnt;

    } else if (data.id == "Actionable") {
      data.subjectCnt = actionableCnt;

    } else if (data.id == "Pathogenic") {
    
      data.subjectCnt = pathogenicCnt;

    } else if (data.id == "VUS") {
      data.subjectCnt = vusCnt;
    }

  } 

  render() {
    const {
      refresh,
      tab,
      classes,
      cancerTypeData,
      geneTypeData,
      variantTypeListData,
      variantTypeData,
      returnKeyWord,
      insertFilterKeyword,
      mainFilterObject,
      onClickMainFilter,
      onClickCriteriaFilter,
      select,
      home: {variantCategory}
    } = this.props;
    let middleType
    let variant = []
    let chr

    if (variantCategory) {
      // not update by jdy 2020-11-20
      // _variantGroupData.forEach((e, i) => {
      //   e.content.map((data, length) => this.onUpdateCnt(data))
      // });
    }

    // 왼쪽 탭메뉴 생성
    switch (tab) {
      case 0:
        middleType = (
          <div className={classes.panelmargin}>
            <LeftTabPanel
              refresh={refresh}
              title={"Cancer Type"}
              Detail={CancerTypeList}
              list={cancerTypeData}
              onClickEvent={onClickMainFilter}
              state={select}
              mainFilterObject={mainFilterObject}
              expandedInit={true}
            />
          </div>
        );
        break;
      case 1:
        middleType = (
          < div className={classes.panelmargin} >
            <LeftTabPanel
              refresh={refresh}
              title={"Gene Type"}
              Detail={GeneTypeList}
              list={geneTypeData}
              onClickEvent={onClickMainFilter}
              state={select}
              mainFilterObject={mainFilterObject}
              expandedInit={true}
            />
          </div >
        );
        break;
      case 2:
        /* 
          변경일자:   2020-06-26
          변경자 이름: 지신복 
          변경 내용:   화면 display 건수 변경
          변경 사유:   암종추가에 따른 화면 display 건수 변경                            
        */
        chr = <Chromosome onClickEvent={onClickMainFilter} mainFilterObject={mainFilterObject} />

        {/* by jdy 2020-11-09 variantTypeListData.forEach((e, i) => { 
          home.VariantTypeList는 Interpretation Criteria 에 따른 item 들이며,
            ALL 아래에 detail 이 있는 2 층 구조이며, VariantTypeList component로 display
          home.variantTypeListData는 SNV type, CNV type, SV type의 list 이며,
            각 list는 단층 구조로서 GeneVariantTypeList component로 display 한다.
        */}
        
        variantTypeListData.forEach((e, i) => {
          variant.push(
            <LeftTabPanel
              refresh={refresh}
              title={`${variantTitle[i]} Type`}
              Detail={GeneVariantTypeList}
              list={e}
              onClickEvent={onClickMainFilter}
              state={select}
              mainFilterObject={mainFilterObject}
              key={i}
              expandedTitle={this.state.expandedTitle}
              expandedInit={i === 0}
            />
          )
        })

        middleType = (
          <div className={classes.panelmargin}>

            <LeftTabPanel
              refresh={refresh}
              title={"Interpretation Criteria"}
              Detail={VariantTypeList}
              list={variantTypeData}
              onClickEvent={onClickMainFilter}
              state={select}
              mainFilterObject={mainFilterObject}
              expandedTitle={this.state.expandedTitle}
              expandedInit={true}
            />

            {variant}
          </div>
        );
        break;
      default:
        break;

    }
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.mainHeader}>
            DATASET FILTER
            {/* <div style={{ textAlign: "right" }}> */}
            {/* </div> */}
          </div>

          <div className={classes.btn}>
            <CustomBtn
              text={"Clear"}
              img={Cached}
              stylebtn={classes.custom}
              onClick={this.clickReset}
            />
          </div>
          {/* 20210408 add IconButton by jdy
            <div className={classes.helpMain}> 
          */}
          <IconButton color="primary" className={classes.helpIconButton} onClick={this.clickHelp}>
            <HelpIcon className={classes.helpIcon} />
          </IconButton>

          <CustomDialog
              open={this.state.open}
              onClickOpen={this.onClose}
              imageUrl={this.state.imageUrl}
              htmlId={this.state.htmlId}
              CustomDialogContent={HelpContentList}
          />
            {/* <CustomBtn
              text={""}
              img={HelpIcon}
              stylebtn={classes.helpCustom}
              onClick={this.clickHelp}
            /> */}
          {/* </div> */}
        </div>

        <div className={classes.search}>
          <SearchKeyword
            getPatientAutoComplete={this.getPatientAutoComplete}
            patientAutoCompleteList={returnKeyWord}
            insertFilterKeyword={insertFilterKeyword}
            tab={tab}
          />
          {chr}
        </div>
        {/* <div className={classes.dashed}></div> */}
        <div className={classes.tools}>
          {/* <div className={classes.btn}>
            <CustomBtn
              text={"Clear"}
              img={Cached}
              stylebtn={classes.custom}
              onClick={this.clickReset}
            />
          </div> */}
          {tab === 1 || tab === 2 ? null : <div className={Object.values(mainFilterObject).length ? classes.checkmenu : classes.nocheckmenu}>
            <CheckCircle className={classes.iconSize} />
            Select up to one.</div>}
          {/* <div className={classes.btn}>
            <CustomBtn
              text={"Cart"}
              img={Cart}
              stylebtn={classes.custom}
              onClick={this.clickOpen}
            />
          </div> */}
        </div>

        {middleType}
        <div className={classes.panelmargin}>
          <LeftTabPanel
            refresh={refresh}
            state={select}
            title={" "}
            Detail={SampleType}
            onClickEvent={onClickCriteriaFilter}
            mainFilterObject={mainFilterObject}
            expandedInit={true}
          />
        </div>
        <div className={classes.panelmargin}>
          <LeftTabPanel
            refresh={refresh}
            state={select}
            title={"Eligibility Criteria "}
            Detail={EligibilityCriteria}
            onClickEvent={onClickCriteriaFilter}
            mainFilterObject={mainFilterObject}
            expandedInit={true}
          />
        </div>
        <div className={classes.panelmargin}>
          <AgeDetailSearch
            state={select}
            onClickEvent={onClickCriteriaFilter}
            mainFilterObject={mainFilterObject}
          />
        </div>

      </div>
    )
  }
}

export default withStyles(styles)(TabContainer);
