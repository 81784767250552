
class CnvTableModel {
  constructor(d, i) {
    let select = ""
    if (d.selectedTotalSubjectCnt !== d.totalSubjectCnt) {
      select = `/ ${d.selectedTotalSubjectCnt.toLocaleString()}`
    }
    this.id = d.id
    this.name = d.name
    this.selectedCancer = d.selectedCancer
    // this.subjectTotal = `${d.subjectCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    this.subjectTotal = `${d.subjectCnt.toLocaleString()}`
    this.subjectCnt = d.subjectCnt
    this.sequenceAlteration = d.sequenceAlteration
    this.targetGene1Id = d.targetGene1Id
    this.cnvLocus = d.cnvLocus
    this.copyNumber = d.copyNumber
    this.selectedCnv = d.selectedCnv
    this.hgvsP1digit = d.hgvsP1digit
    this.checkTotal = select
  }
}

export default CnvTableModel;
