import { observable, action, computed } from "mobx";

class CommStore {
  @observable loggedIn = false;
  @observable user = null;
  @observable originUser = null;
  @observable curPage = "";
  @observable reported = true;
  @observable depth = 0;
  @observable curTab = 0;
  // @observable TableName = [];
  @observable selectedGeneCard = "";
  @observable CancerType = {};
  // @observable tableHeadName = [];
  @observable selectedObj = {};
  @observable selectedName = {};

  @observable mainFilterObject = {};
  @observable subFilterObject = {};


  constructor(root) {
    this.root = root;
  }

  @action
  setSelectedObj(obj) {
    // this.selectedObj = Object.assign(this.selectedObj, obj);
  }
  @action
  setmainFilterObject(obj) {
    this.mainFilterObject = Object.assign(this.mainFilterObject, obj);
  }
  @action
  resetSelectedObj() {
    this.selectedObj = {};
  }

  @action
  resetMainFilterObject() {
    this.mainFilterObject = {};
  }

  @action
  resetSubFilterObject() {
    this.subFilterObject = {};
  }

  // @action
  // resetTableHeadName() {
  //   this.tableHeadName = [];
  // }

  // @action
  // async setTableHeadName(name, type) {
  //   this.tableHeadName.push(name);
  // }

  @action
  setLoggedIn(loggedIn) {
    this.loggedIn = loggedIn;
  }

  @action
  setUser(user, isModified) {
    this.user = user;
    if (!isModified) {
      this.setOriginUser(user);
    }
  }

  @action
  setOriginUser(originUser) {
    this.originUser = originUser;
  }

  @computed
  get isUserModified() {
    return JSON.stringify(this.user) === JSON.stringify(this.originUser);
  }

  @action
  setCurPage(page) {
    this.curPage = page;
  }

  @action
  setReported(reported) {
    this.reported = reported;
  }

  @action
  setCurTab(tab) {
    this.curTab = tab;
  }

  // @action
  // setTableName(i, TableName) {
  //   this.TableName[i] = TableName;
  // }

  @action
  setCancerType(id, name, hgvsp1digit) {
    this.CancerType = { id, name, hgvsp1digit };
  }

  @action
  setDepth(depth) {
    this.depth = depth;
  }

  // @action
  // resetTableName() {
  //   this.TableName = [];
  // }

  @action
  setSelectedGeneCard(selectedGeneCard) {
    this.selectedGeneCard = selectedGeneCard;
  }

  @computed
  get getReported() {
    return this.reported ? "reported" : "all";
  }
}

export default CommStore;
