import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AnalysisTable from "components/atoms/AnalysisTable";
import CustomBtn from "components/atoms/CustomBtn";
import DescriptionIcon from '@material-ui/icons/Description';
import deleteForever from "@material-ui/icons/DeleteForever";
const styles = theme => ({
  chartMargin: {
    paddingBottom: "15px"
  },
  nav: {
    textAlign: "right",
    paddingBottom: "12px"
  },
  person: {
    lineHeight: 'normal',
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "14px"
  },
  chart: {
    width: "100%",
  },
  diagramImage: {
    width: "30%",
    paddingTop: "60px",
    textAlign: "center",
  },
  chartTitlte: {
    float: 'left',
    textSize: "14px",
    lineHeight: "2"
  },
  size: {
    width: '10vw',
    // backgroundColor: '#26a1a4',
  },
  border: {
    width: '10vw',
    margin: 'auto',
    marginTop: "10px",
    borderTop: "solid 1px #dedede",
    paddingTop: "15px"
  },
  btnMargin: {
    marginRight: "5px"
  }
})

class OperationBoard extends Component {
  state = {
    selected: {}
  }

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    if (this.props.detailtab !== nextProps.detailtab) {
      this.setState({ selected: {} });
    }
    return JSON.stringify(this.props.detailType) !== JSON.stringify(nextProps.detailType) || this.state !== nextState
    // return true;
  }

  handlePagination = s => {
    const { handlePagination, detailType } = this.props
    handlePagination(s, detailType.title)

  };

  handleSort = ({ order, orderBy }) => {
    console.log(order, orderBy);
  };

  setSelect = selected => {
    // const { setListData } = this.props
    // setListData.select = selected
    console.log(selected)
    this.setState({ selected });
  };

  onClick = (e) => {
    e.preventDefault();
    this.props.onRun(this.state.selected)
  }

  delete = () => {
    const { deleteSetlist } = this.props
    let data = this.state.selected ? Object.keys(this.state.selected)[0] : null
    this.setState({ selected: {} });
    deleteSetlist(data, false)
  }

  modify = () => {
    const { modify } = this.props
    let data = this.state.selected ? Object.keys(this.state.selected)[0] : null
    this.setState({ selected: {} });
    modify(data)
  }

  render() {
    const { detailType, classes, originUser } = this.props;

    if (detailType.data) {
      return (
        <>
          <div className={classes.chart}>
            <div className={classes.chartMargin}>
              <div className={classes.chartTitlte}>Set List</div>
              <div className={classes.nav}>
                <CustomBtn text={"Delete"}
                  onClick={this.delete}
                  img={deleteForever}
                  stylebtn={classes.btnMargin}
                />
                <CustomBtn text={"Modify"}
                  onClick={this.modify}
                  disabled={originUser ? false : true}
                  img={DescriptionIcon}
                />
              </div>
              <AnalysisTable
                ref={this.node}
                id={"alllist"}
                columns={detailType.columns}
                data={{ content: detailType.data, totalElements: 1 }}
                selectRow={{
                  mode: "radio",
                  // handleClickCheckbox: this.handleClickCheckbox,
                  setSelect: this.setSelect,
                  selected: this.state.selected
                }}
                defaultSort={{
                  order: "asc",
                  orderBy: "geneName",
                  handleSort: this.handleSort
                }}
                defaultPagination={{
                  page: 0,
                  rowsPerPage: 10,
                  handlePatination: this.handlePagination
                }}
                headerIcon={<div style={{ width: '36px' }}></div>}
                {...this.props}
              />

            </div>
          </div>
        </>



      )
    } else {
      return null
    }

  }
}


export default withStyles(styles)(OperationBoard);
