import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import AdminBoard from "components/organisms/AdminBoard";
import queryString from "query-string";


const Admincolumns = [
  { column: "NAME", property: "name", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "E-MAIL", property: "email", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "INSTITUTION", property: "institute", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 600 } } },
  // { column: "CODE", property: "", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "ROLE", property: "status", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "LOCKED", property: "activate", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  // { column: "CONTROL", property: "control", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
];

const styles = theme => ({
  main: {
    border: "solid 1px #dbdbdb",
    backgroundColor: "#ffffff",
    margin: "10px 5px 10px 10px",
    // padding: "20px"
  },
  title: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#3a3a3a"
  },
  tableHeader: {
    border: "solid 1px #dedede",
    backgroundColor: "#f3f3f3",
    fontSize: "11px",
    color: "#3a3a3a"
  },
  headerdiv: {
    padding: "4px 0px",
    textAlign: "center"
  }
})


@inject("system", "comm")
@observer
class AccountManage extends Component {

  state = {
    type: "name",
    keyword: "",
    page: 0,
    size: 10,
  };

  componentDidMount() {
    const { system } = this.props;
    const { page, type, keyword, size } = this.state
    let query = {
      page: page,
      size: size,
      type: type,
      keyword: keyword
    };
    const queryList = queryString.stringify(query);
    system.getAdminUser(`?${queryList}`)

  }

  componentDidUpdate() {
    const { system, system: { accessLogs, getAdminUsers }, comm: { originUser } } = this.props;
    if (originUser && (!accessLogs || !getAdminUsers)) {
      // system.getAdminUser(`?Authorities=admin&size=10`)
      system.getAdminUser(`?size=10`)
    }
  }

  searchUser = (type, keyword) => {
    const { system } = this.props;
    const { page, size } = this.state
    this.setState({
      type: type,
      keyword: keyword
    })
    let query = {
      page: page,
      size: size,
      type: type,
      keyword: keyword
    };
    const queryList = queryString.stringify(query);
    system.getAdminUser(`?${queryList}`)
  }

  deleteCheck = (id) => {
    console.log(id)
  }

  handlePagination = (s, target) => {
    const { system } = this.props;
    const { type, keyword } = this.state
    this.setState({
      page: s.page,
      size: s.rowsPerPage,
    })
    let query = {
      page: s.page,
      size: s.rowsPerPage,
      type: type,
      keyword: keyword
    };
    const queryList = queryString.stringify(query);
    system.getAdminUser(`?${queryList}`)
  };

  render() {
    const { classes, system: { getAdminUsers } } = this.props;

    let adminTableType = { title: "USER LIST", columns: Admincolumns, data: getAdminUsers }

    return (
      <div className={classes.main}>
        {getAdminUsers.content ?
          <AdminBoard deleteUser={this.deleteCheck} detailType={adminTableType} handlePagination={this.handlePagination} searchUser={this.searchUser} />
          : null}
      </div>
    );
  }
}

export default withStyles(styles)(AccountManage);
