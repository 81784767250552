import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class SystemBarChart extends Component {
  // state = {
  //   fooKey: 1
  // };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.series) !== JSON.stringify(this.props.series);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.series.length) {
  //     return {
  //       fooKey: prevState.fooKey + 1
  //     };
  //   }

  //   return null;
  // }

  render() {
    const { series, name, subject } = this.props;
    let options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "column",
        // marginRight: 30
      },
      title: {
        text: null
      }
    }
    if (series) {

      options = {
        chart: {
          backgroundColor: "#00ff0000",
          type: "column",
          // width:100
          height: 170
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: [
          {
            //   linkedTo: 0,
            //   gridLineDashStyle: "longdash",
            //   gridLineWidth: 0.5,
            //   gridLineColor: "#cbcbcb",
            categories: name,
            labels: {
              rotation: 0,
              style: {
                fontSize: "10px",
                fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
                // color: "#ffffff"
              }
            }
          }
        ],
        yAxis: {
          title: {
            text: subject,
            align: "high",
            rotation: 0,
            offset: 10,
            margin: 0,
            y: -4,
            style: {
              fontSize: "8px",
              fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
              // color: "#ffffff"
            }
          },
          // gridLineDashStyle: "longdash",
          // gridLineWidth: 0.5,
          // gridLineColor: "#cbcbcb",
          // min: 0,
          // tickAmount: 8,
          align: 'left',
          x: 3,
          y: 16,
          labels: {
            style: {
              fontSize: "8px",
              fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
              // color: "#ffffff"
            }
          },
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series: series
      };

    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      // key={this.state.fooKey}
      />
    );
  }

}
export default SystemBarChart;
