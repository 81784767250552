import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import VisitorTable from "components/organisms/VisitorTable";
import PersonIcon from '@material-ui/icons/Person';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ListIcon from '@material-ui/icons/List';
import dateFormat from "dateformat";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const styles = theme => ({
  loading: {
    padding: "16px 20px"
  },
  loadingPaper: {
    backgroundColor: '#00ff0000',
    boxShadow: "none",
    width: "95px"
  },
  circlecolor: {
    color: "#ff0000"
  },

  visitor: {
    // width: "100%",
    margin: "10px 5px 10px 10px"
  },
  count: {
    display: "flex",
    margin: "10px 0px 0px 5px"
  },
  main: {
    width: "25%",
    height: "280px",
    border: "solid 1px #d7d7d7",
    backgroundColor: " #ffffff",
    margin: "0px 5px",
    // padding: "1px",
    "&:hover": {
      border: "solid 1px #26a1a4",
      // padding: "0px"

    }
  },
  mainMargin: {
    margin: "70px 40px ",
    "@media (max-width: 1500px)": {
      margin: "70px 20px ",
    },
  },
  countTitle: {
    color: "#7d7d7d",
    fontSize: "11px",
    height: "35px",
    display: "flex",
    borderBottom: "solid 1px #dcdbdb"
  },
  value: {
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "center",
    "@media (max-width: 1500px)": {
      fontSize: "20px",
    },
  },
  goList: {
    border: "solid 1px #dcdbdb",
    borderLeft: "0px",
    borderRight: "0px",
    margin: "10px 30px",
    padding: "5px",
    textAlign: "left",
    fontSize: "11px",
    color: "#7d7d7d",
    cursor: "pointer",
    "@media (max-width: 1500px)": {
      margin: "10px 20px",
    },
  }
})

class DashBoardTable extends Component {
  onClick = (e) => {
    const { handleClick, url } = this.props
    handleClick(url)
  }
  render() {
    const { classes, title, icon, main, url } = this.props;
    
    return (
      <div className={classes.main}>
        <div className={classes.countTitle} >
          <div style={{ width: "40%", textAlign: "right" }} >{icon}</div>
          <div style={{ width: "60%", textAlign: "left", padding: "15px 0px 0px 5px" }}>{title}</div>
        </div>
        <div className={classes.mainMargin}>
          <div className={classes.value} >{main}</div>
          {url === "" ? null : <div className={classes.goList} onClick={this.onClick}>Go to List <ListIcon style={{ fontSize: "13px", float: "right" }} /></div>}
        </div>
      </div>
    );
  }
}

@inject("system", "comm", "home")
@observer
class OverView extends Component {

  state = {
    loading: false,
  };
  componentDidMount() {
    const { system, home, comm: { user } } = this.props;
    if (user) {
      home.getLastUpdateDate();
      system.getQACount();
      system.getVisitorsNumber("day");
    }
  }

  componentDidUpdate() {
    const { system, system: { visitorNumber, QACount, usersCount, visitorsCount }, comm: { originUser } } = this.props;
    if (originUser && (!visitorNumber)) {
      system.getVisitorsNumber("day");
    }
    if (originUser && (!QACount)) {
      system.getQACount();
    }
    if (originUser && (!usersCount)) {
      system.getUsersCount();
    }
    if (originUser && (!visitorsCount)) {
      system.getVisitorsCount();
    }
  }

 onChangeVisitor = async (value) => {
    const { system } = this.props;
    await this.setState({
      loading: true
    });
    let array = [
      system.getVisitorsNumber(value)
    ];
    const promises = array.map(item => (item));
    await Promise.all(promises);
    this.setState({
      loading: false
    });
  };

  handleClick = (url) => {
    const { history } = this.props
    history.push(url)
  }

  render() {
    const { classes, system: { visitorNumber, QACount, usersCount, visitorsCount }, home: { lastUpdateDate } } = this.props;
    const { loading } = this.state;
    let releaseDate = lastUpdateDate ? dateFormat(lastUpdateDate, "mediumDate") : "Oct 30, 2019"
    let boardTable = [
      { value: 1, title: "USER ACCOUNT", icon: <PersonIcon style={{ fontSize: "20px", paddingTop: "10px" }} />, main: usersCount, url: "/admin/useraccount" },
      { value: 0, title: "VISIT COUNT", icon: <PersonIcon style={{ fontSize: "20px", paddingTop: "10px" }} />, main: visitorsCount, url: "/admin" },
      { value: 3, title: "Q&A COUNT", icon: <ChatBubbleOutlineIcon style={{ fontSize: "20px", paddingTop: "10px" }} />, main: QACount, url: "/admin/qaboard" },
      { value: 0, title: "DATA RELEASE", icon: <SettingsApplicationsIcon style={{ fontSize: "18px", paddingTop: "10px" }} />, main: releaseDate, url: "" },
    ]
    let main = []
    for (let i of boardTable) {
      main.push(
        <DashBoardTable classes={classes} value={i.value} title={i.title} icon={i.icon} main={i.main} handleClick={this.handleClick} url={i.url} key={i.title} />
      )
    }

    return (
      <>
        <div className={classes.count}>
          {main}
        </div>
        <div className={classes.visitor}>
          <VisitorTable
            data={visitorNumber}
            static={false}
            onChangeVisitor={this.onChangeVisitor}
          />
        </div>
        <Dialog
          open={loading}
          classes={{ paper: classes.loadingPaper }}
          maxWidth={false}
        >
          <DialogContent className={classes.loading}>
            <CircularProgress
              classes={{ colorPrimary: classes.circlecolor }}
            />
          </DialogContent>
        </Dialog>
      </>

    );
  }
}

export default withStyles(styles)(OverView);;
