import React, { Component } from "react";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import CustomBtn from "components/atoms/CustomBtn";


const styles = theme => ({
  display: {
    display: "flex",
    marginBottom: "4px"
  },
  box: {
    width: "45px",
    height: "30px",
    border: "solid 1px #d4d4d5",
    backgroundImage: "linear-gradient(to bottom, #f1f1f1, #dfdfdf)",
    textAlign: "center",
    fontSize: '12px',
    color: '#434343',
    lineHeight: 2.5

  },
  goBtn: {
    width: "100%",
    height: "25px"
  },
  frominputBox: {
    width: "50%",
    height: "25px",
    border: "solid 1px #dddddd",
    padding: "0px",
    paddingLeft: "10px",
    marginRight: "3px",
    fontSize: '12px',
    backgroundColor: "#ffffff"
  },
  toinputBox: {
    width: "50%",
    height: "25px",
    border: "solid 1px #dddddd",
    padding: "0px",
    paddingLeft: "10px",
    margin: "0px",
    fontSize: '12px',
    backgroundColor: "#ffffff"
  },
  iconsize: {
    fontSize: "14px"
  },
  title: {
    fontSize: "12px",
    margin: "5px",
    display: "flex",
    cursor: 'pointer',
    textDecoration: 'underline',

  },
  error: {
    fontSize: "10px"
  },
  errorDiv: {
    fontSize: "12px",
    padding: "2px 5px",
    color: "#f03a4c"
  },
  passDiv: {
    fontSize: "12px",
    padding: "2px 5px",
    color: "#26a1a4"
  },
  nonDiv: {
    fontSize: "12px",
    padding: "2px 5px",
    color: "#a9a9a9"
  }
});

class AgeDetailSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: true,
      startage: '',
      endage: '',
      Isnum: 1,
      fromTo: 1
    };
  }

  onClickDiv = () => {
    const { page } = this.state;
    this.setState({
      page: !page
    });
  };

  hadleClick = e => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  minChange = event => {
    const { endage } = this.state
    this.setState({
      startage: event.target.value
    });
    if (Number.parseInt(endage) > Number.parseInt(event.target.value)) {
      this.setState({
        fromTo: true
      });
    } else {
      this.setState({
        fromTo: false
      });
    }
    if (Number.parseInt(event.target.value)) {
      this.setState({
        Isnum: true
      });
    } else {
      this.setState({
        Isnum: false
      });
    }
    if (endage === '' && event.target.value === '') {
      this.setState({
        Isnum: 1,
        fromTo: 1
      })
    }
  };
  maxChange = event => {
    const { startage } = this.state
    this.setState({
      endage: event.target.value
    });
    if (Number.parseInt(startage) < Number.parseInt(event.target.value)) {
      this.setState({
        fromTo: true
      });
    } else {
      this.setState({
        fromTo: false
      });
    }
    if (Number.parseInt(event.target.value)) {
      this.setState({
        Isnum: true
      });
    } else {
      this.setState({
        Isnum: false
      });
    }
    if (startage === '' && event.target.value === '') {
      this.setState({
        Isnum: 1,
        fromTo: 1
      })
    }
  };
  onClick = () => {
    const { startage, endage, Isnum, fromTo } = this.state
    const { mainFilterObject, onClickEvent } = this.props;
    mainFilterObject.startAge = startage
    mainFilterObject.endAge = endage
    if (Isnum && fromTo) {
      onClickEvent()
    }
    this.setState({
      startage: '',
      endage: '',
      Isnum: 1,
      fromTo: 1
    });
  }

  render() {
    const { page, startage, endage, Isnum, fromTo } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div onClick={this.onClickDiv} className={classes.title}>
            <div>Age Detail Search</div>
            {page ? (
              <KeyboardArrowUp className={classes.iconsize} />
            ) : (
                <KeyboardArrowDown className={classes.iconsize} />
              )}
          </div>
          {page ? (
            <>
              <div className={classes.display}>
                <input
                  type="number"
                  min={1}
                  max={100}
                  placeholder={'From'}
                  onChange={this.minChange}
                  className={classes.frominputBox}
                  value={startage}
                />
                <input
                  type="number"
                  min={1}
                  max={100}
                  placeholder={'To'}
                  onChange={this.maxChange}
                  className={classes.toinputBox}
                  value={endage}
                />
              </div >
              <div >
                <CustomBtn
                  text={"Search"}
                  stylebtn={classes.goBtn}
                  onClick={this.onClick}
                  disabled={!(startage && endage)}
                />
              </div>
              <div style={{ paddingTop: "5px" }}>
                <div className={Isnum === 1 ? classes.nonDiv : Isnum ? classes.passDiv : classes.errorDiv}><CheckCircle className={classes.error} /> Only numbers can be entered.</div>
                <div className={fromTo === 1 ? classes.nonDiv : fromTo ? classes.passDiv : classes.errorDiv}><CheckCircle className={classes.error} /> "From" cannot go beyond "To".</div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AgeDetailSearch);
