import { action, observable } from "mobx";
import HomeRepository from "../repository/HomeRepository";
// import AbsoluteModel from "modules/model/AbsoluteModel";
import AbsoluteCancerModel from "modules/model/AbsoluteCancerModel";
import AbsoluteGeneModel from "modules/model/AbsoluteGeneModel";
import AbsoluteGeneHgvsModel from "modules/model/AbsoluteGeneHgvsModel";
import AbsoluteSNVModel from "modules/model/AbsoluteSNVModel";
import AbsoluteCNVModel from "modules/model/AbsoluteCNVModel";
import AbsoluteSVModel from "modules/model/AbsoluteSVModel";
import RelativeModel from "modules/model/RelativeModel";
import CancerModel from "modules/model/CancerModel";
import DxModel from "modules/model/DxModel";
import CancerTableModel from "modules/model/CancerTableModel";
import GeneTableModel from "modules/model/GeneTableModel";
import ActionableTableModel from "modules/model/ActionableTableModel";
import SnvTableModel from "modules/model/SnvTableModel";
import SnvTableModelAnaly from "modules/model/SnvTableModelAnaly";
import CnvTableModel from "modules/model/CnvTableModel";
import SvTableModel from "modules/model/SvTableModel";
import ProteinDataModel from "modules/model/ProteinDataModel";
import AnnotationDataModel from "modules/model/AnnotationDataModel"
import DrugActionableModel from "modules/model/DrugActionableModel"
import SelectedGeneListModel from "modules/model/SelectedGeneListModel";

// configure({ enforceAction: "true" });
export default class HomeStore {
  // @observable.shallow categoryTotalCounts = {};
  @observable lastUpdateDate;
  // @observable categoryTotalCountList = [];
  // @observable categoryTotalOriginCounts = [];
  // @observable isBannerClose = false;
  @observable cancerTypeData;
  @observable geneTypeData;
  @observable variantTypeListData = [];

  @observable variantTypeData;
  @observable returnCancerData;
  @observable returnDetailCancerData;
  @observable DemographyData;
  @observable returnGeneData;
  @observable returnGeneActionData;
  @observable allGeneData;
  @observable allSnvData;
  @observable allCnvData;
  @observable allSvData;
  @observable returnPersonData;
  @observable returnSnvData;
  @observable returnCnvData;
  @observable returnSvData;
  @observable returnKeyWord;
  @observable downloadData;
  @observable valueData;
  @observable SubFilterData;
  @observable PersonGraphicsData;

  @observable SampleType;
  @observable primarySite;
  @observable cancerTableData;
  @observable cancerChartData;
  @observable dxAge;

  //@observable GeneGraphicsData;

  @observable backgroundCancerChartData;
  @observable totalData;

  @observable absoluteData = {};
  @observable rowDataSearchPerson = {};

  @observable proteinData; // by jdy 2020-11-17
  @observable variantCategory; // by jdy 2020-11-17
  @observable geneActionableData; // by jdy 2020-11-17
  @observable annotationCategory; // by jdy 2020-11-24
  @observable selectedGeneList; // by jdy 2020-12

  // @observable TESTDATA;

  constructor(root) {
    this.root = root;
  }

  // @action
  // setIsBannerClose(data) {
  //   this.isBannerClose = data;
  // }

  @action
  async resetChartData() {
    this.absoluteData = {};
    // this.cancerChartData = {};
    // this.allGeneData = {};
    // this.allSnvData = {};
    // this.allCnvData = {};
    // this.allSvData = {};
    this.dxAge = {};
    // this.PersonGraphicsData = {};
    // this.proteinData = null;
    
  }

  @action
  async getLastUpdateDate() {
    const { data } = await HomeRepository.fetchLastUpdateDate();
    this.lastUpdateDate = data.lastUpdateDate;
  }

  @action
  async getLastUpdateDateHgvs() {
    const { data } = await HomeRepository.fetchLastUpdateDateHgvs();
    this.lastUpdateDate = data.lastUpdateDate;
  }

  @action
  async TotalData(dat) {
    const { data } = await HomeRepository.returnCanceTableData(dat);
    this.totalData = data;
  }

  @action
  async getCancerTypeData(dat) {
    const { data } = await HomeRepository.returnCancerTypeData(dat);
    this.cancerTypeData = data;
  }
  @action
  async getGeneTypeData(dat) {
    const { data } = await HomeRepository.returnGeneData(dat);
    this.geneTypeData = data;
  }
  @action
  async getVariantTypeListData(dat) {
    const { data: snv } = await HomeRepository.returnVariantData(dat, 'snvs');
    this.variantTypeListData[0] = snv;
    const { data: cnv } = await HomeRepository.returnVariantData(dat, 'cnvs');
    this.variantTypeListData[1] = cnv;
    const { data: sv } = await HomeRepository.returnVariantData(dat, 'svs');
    this.variantTypeListData[2] = sv;
  }

  @action
  async backGroundCancerChartData(dat) {
    const { data } = await HomeRepository.returnCancerData(dat);
    this.backgroundCancerChartData = data;
  }

  @action
  async getDetailCancerDate(dat) {
    const { data } = await HomeRepository.returnCancerData(dat);

    let DetailCancer = []
    for (let i of data) {
      if (i.subject > 0) {
        DetailCancer.push(i)
      }
    }

    this.returnDetailCancerData = DetailCancer;
  }

  @action
  async getDemagraphyDate(dat) {
    const { data } = await HomeRepository.returnCancerData(dat);
    this.DemographyData = data;
  }

  @action
  async getreturnPersonDate(dat) {
    const { data } = await HomeRepository.returnpersonsData(dat);
    this.returnPersonData = data;
  }

  @action
  async getreturnPersonDateAnaly(dat) {
    const { data } = await HomeRepository.returnpersonsDataAnaly(dat);
    this.returnPersonData = data;
  }

  @action
  async getDownloadDate(dat) {
    const { data } = await HomeRepository.returnpersonsData(dat);
    this.downloadData = data;
  }

  @action
  async getreturnGeneDate(dat) {
    const { data } = await HomeRepository.returnGeneData(dat);
    // this.returnGeneData = data;
    this.returnGeneData = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new GeneTableModel(d, i))
    };

  }

  //DrugActionable 표데이터  - ksh 20210128
  @action
  async getreturnGeneActionData(dat) {
    const { data } = await HomeRepository.returnGeneDataDrugAction(dat);

    // this.returnGeneData = data;
    this.returnGeneActionData = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new ActionableTableModel(d, i))
    };

  }

  @action
  async getreturnGeneDateHgvs(dat) {
    const { data } = await HomeRepository.returnGeneDataHgvs(dat);
    // this.returnGeneData = data;
    this.returnGeneData = {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      last: data.last,
      sort: data.sort,
      first: data.first,
      numberOfElements: data.numberOfElements,
      size: data.size,
      number: data.number,
      content: data.content.map((d, i) => new GeneTableModel(d, i))
    };

  }

  @action
  async getKeyWord(dat) {
    if (dat.length > 30) {
      const { data } = await HomeRepository.returnKeyWord(dat);
      this.returnKeyWord = data;
    } else {
      this.returnKeyWord = null;
    }

  }

  @action
  async getreturnVariantDate(dat, variant) {
    const { data } = await HomeRepository.returnVariantData(dat, variant);
    if (variant === "snvs") {
      this.returnSnvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new SnvTableModel(d, i))
      };
    }
    if (variant === "cnvs") {
      this.returnCnvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new CnvTableModel(d, i))
      };
    }
    if (variant === "svs") {
      this.returnSvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new SvTableModel(d, i))
      };
    }
  }

  @action
  async getreturnVariantDateAnaly(dat, variant) {
    const { data } = await HomeRepository.returnVariantDataAnaly(dat, variant);
    if (variant === "snvs") {
      this.returnSnvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new SnvTableModelAnaly(d, i))
      };
    }
    if (variant === "cnvs") {
      this.returnCnvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new CnvTableModel(d, i))
      };
    }
    if (variant === "svs") {
      this.returnSvData = {
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        last: data.last,
        sort: data.sort,
        first: data.first,
        numberOfElements: data.numberOfElements,
        size: data.size,
        number: data.number,
        content: data.content.map((d, i) => new SvTableModel(d, i))
      };
    }
  }

  @action
  async getVariantTypeData(dat) {
    const { data } = await HomeRepository.returnVariantTypesData(dat);
    this.variantTypeData = data;
  }

  @action
  async getCancerTableData(dat) {
    // console.log("returnCanceTableData : ", dat);

    const { data } = await HomeRepository.returnCanceTableData(dat);
    // this.cancerTableData = data;
    this.cancerTableData = data.map((d, i) => new CancerTableModel(d, i));;
  }

  @action
  async getValueData(dat) {
    const { data } = await HomeRepository.returnValueList(dat);
    this.valueData = data;
  }


  @action
  async getSubFilterData(type, dat) {
    const { data } = await HomeRepository.returnSubFilter(type, dat);
    this.SubFilterData = data;
  }

  @action
  async getPersonGraphicsData(dat) {
    const { data } = await HomeRepository.returnPersonDemoGraphicsData(dat);
    this.PersonGraphicsData = data;
  }


  //SampleType 그래프 데이터 API
  @action
  async getSampleType(dat) {
    const { data } = await HomeRepository.returnSampleTypeData(dat);
    this.SampleType = data;
  }

  //PrimarySite 그래프 데이터 API
  @action
  async getPrimarySite(dat) {
    const { data } = await HomeRepository.returnPrimarySiteData(dat);
    this.primarySite = data;
  }

  // @action
  // async getPersonGraphicsDataH(dat) {
  //   const { data } = await HomeRepository.returnPersonDemoGraphicsDataH(dat);
  //   this.GeneGraphicsData = data;
  // }

  @action
  async getDxGraphicsData(dat, absolute) {
    let type = absolute ? '/absolute' : ''
    const { data } = await HomeRepository.returnPersonDemoGraphicsDatadx(type, dat);
    this.dxAge = new DxModel(data, absolute, dat);
  }

  @action
  async getCancerChartData(dat) {
    const { data } = await HomeRepository.returnCanceTableData(dat);
    this.cancerChartData = new CancerModel(data);
  }

  @action
  async getAllGeneDate(dat) {
    const { data } = await HomeRepository.returnCancerGeneData(dat);
    this.allGeneData = new RelativeModel(data.content);
  }

  @action
  async getAllVarientDate(dat, variant) {
    const { data } = await HomeRepository.returnVariantCancerData(dat, variant);
    if (variant === "snvs") {
      this.allSnvData = new RelativeModel(data.content);
    }
    if (variant === "cnvs") {
      this.allCnvData = new RelativeModel(data.content);
    }
    if (variant === "svs") {
      this.allSvData = new RelativeModel(data.content);
    }
  }

  @action
  async getAbsoluteData(type, dat, tab) {
    
    // alert(type);
    // console.log("getAbsoluteData : type, dat : " + type + dat);

    const { data } = await HomeRepository.returnCancerAbsoluteData(type, dat); //API 호출
    // tab === 0 ? await HomeRepository.returnCancerAbsoluteData(type, dat)
    //   : tab === 1 ? await HomeRepository.returnAbsoluteGeneData(type, dat)
    //     : await HomeRepository.returnAbsolutevariantData(type, dat);



    // 그래프 그리기
    switch (type) {
      case 'cancers':
        this.absoluteData = {
          ...this.absoluteData,
          cancer: new AbsoluteCancerModel(data, 'selectedCancer')
        }
        break;
      case 'genes':
        this.absoluteData = {
          ...this.absoluteData,
          gene: new AbsoluteGeneModel(data.content, 'selectedGene')
        }
        break;
      case 'variants/snvs':
        this.absoluteData = {
          ...this.absoluteData,
          snv: new AbsoluteSNVModel(data.content, 'selectedSnv')
        }
        break;
      case 'variants/cnvs':
        this.absoluteData = {
          ...this.absoluteData,
          cnv: new AbsoluteCNVModel(data.content, 'selectedCnv')
        }
        break;
      case 'variants/svs':
        this.absoluteData = {
          ...this.absoluteData,
          sv: new AbsoluteSVModel(data.content, 'selectedSv')
        }
        break;
      case 'genesHgvs':
        this.absoluteData = {
          ...this.absoluteData,
          geneHgvs: new AbsoluteGeneHgvsModel(data.content, 'selectedGeneHgvs')
        }
        break;
      default:
        break;
    }
  }

  @action
  async getRowDataSearchPerson(query) {
    const { data } = await HomeRepository.getRowDataSearchPerson(query);
    this.rowDataSearchPerson = data;
  }

  // @action
  // async getReturnProteinData(query) {
  //   const { data } = await HomeRepository.getreturnProteinData(query);
  //   this.rowDataSearchPerson = data;
  // }

  // Gene Protein Chart 용 data 생성, added by jdy 2020-11
  @action
  async getProteinData(query) {
    const { data } = await HomeRepository.returnProteinData(query);

    this.proteinData = new ProteinDataModel(data.content);

    // this.proteinData = {
    //   ...this.proteinData,
    //   chart: new ProteinDataModel(data)
    // }
    
  }
  
  // Variant presentTab Interpretion Criteria Chart 용 data 생성, added jdy 2020-11
  @action
  async getVariantCategory(dat) {
    const  { data } = await HomeRepository.returnVariantCategory(dat);
    this.variantCategory = data;
  }

  // Gene presentTab의 Drug Actionable Chart 용 data를 생성, added by jdy 2020-11
  @action
  async getGeneActionableData(dat) {
    const  { data } = await HomeRepository.returnGeneActionableData(dat);
    // this.geneActionableData = data;
    this.geneActionableData = new DrugActionableModel(data.content, 'selectedGene');
    //new GeneActionableModel(data.content)
  }
  
  // Variant presentTab Annotation Data Chart 용 data 생성, added by jdy 2020-11   
  @action
  async getAnnotationCategory(dat) {
    const  { data } = await HomeRepository.returnAnnotationCategory(dat);
    // this.annotationCategory = data;
    this.annotationCategory = new AnnotationDataModel(data.content);
  }

  // Variant presentTab의 Gene Protein Chart 상단의 GENE type 선택 창의 list 항목을 생성, added by jdy 2020-12 
  @action
  async getSelectedGeneList(query) {
    let selectedGeneList = [];
    
    if (query.majorGene && query.majorGene.length > 0){

      query.majorGene.map((data, i) => {
        selectedGeneList.push({geneName:data});
      });
    }
 
    this.selectedGeneList = new SelectedGeneListModel(selectedGeneList, this.proteinData);

  }


}
