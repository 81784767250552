import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
// import DataPageTable from "components/organisms/DataPageTable";
// import PageTemplate from "pages/template/pageTemplate";
// import LeftMenu from "components/organisms/LeftMenu";
// import queryString from "query-string";
import CustomBtn from "components/atoms/CustomBtn";
import SetListTable from "components/organisms/SetListTable";
import CheckCircle from "@material-ui/icons/CheckCircle";
// import DiaGram from "assets/images/analysis/diagramthree.svg";
import Run from "assets/images/analysis/runwhite";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import CustomDialog from "components/atoms/CustomDialog";
import GoLogin from "components/atoms/CustomDialog/GoLogin";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

let localStorage = window.localStorage;

const styles = theme => ({
  container: {
    display: 'flex'
  },
  mainTop: {
    width: "100%",
    paddingTop: "50px",
    margin: "auto",
    borderTop: "1px solid #c6c6c6"
    // "@media (max-width: 1500px)": {
    //   width: '90%',
    // },
  },
  title: {
    // paddingBottom: "15px",
    margin: "0px 100px"
  },
  maintitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#4a4a4a",
    margin:'5px 2px 2px 2px',
    display: "flex"
  },
  smalltitle: {
    paddingTop: "8px",
    fontSize: "15px",
    textAlign: "center",
    color: "#4a4a4a",
    height: "40px"
  },
  center: {
    minHeight: "700px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#f6f6f6",
    // overflowY: 'auto',
    margin: "0px 100px"
  },
  runbtn: {
    width: "190px",
    height: "30px",
    margin: "0px 5px",
    float: "right",
    "@media (max-width: 1500px)": {
      width: "120px"
    }
  },
  activerunbtn: {
    width: "190px",
    height: "30px",
    margin: "0px 5px",
    float: "right",
    color: "#ffffff",
    backgroundColor: "#26a1a4",
    "&:hover": {
      backgroundColor: "#26a1a4"
    },
    "@media (max-width: 1500px)": {
      width: "120px"
    }
  },
  options: {
    color: "#26a1a4",
    padding: "10px 5px 0px 20px",
    float: "right",
    fontSize: "14px"
  },
  nooptions: {
    color: "#a9a9a9",
    padding: "10px 5px 0px 20px",
    float: "right",
    fontSize: "14px"
  },
  iconSize: {
    fontSize: "12px"
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  btunsize: {
    width: "20px"
  },
  example: {
    float: "left"
  }
});

@inject("analysis", "comm")
@observer
class Analysis extends Component {
  state = {
    open: false,
    checklist: [],
    reported: null,
    helpOpen: false,
    imageUrl: "help/2_5_1.png",
    htmlId: 0
  };

  componentDidMount() {
    const {
      analysis,
      comm: { originUser }
    } = this.props;
    if (originUser) {
      analysis.getSetListData("");
    } else {
      this.setState({
        open: true
      });
    }
  }

  componentDidUpdate() {
    const {
      analysis,
      analysis: { manageSet },
      comm: { originUser }
    } = this.props;
    const { open } = this.state;
    if (originUser && !manageSet) {
      analysis.getSetListData("");
    }
    if (originUser && open) {
      this.setState({
        open: false
      });
    }
  }

  onClickOpen = () => {
    this.setState({
      open: true
    });
  };

  checkReported = value => {
    let booleanValue = null;
    if (value === "true") {
      booleanValue = true;
    } else if (value === "false") {
      booleanValue = false;
    } else {
      booleanValue = null;
    }
    this.setState({
      reported: booleanValue
    });
  };

  run = () => {
    const { history } = this.props;
    const { checklist } = this.state;
    if (checklist && checklist.length > 1) {
      let test = checklist.reduce((x, y) => `${x}**${y}`);
      history.push(`/analysis/operation/${test}`);
    }
  };

  setCehckList = data => {
    this.setState({
      checklist: data
    });
    if (data.length === 0) {
      this.setState({
        reported: null
      });
    }
  };

  clickMainHelp = () => {
    let url;
    let htmlId;

    url = "/2_5_1.png";
    htmlId = 19;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  render() {
    const { classes } = this.props;
    const {
      analysis: { manageSet },
      comm: { originUser }
    } = this.props;
    const { checklist, reported, open } = this.state;
    return (
      <>
        <div className={classes.mainTop}>
          <div className={classes.title}>
            <div className={classes.container}>
              <div className={classes.maintitle}>Set Operations</div>
              <IconButton color="primary" className={classes.helpIconButton} onClick={this.clickMainHelp}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </div>

            <CustomDialog
              open={this.state.helpOpen}
              onClickOpen={this.onClose}
              imageUrl={this.state.imageUrl}
              htmlId={this.state.htmlId}
              CustomDialogContent={HelpContentList}
            />
            <div className={classes.smalltitle}>
              <span className={classes.example}>
                Set Operation analyzes the relationship between clinical data
                sets selected by the user.
              </span>
              <CustomBtn
                text={"Run"}
                img={checklist.length > 1 ? Run : DirectionsRunIcon}
                disabled={checklist.length > 1 ? false : true}
                isSVG={checklist.length > 1 ? true : false}
                stylebtn={
                  checklist.length > 1 ? classes.activerunbtn : classes.runbtn
                }
                styleicon={classes.btunsize}
                onClick={this.run}
              />
              <div
                className={
                  checklist.length > 1 ? classes.options : classes.nooptions
                }
              >
                <CheckCircle className={classes.iconSize} />
                Only two to three can be selected.
              </div>
            </div>
          </div>
          <div className={classes.center}>
            <SetListTable
              manageSet={originUser ? manageSet : Object.entries(localStorage)}
              checklist={checklist}
              reported={reported}
              setCehckList={this.setCehckList}
              checkReported={this.checkReported}
              originUser={originUser}
            />
          </div>
        </div>
        <CustomDialog
          open={open}
          onClickOpen={this.onClickOpen}
          type={"Alert"}
          CustomDialogContent={GoLogin}
        />
      </>
    );
  }
}
export default withRouter(withStyles(styles)(Analysis));
