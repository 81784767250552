import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import LogTable from "components/organisms/LogTable";
import VisitorTable from "components/organisms/VisitorTable";

@inject("system")
@observer
class ApplicationServerAccessLog extends Component {
  async componentDidMount() {
    const { system } = this.props;
    system.getVisitorsNumber("day");
    system.getAccessLog("/server-access-log");
  }

  onChangeVisitor = value => {
    const { system } = this.props;
    system.getVisitorsNumber(value);
  };

  render() {
    const {
      system: { visitorNumber, accessLogs }
    } = this.props;

    return (
      <>
        <VisitorTable
          data={visitorNumber}
          static={false}
          onChangeVisitor={this.onChangeVisitor}
        />
        <LogTable
          isPagination={true}
          tableList={accessLogs}
          length={accessLogs.length}
          title={"Application server access log"}
          subTitle={"click system administrator column to view access log"}
        />
      </>
    );
  }
}

export default ApplicationServerAccessLog;
