import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import ColumnChart from "components/atoms/ColumnChart";
import Absolute1Chart from "components/atoms/AbsoluteChart/Absolute1Chart";
import NoScrollChart from "components/atoms/NoScrollChart";
import Absolute2Chart from "components/atoms/AbsoluteChart/Absolute2Chart";

import CheckIcon from '@material-ui/icons/Check';
import LollipopChart from "components/atoms/LollipopChart";
import ChooseChartRange from 'components/atoms/ChooseChartRange';

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

// Gene presentTab 선택시 display 되는 chart, added by jdy 2020-11

const styles = theme => ({
  container: {
    display: 'flex'
  },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "2px 7px 1px 12px",
    fontWeight: "500",
    lineHeight: '26px',
  },
  board2: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  boardHover2: {
    // DATA Explorer 메인 그래프 반응형 웹 3 -> 2개로 변경 - jdy 2021-01-13
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  checkBoard2: {
    width: "47.5%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 2px #26a1a4",
    backgroundColor: "#ffffff",
    margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)",
    cursor: 'pointer',
    "&:hover": {
      border: "solid 2px #26a1a4",
      margin: "calc(1% - 1px) calc(1% - 1px) calc(1% - 1px) calc(1% - 1px)"
    },
    "@media (max-width: 1915px)": {
      width: "47.5%",
    },
  },
  boardOne: {
    width: "calc(97% + 2px)",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "1% 1% 1% 1%",
    cursor: 'pointer',
    "@media (max-width: 1915px)": {
      width: "calc(97% + 2px)",
    },
  },
  chartWidth100: {
    width: '100%',
    margin: 'auto',
    height: '200px'
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  helpIconButton2: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 0px"
  },
  tabcontainer: {
    background: "#f6f6f6",
    border: "solid 1px #c6c6c6",
    padding: "7.5px",
    display: "flex",
    flexFlow: "wrap"
  },
})

@inject("home")
@observer
class GeneChartPages extends Component {

  constructor(props) {
    super(props);
    
    this.m_iActiveGene = 0;
  }

  state = {
    iActiveNum : 0,
    helpOpen : false,
    imageUrl: "help/2_4_1.png",
    htmlId: 7
  }

  proteinHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_3_1.png";
    htmlId = 11;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  geneChartHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_3_2.png";
    htmlId = 12;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  drugActHelp = () => {
    let url;
    let htmlId;

    url = "/2_4_3_3.png";
    htmlId = 13;

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  divOpen = (e) => {
    const { chartOpen } = this.props
    chartOpen(e)
  }

  onHandlePageChoice = (iRange) => {
    this.setState(
      {iActiveNum : iRange}
    );
  }

  onHandleGeneChoice = (geneName) => {
    const { onClickMainFilter, mainFilterObject, home: { proteinData, selectedGeneList} } = this.props;
    
    if (selectedGeneList.nNames > 0) {
      for (let i=0; i<selectedGeneList.nNames; i++)
      {
        if (selectedGeneList.geneNames[i] == geneName) {
          this.m_iActiveGene = i;
          break;
        }
      }
    }
    else {
      this.m_iActiveGene = 0;   
    }
    
    mainFilterObject.proteinGene = geneName;

    const queryObj = {
      store : 'home',
      action : 'protein'
    };

    onClickMainFilter(queryObj);

    this.setState(
      {
        iActiveNum : 0
      }
    );
  }

  render() 
  {
    const { mainDataType, mainFilterObject, dxAge, filterTab, checked, absoluteData, absolute, cancerTypeData, posQuery, originUser, classes, home: {proteinData, selectedGeneList, geneActionableData} } = this.props;
   
    let iActiveNum = this.state.iActiveNum;
    let iActiveGene;

    let resultChart = [];

    let geneName = "";

    if (selectedGeneList.nNames > 0) {
      iActiveGene = -1;
      for (let i=0; i<selectedGeneList.nNames; i++)
      {
        if (selectedGeneList.geneNames[i] == proteinData.geneName) {
          iActiveGene = i;
          break;
        }
      }
    }
    else {
      iActiveGene = 0;
    }

    if (-1 === iActiveGene) {
      // this is proteinData.geneName is ""
      geneName = selectedGeneList.geneNames[this.m_iActiveGene];

      if (proteinData && proteinData.geneName !== "") {
        geneName = "Loading...";
      }
    }
    else {
      if (proteinData && proteinData.geneName) {
        geneName = proteinData.geneName;
      }
    }

    resultChart.length = 0;

    let i = 'gene';

    resultChart.push(
      <div className={checked[i] ? classes.checkBoard2 : classes.boardHover2} key={mainDataType[i].type} >
        <div className={classes.chartTitle} onClick={() => this.divOpen(i)} >
          {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
          <span>{mainDataType[i].type}</span>
          <span>
            <IconButton color="primary" className={classes.helpIconButton} onClick={this.geneChartHelp}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </span>
        </div>
        <div className={classes.chartwidth}>

          {absolute ?
            <Absolute1Chart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
            : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
              <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
              <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
          }

        </div>
      </div >
    );
    let j = 'drug';
    resultChart.push(
      <div className={checked[j] ? classes.checkBoard2 : classes.boardHover2}  key={mainDataType[j].type} >
        <div className={classes.chartTitle} onClick={() => this.divOpen(j)} >
          {/* by jdy : {checked[i] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null} */}
          {checked[j] ? <span><CheckIcon style={{ fontSize: "18px", color: "#26a1a4" }} /></span> : null}
          <span> Drug Actionable</span>
          <span>
            <IconButton color="primary" className={classes.helpIconButton} onClick={this.drugActHelp}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </span>    
        </div>
        <div className={classes.chartwidth}>
          <Absolute2Chart type={mainDataType[j].type} list={geneActionableData} mainFilterObject={mainFilterObject} />
          {/* by jdy {absolute ?
            <AbsoluteChart type={mainDataType[i].type} list={absoluteData[i]} mainFilterObject={mainFilterObject} />
            : mainDataType[i].chart && mainDataType[i].chart.id.length > 5 ?
              <ColumnChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} /> :
              <NoScrollChart type={mainDataType[i].type} list={mainDataType[i].chart} mainFilterObject={mainFilterObject} />
          } */}

        </div>
      </div >
    );

    return (

      <div className={classes.tabcontainer}>

        <div className={classes.boardOne}>
            <div className={classes.chartTitle}>
        
              <ChooseChartRange iActiveNum={iActiveNum} geneName={geneName} proteinData={proteinData} selectedGeneList={selectedGeneList} onHandlePageChoice={this.onHandlePageChoice} onHandleGeneChoice={this.onHandleGeneChoice} onProteinHelp={this.proteinHelp}/>

            </div>
            <div className={classes.chartWidth100}>       
        
              <LollipopChart iActiveNum={iActiveNum} geneName={geneName} proteinData={proteinData} />
              
            </div>
        
        </div> 
        
        <div>
          <br></br>
        </div>

        {resultChart}

        <CustomDialog
          open={this.state.helpOpen}
          onClickOpen={this.onClose}
          imageUrl={this.state.imageUrl}
          htmlId={this.state.htmlId}
          CustomDialogContent={HelpContentList}
        />
      </div>
    );
  }
  
}


export default withStyles(styles)(GeneChartPages);
