import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import AccountTable from "components/organisms/AccountTable";
import LogTable from "components/organisms/LogTable";

@inject("system")
@observer
class UserAccount extends Component {
  componentDidMount() {
    const { system } = this.props;
    system.getAccounts("ROLE_KM_USER");
    system.getAccessLog("/server-access-log");
  }

  render() {
    const {
      system: { accountList, accessLogs }
    } = this.props;

    return (
      <>
        <AccountTable
          isPagination={true}
          isRowSpan={false}
          isCheckBox={true}
          isSort={true}
          tableList={accountList}
          length={accountList.length}
          title={"User account"}
          subTitle={"administrator management"}
        />

        <LogTable
          isPagination={true}
          tableList={accessLogs}
          length={accessLogs.length}
          title={"Application server access log"}
          subTitle={"click system user account column to view access log"}
        />
      </>
    );
  }
}

export default UserAccount;
