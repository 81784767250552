class LineModel {
  constructor(data, i) {
    //cntKr : 한국 방문자 수,  cntFo : 해외 방문자 수,  
    const { name, value , cntKr, cntFo } = data;
    this.x = name;
    this.y = value;
    this.cntKr = cntKr;
    this.cntFo = cntFo;
  }
}

export default LineModel;
