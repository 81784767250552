import React, { useCallback, useMemo } from "react";
import TableRow from "@material-ui/core/TableRow";
import useCustomContext from "./useCustomContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  head: {
    backgroundColor: "#f3f3f3"
  },
  footer: {
    backgroundColor: "none"
  },
  root: {
    height: 35
  },
  oddRoot: {
    height: 35,
    "&:nth-of-type(odd)": {
      backgroundColor: "#14a4e7"
    },
    "&$selected": {
      backgroundColor: "#f3f3f3"
    }
  },
  selected: {}
});

function CustomRow(props) {
  const { rowType, d, detail, selectRow, oddRow } = props;
  const { children } = props;

  const { actions } = useCustomContext("SelectContext");
  const classes = useStyles();
  // const className = rowType ? classes[rowType] : "";

  let isItemSelected = false;
  if (selectRow) {
    if (rowType === "head" || rowType === "footer") isItemSelected = false;
    else if (rowType === "body") {
      isItemSelected = actions.isSelected(d);
    } else isItemSelected = actions.isDetailsSelected(detail);
  }

  const handleClickRow = useCallback(
    e => {
      if (rowType === "head" || rowType === "footer") {
        return;
      } else {
        if (selectRow) {
          // FIXME: handleClickCheckbox가 ture이지만 클릭되지 않아야 하는 조건
          // 1. if selected === maxSelectCount then true
          // 3. if selected.hasOwnProperty('d.id') then false
          // 4. if mode === 'checkbox'
          if (rowType === "body") actions.handleClickRow(e, d);
          else actions.handleClickDetailRow(e, detail);

          if (selectRow.handleClickCheckbox) {
            const { handleClickCheckbox } = selectRow;
            actions.handleClickCheckbox(handleClickCheckbox, detail || d);
          }
        }
      }
    },
    [actions, rowType, d, detail, selectRow]
  );

  const className = detail ? `${d.id}-detail` : "";

  const row = useMemo(() => {
    return (
      <TableRow
        hover={rowType === "body"}
        className={className}
        variant={rowType}
        selected={isItemSelected}
        onClick={handleClickRow}
        classes={{
          root: oddRow ? classes.oddRoot : classes.root,
          selected: classes.selected,
          head: classes.head
        }}
      >
        {children}
      </TableRow>
    );
  }, [
      children,
      handleClickRow,
      isItemSelected,
      classes.root,
      classes.selected,
      classes.head,
      classes.oddRoot,
      oddRow,
      rowType,
      className
    ]);

  return row;
}

export default React.memo(CustomRow);
