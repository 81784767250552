import React, { useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import CustomCell from "./CustomCell";
import CustomRow from "./CustomRow";
import CustomPagination from "./CustomPagination";
import BodyRowContainer from "./BodyRowContainer";
import withContext from "./withContext";
import Checkbox from "./ui/CustomCheckBox";
import OrderIcon from "./ui/CustomOrderIcon";
// import useScroll from "./useScroll";

// const ROWS_PER_PAGE = 10;

// function Tfooter({ defaultPagination, columns, rowCount }) {
//   const isDefaultPagination = defaultPagination && Object.keys(defaultPagination).length > 0;

//   const pagenation = useMemo(() => {
//     return (
//       isDefaultPagination && (
//         <CustomPagination
//           handlePatination={defaultPagination.handlePatination}
//           colSpan={columns.length + 1}
//           count={rowCount}
//         />
//       )
//     );
//   }, [isDefaultPagination, columns.length, rowCount, defaultPagination.handlePatination]);
//   return (
//     <TableFooter>
//       <CustomRow rowType="footer">{pagenation}</CustomRow>
//     </TableFooter>
//   );
// }

function Tbody(props) {
  const { state, parentEl, ...rest } = props;
  // const [page, setPage] = useState(0);
  // const [rows, setRows] = useState([]);

  // function update() {
  //   setRows(
  //     props.defaultPagination
  //       ? state.data
  //       : rows.concat(state.data.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE))
  //   );
  // }

  // useEffect(() => {
  //   console.log("pagination update");
  //   update();
  //   console.log("pagination", rows);
  // }, [state.data]);

  // useEffect(() => {
  //   // 초기 랜더링
  //   console.log("init");
  //   if (state.data.length > 0) {
  //     update();
  //     setPage(page + 1);
  //   }
  // }, []);

  // const [scrollTop, el] = useScroll(parentEl);
  // const isBottomOfWindow = scrollTop + el.clientHeight === el.scrollHeight;
  // const maxPage = Math.ceil(state.data.length / ROWS_PER_PAGE);

  // useEffect(() => {
  //   if (isBottomOfWindow) {
  //     if (maxPage > page) {
  //       console.log("update");
  //       update();
  //       setPage(page + 1);
  //     }
  //   }
  // });

  const body = useMemo(() => {
    let colSpan = props.columns.length;
    if (props.selectRow || !props.hide) {
      colSpan++;
    }

    return (
      <TableBody>
        {props.data.length > 0 ? (
          props.data.map((d, i) => <BodyRowContainer key={i} d={d} parentEl={parentEl} {...rest} />)
        ) : (
            <CustomRow>
              <CustomCell colSpan={colSpan} align="center">
                No data...
            </CustomCell>
            </CustomRow>
          )}
      </TableBody>
    );
  }, [props.data]);

  return body;
}

function Thead(props) {
  const { columns, selectRow, headerIcon, defaultSort } = props;
  const cellType = "head";

  // FIXME: isSelectRow가 새로운 객체로 들어옴
  const isSelectRow = selectRow && Object.keys(selectRow).length > 0;
  const isDefaultSort = defaultSort && Object.keys(defaultSort).length > 0;
  const hide = selectRow && selectRow.hide;

  const checkCell = useMemo(() => {
    return (
      isSelectRow &&
      !hide && (
        <CustomCell cellType={cellType} padding={"checkbox"} align="center">
          {headerIcon || <Checkbox checkType={cellType} injectContext={"SelectContext"} />}
        </CustomCell>
      )
    );
  }, [headerIcon, isSelectRow]);

  const headerCellList = useMemo(() => {
    return columns.map((c, i) => {
      const { column, sort, align, style } = c;
      return (
        <CustomCell
          key={i}
          cellType={cellType}
          align={align}
          style={style && style.head}
          right={
            isDefaultSort &&
            sort && (
              <OrderIcon injectContext={"SortContext"} c={c} handleSort={defaultSort.handleSort} />
            )
          }
        >
          {column}
        </CustomCell>
      );
    });
  }, [columns, isDefaultSort, defaultSort.handleSort]);

  const headRow = useMemo(() => {
    return (
      <CustomRow rowType={cellType} {...props}>
        {checkCell}
        {headerCellList}
      </CustomRow>
    );
  }, [checkCell, headerCellList]);

  return <TableHead>{headRow}</TableHead>;
}

function CustomTable(props) {
  const { id, stickyHeader, ...rest } = props;
  //FIXME: console.log(props.state); //이쪽데이터를 쓰도록 수정해야함

  const table = useMemo(() => {
    return (
      <Table id={id} stickyHeader={stickyHeader}>
        <Thead {...rest} {...props.state} />
        <Tbody {...rest} />
        {/* {props.defaultPagination && props.data.length > 0 && <Tfooter {...rest} {...props.state} />} */}
      </Table>
    );
  }, [id, props.defaultPagination, props.state, stickyHeader, rest]);

  return table;
}

export default withContext(CustomTable);
