import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import dateFormat from "dateformat";
import "pages/home/home.scss";
import DateRange from "@material-ui/icons/DateRange";
import CancerTypeBarTable from "components/organisms/CancerTypeBarTable";
import HomeCaseTable from "components/organisms/HomeCaseTable";
// import IconButton from "@material-ui/core/IconButton";
// import Close from "@material-ui/icons/Close";
import HomeHeader from "components/organisms/HomeHeader";
// import Arrow from "@material-ui/icons/ArrowDownward";
import health from "assets/images/home/health.png";
import khidi from "assets/images/home/khidi.png";
import koreamedical from "assets/images/home/koreamedical.png";
import korea from "assets/images/home/korea.png";
import Api from "assets/images/home/api.svg";
import Data from "assets/images/home/data.svg";
import Documentation from "assets/images/home/documentation.svg";
import Releas from "assets/images/home/releas.svg";
import Assessment from "@material-ui/icons/Assessment";

@inject("home")
@observer
class Home extends Component {
  componentDidMount() {
    const { home } = this.props;
    home.getLastUpdateDate();
    home.getCancerTypeData({ sort: ["subject,desc"] });
    home.getValueData({})
    // this.props.home.getCategoryTotalCounts();
    // this.props.comm.setCurPage("");
    // home.setIsBannerClose(false);
  }

  // handleClick = () => {
  //   const { home } = this.props;
  //   home.setIsBannerClose(true);
  // };

  render() {
    const {
      home: { lastUpdateDate, cancerTypeData, valueData },
      // comm: { loggedIn },
      location: { pathname },
    } = this.props;
    let genePersonCount = valueData ? valueData[3].value : 0;
    let geneTotalCount = valueData ? valueData[0].value : 0;
    let TotalCount = valueData ? valueData[1].value : 0
    let cancercount = valueData ? valueData[2].value : 0;
    return (
      <div className="row">
        <div className="get-started">
          <div>
            <HomeHeader pathname={pathname} />
            <div className="test">
              <div className="MainTitltle">GET STARTED</div>
              <div className="subTitltle">K-MASTER Portal Application is a robust data-driven platform that allows cancer </div>
              <div className="subTitltle-btn">researchers and bioinformaticians to search and download cancer data for analysis</div>
            </div>
            <div className="get-started-chart-position">
              <div className="started">
                <div className="stated-icon">
                  <DateRange className="image" />
                </div>
                <div className="stated-title">
                  <p className="get-started-title-bold">
                    Last Data Release - {dateFormat(lastUpdateDate, "mediumDate")}
                  </p>
                </div>
              </div>
              <div className="get-started-chart-center">
                <div className="get-started-release">
                  <HomeCaseTable
                    categoryTotalCounts={cancercount}
                    name={"CANCER"}
                  />
                  <HomeCaseTable
                    categoryTotalCounts={genePersonCount}
                    name={"CASES"}
                  />
                  <HomeCaseTable
                    categoryTotalCounts={geneTotalCount}
                    name={"GENES"}
                  />
                  <HomeCaseTable
                    categoryTotalCounts={TotalCount}
                    name={"MUTATIONS"}
                  />
                </div>
              </div>
            </div>
            <div className="get-started-chart-table">
              <div className="started-case">
                <div className="get-started-case">
                  <div>
                    <Assessment className="image" />
                  </div>
                  Subject by cancer type kind of {cancercount}
                </div>
              </div>
              <div className="get-started-chart-centers">
                <CancerTypeBarTable
                  cancerList={cancerTypeData}
                  pathname={pathname}
                />
              </div>
            </div>
          </div>
          <div className={"home-bottom"}>
            <div className={"home-bottom-sponsor"}>Sponsor & Family site</div>
            <div className={"home-bottom-app"}>K-MASTER Applications </div>
            {/* <div className={"home-bottom-arrow"}>
              <Arrow />
            </div> */}
          </div>
        </div>
        <div className={"icon-bottom"}>
          <div className={"icon-bottom-sponser"}>
            <a target="_blank" rel="noopener noreferrer" href='http://www.mohw.go.kr/react/index.jsp' >
              <img src={health} alt="" className={"icon-bottom-family-site"} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='https://www.khidi.or.kr/kps' >
              <img src={khidi} alt="" className={"icon-bottom-family-site"} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='http://www.kumc.or.kr/main/index.do' >
              <img src={koreamedical} alt="" className={"icon-bottom-family-site"} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='http://www.korea.ac.kr/mbshome/mbs/university/index.do' >
              <img src={korea} alt="" className={"icon-bottom-family-site"} />
            </a>
          </div>
          <div className={"icon-bottom-app"}>
            <div className={"icon-bottom-appitem"}>
              <div>API</div>
              <img src={Api} alt="" />
            </div>
            <div className={"icon-bottom-appitem"}>
              <div>Releas Date</div>
              <img src={Data} alt="" />
            </div>
            <div className={"icon-bottom-appitem"}>
              <div>Documentation </div>
              <img src={Documentation} alt="" />
            </div>
            <div className={"icon-bottom-appitem"}>
              <div>Data 분양 </div>
              <img src={Releas} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
