import React, { Component } from "react";
import TopMenu from "components/atoms/TopMenu";
import { withStyles } from "@material-ui/core/styles";
import { inject } from "mobx-react";

const styles = theme => ({
  nav: {
    width: "70%",
    display: "flex",
    paddingLeft: "38px"
  }
});

@inject("comm")
class TopNav extends Component {
  onClickMenu = name => {
    const { comm } = this.props;
    comm.setCurPage(name);
    // comm.resetSelectedObj();
  };

  render() {
    const { classes, pathname } = this.props;
    return (
      <div className={classes.nav}>
        <TopMenu onClickMenu={this.onClickMenu} pathname={pathname} />
      </div>
    );
  }
}

export default withStyles(styles)(TopNav);
