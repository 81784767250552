import {
  axios
} from 'dependencyLibrary';

class SummaryRepository {
  URL = "/api/kmaster/summary";

  getSearchCancer(search, type, query) {
    return axios.get(`${this.URL}/${search}-cancer-${type}${query}`);
  }

  getCancer(cancerType, type, query) {
    return axios.get(`${this.URL}/${cancerType}/cancer-${type}${query}`);
  }

  getGeneTopByCancerData(query) {
    return axios.get(`${this.URL}/gene-top/by-cancer${query}`);
  }

  getRowDataSearchPerson(query) {
    return axios.get(`${this.URL}/search-person/row-data${query}`);
  }

  getRowDataSearch(mutated, query) {
    return axios.get(`${this.URL}/search-mutated-${mutated}/row-data${query}`);
  }

  // all list
  // filter - gene-all, variant-all-cnv-amp, variant-all-cnv-del, variant-all-snv, variant-all-sv
  // type - type, detail
  // gene
  // variant
  getAllList(filter, type, query) {
    return axios.get(`${this.URL}/${filter}/${type}${query}`);
  }
}

export default new SummaryRepository();