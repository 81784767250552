import HomeStore from "./HomeStore";
import CommStore from "./CommStore";
import SearchStore from "./SearchStore";
import SummaryStore from "./SummaryStore";
import SearchFormStore from "./SearchFormStore";
import SystemStore from "./SystemStore";
import LoginStore from "./LoginStore";
import AnalysisStore from "./AnalysisStore";

class Store {
  constructor() {
    this.home = new HomeStore(this);
    this.comm = new CommStore(this);
    this.search = new SearchStore(this);
    this.summary = new SummaryStore(this);
    this.searchform = new SearchFormStore(this);
    this.system = new SystemStore(this);
    this.auth = new LoginStore(this);
    this.analysis = new AnalysisStore(this);
  }
}

export default Store;
