const basicColorList = [
  "#5c4306",
  "#8a6c1c",
  "#ca9918",
  "#ffc429",
  "#f6de9f",
];
// let variants = ['snvs', 'cnvs', 'svs']

class AbsolutCancerModel {
  constructor(data, select) {
    this.id = []
    this.name = []
    this.totalSubject = []
    this.selectSubject = []
    this.selectedTotalSubject = []
    this.totalSubjectCnt = []
    this.selected = {}
    this.selectedgene = {}
    this.selectedvariant = {}
    this.series = []
    this.tickPositions = []
    this.tickPixelInterval = 20
    this.marginLeft = 70
    for (let i of data) {
      this.id.push(i.id);
      this.name.push(i.name.replace(" cancer", "").replace(" carcinoma", ""));
      this.totalSubject.push(i.subject);
      this.selectSubject.push(i[select] ? i[select].subject : 0);
      this.totalSubjectCnt.push(i.totalSubjectCnt);
      this.selectedTotalSubject.push(i.selectedTotalSubjectCnt);
      
      // if (i && i.genes) {
      //   for (let j of i.genes) {
      //     if (this.selectedgene[j.id]) {
      //       this.selectedgene[j.id].push(j.subjectCnt)
      //     } else {
      //       this.selectedgene[j.id] = [j.subjectCnt]
      //     }
      //   }
      // }
      // for (let name of variants) {
      //   if (i && i[select] && i[select][name]) {
      //     for (let j of i[select][name]) {
      //       if (this.selectedvariant[j.name]) {
      //         this.selectedvariant[j.name].push(j.subjectCnt)
      //       } else {
      //         this.selectedvariant[j.name] = [j.subjectCnt]
      //       }
      //     }
      //   }
      // }
    }

    if (this.selectedTotalSubject[0] === this.totalSubjectCnt[0]) {
      this.breaksFrom = this.totalSubject.reduce(function (a, b) {
        return Math.max(a, b);
      });
      this.selectedTotalSubject = [];

    } else {
      this.breaksFrom = this.totalSubject.reduce(function (a, b) { return Math.max(a, b); }) > this.selectedTotalSubject[0] ?
        this.totalSubject.reduce(function (a, b) { return Math.max(a, b); }) : this.selectedTotalSubject[0]
    }
    this.breaksFrom = this.totalSubject.reduce(function (a, b) {
      return Math.max(a, b);
    });

    // original
    // this.breaksFrom = this.breaksFrom + 100
    // this.breaksTo = this.totalSubjectCnt[0] - 100

    // breaksFrom, breaksTo 를 정밀히 계산하고, tick를 표시할 위치를 직접 지정, by jdy 2020-12

    this.maxTotalSubjectCnt = this.totalSubjectCnt[0];// + this.breaksFrom;

    let initPos = 0;
    let intervalPos = 10;

    let act = this.breaksFrom;

    let band = Math.ceil(act / 4);
  
    this.breaksFrom = act + band;
    this.breaksTo = this.maxTotalSubjectCnt - band;

    this.breakSize = 0;

    let interval = act; //Math.ceil(act / 2);

    this.tickPositions.push(0);

    initPos =  interval / 3.0;

    let iPos = 1;
    for (let ip=1; ip<10; ip++)
    {
      iPos = iPos * 10;

      if (initPos < iPos) {
        break;
      }
    }

    iPos /= 10;

    intervalPos = Math.ceil(initPos / iPos) * iPos;
      
    initPos = intervalPos;

    this.tickPositions.push(initPos);

    for (let iLoop=0; iLoop<2; iLoop++)
    {
      if (initPos < interval)
      {
        initPos += intervalPos;
        this.tickPositions.push(initPos);
      }
    }

    this.tickPositions.push(this.totalSubjectCnt[0]);

    // this.tickInterval = Math.ceil(act / 2);

    // Original
    // this.tickPixelInterval = this.totalSubject[0] > 100 ? 5 : 2
    // this.breaksFrom = this.breaksFrom + 70
    // this.breaksTo = this.totalSubjectCnt[0] - 10


    this.series = [
      {
        name: 'K-MASTER Total ',
        data: this.totalSubjectCnt,
        color: "#c6c6c6",
        stack: 2
      },
      {
        name: 'Selected DataSet Total ',
        data: this.selectedTotalSubject,
        color: "#7b7b7b",
        stack: 3
      },
      {
        name: 'DataSet Total ',
        data: this.totalSubject,
        color: "#26a1a4",
        stack: 0
      }
    ]
    if (Object.keys(this.selectedgene).length) {
      Object.keys(this.selectedgene).map((data, k) => {
        this.series.push({
          name: data,
          data: this.selectedgene[data],
          color: basicColorList[k],
          stack: 1
        })
        return null
      })
    } else if (Object.keys(this.selectedvariant).length) {
      Object.keys(this.selectedvariant).map((data, k) => {
        this.series.push({
          name: data,
          data: this.selectedvariant[data],
          color: basicColorList[k],
          stack: 1
        })
        return null
      })
    } else {
      this.series.push(
        {
          name: 'Selected DataSet ',
          data: this.selectSubject,
          color: "#ffc019",
          stack: 1
        }
      )
    }
  }
}

export default AbsolutCancerModel;
