import { extendObservable } from "mobx";

function getFormatDate(date) {
  var year = date.getFullYear(); //yyyy
  var month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  var day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  var hours = date.getHours();
  hours = hours >= 10 ? hours : "0" + hours; //day 두자리로 저장
  var min = date.getMinutes();
  min = min >= 10 ? min : "0" + min; //min 두자리로 저장
  return `${year}-${month}-${day} ${hours}:${min}`;
}

class DateFormatModel {
  constructor(data, property) {
    extendObservable(this, data);
    this[property || "createdDate"] = getFormatDate(
      new Date(property ? data[property] : data.createdDate)
    );
  }
}

export default DateFormatModel;
