const AgeList = ['age10', 'age20', 'age30', 'age40', 'age50', 'age60', 'age70', 'age80', 'age90', 'age100']

class DxModel {
  constructor(data, absolute, dat) {
    this.male = [];
    this.female = [];
    this.totalMale = [];
    this.totalFemale = [];
    this.all = [];
    this.series = []
    this.pieSeries = []
    this.maleAll = 0;
    this.femaleAll = 0;
    this.totalAll = []
    this.isAll = true
    if (Object.keys(dat).length > 2) {
      this.isAll = false
    }
    for (let i of AgeList) {
      this.male.push(data[i].male);
      this.female.push(data[i].female);
      this.all.push(data[i].male + data[i].female);
      this.totalMale.push(data[i].totalMale);
      this.totalFemale.push(data[i].totalFemale);
      this.totalAll.push(data[i].totalMale + data[i].totalFemale);
    }
    this.maleAll = this.male.reduce((x, y) => x + y);
    this.femaleAll = Math.abs(this.female.reduce((x, y) => x + y));
    this.pieSeries = [
      {
        name: "Female",
        y: this.femaleAll,
        color: "#ff7e77"
      },
      {
        name: "Male",
        y: this.maleAll,
        color: "#2c82be"
      },
    ]
    if (absolute) {
      this.series = [
        {
          name: 'Total',
          data: this.totalAll,
          color: "#26a1a4",
          stack: 0
        }, {
          name: 'Select',
          data: this.all,
          color: "#ffc019",
          stack: 1
        },
      ]
    } else {
      this.series = [
        {
          name: 'Select',
          data: this.all,
          color: "#c6c6c6",
          stack: 0
          // }, {
          //   name: 'Select',
          //   data: this.all,
          //   color: "#26a1a4",
          //   stack: 1
        },
      ]
    }

  }
}

export default DxModel;
