import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import CustomPagination from "./CustomPagination";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";

const styles = theme => ({
  th: {
    background: "#f5f5f5",
    border: "1px solid #c0bebe",
    color: "#6a6a6a",
    padding: "10px 10px",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    textAlign: "center",
    // fontSize: "1.4rem",
    fontWeight: 700
  },
  tr: {
    border: "1px solid #dddddd",
    background: "#fff"
  },
  pagination: {
    fontSize: "1.1rem"
  },
  regular: {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  space: {
    flex: "none"
  }
});

const row = ({
  page,
  rowsPerPage,
  tableList,
  isPagination,
  order,
  orderBy,
  isSort
}) => {
  const sortArray = () => {
    return isSort
      ? stableSort(tableList, getSorting(order, orderBy))
      : tableList;
  };

  return isPagination
    ? sortArray().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortArray();
};

const assign = (obj, newObj, filter) => {
  let retObj = {};

  if (Object.keys(obj).length <= 0) {
    return newObj;
  }

  for (let o in obj) {
    if (
      filter !== "variant-all-sv" &&
      (o === "gene" ||
        o === "genePersonCount" ||
        o === "geneSpecimenCount" ||
        o === "percentageVariantOfTotal" ||
        o === "chromosome" ||
        o === "start" ||
        o === "end" ||
        o === "ref" ||
        o === "var" ||
        o === "hgvsc" ||
        o === "hgvsp" ||
        o === "hgvsp" ||
        o === "mutType")
    ) {
      retObj[o] = obj[o];
    } else {
      !Array.isArray(obj[o])
        ? (retObj[o] = [obj[o], newObj[o]])
        : (retObj[o] = obj[o].concat(newObj[o]));
    }
  }

  return retObj;
};

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

class Tfooter extends Component {
  handleChangePage = (e, page) => {
    this.props._onChangePage(page);
  };

  handleChangeRowsPerPage = e => {
    this.props._onChangeRowsPerPage(e);
  };

  render() {
    const { length, rowsPerPage, page, classes, columnSize } = this.props;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            colSpan={columnSize}
            count={length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{ native: true }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={CustomPagination}
            classes={{
              caption: classes.pagination,
              input: classes.pagination,
              spacer: classes.space,
              toolbar: classes.regular
            }}
          />
        </TableRow>
      </TableFooter>
    );
  }
}

class CustomRow extends Component {
  render() {
    const {
      Component,
      columnList,
      data,
      trStyle,
      pathname,
      isRender
    } = this.props;

    return (
      <TableRow style={trStyle}>
        {columnList.map((c, i) => (
          <Component
            data={data}
            column={c}
            key={i}
            pathname={pathname}
            isRender={isRender}
          />
        ))}
      </TableRow>
    );
  }
}

class RBodyRow extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.isRender || nextProps.isRender !== this.props.isRender;
  }
  render() {
    const { v, filter } = this.props;
    let obj = {};
    let arr = [];

    // if (Array.isArray(v)) {
    if (v.length) {
      arr = v.map((data, index) => (obj = assign(obj, data, filter)));
      return <CustomRow data={arr[v.length - 1]} {...this.props} />;
    } else {
      return <CustomRow data={v} {...this.props} />;
    }
  }
}

class TbodyRow extends Component {
  render() {
    const {
      data,
      columnList,
      index,
      Component,
      trStyle,
      pathname,
      selected,
      handleClick,
      isCheckBox,
      page,
      rowsPerPage
    } = this.props;

    const isSelected = id => selected.indexOf(id) !== -1;
    const labelId = `enhanced-table-checkbox-${index}`;
    const isItemSelected = isSelected(data.id);

    return (
      <TableRow
        style={trStyle}
        onClick={e => handleClick(e, data.id)}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        {isCheckBox && (
          <TableCell
            padding="checkbox"
            align="center"
            style={{
              fontSize: "1.1rem",
              border: "1px solid #c0bebe",
              color: "#6a6a6a",
              padding: "10px 10px",
              wordBreak: "break-all"
            }}
          >
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
        )}
        {columnList.map((c, i) => (
          <Component
            data={data}
            index={index}
            page={page}
            rowsPerPage={rowsPerPage}
            column={c}
            key={i}
            pathname={pathname}
          />
        ))}
      </TableRow>
    );
  }
}

const Tbody = props => {
  return (
    <TableBody>
      {row(props).map((c, i) => (
        <TbodyRow key={i} data={c} index={i} {...props} />
      ))}
    </TableBody>
  );
};

@inject("comm")
@observer
class RTbody extends Component {
  renderList = () => {
    const {
      comm: { selectedGeneCard }
    } = this.props;
    let returnArr = [];
    this.props.tableList.forEach((v, k) => {
      returnArr = returnArr.concat(
        <RBodyRow
          key={k}
          v={v}
          k={k}
          isRender={k === selectedGeneCard}
          {...this.props}
        />
      );
    });
    return returnArr;
  };

  render() {
    const { page, rowsPerPage, isPagination } = this.props;
    return (
      <TableBody>
        {row({ page, rowsPerPage, tableList: this.renderList(), isPagination })}
      </TableBody>
    );
  }
}

class Thead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      columnList,
      classes,
      thStyle,
      trStyle,
      isCheckBox,
      isSort,
      numSelected,
      rowCount,
      onSelectAllClick,
      orderBy,
      order
    } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.tr} style={trStyle}>
          {isCheckBox && (
            <TableCell padding="checkbox" className={classes.th}>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "Select all desserts" }}
              />
            </TableCell>
          )}

          {columnList.map(({ column, key }, i) =>
            isSort ? (
              <TableCell
                key={i}
                className={classes.th}
                style={thStyle}
                sortDirection={orderBy === key ? order : false}
              >
                <TableSortLabel
                  active={orderBy === key}
                  direction={order}
                  onClick={this.createSortHandler(key)}
                >
                  {column}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={i} className={classes.th} style={thStyle}>
                {column}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
}

class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 100,
      selected: [],
      order: "asc",
      orderBy: props.orderBy
    };
  }

  _onChangePage = page => {
    this.setState({ page });
  };

  _onChangeRowsPerPage = e => {
    this.setState({ page: 0, rowsPerPage: Number(e.target.value) });
  };

  handleSelectAllClick = e => {
    if (e.target.checked) {
      const newSelecteds = this.props.tableList.map(n => n.id);
      this.setState({
        selected: newSelecteds
      });
      return;
    }
    this.setState({
      selected: []
    });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected
    });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;

    const isDesc = orderBy === property && order === "desc";

    this.setState({
      order: isDesc ? "asc" : "desc",
      orderBy: property
    });
  };

  render() {
    const { isPagination, id, isRowSpan, length } = this.props;
    const { selected, order, orderBy } = this.state;

    return (
      <>
        <Table id={id}>
          <Thead
            {...this.props}
            numSelected={selected.length}
            onSelectAllClick={this.handleSelectAllClick}
            rowCount={length}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          {isRowSpan ? (
            <RTbody {...this.props} {...this.state} />
          ) : (
            <Tbody
              {...this.props}
              {...this.state}
              handleClick={this.handleClick}
            />
          )}
          {isPagination && (
            <Tfooter
              {...this.state}
              {...this.props}
              _onChangePage={this._onChangePage}
              _onChangeRowsPerPage={this._onChangeRowsPerPage}
            />
          )}
        </Table>
      </>
    );
  }
}

export default withStyles(styles)(CustomTable);
