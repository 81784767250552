import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import Input from '@material-ui/core/Input';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
const styles = theme => ({
  listTab: {
    display: "flex",
    height: "35pxq"
  },
  blank: {
    width: "60px",
    borderBottom: "solid 1px #c6c6c6"
  },
  selectedTab: {
    border: 'solid 1px #c6c6c6',
    display: "flex",
    borderBottom: "0px",
    backgroundColor: "#ffffff",
    width: "140px",
    "&:hover": {
      backgroundColor: '#edecec'
    }
  },
  tab: {
    display: "flex",
    backgroundColor: '#f6f6f6',
    width: "140px",
    // marginLeft: "3px",
    borderBottom: 'solid 1px #c6c6c6',
    "&:hover": {
      backgroundColor: '#edecec'
    }
  },
  none: {
    width: "3px",
    borderBottom: 'solid 1px #c6c6c6',
  },
  elesline: {
    flex: "auto",
    borderBottom: 'solid 1px #c6c6c6',
  },
  Test: {
    fontSize: "12px",
    fontWeight: "normal",
    textTransform: "none",
    "&:hover": {
      backgroundColor: 'transparent',
      color: "#434343"
    }
  },
  btnDiv: {
    width: "80%"
  },
  iconDiv: {
    width: "20%"
  },
  BtnSize: {
    width: "100%"
  },
  clearBtn: {
    padding: "0px",
    minWidth: '0px',
    height: "33px",
    width: "100%"
  },
  clearIcon: {
    color: "#b4b4b4",
    fontSize: "20px"
  },
  penicon: {
    fontSize: "14px",
    paddingLeft: "15px"
  }
});

class TabHeader extends Component {
  state = {
    targetname: ""
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    return this.props.value !== nextProps.value || this.props.tabList !== nextProps.tabList || this.state.targetname !== nextState.targetname
    // return true;
  }

  handleChange = (e) => {
    const { onChangeTab } = this.props;
    onChangeTab(e.currentTarget.value);
  };

  delete = (e) => {
    const { deleteTab } = this.props
    deleteTab(e.currentTarget.value, true)
  }
  onSubmit = (e) => {
    const { onSubmitGroupName } = this.props
    onSubmitGroupName(e.currentTarget.value, this.state.targetname)
  }

  ChangeName = (e) => {
    const { originUser, onChangeName, tabList } = this.props
    if (originUser) {
      this.setState({
        targetname: tabList[e.currentTarget.value].name
      })
      onChangeName()
    }
  }
  changeGroupName = (e) => {
    this.setState({
      targetname: e.target.value
    })
  }

  render() {
    const { tabList, value, isChange, classes } = this.props;
    const { targetname } = this.state
    let groupList = tabList.map((t, i) => (
      <>
        <div className={i === Number.parseInt(value) ? classes.selectedTab : classes.tab}>
          <div className={classes.btnDiv}>
            {isChange && Number.parseInt(value) === i ?
              <Input
                className={classes.BtnSize}
                type="text"
                value={targetname}
                onChange={this.changeGroupName}
                classes={{ root: classes.Test }}
              />
              :
              <Button
                key={i}
                value={i}
                onClick={this.handleChange}
                classes={{ root: classes.Test }}
                className={classes.BtnSize}
                onDoubleClick={this.ChangeName}
              >
                {t.name}
                <CreateIcon className={classes.penicon} />
              </Button>}
          </div>
          <div className={classes.iconDiv}>
            {isChange && Number.parseInt(value) === i ?
              <Button classes={{ root: classes.clearBtn }}
                key={i}
                value={t.id}
                onClick={this.onSubmit}>
                <CheckIcon className={classes.clearIcon} />
              </Button>
              :
              <Button classes={{ root: classes.clearBtn }}
                key={i}
                value={t.id}
                onClick={this.delete}>
                <Clear className={classes.clearIcon} />
              </Button>
            }
          </div>
        </div>
        <div className={classes.none} />
      </>
    ))




    return (
      <div className={classes.listTab}>
        <div className={classes.blank}></div>
        {groupList}
        <div className={classes.elesline} />
      </div>
    );
  }
}

export default withStyles(styles)(TabHeader);
