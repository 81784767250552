import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Apps from "@material-ui/icons/Apps";
import Male from "assets/images/dataexplorer/male.svg";
import Female from "assets/images/dataexplorer/female.svg";
import Child from "assets/images/dataexplorer/child.svg";
import Adult from "assets/images/dataexplorer/adult.svg";
import OlderAdult from "assets/images/dataexplorer/olderadult.svg";
import MaleWhite from "assets/images/dataexplorer/maleWhite.svg";
import FemaleWhite from "assets/images/dataexplorer/femaleWhite.svg";
import ChildWhite from "assets/images/dataexplorer/childWhite.svg";
import AdultWhite from "assets/images/dataexplorer/adultWhite.svg";
import OlderAdultWhite from "assets/images/dataexplorer/olderadultWhite.svg";
const styles = theme => ({
  navigation: {
    height: "40px",
    // width: "100%",
    fontSize: "10px",
    minWidth: "0px!important"
  },
  selectbtn: {
    color: "#ffffff!important",
    backgroundColor: "#26a1a4 !important",
    padding: "0px !important",
  },
  padding: {
    padding: "0px!important",
    display: "inline"
  },
  btnmin: {
    minWidth: "0px",
    padding: "0px",
    '&:hover': {
      backgroundColor: "#efefef"
    }
  },
  midbtnmin: {
    minWidth: "0px",
    padding: "0px",
    borderLeft: "solid 1px #dddddd",
    borderRight: "solid 1px #dddddd",
    '&:hover': {
      backgroundColor: "#efefef"
    }
  },
  midbchildtnmin: {
    minWidth: "0px",
    padding: "0px",
    borderLeft: "solid 1px #dddddd",
    '&:hover': {
      backgroundColor: "#efefef"
    }
  },
  name: {
    borderTop: "solid 1px #dddddd",
    borderBottom: "solid 1px #dddddd",
    padding: "5px 0px 5px 20px",
    // height: "29px",
    fontSize: "12px"
  },
  childlabel: {
    paddingTop: "4px",
    width: "50px",
    fontSize: '10px !important',
    '&:hover': {
      backgroundColor: "#efefef"
    }
  },
  adultlabel: {
    width: "42px",
    fontSize: '10px !important',
    '&:hover': {
      backgroundColor: "#efefef"
    }
  },
  fontsize: {
    fontSize: '10px !important',

  }
});

class EligibilityCriteria extends Component {
  setGenderValue = value => {
    const { mainFilterObject, onClickEvent, state } = this.props;
    mainFilterObject.gender = value
    state.gender = value;
    onClickEvent();
  };

  setAgeValue = value => {
    const { mainFilterObject, onClickEvent, state } = this.props;
    let Age = value.split(",");
    mainFilterObject.startAge = Age[0];
    mainFilterObject.endAge = Age[1];
    if (Age[1]) {
      state.age = `${mainFilterObject.startAge},${mainFilterObject.endAge}`;
    } else {
      state.age = ""
    }
    onClickEvent();
  };
  render() {
    const { classes, state } = this.props;
    return (
      <ExpansionPanelDetails classes={{ root: classes.padding }}>
        <div>
          <div className={classes.name}>Gender</div>
          <BottomNavigation
            value={state.gender}
            onChange={(event, newValue) => {
              this.setGenderValue(newValue);
            }}
            showLabels
            className={classes.navigation}
          >
            <BottomNavigationAction
              value=""
              label="All"
              icon={<Apps />}
              classes={{ root: classes.btnmin, selected: classes.selectbtn, label: classes.fontsize }}
            />
            <BottomNavigationAction
              value="F"
              label="Female"
              icon={state.gender === "F" ? <img src={FemaleWhite} alt="" /> : <img src={Female} alt="" />}
              classes={{ root: classes.midbtnmin, selected: classes.selectbtn, label: classes.fontsize }}
            />
            <BottomNavigationAction
              value="M"
              label="Male"
              icon={state.gender === "M" ? <img src={MaleWhite} alt="" /> : <img src={Male} alt="" />}
              classes={{ root: classes.btnmin, selected: classes.selectbtn, label: classes.fontsize }}
            />
          </BottomNavigation>
        </div>
        <div>
          <div className={classes.name}>Age</div>
          <BottomNavigation
            value={state.age}
            onChange={(event, newValue) => {
              this.setAgeValue(newValue);
            }}
            showLabels
            className={classes.navigation}
          >
            <BottomNavigationAction
              value=""
              label="All"
              icon={<Apps />}
              classes={{ root: classes.btnmin, selected: classes.selectbtn, label: classes.fontsize }}
            />
            <BottomNavigationAction
              value="0,17"
              label="(Birth-17)"
              icon={state.age === "0,17" ? <img src={ChildWhite} alt="" /> : <img src={Child} alt="" />}
              classes={{
                root: classes.midbchildtnmin,
                selected: classes.selectbtn,
                label: classes.childlabel
              }}
            />
            <BottomNavigationAction
              value="18,64"
              label="(18-64)"
              icon={state.age === "18,64" ? <img src={AdultWhite} alt="" /> : <img src={Adult} alt="" />}
              classes={{
                root: classes.midbtnmin,
                selected: classes.selectbtn,
                label: classes.adultlabel
              }}
            />
            <BottomNavigationAction
              value="65,150"
              label="(65+)"
              icon={state.age === "65,150" ? <img src={OlderAdultWhite} alt="" /> : <img src={OlderAdult} alt="" />}
              classes={{ root: classes.btnmin, selected: classes.selectbtn, label: classes.fontsize }}
            />
          </BottomNavigation>
        </div>
      </ExpansionPanelDetails>
    );
  }
}

export default withStyles(styles)(EligibilityCriteria);
