import {
  axios
} from 'dependencyLibrary';

class AnalysisRepository {
  URL = "/api/kmaster";

  getManageSet = data => {
    return axios.get(`${this.URL}/sets${data}`);
  };

  postChartData = (type, data) => {
    return axios.post(`${this.URL}/sets/${type}`, data);
  };

  changeSetData = (type, data) => {
    return axios.put(`${this.URL}/sets/name${type}`, data);
  };

  postManageSet = (data) => {
    return axios.post(`${this.URL}/sets`, data);
  };

  deleteManageSet = (data) => {
    return axios.delete(`${this.URL}/sets${data}`);
  };

  getOncoPrint = () => {
    return axios.get(`${this.URL}/cancers/oncogrid?size=20`);
  };

  getChangeGropuName = (data) => {
    return axios.put(`${this.URL}/sets/group${data}`);
  };
}

export default new AnalysisRepository();