import React from "react";
import CustomDialog from "components/atoms/ui/CustomDialog";
import { withStyles } from "@material-ui/core/styles";
import TableTemplate from "components/organisms/TableTemplate";
// import useStore from "modules/hooks/useStore";
import { observer, inject } from "mobx-react";
import RefreshIcon from "@material-ui/icons/Refresh";

const style = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #dedede",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede"
  },
  lastContainer: {
    borderBottom: "1px solid #dedede"
  },
  div: {
    textAlign: "center",
    // display: "flex",
    // justifyItems: "center",
    // alignItems: "center",
    height: 15,
    padding: "10px 5px",
    borderRight: "1px solid #dedede",
    width: "50%"
  },
  title: {
    fontSize: 12,
    whiteSpace: "nowrap",
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    fontSize: 12,
    display: "flex"
    // alignItems: "center",
    // justifyContent: "center"
  },
  lastDiv: {
    borderRight: "none"
  },
  input: {
    width: 171
  },
  button: {
    background: "none rgb(252, 252, 252)",
    border: "none",
    cursor: "pointer",
    opacity: 1,
    color: "rgb(62, 62, 62)",
    fontSize: "12px",
    boxShadow: "rgba(121, 121, 121, 0.8) 1px 1px 3px 0px",
    fontWeight: "normal",
    lineHeight: "normal",
    borderRadius: 1,
    textTransform: "none",
    padding: "5px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

@inject("system")
@observer
class SyncApiButton extends React.Component {
  state = {
    open: false
  };

  componentDidMount() {
    this.props.system.loadApiKey("publish");
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  onRefresh = e => {
    e.preventDefault();
    this.props.system
      .changeApiKey("publish", { id: this.props.system.apiKey.publish.id })
      .then(value => {
        if (value) {
          this.props.system.loadApiKey("publish");
        }
      });
  };

  render() {
    const {
      classes,
      system: { apiKey }
    } = this.props;
    return (
      <>
        <button
          onClick={this.handleClickOpen}
          style={{
            backgroundColor: "#e4e4e4",
            border: "1px solid #dedede",
            fontSize: 10,
            borderRadius: 4,
            // padding: 0,
            cursor: "pointer",
            color: "#343434",
            marginLeft: 5
          }}
        >
          Api key
        </button>
        <CustomDialog
          open={this.state.open}
          onClose={this.onClose}
          title={"Api key"}
          // actions={
          //   <button
          //     onClick={this.onClose}
          //     style={{ width: 100 }}
          //     className={classes.button}
          //   >
          //     Close
          //   </button>
          // }
          maxWidth={"xs"}
        >
          <TableTemplate
            title={"MATCH API KEY"}
            size={
              <button
                type="button"
                style={{ height: 20 }}
                className={classes.button}
                onClick={this.onRefresh}
              >
                <RefreshIcon style={{ fontSize: 12 }} />
                Refresh
              </button>
            }
          >
            <div style={{ marginBottom: 5 }}>
              <div className={`${classes.container}`}>
                <div className={`${classes.div} ${classes.title}`}>
                  Server Name
                </div>
                <div
                  className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
                >
                  {apiKey.publish.serverName}
                </div>
              </div>
              <div className={`${classes.container}`}>
                <div className={`${classes.div} ${classes.title}`}>Api key</div>
                <div
                  className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
                >
                  <input
                    value={apiKey.publish.apiKey}
                    readOnly
                    className={`${classes.input}`}
                  />
                </div>
              </div>
              <div className={`${classes.container} ${classes.lastContainer}`}>
                <div className={`${classes.div} ${classes.title}`}>
                  Apisig Key
                </div>
                <div
                  className={`${classes.div} ${classes.lastDiv} ${classes.content}`}
                >
                  <input
                    value={apiKey.publish.apiSig}
                    readOnly
                    className={`${classes.input}`}
                  />
                </div>
              </div>
            </div>
          </TableTemplate>
          <br />
        </CustomDialog>
      </>
    );
  }
}

export default withStyles(style)(SyncApiButton);
