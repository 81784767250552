import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Cancer from "../../../assets/images/home/cancer.svg";
import Mutation from "../../../assets/images/home/variant.svg";
import Gene from "../../../assets/images/home/gene.svg";


const styles = theme => ({
  title: {
    display: 'flex',
    float: 'right',
    paddingTop: "7px",
    height: "25px"

  },
  subtitle: {
    border: "solid 1px #d4d4d5",
    backgroundColor: "#a5a5a5",
  },
  titleFont: {
    width: "310px",
    fontSize: "12px",
    height: "25px",
    color: "#ffffff",
    textAlign: "center",
    paddingTop: "5px",
    "@media (max-width: 1500px)": {
      width: '265px',
      fontSize: "10px",
      paddingTop: "0px",
      lineHeight: '2.5'
    },
  },
  OtitleFont: {
    width: "310px",
    fontSize: "12px",
    height: "25px",
    color: "#ffffff",
    textAlign: "center",
    paddingTop: "0px",
    "@media (max-width: 1500px)": {
      width: '205px',
      fontSize: "10px",
      lineHeight: '2.5'
    },
  },
  name: {
    borderTop: "solid 1px  #d4d4d5",
    borderBottom: "solid 1px  #d4d4d5",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    backgroundColor: "#f3f3f3",
    width: '25px'
  },
  value: {
    backgroundColor: "#ffffff",
    borderTop: "solid 1px  #d4d4d5",
    borderBottom: "solid 1px  #d4d4d5",
  },
  floating: {
    display: 'flex'
  },
  smallsample: {
    width: "60px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "5px",
    "@media (max-width: 1500px)": {
      width: '40px',
      fontSize: "10px",
      paddingTop: "0px",
      lineHeight: '2.5'
    },
  },
  sample: {
    width: "80px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "5px",
    "@media (max-width: 1500px)": {
      width: '60px',
      fontSize: "10px",
      paddingTop: "0px",
      lineHeight: '2.5'
    },
  },
  bigSample: {
    width: "155px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "5px",
    "@media (max-width: 1500px)": {
      width: '125px',
      fontSize: "10px",
      paddingTop: "0px",
      lineHeight: '2.5'
    },
  },
  Osmallsample: {
    width: "60px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "0px",
    "@media (max-width: 1500px)": {
      width: '40px',
      fontSize: "10px",
      lineHeight: '2.5'
    },
  },
  Osample: {
    width: "80px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "0px",
    "@media (max-width: 1500px)": {
      width: '60px',
      fontSize: "10px",
      lineHeight: '2.5'
    },
  },
  ObigSample: {
    width: "155px",
    height: "25px",
    fontSize: "12px",
    color: "#434343",
    textAlign: "center",
    paddingTop: "0px",
    "@media (max-width: 1500px)": {
      width: '125px',
      fontSize: "10px",
      lineHeight: '2.5'
    },
  },
  tooltip: {
    fontSize: "12px",
    margin: "0px",
    borderRadius: '0px'
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  arrowPopper: {
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 15,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `rgba(97, 97, 97, 0.9) transparent transparent transparent`,
      },
    },
  },
});

class TypeSubject extends Component {
  state = {
    arrowRef: null
  }

  setArrowRef = e => {
    // e.preventDefault();
    this.setState({
      arrowRef: e,
    });
  }

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.valueData !== nextProps.valueData
    // return true;
  }

  render() {
    const { valueData, IsOperation, classes } = this.props;
    return (
      < div className={classes.title} >
        <div className={classes.subtitle}>
          <div className={IsOperation ? classes.OtitleFont : classes.titleFont}>Cancer/ Gene/ Variant ( SNV / CNV / SV ), unit:#Types</div>
        </div>
        <div className={classes.floating}>
          <Tooltip title="Cancer" >
            <div className={classes.name}>
              <img src={Cancer} alt="" style={{ paddingTop: "3px" }} />
            </div>
          </Tooltip>
          <div className={classes.value}>
            <div className={IsOperation ? classes.Osmallsample : classes.smallsample}>
              {valueData ? valueData[2].value : 0}
            </div>
          </div>
        </div>
        <div className={classes.floating} >
          <Tooltip title="Gene" >
            <div className={classes.name}>
              <img src={Gene} alt="" style={{ paddingTop: "2px" }} />
            </div>
          </Tooltip>
          <div className={classes.value}>
            <div className={IsOperation ? classes.Osample : classes.sample}>
              {valueData ? valueData[0].value.toLocaleString() : 0}
            </div>
          </div>
        </div>
        <div className={classes.floating} >
          <Tooltip title="Variant ( SNV / CNV / SV )" >
            <div className={classes.name}>
              <img src={Mutation} alt="" style={{ paddingTop: "2px" }} />
            </div>
          </Tooltip>
          <div className={classes.value}>
            <div className={IsOperation ? classes.ObigSample : classes.bigSample}>
              {valueData ? valueData[1].value.toLocaleString() : 0}
              ( {valueData ? valueData[4].value.toLocaleString() : 0} / {valueData ? valueData[5].value.toLocaleString() : 0} / {valueData ? valueData[6].value.toLocaleString() : 0} )
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withStyles(styles)(TypeSubject);
