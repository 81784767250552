import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Error from "@material-ui/icons/Error";
import CustomBtn from "components/atoms/CustomBtn";
import { Link } from "react-scroll";
import { withStyles } from "@material-ui/core/styles";
// import { teal } from "@material-ui/core/colors";
import { inject } from "mobx-react";

const styles = theme => ({
  cardArea: {
    // display: "flex"
  },
  card: {
    maxWidth: "max-content",
    border: "1px solid #adadad",
    display: "inline-block",
    cursor: "pointer",
    margin: "0 5px 5px 0",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #26a69a",
      "& span": {
        color: '#26a1a4'
      }
    }
  },
  cardContent: {
    padding: "0px 10px",
    "&:last-child": {
      paddingBottom: "0px"
    }
  },
  typography: {
    fontSize: "1.4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: "2rem",
    color: '#26a1a4',
    margin: "5px"
  },
  selected: {
    backgroundColor: '#26a1a4',
    maxWidth: "max-content",
    border: "1px solid #26a69a",
    display: "inline-block",
    cursor: "pointer",
    margin: "0 5px 5px 0",
    boxShadow: "none",
    "& span": {
      color: "#fff"
    },
    "& svg": {
      color: "#fff"
    }
  }
});

class GeneCard extends Component {
  handleClick = () => {
    const {
      data: { gene, hgvsp },
      onClickCard,
      filter
    } = this.props;

    onClickCard(filter === "variant-all-snv" ? hgvsp : gene);
  };

  filter = () => {
    const { filter, data } = this.props;
    return filter === "variant-all-snv" ? data.hgvsp : data.gene;
  };

  render() {
    const {
      data,
      // data: { gene, genePersonCount, geneSpecimenCount },
      selectedValue,
      classes,
      filter,
      CardText
    } = this.props;

    const selected =
      selectedValue === this.filter(filter) ? classes.selected : classes.card;

    return (
      <Card className={selected} onClick={this.handleClick}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.typography}>
            <Error className={classes.icon} />
            <CardText data={data} />
            {/* <span>
              {gene} ({genePersonCount}/{geneSpecimenCount})
            </span> */}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

@inject("comm")
// @observer
class GeneCardList extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.maxPage > 0;
  }

  state = {
    page: 1
  };

  onClickMoreBtn = () => {
    const { page } = this.state;
    const { maxPage } = this.props;

    if (maxPage > page)
      this.setState({
        page: page + 1
      });
  };

  onClickResetBtn = () => {
    this.setState({
      page: 1
    });
    this.props.comm.setSelectedGeneCard("");
  };

  onClickCard = selectedValue => {
    this.props.comm.setSelectedGeneCard(selectedValue);
  };

  render() {
    const {
      geneCardList,
      rowsPerPage,
      maxPage,
      classes,
      comm: { selectedGeneCard },
      filter
    } = this.props;
    const { page } = this.state;

    const to = filter === "variant-all-snv" ? "hgvsp" : "gene";

    return (
      <>
        <div className={classes.cardArea}>
          {geneCardList.slice(0, page * rowsPerPage).map(gene => (
            <Link
              to={gene[to]}
              spy={true}
              smooth={true}
              duration={500}
              key={`${gene.gene}${gene.hgvsp}`}
              offset={-window.innerHeight / 2}
            >
              <GeneCard
                data={gene}
                {...this.props}
                selectedValue={selectedGeneCard}
                onClickCard={this.onClickCard}
              />
            </Link>
          ))}
        </div>
        {maxPage > page && (
          <CustomBtn text={"Read more Gene"} onClick={this.onClickMoreBtn} />
        )}
        {(page > 1 || selectedGeneCard.length > 0) && (
          <CustomBtn text={"Reset"} onClick={this.onClickResetBtn} />
        )}
      </>
    );
  }
}

export default withStyles(styles)(GeneCardList);
