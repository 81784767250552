import React, { Component } from "react";
import Header from "components/organisms/Header";
import { withRouter } from "react-router-dom";

class PresentHeader extends Component {
  render() {
    const {
      location: { pathname }
    } = this.props;

    return pathname === "/" || pathname === "home" ? null : <Header pathname={pathname} />;
  }
}

export default withRouter(PresentHeader);
