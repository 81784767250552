import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
const styles = theme => ({
  nousercontain: {
    // height: "650px",
    width: "550px",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "dashed 1px #d8d8d8",
    height: "20px",
    fontSize: "25px",
    padding: "20px 25px",
    fontWeight: "bold"
  },
  titlename: {
    float: "left"
  },
  main: {
    padding: "40px 50px 30px 50px"
  },
  mainPaddingTop: {
    paddingTop: "20px",
    paddingBottom: "4px",
    color: "#707070",
    fontSize: "13px",
    fontWeight: "bold"
  },
  set: {
    width: "100%",
    height: "48px",
    fontSize: "14px",
    border: "solid 1px #979797"
  },
  description: {
    width: "100%",
    height: "250px",
    fontSize: "14px",
    border: "solid 1px #979797"
  },

  button: {
    width: "130px",
    height: "30px",
    borderRadius: "0px",
    backgroundColor: "#ffffff",
    border: "solid 1px #979797",
    color: "#d3d3d3",
    fontSize: "10px",
    "&:hover": {
      backgroundColor: "#00c8a3",
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "8px"
  },
  textInput: {
    padding: "10px",
    height: "100%"
  },
  select: {
    width: "100%",
    border: "solid 1px #979797",
    height: "50px"
  },
  selectinput: {
    height: "35px",
    fontSize: "13px",
    paddingLeft: "10px"
  },
  warming: {
    paddingTop: "10px",
    fontSize: "10px",
    color: "#ff4e5f"
  },
  alert: {
    fontSize: "16px"
  },
  alertAherf: {
    fontSize: "16px",
    paddingLeft: "5px"
  }
});

const questionList = [
  { name: "Bug Report", id: "Bug Report" },
  { name: "Suggestions", id: "Suggestions" },
  { name: "Data error", id: "Data error" },
  { name: "Etc", id: "Etc" }
];

@inject("comm", "system")
@observer
class Question extends Component {
  state = {
    list: "",
    title: "",
    main: ""
  };
  listChange = event => {
    this.setState({
      list: event.target.value
    });
  };

  titleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  mainChange = event => {
    this.setState({
      main: event.target.value
    });
  };
  onClickEvent = () => {
    const {
      comm: { user },
      system,
      close
    } = this.props;
    const { list, title, main } = this.state;
    let message;
    let query = {
      title: title,
      type: list,
      contents: main,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    };
    message = system.postQA(query);
    if (message) {
      alert("QA Registration successful");
    }
    close();
  };

  render() {
    const { classes, close } = this.props;
    const { list, title, main } = this.state;

    let options = [];
    for (let i of questionList) {
      options.push(
        <option value={i.name} key={i.id}>
          {i.name}
        </option>
      );
    }

    return (
      <>
        <DialogContent className={classes.nousercontain}>
          <div className={classes.title}>
            <div className={classes.titlename}>{`Q&A`}</div>
          </div>
          <div className={classes.main}>
            <div style={{ display: "flex", paddingBottom: "15px" }}>
              {/* <div className={classes.alert}>The registered questions can be found in </div> <a href="/my-page" className={classes.alertAherf}>{" Q&A Board Page."}</a> */}
              <div className={classes.alert}>
                The registered questions can be found in{" "}
              </div>{" "}
              <Link to={"/my-page"} className={classes.alertAherf}>
                {" Q&A Board Page."}
              </Link>
            </div>
            <div className={classes.mainPaddingTop}>TYPE OF QUESTION</div>
            <Select
              native
              value={this.state.group}
              disableUnderline={true}
              displayEmpty
              onChange={e => this.listChange(e)}
              className={classes.select}
              classes={{ root: classes.selectinput }}
            >
              <option value={""}>Please select a question type</option>
              {options}
            </Select>
            {list === "" ? (
              <div className={classes.warming}>
                <CheckIcon style={{ fontSize: "10px" }} />
                Please make sure to select an item that corresponds to your
                question.
              </div>
            ) : null}

            <div className={classes.mainPaddingTop}>TITLE</div>
            <Input
              className={classes.set}
              type="text"
              disableUnderline={true}
              placeholder="Enter a title."
              value={this.state.title}
              onChange={this.titleChange}
              classes={{ input: classes.textInput }}
            />
            {title === "" ? (
              <div className={classes.warming}>
                <CheckIcon style={{ fontSize: "10px" }} />
                Please write down the title.
              </div>
            ) : null}

            <div className={classes.mainPaddingTop}>CONTENT</div>
            <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="Enter a content."
              value={this.state.main}
              onChange={this.mainChange}
              multiline={true}
              rows={4}
              classes={{ input: classes.textInput }}
            />

            <div
              className={classes.mainPaddingTop}
              style={{ textAlign: "right" }}
            >
              <Button
                className={`${classes.button} ${classes.buttonpadding}`}
                type="button"
                onClick={close}
              >
                CANCEL
              </Button>
              <Button
                className={classes.button}
                type="button"
                disabled={
                  list === "" || title === "" || main === "" ? true : false
                }
                onClick={this.onClickEvent}
              >
                Send
              </Button>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(Question);
