import React, { Component } from "react";
// import { withStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableRow from "@material-ui/core/TableRow";
// import TableFooter from "@material-ui/core/TableFooter";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import BarChart from "components/atoms/BarChart";

import BileDuct from "assets/images/home/barchart/bileductcancer.svg";
import Breast from "assets/images/home/barchart/breastcancer.svg";
import Cervix from "assets/images/home/barchart/cervixcancer.svg";
import Colonrectal from "assets/images/home/barchart/colonrectalcancer.svg";
import Endometrial from "assets/images/home/barchart/endometrialcancer.svg";
import HeadAndNeck from "assets/images/home/barchart/headandneckcancer.svg";
import Liver from "assets/images/home/barchart/livercancer.svg";
import Lung from "assets/images/home/barchart/lungcancer.svg";
import Melanoma from "assets/images/home/barchart/melanoma.svg";
import MultiplePrimary from "assets/images/home/barchart/multipleprimarycancer.svg";
import Other from "assets/images/home/barchart/othercancer.svg";
import Ovarian from "assets/images/home/barchart/ovariancancer.svg";
import Pancreas from "assets/images/home/barchart/pancreascancer.svg";
import Prostate from "assets/images/home/barchart/prostatecancer.svg";
import Renal from "assets/images/home/barchart/renalcancer.svg";
import Sarcoma from "assets/images/home/barchart/sarcoma.svg";
import Stomach from "assets/images/home/barchart/stomachcancer.svg";
import UrothelialCarcinoma from "assets/images/home/barchart/urothelialcarcinoma.svg";
// import Grid from "assets/images/home/barchart/grid_on.svg"

const chartImage = {
  "Colon/rectal": Colonrectal,
  Breast: Breast,
  Other: Other,
  Stomach: Stomach,
  Sarcoma: Sarcoma,
  Lung: Lung,
  "Bile duct": BileDuct,
  Ovarian: Ovarian,
  "Head and neck": HeadAndNeck,
  Pancreas: Pancreas,
  "Urothelial carcinoma": UrothelialCarcinoma,
  Melanoma: Melanoma,
  Prostate: Prostate,
  Renal: Renal,
  Cervix: Cervix,
  Liver: Liver,
  Endometrial: Endometrial,
  "Multiple primary": MultiplePrimary
};

class CancerTypeBarTable extends Component {
  render() {
    const { cancerList, pathname } = this.props;

    return (
      <BarChart
        series={cancerList}
        chartImage={chartImage}
        pathname={pathname}
      />
    );
  }
}

export default CancerTypeBarTable;
