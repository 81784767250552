import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import Container from "@material-ui/core/Container"
import { helpList } from 'pages/help/helpList';

const styles = theme => ({
  button: {
    align: 'right',
    margin: "5px 10px 10px 5px"
  },
  contain: {
    // height: "40vh",
    // width: "30vw",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "2vw",
    lineHeight: '2.3',
    fontSize: "14px",
    padding: "0px 8px"
  },
  titlename: {
    float: 'left'
  },
  main: {
    padding: "20px 10px"
  },
  button: {
    width: "49%",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "2px"
  },
  container: {
    display: "flex"
  },
  helpContents: {
    width: '800px',
    fontSize: 12
  },
  helpTitle: {
    backgroundColor: "#97f797",
    color: '#2651a4',
    padding: '10px',
    fontSize: "15px",
    height: "26px"
  }
})

class HelpContentList extends Component {

  state = { src: '', err: '' };

  componentDidMount = () => {
    this.loadImage();
  };
  loadImage = async () => {
    const { imageUrl } = this.props;
    try {
      const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../public/${imageUrl}`);
      this.setState({ src });
    } catch (err) {
      this.setState({ err: err.toString() });
    }
  }; 

  handleClose = () => {
    const { close } = this.props;

    close();
  }

  render() {
    const { classes, imageUrl, htmlId } = this.props;
    
    let htmlTitle = "";
    let listTable = [];
    let iSeq = 0;

    listTable.length = 0;

    // let myImgUrl = "../../../../../public/" + imageUrl;

    // console.log("HelpContentList imgURL : ", imageUrl, myImgUrl);

    if (htmlId != -1) {
      helpList.map(row => {
        if (row.id === htmlId) {
          htmlTitle = row.title;
          iSeq ++;
          row.items.map(col => {
            listTable.push(
              <TableRow>
                <TableCell align="center" style={{minWidth:20}} >{iSeq++}</TableCell>
                <TableCell align="center" style={{minWidth:60}} >{col.conti}</TableCell>
                <TableCell style={{minWidth:300}} >{col.desc}</TableCell>
              </TableRow>
            );
            // listTable.push(
            //   <tr align="center">
            //     <td bgcolor='#f0fdfa' align='center'><font size='-1'>{iSeq++} </font></td>
            //     <td bgcolor='#f0fdfa' align='center'><font size='-1'>{col.conti}</font></td>
            //     <td bgcolor='#f0fdfa' align='left'><font size='-1'>{col.desc}</font></td>
            //   </tr>
            // );
          });
        }
      });
    }
    else
    {
      htmlTitle = "";
      listTable = [];
      listTable.length = 0;
    }

    return (
              
      <div className={classes.helpContents}>
        <br />
        <div align="right" style={{marginRight:20}}>
          <Button onClick={this.handleClose} variant="contained" color="primary" size="small" startIcon={<CancelIcon />}>Close</Button>
        </div>

        <br />
        <div align="center" className={classes.helpTitle} > {htmlTitle} </div>
        <br />

        {/* <hr />
          <div align="center"> {htmlTitle} </div> 
        <br />
        <hr /> */}
        <img src={this.state.src} alt="Not found ${this.state.src}" />
        {this.state.err && <p>{this.state.err} </p>}
        {/* <img src={require({myImgUrl})} alt="Not found ${imageUrl}" /> */}
        {/* <Table>
          <TableHead style={{fontSize:"12px"}}>
            <TableRow>
              <TableCell align="center" style={{minWidth:20, maxWidth:30, backgroundColor:"#00c8a3"}}>
                인덱스</TableCell>
              <TableCell align="center" style={{minWidth:60, maxWidth:80, backgroundColor:"#a001b1"}}>
                항 목</TableCell>
              <TableCell align="center" style={{ minWidth:400, maxWidth:600, backgroundColor:"#b0c803"}}>
                설 명</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listTable}
          </TableBody>
        </Table> */}
        <br />
        <table width='100%' border='0' cellspacing='2' cellpadding='2'>
          <tr align='center' bgcolor='#d0aab0'>
            <td width='10%'><font color='#ffffff' size='-1'>인덱스</font></td>
            <td width='20%'><font color='#ffffff' size='-1'>항 목</font></td>
            <td width='70%'><font color='#ffffff' size='-1'>설 명</font></td>
          </tr>

          {listTable}
        </table>
        {/* <hr size='3' noshade /> */}
        <br />
        <div align="right" style={{marginRight:20}}>
          <Button onClick={this.handleClose} variant="contained" color="primary" size="small" startIcon={<CancelIcon />}>Close</Button>
        </div>
        <br />
      </div>

    );
  }
}

export default withStyles(styles)(HelpContentList);

              /* <TableRow>
                <TableCell align="center" style={{minWidth:20, maxWidth:30}} >{iSeq++}</TableCell>
                <TableCell align="center" style={{minWidth:60, maxWidth:80}} >{col.conti}</TableCell>
                <TableCell style={{minWidth:400, maxWidth:600}} >{col.desc}</TableCell>
              </TableRow> */