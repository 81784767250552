import React, { Component } from "react";
import CustomTable from "components/atoms/CustomTable";
import CustomCell from "components/atoms/CustomTable/CustomCell";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
// import { teal } from "@material-ui/core/colors";
import { inject } from "mobx-react";

const columnMap = new Map()
  .set("person", [
    { column: "No.", key: "index", align: "left" },
    { column: "Person ID", key: "personSourceValue", align: "left" },
    { column: "Gender", key: "genderConceptName", align: "left" },
    { column: "Birth year", key: "birthYear", align: "right" },
    { column: "Race concept name", key: "raceConceptName", align: "left" },
    {
      column: "Ethnicity concept name",
      key: "specimenSourceId",
      align: "left"
    },
    {
      column: "Specimen source ID",
      key: "ethnicityConceptName",
      align: "left"
    },
    {
      column: "Specimen concept name",
      key: "specimenConceptName",
      align: "left"
    },
    {
      column: "Specimen type concept name",
      key: "specimenTypeConceptName",
      align: "left"
    },
    { column: "Specimen date", key: "specimenDate", align: "left" },
    { column: "Cancer", key: "cacerType", align: "left" },
    { column: "Cancer detail", key: "cancerDetail", align: "left" }
  ])
  .set("snv", [
    { column: "No.", key: "index", align: "left" },
    { column: "Person ID", key: "personSourceValue", align: "left" },
    { column: "Sample ID", key: "specimenSourceId", align: "left" },
    { column: "Gene", key: "gene", align: "left" },
    { column: "Panel", key: "panel", align: "left" },
    { column: "Muttype", key: "mutType", align: "left" },
    { column: "Hgvsc", key: "hgvsc", align: "left" },
    { column: "Hgvsp", key: "hgvsp1digit", align: "left" },
    { column: "Sample count", key: "specimenCount", align: "right" },
    { column: "Person count", key: "personCount", align: "right" }
  ])
  .set("cnv", [
    { column: "No.", key: "index", align: "left" },
    { column: "Person ID", key: "personSourceValue", align: "left" },
    { column: "Sample ID", key: "specimenSourceId", align: "left" },
    { column: "Gene", key: "gene", align: "left" },
    { column: "Panel", key: "panel", align: "left" },
    { column: "Muttype", key: "mutType", align: "left" },
    { column: "Locus", key: "locus", align: "left" },
    { column: "Sample count", key: "specimenCount", align: "right" },
    { column: "Person count", key: "personCount", align: "right" }
  ])
  .set("sv", [
    { column: "No.", key: "index", align: "left" },
    { column: "Person ID", key: "personSourceValue", align: "left" },
    { column: "Sample ID", key: "specimenSourceId", align: "left" },
    { column: "Gene11", key: "gene11", align: "left" },
    { column: "Gene12", key: "gene12", align: "left" },
    { column: "Panel", key: "panel", align: "left" },
    { column: "Sample count", key: "specimenCount", align: "right" },
    { column: "Person count", key: "personCount", align: "right" }
  ]);

const styles = theme => ({
  container: {
    padding: "40px 50px",
    background: "#fff",
    margin: "10px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    border: "2px solid ",
    borderColor: "white",
    "&:hover": {
      borderColor: '#26a1a4'
    }
  },
  tableHeader: {
    display: "flex",
    marginBottom: 5
  },
  leftHeader: {
    display: "flex",
    alignItems: "center",
    width: "80%"
  },
  rightHeader: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  h3: {
    margin: "5px"
  },
  tableName: {
    color: '#26a1a4'
  },
  searchForm: {
    margin: "5px"
  },
  searchBtn: {
    margin: "5px",
    border: "1px solid transparent",
    backgroundColor: "#fff",
    borderColor: "#ccc",
    color: "#000",
    fontFamily: "Open Sans,sans-serif",
    fontWeight: 300,
    padding: "4px 12px"
  },
  textField: {
    height: "32px",
    margin: 0
  }
});

const tranferTableName = obj => {
  const arr = [];
  const arrSort = [
    "cancerName",
    "cancerDetailType",
    "hgvsp1digit",
    "gene",
    "hgvsc",
    "mutType"
  ];

  for (let as of arrSort) {
    if (obj[as]) arr.push(obj[as]);
  }

  return arr.join(", ");
};

@inject("comm")
class TableContainer extends Component {
  inputChange = e => {
    this.props.setValue({ value: e.target.value });
  };

  searchPerson = () => {
    this.props.comm.resetTableHeadName();
    this.props.onSearchPerson();
  };

  onSubmit = e => {
    e.preventDefault();
    this.searchPerson();
  };

  handleClick = e => {
    e.preventDefault();
    this.searchPerson();
  };

  render() {
    const {
      list,
      k,
      isPagination,
      length,
      classes,
      value,
      isSearch,
      selectedObj
    } = this.props;

    const thStyle = {
      fontSize: "1.4rem",
      width: `${columnMap.get(k).length / 100}%`
    };

    return (
      <div className={classes.container}>
        <div className={classes.tableHeader}>
          <div className={classes.leftHeader}>
            {Object.entries(selectedObj).length > 0 && (
              <h3 className={classes.h3}>
                Search{" "}
                <span className={classes.tableName}>
                  {tranferTableName(selectedObj)}
                </span>
              </h3>
            )}
            {isSearch && (
              <h3 className={classes.h3}>
                Search <span className={classes.tableName}>{value}</span>
              </h3>
            )}
            <h3 className={classes.h3}>{k.toUpperCase()}</h3>
            {k === "person" && (
              <>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={this.onSubmit}
                  className={classes.searchForm}
                >
                  <TextField
                    id="outlined-bare"
                    margin="normal"
                    variant="outlined"
                    placeholder="S00000"
                    onChange={this.inputChange}
                    value={this.props.value}
                    classes={{
                      root: classes.textField
                    }}
                    InputProps={{ classes: { root: classes.textField } }}
                  />
                </form>
                <button
                  onClick={this.handleClick}
                  className={classes.searchBtn}
                >
                  Search
                </button>
              </>
            )}
          </div>
          <div className={classes.rightHeader}>
            <ReactHtmlTableToExcel
              className={classes.searchBtn}
              buttonText="Excel"
              table={`table-${k}`}
              filename={`${k}_table`}
              sheet="Sheet 1"
            />
          </div>
        </div>
        <CustomTable
          columnList={columnMap.get(k)}
          columnSize={columnMap.get(k).size}
          tableList={list}
          Component={CustomCell}
          isPagination={isPagination}
          length={length}
          id={`table-${k}`}
          thStyle={thStyle}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TableContainer);
