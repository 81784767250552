import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Typography, Link, Button} from '@material-ui/core';

// presentTab의 Table List 에 있는 item 선택시 filterTab의 메뉴 item 선택과 동일한 결과를 만들어 내기 위해 만든 component, added by jdy 2020-12

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },

  },

});

@inject("home", "comm")
@observer
class ChooseItemName extends Component {

  // chartOpen = (e) => {
  //   const { chartOpen } = this.props
  //   chartOpen(e.target.value)
  // }

  state = {
    spacing : 2
  }

  // shouldComponentUpdate(nextProps) {
  //   // return false 하면 업데이트를 안함
  //   return this.props.children !== nextProps.children || this.props.type !== nextProps.type
  //   // return this.props.TESTDATA !== nextProps.TESTDATA
  //   // return true;
  // }

  dataFormat = str => {
    let retStr = str;
  
    if (typeof str === "number") {
      const regexp = /\B(?=(\d{3})+(?!\d))/g;
      return Number(retStr)
        .toString()
        .replace(regexp, ",");
    }
  
    if (typeof str === "boolean") {
      return String(retStr);
    }
  
    if (typeof str === "string" && str.length > 12) {
      return `${str.slice(0, 12)}...`;
    }
  
    if (!str) {
      return "-";
    }
  
    return retStr;
  }

  onclickCancer = (check, id, name, state, e) => {
    const { onClickEvent, comm:{mainFilterObject} } = this.props;
    if (check) {
      state.cancerList.push({ id: id, name: name })
    } else {
      const idx = state.cancerList.findIndex(function (item) {
        return item.id === id
      });
      if (idx > -1) {
        state.cancerList.splice(idx, 1);
        delete state.detailCancer[name]
      }
    }
    mainFilterObject.cancerList = state.cancerList
    if (e) {
      onClickEvent();
    }
  };

  onclickDetail = (check, detail, state, e) => {
    const { onClickEvent, comm:{mainFilterObject} } = this.props;
    if (check) {
      if (state.detailCancer[detail.parentName]) {
        const idx = state.detailCancer[detail.parentName].findIndex(function (item) {
          return item.id === detail.id
        });
        if (idx === -1) {
          state.detailCancer[detail.parentName].push({ id: detail.id, name: detail.name });
        }
      } else {
        state.detailCancer[detail.parentName] = [{ id: detail.id, name: detail.name }];
      }
    } else {
      if (state.detailCancer[detail.parentName]) {
        const idx = state.detailCancer[detail.parentName].findIndex(function (item) {
          return item.id === detail.id
        });
        if (idx > -1) {
          state.detailCancer[detail.parentName].splice(idx, 1);
        }
      }
    }
    if (state.detailCancer[detail.parentName] && !state.detailCancer[detail.parentName].length) {
      delete state.detailCancer[detail.parentName]
    }
    mainFilterObject.detailcancer = state.detailCancer;
    if (e) {
      onClickEvent();
    }
  };

  detailClick = (checked, detail, data, parentCheck) => {

    const { onClickEvent, comm:{mainFilterObject} } = this.props;

    let select = {
      cancerList: [],
      detailCancer: {}
    };

    this.onclickDetail(checked, detail, select, false); // true -> false by jdy

    const idx = select.cancerList.findIndex(function (item) {
      return item.id === data.id
    })
    if (select.detailCancer[data.name] && select.detailCancer[data.name].length) {
      if (idx <= -1) {
        this.onclickCancer(true, data.id, data.name, select, false);
      }
    } else {
      this.onclickCancer(false, data.id, data.name, select, false);
    }
  };

  cancerCheck = (isCheck, data) => {

    const { onClickEvent, comm:{mainFilterObject} } = this.props;

    let select = {
      cancerList: [],
      detailCancer: {}
    };

    for (let i of data.details) {
      //select.detailCancer[i.parentName].push({ id: i.id, name: i.name });
      this.onclickDetail(!isCheck, i, select, false);
    }
    //mainFilterObject.detailcancer = select.detailCancer;
    this.onclickCancer(!isCheck, data.id, data.name, select, false); // true->false by jdy
    //select.cancerList.push({ id: data.id, name: data.name });
    //mainFilterObject.cancerList = select.cancerList;

  }

  handleChange = (event) => {
    const { name, type, index, detail, dataRow, id, onClickEvent, home:{cancerTypeData, cancerTableData}, comm: {mainFilterObject}} = this.props;

    let select = {
      cancerList: [],
      detailCancer: {},
      gene: [],
      snv: [],
      cnv: [],
      sv: [],
      sample:"",
      primary:"",
      gender: "",
      age: "",
      value: "",
      variantType: {},
      Variant: []
    };

    //let id = 0;

    let parentCheck = false;

    //alert(type);
    if (type == 'Cancer') { // mainFilterObject.detailcancer 나중애
      if (detail) {
        this.detailClick(true, detail, dataRow, parentCheck);
      } else {
        cancerTypeData.map((data, i) => {
          if (data.name == name) {
            this.cancerCheck(false, data);
          }
        });
      }

    } 
    else if (type == 'Gene') {
      select.gene = [];
      select.gene.push(id);
      mainFilterObject.gene = select.gene;
      mainFilterObject.proteinGene = "";
    } 
    else if (type == 'SNV') {
      if(index == 0) {
        select.snv = [];
        select.snv.push(id);
        mainFilterObject.snv = select.snv;
      }
      else { 
        // search id
        // cancerTableData.map((cencerName, i) => {
        //   if (cencerName == name) {
        //     cancerId = i.id;
        //   }
        // });
        cancerTypeData.map((data, i) => {
          if (data.name == name) {
            this.cancerCheck(false, data);
          }
        });
      }

    } 
    else if (type == 'CNS') {
      select.cnv = [];
      select.cnv.push(id);
      mainFilterObject.cnv = select.cnv;
    }else if (type == 'SV') {
      select.sv = [];
      select.sv.push(id);
      mainFilterObject.sv = select.sv;
    }
    else if (type == 'Drug') {
      select.gene = [];
      select.gene.push(id);
      mainFilterObject.gene = select.gene;
      mainFilterObject.proteinGene = "";
    }
  
    onClickEvent(); // onClickMainFilter
  }

  render() 
  {
    const {spacing} = this.state;

    const { name, type, index, detail, dataRow, id } = this.props;

    let displayName = "";
    let blankChar = "  "
    
    if (type == 'SNV' && index == 1) {
      let nameRet = name.replace(" cancer", "").replace(' carcinoma', '').replace(' Cancer', '');
      displayName = this.dataFormat(nameRet);
    }
    else {
      displayName = name;
    }
        
      return (
        <Typography className={styles.root}>

            <Link underline="always" color="primary" variant="body2" onClick={this.handleChange} style={{ cursor: "pointer" }}>
              {displayName}
            </Link>          

        </Typography>
    );

  }
  
}


export default ChooseItemName;