import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import AdminQABoard from "components/organisms/AdminQABoard";
import SyncUpdateButton from "components/molecules/SyncUpdateButton";
// import queryString from "query-string";

const styles = theme => ({
  head: {
    margin: "10px 5px 0px 10px",
    height: "calc(100% - 32px)"
  },
  tabDiv: {
    backgroundColor: "#00ff0000",
    height: "35px",
    display: "flex",
    textAlign: "center",
    fontSize: "12px"
  },
  main: {
    border: "solid 1px #dbdbdb",
    // height: "calc(100% - 45px)",
    // borderTop: "0px",
    backgroundColor: "#ffffff",
    padding: "10px"
  },
  tab: {
    border: "solid 1px #dbdbdb",
    borderBottom: "0px",
    background: "#ffffff",
    width: "15%",
    padding: "10px 0px"
  },
  tabBlank: {
    width: "85%",
    borderBottom: "solid 1px #dbdbdb"
  }
});

const updateColumns = [
  {
    column: "Event Date",
    property: "updateDatetime",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 100 } }
  },
  {
    column: "Person",
    property: "updatePersonCount",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 50 } }
  },
  {
    column: "Specimen",
    property: "updateSpecimenCount",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 100 } }
  },
  {
    column: "SNV",
    property: "updateSnvCount",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 50 } }
  },
  {
    column: "CNV",
    property: "updateCnvCount",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 50 } }
  },
  {
    column: "SV",
    property: "updateSvCount",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 50 } }
  }
];

const CdmColumns = [
  ...updateColumns,
  {
    column: "MD5",
    property: "updateMd5",
    align: "left",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 230 } }
  },
  {
    column: "Code",
    property: "updateResultCode",
    align: "right",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 50 } }
  },
  {
    column: "Msg",
    property: "updateResultMessage",
    align: "left",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 210 } }
  },
  {
    column: "Updated",
    property: "portal",
    align: "center",
    sort: false,
    options: { link: { isLinked: false }, isEditable: false },
    style: { head: { width: 100 } }
  }
];

@inject("system")
@observer
class SyncLog extends Component {
  // TODO: 업데이트 로그 작업 시 store와 repository 작업 함께

  syncHandlePagination = async ({ page, rowsPerPage }) => {
    const { system } = this.props;
    await system.setSyncSearch({ page, size: rowsPerPage });
    await system.loadSync();
  };

  updatedHandlePagination = async ({ page, rowsPerPage }) => {
    // const { system } = this.props;
    // TODO: 업데이트 로그 서치 변경 작업과 로드 작업
    const { system } = this.props;
    await system.setSyncUpdateSearch({ page, size: rowsPerPage });
    await system.loadUpdateSync();
  };

  componentDidMount() {
    this.props.system.loadSync();
    this.props.system.loadUpdateSync();
    // TODO: 업데이트 로그 로드
  }

  componentWillUnmount() {
    this.props.system.resetSyncSearch();
    this.props.system.resetSyncUpdateSearch();
    // TODO: 업데이트 로그 리셋 작업
  }

  tableClick = e => {};

  render() {
    const {
      classes,
      system: { syncData, syncUpdateData, syncSearch, syncUpdateSearch }
    } = this.props;

    let tableList = {
      title: "Sync Log",
      columns: CdmColumns,
      data: syncData,
      search: syncSearch,
      isButton: true
    };

    let updatedTable = {
      title: "Updated Log",
      columns: updateColumns,
      data: syncUpdateData, // TODO: 업데이트 로그 데이터 할당
      search: syncUpdateSearch
    };

    return (
      <div className={classes.head}>
        <div className={classes.main}>
          <AdminQABoard
            detailType={tableList}
            handlePagination={this.syncHandlePagination}
            tableClick={this.tableClick}
            isNotRegistered={true}
            button={<SyncUpdateButton type={"portal"} />}
          />
          <AdminQABoard
            detailType={updatedTable}
            handlePagination={this.updatedHandlePagination}
            tableClick={this.tableClick}
            isNotRegistered={true}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SyncLog);
