import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import LogTable from "components/organisms/LogTable";

@inject("system")
@observer
class SystemInfraLog extends Component {
  componentDidMount() {
    const { system } = this.props;
    system.getAccessLog("/api");
  }

  render() {
    const {
      system: { accessLogs }
    } = this.props;

    return (
      <LogTable
        isPagination={true}
        tableList={accessLogs}
        length={accessLogs.length}
        title={"Web api server log"}
        subTitle={"report for system alert, system error, security risk"}
      />
    );
  }
}

export default SystemInfraLog;
