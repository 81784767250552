import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Input from '@material-ui/core/Input';

const styles = theme => ({
  contain: {
    height: "40vh",
    width: "30vw",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "2vw",
    lineHeight: '2.3',
    fontSize: "14px",
    padding: "0px 8px"
  },
  titlename: {
    float: 'left'
  },
  date: {
    float: 'right'
  },
  main: {
    padding: "20px 25px 30px 25px"
  },
  mainPaddingTop: {
    paddingTop: "20px"
  },
  set: {
    width: "100%",
    height: "27px",
    fontSize: "14px",

    border: 'solid 1px #979797'

  },
  description: {
    width: "100%",
    height: "88px",
    fontSize: "14px",
    border: 'solid 1px #979797'

  },
  button: {
    width: "49%",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "8px"
  }
})
@inject('analysis')
@observer
class NewCartSet extends Component {
  state = {
    title: "",
    main: ""
  }
  titleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  mainChange = event => {
    this.setState({
      main: event.target.value
    });
  };
  onClickEvent = () => {
    const { analysis, originUser, list } = this.props
    const { title, main } = this.state
    let data = {
      description: main,
      length: list.current.selectionContext.state.selected.length,
      owner: originUser.login,
      personLists: list.current.selectionContext.state.selected.toString(),
      title: title
    }
    analysis.postSetData(data)
  }
  render() {
    const { classes, list } = this.props
    return (
      <>
        <DialogContent className={classes.contain}>
          <div className={classes.title}>
            <div className={classes.titlename}>Cart Save Patient as New Set</div>
            <div className={classes.date}>{`${Date.now()}`}</div>
          </div>
          <div className={classes.main}>
            <div>
              <div>Items {list.current ? list.current.selectionContext.state.selected.length : 0} Patient </div>
            </div>
            <div className={classes.mainPaddingTop}>Title</div>
            <Input
              className={classes.set}
              type="text"
              disableUnderline={true}
              placeholder="Set1"
              value={this.state.title}
              onChange={this.titleChange}
            />
            <div className={classes.mainPaddingTop}>Description</div>
            <Input
              className={classes.description}
              type="text"
              disableUnderline={true}
              placeholder="논문 참고용."
              value={this.state.main}
              onChange={this.mainChange}
            />
            <div className={classes.mainPaddingTop}>

              <Button className={`${classes.button} ${classes.buttonpadding}`} type="button">
                CANCEL
              </Button>

              <Button className={classes.button}
                type="button"
                onClick={this.onClickEvent} >
                ADD CART
              </Button>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(NewCartSet);
