import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import Email from "assets/images/join/email.svg";
import Completed from "assets/images/join/completed.svg";
import back from "assets/images/join/joincertified.jpg";

const styles = theme => ({
  backgroundImg: {
    backgroundImage: `url(${back})`
  },
  maintitle: {
    paddingTop: "28px",
    fontSize: "25px",
    color: "#ffffff"
  },
  cermiddletitle: {
    paddingTop: "9px",
    fontSize: "25px",
    color: "#ffffff"
  },
  cersmall: {
    paddingTop: "16px",
    fontSize: "15px",
    color: "#ffffff"
  },
  commiddletitle:{
    paddingTop: "8px",
    fontSize: "25px",
    color: "#ffffff"
  },
  comsmall:{
    paddingTop: "13px",
    fontSize: "15px",
    color: "#ffffff"
  }
});

class Certified extends Component {
  render() {
    const { classes, rank } = this.props;
    if (rank === 0) {
      return (
        <div>
          <img src={Email} alt="" />

          <div className={classes.maintitle}>
            “Congratulations” on joining us.
          </div>
          <div className={classes.cermiddletitle}>
            But! You're not fully subscribed
          </div>
          <div className={classes.cersmall}>
            Please continue to sign up after accessing the e-mail you filled in
            when you sign up.
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <img src={Completed} alt="" />

          <div className={classes.maintitle}>“Congratulations”</div>
          <div className={classes.commiddletitle}>
            You are now a member of K-MASTER PORTAL.
          </div>
          <div className={classes.comsmall}>
            Enjoy the service through your login.
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(Certified);
