import React, { Component } from "react";
import "pages/signin/signin.scss";
import { inject } from "mobx-react";

let isCharacterSizeConfirm = false
let isCharacterMatchConfirm = false

@inject("auth")
class changePassword extends Component {
  state = {
    passwordConfirm: "",
    newPassword: "",
  };

  changeInput = e => {
    if (e.target.name === "password") {
      this.setState({
        newPassword: e.target.value
      });
    } else if (e.target.name === "passwordConfirm") {
      this.setState({
        passwordConfirm: e.target.value
      });
    }
  };

  onSubmit = e => {

    /* 
	    변경일자:   2020-06-23
	    변경자 이름: 지신복 
	    변경 내용:  패스워드 규칙이 맞지 않을 경우 경고창 및 return
	    변경 사유:  패스워드 규칙이 맞지 않아도 서버단에 전송                               
	  */     
    e.preventDefault(); 

    if(!isCharacterSizeConfirm){
      alert("Please enter at least 8 characters for password");
      console.log("Please enter at least 8 characters for password")
      return;
    
    }else if(!isCharacterMatchConfirm){
      alert("The password and confirmation password do not match");
      return;
    }
    
    const { auth, history } = this.props;
    const { newPassword } = this.state
    let data = {
      key: history.location.search.replace('?key=', ""),
      newPassword: newPassword
    }
    try {
      auth.postChangePassword(data)
    } catch (e) {
      history.push("/");
    }
    console.log(e)


  };

  render() {
    const { passwordConfirm, newPassword } = this.state;
    if (newPassword.length >= 8) {
      isCharacterSizeConfirm = true
    } else {
      isCharacterSizeConfirm = false
    }
    if (newPassword.length >= 8 && newPassword === passwordConfirm) {
      isCharacterMatchConfirm = true
    } else {
      isCharacterMatchConfirm = false

    }
    return (
      <div className="backcolor">
        <div className="row display-table">
          <div className="container display-table-cell">
            <div className="signin">
              {/* <div> */}
              <div className="logintitle">
                <p className="logintitle-bold">Change Password</p>
                <p className="logintitle-small">
                  Please Change Password
                </p>
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="loginRow">
                  <div>
                    <input
                      name="password"
                      type="password"
                      className="input-top"
                      id=" registrationPwd"
                      placeholder=" Password"
                      value={newPassword}
                      onChange={this.changeInput}
                    />
                    <input
                      name="passwordConfirm"
                      type="password"
                      className="input"
                      id=" registrationRepwd"
                      placeholder=" Re-type Password"
                      value={passwordConfirm}
                      onChange={this.changeInput}
                    />
                  </div>
                  <button
                    type="submit"
                    className="logintext"
                    onClick={this.onSubmit}
                    enabled={false}
                  >
                    Login
                  </button>
                </div>
              </form>

              <div className="addbtn">
                {!isCharacterSizeConfirm && (
                  <p >
                    * Please enter at least 8 characters for password.
                    </p>
                )}
                {!isCharacterMatchConfirm && (
                  <p >
                    * The password and confirmation password do not match.
                      </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default changePassword;
