import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import "pages/registration/registration.scss";
import { withStyles } from "@material-ui/core/styles";
import JoinTable from "components/organisms/JoinTable";
import Certified from "components/organisms/Certified";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Warning from "@material-ui/icons/Warning";
import ChevronRight from "@material-ui/icons/ChevronRight";
import StepConnector from "@material-ui/core/StepConnector";

const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 15
  },
  active: {},
  completed: {},
  line: {}
})(StepConnector);

// let setActiveStep = 0
class StepBtn extends Component {
  onClickNext = () => {
    const { handleNext } = this.props;
    handleNext();
  };

  onClickBack = () => {
    const { handleBack } = this.props;
    handleBack();
  };

  render() {
    const {
      resisterStep,
      user,
      changeInput,
      isCharacterSizeConfirm,
      isCharacterMatchConfirm,
      isConfirm,
      isEmailCheck
    } = this.props;
    switch (resisterStep) {
      case 0:
        return (
          <>
            <div className="registration">
              <JoinTable
                user={user}
                changeInput={changeInput}
                isCharacterSizeConfirm={isCharacterSizeConfirm}
                isCharacterMatchConfirm={isCharacterMatchConfirm}
                isEmailCheck={isEmailCheck}
              />
              <div>
                <Link to={"/"} className="link">
                  <Button className="backButton">Home</Button>
                </Link>
                <Button
                  onClick={this.onClickNext}
                  className={!isConfirm ? "disableNextButton" : "nextButton"}
                  disabled={!isConfirm}
                >
                  Next
                  <ChevronRight />
                </Button>
                {!isConfirm ? (
                  <div className="warming">
                    <Warning />
                    <div className={"email"}>
                      E-mail authentication is required to complete
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="backgroundimage">
              <div className="registration-certified">
                <Certified rank={0} />
              </div>
            </div>
            {/* <div className="registration">
              <Link to={"/"} className="link">
                <Button className="nextButton-login">Home</Button>
              </Link>
            </div> */}
          </>
        );
      case 2:
        return (
          <>
            <div className="backgroundimage">
              <div className="registration-certified">
                <Certified rank={1} />
              </div>
            </div>
            <div className="registration">
              <Link to={"/signin"} className="link">
                <Button className="nextButton-login">Login</Button>
              </Link>
            </div>
          </>
        );
      default: {
        return null;
      }
    }
  }
}
@inject("auth", "comm")
@observer
class Registration extends Component {
  constructor(props) {
    super(props);
    const path = this.props.location.pathname.split("/");
    let num = path[path.length - 1];
    if (num !== "2") {
      num = 0;
    }
    this.state = {
      user: {
        login: "",
        password: "",
        passwordConfirm: "",
        firstName: "",
        lastName: "",
        institute: ""
      },
      resisterStep: Number(num) || 0
    };
  }

  componentDidMount() {
    const { auth } = this.props;
    if (this.state.resisterStep === 2 && this.props.history.location.search) {
      auth.activateUser(this.props.history.location.search);
    }

    this.props.comm.setCurPage("Registration");
  }

  getSteps = () => {
    return ["Join", "Certified", "Complete"];
  };

  getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return "Select campaign settings...";
      case 1:
        return "What is an ad group anyways?";
      case 2:
        return "This is the bit I really care about!";
      default:
        return "Uknown stepIndex";
    }
  };

  handleNext = async () => {
    const { resisterStep } = this.state;
    let value = 0
    if (resisterStep === 0) {
      value = await this.onSubmit();
    }
    this.setState({
      resisterStep: resisterStep + value
    });
  };

  handleBack = () => {
    const { resisterStep } = this.state;
    this.setState({
      resisterStep: resisterStep - 1
    });
  };

  changeInput = e => {
    const { name, value } = e.target;
    const { user } = this.state;

    this.setState({
      user: Object.assign(user, { [name]: value })
    });
  };

  onSubmit = async e => {
    // e.preventDefault();
    let data = 0
    const { auth } = this.props;
    let value = await auth.registerUser(this.state.user);
    if (value) {
      data = 1
    }
    return data
  };

  render() {
    const { resisterStep, user } = this.state;
    const steps = this.getSteps();
    const isEmailCheck = user.login.match(regExp) != null;
    const isCharacterSizeConfirm = user.password.length >= 8;
    const isCharacterMatchConfirm = user.password
      ? user.password === user.passwordConfirm
      : false;
    const isConfirm =
      isCharacterMatchConfirm &&
      isCharacterSizeConfirm &&
      isEmailCheck &&
      user.firstName.length > 0 &&
      user.lastName.length > 0 &&
      user.institute.length > 0;

    return (
      <div className="backcolor">
        <div className="row display-table">
          <div>
            <div className="registration">
              <Stepper
                activeStep={resisterStep}
                alternativeLabel
                connector={<QontoConnector />}
                className={"stepper"}
              >
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel
                      classes={{
                        labelContainer: "stepper-line"
                      }}
                      StepIconProps={{
                        classes: {
                          root: "stepper-nonlabel",
                          completed: "stepper-label",
                          active: "stepper-label"
                        }
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <StepBtn
              resisterStep={resisterStep}
              handleBack={this.handleBack}
              handleNext={this.handleNext}
              user={user}
              changeInput={this.changeInput}
              isCharacterSizeConfirm={isCharacterSizeConfirm}
              isCharacterMatchConfirm={isCharacterMatchConfirm}
              isConfirm={isConfirm}
              isEmailCheck={isEmailCheck}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
