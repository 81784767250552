import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Gauge from "components/atoms/Gauge";
import SystemBarChart from "components/atoms/SystemBarChart";

import { grey } from "@material-ui/core/colors";
const capatity = {
  pie: "cancer",
  cpu: "2.20GHz",
  memory: "32GB",
  filesystem: "256GB",
  network: "1Gbps",
  Transaction: "3,000 Sessions"
};
const titleName = {
  cpu: "CPU",
  memory: "MEMORY",
  filesystem: "DISK",
  network: "NETWORK",
  Transaction: "TRANSACTION"
};

const styles = theme => ({
  head: {
    // width: "-webkit-fill-available",
    border: "solid 1px #c6c6c6 ",
    // padding: "1px",
    // borderColor: "white",
    // boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    // margin: "11px 6px",
    // padding: "20px 20px 40px 20px",
    background: "#ffffff",
    "&:hover": {
      // margin: "10px 5px",
      // padding: "0px ",
      border: "solid 1px #26a1a4 ",
    }
  },
  headBar: {
    // width: "-webkit-fill-available",
    border: "solid 1px #c6c6c6 ",
    // padding: "1px",
    // margin: "11px 6px",
    // borderColor: "white",
    // boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    // padding: "20px 20px 40px 20px",
    background: "#ffffff",
    "&:hover": {
      // margin: "10px 5px",
      // padding: "0px",
      border: "solid 1px #26a1a4 ",
    }
  },
  title: {
    display: "flex",
    fontSize: "11px",
    color: "#7d7d7d",
    // borderBottom: "solid 1px #dcdbdb",
    // height: "30px",
    // lineHeight: "2.5"
  },
  capacity: {
    fontSize: "12px",
    textAlign: "center",
    padding: "13px 0px 10px 0px",
    color: "black"
  },
  capicitycolor: {
    color: grey[400]
  },
  main: {
    fontSize: "14px",
    // textAlign: "left",
    padding: "15px 5px 0px 5px",
    color: grey[400],
    height: "148px",
  },
  mainBar: {
    fontSize: "14px",
    textAlign: "left",
    padding: "15px 5px 0px 5px",
    color: grey[400],
    height: "176px",
  },
  maintext: {
    fontSize: "20px",
    color: '#26a1a4',
    fontWeight: "500",
    padding: "0px 10px"
  },
  total: {
    paddingBottom: "20px",
    fontSize: "10px",
    textAlign: "center",
    color: "#4a4a4a"
  }
});

class GaugeTable extends Component {

  // shouldComponentUpdate(nextProps) {
  //   // return false 하면 업데이트를 안함
  //   return this.props.currentUsage !== nextProps.currentUsage
  //   // return true;
  // }

  render() {
    const { name, currentUsage, isChart, Icon, isBar } = this.props;
    const { classes } = this.props;
    let main = null
    if (currentUsage && isChart) {
      main = (
        <>
          <Gauge data={currentUsage} />
          <div className={classes.capacity}>
            <span className={classes.capicitycolor}>{"CAPACITY "}</span>
            <span>{capatity[name]}</span>
          </div>
        </>
      )
    } else if (isBar && currentUsage) {
      main = (
        <div className={classes.mainBar}>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "25%" }}><SystemBarChart series={currentUsage.pck.value} name={currentUsage.pck.name} subject={"(bytes)"} /> </div>
            <div style={{ width: "25%" }}> <SystemBarChart series={currentUsage.kB.value} name={currentUsage.kB.name} subject={"(bytes)"} /> </div>
            <div style={{ width: "25%" }}> <SystemBarChart series={currentUsage.cmp.value} name={currentUsage.cmp.name} subject={"(bytes)"} /> </div>
            <div style={{ width: "25%" }}> <SystemBarChart series={currentUsage.rxmcst.value} name={currentUsage.rxmcst.name} subject={"(bytes)"} />  </div>
          </div>
        </div>
      )
    } else if (currentUsage) {
      main = (
        <div className={classes.main}>
          <div className={classes.total}>TOTAL Rx/Tx(Mb)</div>
          <div>
            <span style={{ fontSize: "15px", color: "#4a4a4a" }}>Rx :</span>
            <span className={classes.maintext}>
              {currentUsage.rxBytes ? currentUsage.rxBytes.toFixed(2) : 0}
            </span>
            <span style={{ fontSize: "11px", color: "#4a4a4a", float: "right", paddingTop: "10px" }}>(Mb)</span>
          </div>
          <div style={{ borderBottom: "1px dashed #dcdbdb" }}></div>
          <div style={{ paddingBottom: "15px" }}>
            <span style={{ fontSize: "15px", color: "#4a4a4a" }}>Tx :</span>
            <span className={classes.maintext}>
              {currentUsage.txBytes ? currentUsage.txBytes.toFixed(2) : 0}
            </span>
            <span style={{ fontSize: "11px", color: "#4a4a4a", float: "right", paddingTop: "10px" }}>(Mb)</span>
          </div>
          <div className={classes.capacity}>
            <span className={classes.capicitycolor}>{"CAPACITY :"}</span>
            <span>{capatity[name]}</span>
          </div>
        </div>
      )
    }
    if (isChart) {
      return (
        <Table className={classes.head}>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: " 0px" }}>
                <div className={classes.title}>
                  <div style={{ width: `${51 - titleName[name].length}%`, textAlign: "right", paddingTop: "5px" }}>{Icon}</div>
                  <div style={{ width: `${49 + titleName[name].length}%`, textAlign: "left", paddingLeft: "5px", paddingTop: "10px" }}>{titleName[name]}</div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{ padding: "14px 0px", border: "0px" }}>
                {main}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else if (isBar) {
      return (
        <Table className={classes.headBar}>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: " 0px" }}>
                <div className={classes.title}>
                  <div style={{ width: `${51 - titleName[name].length}%`, textAlign: "right", paddingTop: "5px" }}>{Icon}</div>
                  <div style={{ width: `${49 + titleName[name].length}%`, textAlign: "left", paddingLeft: "5px", paddingTop: "10px" }}>{titleName[name]}</div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{ padding: "0px", border: "0px" }}>
                {main}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else {
      return (
        <Table className={classes.head}>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: "0px" }}>
                <div className={classes.title}>
                  <div style={{ width: `${51 - titleName[name].length}%`, textAlign: "right", paddingTop: "5px" }}>{Icon}</div>
                  <div style={{ width: `${49 + titleName[name].length}%`, textAlign: "left", paddingLeft: "5px", paddingTop: "10px" }}>{titleName[name]}</div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell style={{ border: "0px" }}>
                {main}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }

  }
}

export default withStyles(styles)(GaugeTable);
