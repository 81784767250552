import React, { Component } from "react";
// import footerlogo from "assets/images/home/footerlogo.svg"
import dateFormat from "dateformat";
import Select from '@material-ui/core/Select';
import RoomIcon from '@material-ui/icons/Room';
// import CommentIcon from '@material-ui/icons/Comment';
import CustomDialog from "components/atoms/CustomDialog";
import Question from "components/atoms/CustomDialog/Question";
import CheckPermission from "components/lib/CheckPermission";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
class Footer extends Component {

  state = {
    open: false
  }

  onClickOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { lastUpdateDate } = this.props;
    const { open } = this.state
    return (
      <>
        <div className="footer">
          <div className="footer-logo">
            {/* <img src={footerlogo} alt="" /> */}
          </div>
          <div className="footer-map">
            <div className="footer-addr">
              <span className="footer-name">
                <RoomIcon className="footer-icon" />
                Korea University Medical Center 73, Inchon-ro, Seongbuk-gu, Seoul 02841, Korea
            </span>
              <div className="footer-accent">
                Copyrightⓒ{new Date().getFullYear()} K-MASTER ALL RIGHTS RESERVED. | Last Data Release - {lastUpdateDate ? dateFormat(lastUpdateDate, "mediumDate") : "Oct 30, 2019"}
              </div>
            </div>
          </div>
          <CheckPermission
            key={"report"}
            permission={"ROLE_DEFAULT_USER"}
          >
            <div style={{ paddingRight: "5px" }}> <LiveHelpIcon style={{ fontSize: "14px" }} /></div>
            <div className="footer-contact" onClick={this.onClickOpen}> Q&A</div>
          </CheckPermission>
          <div className="footer-selectdiv">
            <Select native value={""} disableUnderline={true} displayEmpty onChange={(e) => e.target.value === '' ? null : window.open(e.target.value, '_blank')} className="footer-select" >
              <option classes={{ root: "footer-selectmenu" }} value="">FAMILY SITE</option>
              <option classes={{ root: "footer-selectmenu" }} value="http://www.mohw.go.kr/react/index.jsp">보건복지부</option>
              <option classes={{ root: "footer-selectmenu" }} value="https://www.khidi.or.kr/kps">한국보건산업진흥원</option>
              <option classes={{ root: "footer-selectmenu" }} value="http://www.kumc.or.kr/main/index.do">고려대학교의료원</option>
              <option classes={{ root: "footer-selectmenu" }} value="http://www.korea.ac.kr/mbshome/mbs/university/index.do">고려대학교</option>
            </Select>
          </div>
        </div>
        <CustomDialog
          open={open}
          onClickOpen={this.onClickOpen}
          type={'QA'}
          CustomDialogContent={Question}
        />
      </>
    );
  }
}

export default Footer;
