const basicColorList = [
  "#ff7e77",  
  "#2c82be",
  "#7F505F",
  "#5FB04F"

];

const labelGV = [
  "Actionable",  
  "Pathogenic",
  "VUS"
];
// let variants = ['snvs', 'cnvs', 'svs']

const ciMaxDivide = 7;
// const ciMaxDivide = 2;


// ProteinDataModel created by jdy 2020-12
// Gene DataPage의 Gene Protein Chart와 연계된다.

class ProteinDataModel {
  constructor(data) {
    this.id = []
    this.totalSubject = []
    this.selectSubject = []
    this.selectedTotalSubject = []
    this.totalSubjectCnt = []
    this.selected = {}
    this.selectedgene = {}
    this.selectedvariant = {}
    this.allSeries = []
    this.series = []
    this.seriesData = []
    this.categories = []
    this.positionCategory = []
    this.choiceText = []
    this.tickPixelInterval = 20
    this.marginLeft = 70
    this.hgvspPosition = 0;
    this.total = 0;
    this.length = 0;
    this.nTypes = 0;
    this.nItems = 0;
    this.numPages = 1;

    this.geneName = 'TP53';

    let addString = '';
    let insertString = '';
    let nameType = [];
    let nameNoType = [];

    let nShowItems = 30;
    // let nShowItems = 2;
    //  console.log(data);

    if (data.length > 0)
    {
      this.hgvspPosition = data[0].hgvspPosition;
      this.geneName = data[0].geneName;
    }
    else
    {
      this.numPages = 0;
      this.geneName = "";

      this.allSeries = [
        {
          name: 'Actionable',
          data: [],
          color: basicColorList[0],
        },
        {
          name: 'Pathogenic',
          color: basicColorList[1],
        },
        {
          name: 'VUS',
          color: basicColorList[2]
        },
        {
          name: 'Complex',
          color: basicColorList[3]
        },
      ];
      return;
    }

    for (let i of data) 
    {
      if (this.hgvspPosition == i.hgvspPosition) // duplicate
      {
        if (0 == this.nItems) // 첫번째 record에만 적용되며, 다음부터는 여기로 오지 않는다
        {
          this.nItems = 1;
          this.total = i.cnt;
          addString = `${i.hgvsp1Digit}: ${i.cnt} ${labelGV[i.type]}`;
          nameType = addString; 
          addString = `${i.hgvsp1Digit}: ${i.cnt}`;
          nameNoType = addString; 
          this.type = i.type;
          this.nTypes = 1;    
        }
        else
        {
          this.nItems += 1;
          this.total += i.cnt;
          addString = `${i.hgvsp1Digit}: ${i.cnt} ${labelGV[i.type]}`;
          nameType = nameType + '<br>' + addString; 
          addString = `${i.hgvsp1Digit}: ${i.cnt}`;
          nameNoType = nameNoType + '<br>' + addString; 
          if (this.type != i.type)
          {
            this.nTypes += 1;
          }
        }

        continue;
      }
      else
      {
        // eval series
        if (this.nTypes > 1) // complex
        {
          this.color = basicColorList[3];
        }
        else
        {
          this.color = basicColorList[this.type];     
        }

        this.categories.push(this.hgvspPosition);

        // insert to nameType on the first : Detail: Items=n
        // insertString = `Detail: Items = ${this.nItems} <br>`;
        // nameType = insertString + nameType;
        // nameNoType = insertString + nameNoType;

        if (this.nTypes == 1)
        {
          this.seriesData.push(
            {
              // hgvspPosition: this.hgvspPosition,
              low: this.total,
              name: nameNoType,
              color: this.color
            }
          );
        }
        else
        {
          this.seriesData.push(
            {
              // hgvspPosition: this.hgvspPosition,
              low: this.total,
              name: nameType,
              color: this.color
            }
          );
        }

        // init

        this.total = i.cnt;
        nameType = `${i.hgvsp1Digit}: ${i.cnt} ${labelGV[i.type]}`; 
        nameNoType = `${i.hgvsp1Digit}: ${i.cnt}`; 
        this.type = i.type;
        this.hgvspPosition = i.hgvspPosition;
        this.nItems = 1;
        this.nTypes = 1;
      }
    }
    // end for data
    // 
    // eval last group series
    if (this.nTypes > 1) // complex
    {
      this.color = basicColorList[3];
    }
    else
    {
      this.color = basicColorList[this.type];        
    }

    this.categories.push(this.hgvspPosition);

    // insert to nameType on the first : Detail: Items=n
    // insertString = `Detail: Items= ${this.nItems} <br>`;
    // nameType = insertString + nameType;
    // nameNoType = insertString + nameNoType;

    if (this.nTypes == 1)
    {
      this.seriesData.push(
        {
          // hgvspPosition: this.hgvspPosition,
          low: this.total,
          name: nameNoType,
          color: this.color
        }
      );
    }
    else
    {
      this.seriesData.push(
        {
          // hgvspPosition: this.hgvspPosition,
          low: this.total,
          name: nameType,
          color: this.color
        }
      );
    }
    // end last group series

    // eval divide from categories, seriesData

    this.length = this.categories.length;

    let nDivide = Math.floor(this.length / nShowItems);
    let iRemain = this.length % nShowItems;

    if (nDivide < ciMaxDivide)
    {
      if (iRemain == 0)
      {
        this.numPages = nDivide;
      }
      else
      {
        this.numPages = nDivide + 1;
      }
      
    }
    else
    {
      nShowItems = Math.floor(this.length / ciMaxDivide) + 1;
      
      nDivide = Math.floor(this.length / nShowItems);
      iRemain = this.length % nShowItems;

      if (iRemain == 0)
      {
        this.numPages = nDivide;
      }
      else
      {
        this.numPages = nDivide + 1;
      }
    }
    
    for (let i=0; i<this.numPages; i++)
    {
      let index;
      let nLoop;
      let itemSeriesData = [];
      let itemSeries = [];
      let itemCategories = [];

      itemSeriesData.length = 0;
      itemSeries.length = 0;
      itemCategories.length = 0;

      if (i == (this.numPages - 1) && iRemain != 0)
      {
        nLoop = iRemain;
      }
      else
      {
        nLoop = nShowItems;
      }

      index = nShowItems * i;

      for (let j=0; j<nLoop; j++)
      {
        itemSeriesData.push(this.seriesData[j + index]);
      }
      
      itemSeries = [
        {
          name: 'Actionable',
          data: itemSeriesData,
          color: basicColorList[0],
        },
        {
          name: 'Pathogenic',
          color: basicColorList[1],
        },
        {
          name: 'VUS',
          color: basicColorList[2]
        },
        {
          name: 'Complex',
          color: basicColorList[3]
        },
      ];
      
      this.series.push(itemSeries);

      // itemCategories
      for (let j=0; j<nLoop; j++)
      {
        itemCategories.push(this.categories[j + index]);
      }
      this.positionCategory.push(itemCategories);

      // set choiceText
      this.choiceText[i] = `${itemCategories[0]}~${itemCategories[nLoop - 1]}`;
    } // for (let i=0; i<this.numPages; i++)

    // set all series
    this.allSeries = [
      {
        name: 'Actionable',
        data: this.seriesData,
        color: basicColorList[0],
      },
      {
        name: 'Pathogenic',
        color: basicColorList[1],
      },
      {
        name: 'VUS',
        color: basicColorList[2]
      },
      {
        name: 'Complex',
        color: basicColorList[3]
      },
    ];

  } // end constructor
}

export default ProteinDataModel;
