import React, { Component } from "react";
import { inject, observer } from "mobx-react";
// import Error from "assets/images/error.svg";

const permissionCoverage = ["ROLE_ADMIN", "ROLE_KM_USER", "ROLE_DEFAULT_USER", "ALL"];

@inject("comm")
@observer
class CheckPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: props.permission,
      isAllowed: false
    };
  }

  static getDerivedStateFromProps(
    { comm: { user } }, { permission, only }) {
    if (user) {
      const permissionOfUser = permissionCoverage.indexOf(user.authorities[0]); // 유저의 권한
      const permissionOfComponent = permissionCoverage.indexOf(permission); // 컴포넌트의 접근권한
      if (only) {
        if (permissionOfUser === permissionOfComponent) return { isAllowed: true };
      } else {
        if (permissionOfUser <= permissionOfComponent) return { isAllowed: true };
      }
    } else {
      if (permission === "ALL") return { isAllowed: true };
      else return { isAllowed: false };
    }

    return null;
  }

  render() {
    const { isAllowed } = this.state
    const { children } = this.props
    return <>{isAllowed ? children : null}</>;
  }
}

export default CheckPermission;

