import React, { Component } from "react";
import * as highstock from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
// require("highcharts/modules/exporting")(highstock);


let e
(function (H) {
  //internal functions
  function stopEvent(e) {
    if (e) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      e.cancelBubble = true;
    }
  }

  //the wrap
  H.wrap(H.Chart.prototype, 'render', function (proceed) {
    var chart = this,
      mapNavigation = chart.options.mapNavigation;
    proceed.call(chart);
    // Add the mousewheel event
    H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'mousewheel', function (event) {
      var delta, extr, step, newMin, newMax, axis = chart.xAxis[0];
      e = chart.pointer.normalize(event);
      // Firefox uses e.detail, WebKit and IE uses wheelDelta
      delta = e.detail || -(e.wheelDelta / 120);
      delta = delta > 0 ? 1 : -1;
      if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
        extr = axis.getExtremes();
        step = (extr.max - extr.min) / 5 * delta;
        axis.setExtremes(extr.min + step, extr.max + step, true, false);
      }
      stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
      return false;
    });
  });
}(highstock));


class AnalysisChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list || this.props.mainFilterObject !== nextProps.mainFilterObject
    // return this.props.TESTDATA !== nextProps.TESTDATA
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { type, list, select, operationSet } = this.props;
    let returnData = [];
    let selectData = []
    let name = [];
    let options;
    let titleName;
    let series = [];
    if (list && type === "Cancer") {
      if (select === 'unionAAndBAndCSerial' || (select !== '' && operationSet[select.replace("Serial", '')] === 0)) {
        for (let data of list) {
          returnData.push(data.subject || 0);
          name.push(data.name.replace(" cancer", "").replace(" carcinoma", ""));
        }
        /* 
          변경일자:   2020-06-25
          변경자 이름: 지신복 
          변경 내용:   화면 display 건수 변경
          변경 사유:   암종추가에 따른 화면 display 건수 변경                            
        */
        titleName = list.length > 17 ? `Subjects by ${list.length} Cancers` : `Subjects by ${list.id.length} Cancers`;
        series.push({
          name: "cancer",
          data: returnData,
          color: "#c6c6c6"
        });
      }
      else {
        for (let data of list) {
          returnData.push(data.subject || 0);
          selectData.push(data.selectedCancer ? data.selectedCancer.subject || 0 : 0)
          name.push(data.name.replace(" cancer", "").replace(" carcinoma", ""));
        }
        titleName = list.length > 17 ? `Subjects by ${list.length} Cancers` : `Subjects by ${list.id.length} Cancers`;
        series.push({
          name: "Whole Union Set",
          data: returnData,
          color: "#c6c6c6"
        }, {
            name: "Selected Subset",
            data: selectData,
            color: "#26a1a4"
          }
        );
      }
    } else if (list && type === "Gene") {
      if (select === 'unionAAndBAndCSerial' || (select !== '' && operationSet[select.replace("Serial", '')] === 0)) {
        for (let data of list.content) {
          returnData.push(data.subjectCnt);
          name.push(data.geneName);
        }
        titleName = list.content.length > 19 ? `Subjects by Top 20 Genes` : `Subjects by ${list.id.length} Genes`;
        series = [
          {
            name: "gene",
            data: returnData,
            color: "#c6c6c6"
          }
        ];
      } else {
        for (let data of list.content) {
          returnData.push(data.subjectCnt);
          selectData.push(data.selectedGene ? data.selectedGene.subjectCnt || 0 : 0)
          name.push(data.geneName);
        }
        titleName = list.content.length > 19 ? `Subjects by Top 20 Genes` : `Subjects by ${list.id.length} Genes`;
        series = [
          {
            name: "Whole Union Set",
            data: returnData,
            color: "#c6c6c6"
          },
          {
            name: "Selected Subset",
            data: selectData,
            color: "#26a1a4"
          }
        ];
      }
    } else if (list && (type === "SNV" || type === "CNV" || type === "SV")) {
      if (select === 'unionAAndBAndCSerial' || (select !== '' && operationSet[select.replace("Serial", '')] === 0)) {
        for (let data of list.content) {
          returnData.push(data.subjectCnt);
          switch (type) {
            case 'SNV':
              name.push(`${data.targetGene1Id}, ${data.hgvsP1digit}`);
              break;
            case 'CNV':
              name.push(`${data.targetGene1Id}, ${data.sequenceAlteration.replace("Amplification", "Amp.").replace("Deletion", "Del.")}`);
              break;
            case 'SV':
              name.push(`${data.targetGene1Id}, ${data.targetGene2Id}`);
              break;
            default:
              break;
          }
        }

        titleName = list.content.length > 19 ? `Subjects by Top 20 Variants` : `Subjects by ${list.id.length} Variants`;
        series = [
          {
            name: "variant",
            data: returnData,
            color: "#c6c6c6"
          }
        ];
      } else {
        for (let data of list.content) {
          returnData.push(data.subjectCnt);
          switch (type) {
            case 'SNV':
              selectData.push(data.selectedSnv ? data.selectedSnv.subjectCnt || 0 : 0)
              name.push(`${data.targetGene1Id}, ${data.hgvsP1digit}`);
              break;
            case 'CNV':
              selectData.push(data.selectedCnv ? data.selectedCnv.subjectCnt || 0 : 0)
              name.push(`${data.targetGene1Id}, ${data.sequenceAlteration.replace("Amplification", "Amp.").replace("Deletion", "Del.")}`);
              break;
            case 'SV':
              selectData.push(data.selectedSv ? data.selectedSv.subjectCnt || 0 : 0)
              name.push(`${data.targetGene1Id}, ${data.targetGene2Id}`);
              break;
            default:
              break;
          }
        }

        titleName = list.content.length > 19 ? `Subjects by Top 20 Variants` : `Subjects by ${list.id.length} Variants`;
        series = [
          {
            name: "Whole Union Set",
            data: returnData,
            color: "#c6c6c6"
          },
          {
            name: "Selected Subset",
            data: selectData,
            color: "#26a1a4"
          }
        ];
      }
    }

    if (list && (list.length === 0 || list.totalPages === 0)) {
      titleName = "No Data";
    }

    let softMax = returnData.length ? returnData.reduce(function (a, b) {
      return Math.max(a, b);
    }) : 800;


    options = {
      chart: {
        type: "bar",
        marginLeft: 95,
        marginTop: 30,
        height: 200
      },

      title: { text: null },
      subtitle: { text: titleName },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          autoRotationLimit: 10,
          formatter: function () {
            return typeof this.value !== 'number' ? this.value : ''
          },
          style: {
            fontSize: "9px"
          }
        },
        scrollbar: {
          enabled: true,
          liveRedraw: true,
          buttonBackgroundColor: '#f2f2f2',
          buttonBorderColor: "#f2f2f2",
          barBorderRadius: 5,
          barBorderWidth: 2,
          barBorderColor: "#f2f2f2",
          rifleColor: "#cccccc",
          trackBorderWidth: 5,
          trackBorderRadius: 2,
          height: 10
        },
        categories: name,
        min: 0,
        max: 4.5,
        events: {
          afterSetExtremes: function () {
            var xAxis = this,
              numberOfPoints = xAxis.series[0].points.length - 1,
              minRangeValue = xAxis.getExtremes().min,
              maxRangeValue = xAxis.getExtremes().max;

            if (minRangeValue < 0) {
              xAxis.setExtremes(0, xAxis.options.max);
            } else if (maxRangeValue > numberOfPoints) {
              xAxis.setExtremes(numberOfPoints - xAxis.options.max, numberOfPoints);
            }
          }
        }
        // visible: false
      },
      yAxis: {
        allowDecimals: false,
        gridLineDashStyle: "longdash",
        gridLineWidth: 0.5,
        tickPixelInterval: 50,
        softMax: softMax || 800,
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          },
          rotation: 0
        },
        title: {
          text: "(Subject)",
          align: "high",
          rotation: 0,
          offset: 0,
          y: -15,
          style: {
            fontSize: "10px",
            fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
            color: "#434343"
          }
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shared: true,
        backgroundColor: "#616161",
        formatter: function () {
          if (select !== 'unionAAndBAndCSerial') {
            return this.points.reduce(function (s, point) {
              if (point.y > 0) {
                return s + '<br/><span style="color:' + point.color + '">\u2B24</span><span style="color: #ffffff">' + point.series.name + ': ' +
                  point.y.toLocaleString() + '</span>';
              } else {
                return s;
              }
            }, '<b><div><span style="color: #ffffff;font-weight:bold;font-size:13px !important;">' + this.x + ' (#Subject)</span></div></b>');
          } else {
            return (
              "<b><span style='color: #ffffff;font-weight:bold;font-size:13px !important;'>" +
              this.x +
              "</span></b><br/><span style='color: #ffffff;'>" +
              "Subject: " +
              this.y.toLocaleString() + "</span>"
            )
          }

        }
      },
      plotOptions: {
        bar: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
          cropThreshold: 1000
        }
      },
      series: series,
    };
    return (
      <HighchartsReact
        highcharts={highstock}
        options={options}
        oneToOne={true}
        key={this.state.fooKey}
      />
    );
  }
}
export default AnalysisChart;
