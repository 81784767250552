import React, { Component } from "react";
import GeneCardList from "components/atoms/GeneCardList";
import AllListTable from "components/organisms/AllListTable";
import queryString from "query-string";
import { inject, observer } from "mobx-react";
import PageTemplate from "pages/template/pageTemplate";
import { withStyles } from "@material-ui/core/styles";
// import { teal } from "@material-ui/core/colors";
import { animateScroll as scroll } from "react-scroll";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const rowsPerPage = 30;

const styles = theme => ({
  container: {
    padding: "40px 50px",
    background: "#fff",
    margin: "10px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    border: "2px solid ",
    borderColor: "white",
    "&:hover": {
      borderColor: '#26a1a4'
    }
  },
  tableHeader: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: 5
  },
  h3: {
    marginRight: "5px"
  },
  tableName: {
    color: '#26a1a4'
  },
  arrowUpward: {
    position: "fixed",
    bottom: "20px",
    right: "18px",
    background: "#ddd",
    border: "1px solid #ddd",
    borderRadius: "25px",
    padding: "10px 13px"
  },
  icon: {
    fontSize: 14
  }
});

const GeneCardText = ({
  data: { gene, genePersonCount, geneSpecimenCount }
}) => (
    <span>
      {gene} ({genePersonCount}/{geneSpecimenCount})
  </span>
  );

const VariantCardText = ({
  data: { hgvsp, genePersonCount, geneSpecimenCount }
}) => (
    <span>
      {hgvsp} ({genePersonCount}/{geneSpecimenCount})
  </span>
  );

const headName = {
  "gene-all": "Gene",
  "variant-all-snv": "SNV",
  "variant-all-cnv-amp": "CNV(Amp.)",
  "variant-all-cnv-del": "CNV(Del.)",
  "variant-all-sv": "SV"
};

@inject("summary", "comm")
@observer
class AllListPage extends Component {
  componentDidMount() {
    const {
      location: { search },
      comm,
      summary
    } = this.props;

    const { filter, type, reported, name, ...rest } = queryString.parse(search);

    const q = queryString.stringify(rest);
    summary.loadAllList({
      filter,
      type,
      query: reported ? `?reported=${reported}&${q}` : `?${q}`
    });

    comm.setCurPage("AllListPage");
  }

  scrollToTop = e => {
    scroll.scrollToTop();
  };

  render() {
    const {
      location: { pathname, search },
      summary: { geneCardList, allList, allListTableMap, allListCnt },
      comm: { selectedGeneCard },
      classes
    } = this.props;

    const { filter, name } = queryString.parse(search);
    const isFilter = filter === "gene-all" || filter === "variant-all-snv";
    const filterName = headName[filter];
    const {
      personTotalCount,
      specimenTotalCount,
      variantTotalCount
    } = allListCnt;

    return (
      <PageTemplate
        pathname={pathname}
        isLeftNavi={false}
        content={
          <div className={classes.container}>
            <div className={classes.tableHeader}>
              {name && (
                <h3 className={classes.h3}>
                  Search <span className={classes.tableName}>{name}</span>
                </h3>
              )}
              <h3 className={classes.h3}>{`${filterName} All List ${
                variantTotalCount ? ", " + variantTotalCount + " variant" : ""
                }(${personTotalCount || 0}/${specimenTotalCount || 0})`}</h3>
              <span>(#subject/#specimen)</span>
            </div>
            {isFilter && (
              <GeneCardList
                geneCardList={geneCardList}
                maxPage={geneCardList.length / rowsPerPage}
                rowsPerPage={rowsPerPage}
                CardText={
                  filter === "variant-all-snv" ? VariantCardText : GeneCardText
                }
                filter={filter}
              />
            )}
            <AllListTable
              isPagination={false}
              allList={allList}
              isRowSpan={true}
              allListTableMap={allListTableMap}
              selectedGeneCard={selectedGeneCard}
              filter={filter}
            />

            <button onClick={this.scrollToTop} className={classes.arrowUpward}>
              <ArrowUpward className={classes.icon} />
            </button>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(AllListPage);
