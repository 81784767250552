import axios from "axios";
import history from "./history";

axios.interceptors.response.use(null, function(err) {
  if (err.response.status === 500) {
    history.push("/500");
  }
  return Promise.reject(err);
});

const api = {
  get: url => {
    return axios({
      method: "get",
      url: `${url}`
    }).catch(
      ({
        response,
        response: {
          data,
          data: { code, message, errors }
        }
      }) => {
        console.log(response);
        const err =
          errors && errors.length > 0
            ? errors.map(
                f => `${message}: "${f.value}" \n${f.field} => ${f.reason}\n`
              )
            : `${message}`;

        if (data) alert(code || err ? `${code} ${err}` : JSON.stringify(data));
        return;
      }
    );
  },
  post: (url, data) => {
    return axios({
      method: "post",
      url: `${url}`,
      data
    }).catch(
      ({
        response,
        response: {
          data,
          data: { code, message, errors }
        }
      }) => {
        console.log(response);
        const err =
          errors && errors.length > 0
            ? errors.map(
                f => `${message}: "${f.value}" \n${f.field} => ${f.reason}\n`
              )
            : `${message}`;

        alert(code || err ? `${code} ${err}` : JSON.stringify(data));
        return;
      }
    );
  },
  put: (url, data) => {
    return axios({
      method: "put",
      url: `${url}`,
      data
    }).catch(
      ({
        response,
        response: {
          data,
          data: { code, message, errors }
        }
      }) => {
        console.log(response);
        const err =
          errors && errors.length > 0
            ? errors.map(
                f => `${message}: "${f.value}" \n${f.field} => ${f.reason}\n`
              )
            : `${message}`;

        alert(code || err ? `${code} ${err}` : JSON.stringify(data));
        return;
      }
    );
  },
  delete: (url, data) => {
    return axios({
      method: "delete",
      url: `${url}`,
      data
    }).catch(
      ({
        response,
        response: {
          data,
          data: { code, message, errors }
        }
      }) => {
        console.log(response);
        const err =
          errors && errors.length > 0
            ? errors.map(
                f => `${message}: "${f.value}" \n${f.field} => ${f.reason}\n`
              )
            : `${message}`;

        alert(code || err ? `${code} ${err}` : JSON.stringify(data));
        return;
      }
    );
  },

  setAuthInHeader: token => {
    axios.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : null;
  }
};

export default api;
