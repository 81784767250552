import React from "react";
// import SyncUpdateButton from "components/molecules/SyncUpdateButton";
import { extendObservable, toJS } from "mobx";

class SyncLogModel {
  constructor(data) {
    extendObservable(this, toJS(data));
    // this.update = (
    //   <>

    //     {/* <SyncUpdateButton type={"match"} disabled={!this.match} /> */}
    //   </>
    // );
  }
}

export default SyncLogModel;
