import { observable, action } from "mobx";
import AnalysisRepository from "../repository/AnalysisRepository";

class AnalysisStore {
  @observable manageSet;
  @observable operationSet;
  @observable postList;
  @observable setListData = {};
  @observable oncogrid;
  @observable chartData = {};
  @observable cartSet = 0;

  constructor(root) {
    this.root = root;
  }


  @action
  async insertCartSet() {
    this.cartSet += 1;
  }
  @action
  async resetCartSet() {
    this.cartSet = 0;
  }

  @action
  async getSetListData(dat) {
    const { data } = await AnalysisRepository.getManageSet(dat);
    this.manageSet = data;
    return data
  }

  @action
  async getModifyData(dat) {
    const { data } = await AnalysisRepository.getManageSet(dat);
    this.modifyData = data;
  }

  @action
  async getChartData(type, dat) {
    const { data } = await AnalysisRepository.postChartData(type, dat);
    let name = type.replace('variants/', '')
    this.chartData = {
      ...this.chartData,
      [name]: data
    }
  }

  @action
  async resetListData() {
    this.setListData = {};
  }

  @action
  async getOperationData(get, dat) {
    const param = `/${get}${dat}`;
    const { data } = await AnalysisRepository.getManageSet(param);
    this.operationSet = data;
  }
  @action
  async getOperationNoUserData(get, dat) {
    const param = `/${get}/anonymous`;
    const { data } = await AnalysisRepository.postChartData(param, dat);
    this.operationSet = data;
  }

  @action
  async changeSetData(get, dat) {
    const param = `?groupName=${get}`;
    const { data } = await AnalysisRepository.changeSetData(param, dat);
    this.operationSet = data;
  }

  @action
  async getListData(set, dat) {
    const param = `/${dat}`;
    const { data } = await AnalysisRepository.getManageSet(param);
    this.setListData[set] = (data);
  }

  @action
  async postSetData(dat) {
    const { data } = await AnalysisRepository.postManageSet(dat);
    this.postList = data;
    return data;
  }
  @action
  async deleteSetData(dat) {
    const param = `/${dat}`;
    const data = await AnalysisRepository.deleteManageSet(param);
    return data
  }

  @action
  async getOncoPrint(dat) {
    const { data } = await AnalysisRepository.getOncoPrint(dat);
    this.oncogrid = data;
  }

  @action
  async getChangeGroupName(id, name) {
    let query = `?groupId=${id}&groupName=${name}`
    const { data } = await AnalysisRepository.getChangeGropuName(query);
    this.oncogrid = data;
  }
}

export default AnalysisStore;
