import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import DialogContent from "@material-ui/core/DialogContent";
import "components/atoms/CustomDialog/forgotDialog.scss";



@inject('auth')
@observer
class CustomDialogContent extends Component {

  state = {
    email: ""
  }
  emailChange = event => {
    this.setState({
      email: event.target.value
    });
  };

  onClickEvent = async (e) => {
    const { auth } = this.props
    const { email } = this.state
    let data = {
      mail: email
    }
    await auth.postSendEmail(data)
  }

  render() {
    const { close } = this.props
    return (
      <>
        <DialogContent>
          <div className="forgot-password-content">
            <div className="common-form-title">
              <p className="common-form-title-bold">FORGOT PASSWORD</p>
              <p className="common-form-title-small">
                If you forgot your passwort, here you can request a new one.
                <br />
                You will receive an email containing a link to set a new
                password.
              </p>
            </div>
            <form onSubmit={this.onClickEvent}>
              <input
                type="email"
                className="form-control "
                placeholder="Enter your email"
                value={this.state.title}
                onChange={this.emailChange}
              />
              <div className="forgot-password-btn">
                <button
                  type="button"
                  className="btn btn-default common-btn-default"
                  onClick={close}
                >
                  CANCEL
              </button>

                <button
                  type="button"
                  className="btn btn-default common-btn-green"
                  onClick={this.onClickEvent}
                >
                  SEND
              </button>
              </div>
            </form>

          </div>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          /> */}
        </DialogContent>
      </>
    );
  }
}

export default CustomDialogContent;
