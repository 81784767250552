import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import BasicInfo from "pages/myPage/basicInfo"
import MyQABoard from "pages/myPage/myQABoard"


function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}
class myPageRouter extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          <PrivateRoute path={`${match.url}/qaboard/:id`} component={MyQABoard} />
          <PrivateRoute path={`${match.url}/qaboard`} component={MyQABoard} />
          <PrivateRoute path={`${match.url}`} component={BasicInfo} />
        </Switch>
      </>
    );
  }
}

export default myPageRouter;
