import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);

// home.variantCategory 에 대한 Pie Chart, added by jdy 2020-12

const basicColorList = [
  "#ff7e77",  
  "#2c82be",
  "#7F505F",
  "#5FB04F"

];

class Pie3Chart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.variantCategory !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }

  render() {
    const { list } = this.props;

    let actionable = 45.0
    let pathogenic = 46.8
    let vus = 8.2
    let total = 10;
    let desc0 = "";
    let desc1 = "";
    let desc2 = "";
    let portion = [];

    let options;


    if (list) {
      actionable = list.actionable;
      pathogenic = list.pathogenic;
      vus = list.vus;

      total = actionable + pathogenic + vus;

      if (total == 0) {
        total = 10;
      }

      portion[0] = Math.ceil(10000 * actionable / total) / 100;
      portion[1] = Math.floor(10000 * pathogenic / total) / 100;
      portion[1] = Math.floor(10000 * vus / total) / 100;

      desc0 = portion[0] + "%";
      desc1 = portion[1] + "%";
      desc2 = portion[2] + "%";
    }



    let category = [
      {
        name: "Actionable",
        y: actionable,
        description: desc0,
        color: basicColorList[0]
      },
      {
        name: "Pathogenic",
        y: pathogenic,
        description: desc1,
        color: basicColorList[1]
      },
      {
        name: "VUS",
        y: vus,
        description: desc2,
        color: basicColorList[2]
      },
    ];
    

    options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "pie",
        height: 200,
      },
      title: {
        text: null
      },
      subtitle: {
        text: "Interpretation Criteria",
        y: 15,
        zIndex: 10,
      },
      credits: {
        enabled: false
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        layout: 'vertical',
    
        align: 'right',
        verticalAlign: 'center',
        floating: true,

        labelFormatter: function () {
          if (this.name.length > 15) {
            return `${this.name.slice(0, 12)}...`
          }
          else {
            return this.name
          }
        }
      },
      // tooltip: {
      //   formatter: function () {
      //     return (
      //       "<b><span >" +
      //       this.point.description +
      //       "</span></b>"
      //     )
      //   }
      // },

      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: 10,
            style: {
              fontSize: "10px"
            },
            formatter: function () {
              return (
                "<b><span >" +
                this.point.name +
                "</span></b><br/><span>" +
                "Subject: " +
                this.y.toLocaleString() + "</span>"
              )
            }
          },
          showInLegend: true
        }
      },
      // series: [{ name: 'Gender', data: category }],
      series: [{
        type: 'pie',
        name: 'Share',
        data: category
      }]
      // drilldown: {
      //   series: [{
      //     name: "Male",
      //     id: "Male",
      //     // innerSize: '20%',
      //     data: Male
      //   }, {
      //     name: "Female",
      //     id: "Female",
      //     // innerSize: '20%',
      //     data: FeMale
      //   }]
      // }
    };
 

    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default Pie3Chart;
