import React, { Component } from "react";
// import CustomTable from "components/atoms/CustomTable";
// import MultiCell from "components/atoms/CustomTable/MultiCell";
// import { teal } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

// const columnList = [
//   {
//     column: "Type",
//     key: "type",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Date",
//     key: "at",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Client IP",
//     key: "clientIp",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Forwarded IP",
//     key: "forwardedIp",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Host IP",
//     key: "hostIp",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Verb",
//     key: "verb",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Referrer",
//     key: "referrer",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Request",
//     key: "request",
//     link: false,
//     align: "center",
//     scroll: false
//   },
//   {
//     column: "Response",
//     key: "response",
//     link: false,
//     align: "center",
//     scroll: false
//   }
// ];

const styles = theme => ({
  container: {
    padding: "40px 50px",
    background: "#fff",
    margin: "10px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    border: "2px solid ",
    borderColor: "white",
    "&:hover": {
      borderColor: '#26a1a4'
    }
  },
  tableHeader: {
    display: "flex",
    marginBottom: 5
  },
  leftHeader: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "5px"
  }
});

class LogTableContainer extends Component {
  render() {
    const { classes, title, subTitle } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.tableHeader}>
          <div className={classes.leftHeader}>
            <h3>{title}</h3>
            <p>{subTitle}</p>
          </div>
        </div>
        {/* <CustomTable {...rest} columnList={columnList} Component={MultiCell} /> */}
      </div>
    );
  }
}

export default withStyles(styles)(LogTableContainer);
