
class ActionableTableModel {
  constructor(d, i) {
    let select = ""
    let sampleselect = ""
    if (d.selectedTotalSubjectCnt !== d.totalSubjectCnt) {
      select = `/ ${d.selectedTotalSubjectCnt.toLocaleString()}`
      sampleselect = `/ ${d.selectedSpecimenCnt.toLocaleString()}`
    }
    this.id = d.id
    this.geneName = d.geneName
    this.hasDetails = d.hasDetails
    this.selectedCancer = d.selectedCancer
    
    // this.subjectTotal = `${d.subjectCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.samplesTotal = `${d.sampleCnt.toLocaleString()} ${sampleselect} / ${d.totalSpecimenCnt.toLocaleString()}`
    // this.variantTotal = `${d.variantCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.snvTotal = `${d.snvCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.cnvTotal = `${d.cnvCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    // this.svTotal = `${d.svCnt.toLocaleString()} ${select} / ${d.totalSubjectCnt.toLocaleString()}`
    this.subjectTotal = `${d.subjectCnt.toLocaleString()}`
    this.samplesTotal = `${d.sampleCnt.toLocaleString()}`
    this.variantTotal = `${d.variantCnt.toLocaleString()} ${select}`
    this.snvTotal = `${d.snvCnt.toLocaleString()}`
    this.cnvTotal = `${d.cnvCnt.toLocaleString()}`
    this.svTotal = `${d.svCnt.toLocaleString()}`
    this.subjectCnt = d.subjectCnt
    this.sampleCnt = d.sampleCnt
    this.variantCnt = d.variantCnt
    this.snvCnt = d.snvCnt
    this.cnvCnt = d.cnvCnt
    this.svCnt = d.svCnt
    this.checkTotal = select
  }
}

export default ActionableTableModel;
