import {
  axios
} from 'dependencyLibrary';

class SearchRepository {
  URL = "/api/kmaster/search";

  getSearchCondition = () => {
    return axios.get(`${this.URL}/search-conditions`);
  };

  getSearchConditionAutoComplete(reported = "") {
    return axios.get(`${this.URL}/auto-complete-factor${reported}`);
  }

  getGeneRankedData(reported = "") {
    return axios.get(`${this.URL}/gene-ranked${reported}`);
  }

  getVariantRankedData(reported = "") {
    return axios.get(`${this.URL}/variant-ranked${reported}`);
  }


}

export default new SearchRepository();