class RelativeModel {
  constructor(data) {
    this.id = []
    this.subject = []
    this.snvName = []
    this.cnvName = []
    this.svName = []
    this.cancers = {}
    if (data[0]) {
      for (let i of data[0].cancers) {
        let name = i.name.replace(" cancer", "").replace(" carcinoma", "")
        this.cancers[name] = []
      }
    }
    for (let i of data) {
      this.id.push(i.id);
      this.snvName.push(`${i.targetGene1Id}, ${i.hgvsP1digit}`)
      this.cnvName.push(`${i.targetGene1Id}, ${i.sequenceAlteration ? i.sequenceAlteration.replace("CNV ", "").replace("Amplification", "Amp.").replace("Deletion", "Del.") : ""}`)
      this.svName.push(`${i.targetGene1Id}, ${i.targetGene2Id}`)
      this.subject.push(i.subject || i.subjectCnt);
      if (i.cancers) {
        for (let j of i.cancers) {
          let name = j.name.replace(" cancer", "").replace(" carcinoma", "")
          if (this.cancers[name]) {
            this.cancers[name].push(j.subject)
          }
        }
      }
    }
  }
}

export default RelativeModel;
