import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import PageTemplate from "pages/template/pageTemplate";
import LeftMenu from "components/organisms/LeftMenu";
import AppliedFilter from "components/organisms/AppliedFilter";
import DataPageTable from "components/organisms/DataPageTable";
// import DetailBoard from "components/organisms/DetailBoard";
import PatientListTable from "components/organisms/PatientListTable";
import TabHeader from "components/atoms/TabHeader";
import queryString from "query-string";
import SubjectSample from "components/atoms/SubjectSample";
import CheckPermission from "components/lib/CheckPermission";
import TestChart from "components/atoms/TestChart";
import TypeSubject from "components/atoms/TypeSubject";
import AgeGenderChart from "components/atoms/AgeGenderChart";

import CustomDialog from "components/atoms/CustomDialog";
import NewCartSet from "components/atoms/CustomDialog/NewCartSet";
// import SubFilter from "components/organisms/SubFilter";
import { StickyContainer, Sticky } from "react-sticky";

// import AgeGenderChart from "components/atoms/AgeGenderChart";
// import CustomBtn from "components/atoms/CustomBtn";
// import Cart from "@material-ui/icons/ShoppingCart";
// import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";


// import TestChart from "components/atoms/TestChart";
// import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

// import Download from "assets/images/dataexplorer/download.svg";
//새창 href:'주소' 
//클릭 이벤트 fn:()=>{} 
// TSV 형식으로 다운로드
// CSV 형식으로 다운로드

// const detailCancerColumns = [
//   { column: "Cancer", property: "name", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Subject", property: "subject", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "Samples", property: "samples", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "Gene", property: "gene", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "Variant", property: "variant", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "Snv", property: "snv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "Cnv", property: "cnv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
//   { column: "SV", property: "sv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } }
// ];
const PatientColumns = [
  { column: "Person ID", property: "personSourceValue", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 600 } } },
  { column: "Sex", property: "sex", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Age", property: "age", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Cancer", property: "cancer", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Detail Cancer", property: "cancerDetail", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene", property: "geneCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "Variant", property: "variantCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
// const genecolumns = [
//   { column: "Gene", property: "geneName", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Subject", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
//   { column: "Samples", property: "sampleCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
//   { column: "Genes Freq", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
//   { column: "Variant", property: "variantCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
//   { column: "SNV", property: "snvCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
//   { column: "CNV", property: "cnvCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
//   { column: "SV", property: "svCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
// ];
// const snvcolumns = [
//   { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Type", property: "sequenceAlteration", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   // { column: "HGVS.c", property: "hgvsC", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "HGVS.p", property: "hgvsP", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "HGVS.p 1digit", property: "hgvsP1digit", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Subject", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
// ];
// const cnvcolumns = [
//   { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Type", property: "sequenceAlteration", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Subject", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
// ];
// const svcolumns = [
//   { column: "Gene1", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Gene2", property: "targetGene2Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
//   { column: "Subject", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
// ];

const styles = theme => ({
  barchart: {
    // width: "56vw",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: " 0px 15px"
    // marginBottom: "0px"
  },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "9px 0px 9px 12px",
    fontWeight: "500"
  },
  chartMargin: {
    padding: "15px"
  },
  filter: {
    minHeight: "137px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#f6f6f6"
  },
  filters: {
    // minHeight: "137px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#f6f6f6",
    width: "100%",
    paddingRight: "5px",
    // margin: 'auto'
    "@media (max-width: 1500px)": {
      width: '1240px',
      margin: 'auto'
    },
  },
  tabsize: {
    width: "125px",
    height: "38px"
  },
  nav: {
    padding: "10px 15px 0px 10px",
    width: '100%',
    height: 'calc(100% - 43px)',

  },
  tabcontainer: {
    background: "#f6f6f6",
    border: "solid 1px #c6c6c6",
    borderTop: "0px",
    paddingTop: "15px"
  },
  board: {
    // width: "56vw",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "15px",
    marginBottom: "0px"
  },
  filterContainer: {
    borderTop: "solid 1px #c6c6c6",
    // padding: "5px 10.5vw",
    verticalAlign: 'top',
    backgroundColor: '#ffffff',
    zIndex: 100,
    boxShadow: "1px 3px 0px 0.5px rgba(121, 121, 121, 0.6)",
    right: "0px",
    // width: "auto !important",
    "@media (max-width: 1500px)": {
      padding: "5px 20px",
    },
  },
  marginContainger: {
    width: "1500px !important",
    margin: 'auto',
    display: "flex",
    "@media (max-width: 1500px)": {
      width: '1240px !important',
      margin: 'auto'
    },
  },
  container: {
    display: "flex"
  },
  chartContainer: {
    paddingTop: "15px",
    display: "flex"
  },
  keyword: {
    width: "15vw",
    margin: "5px 1vw 0px 1vw"
  },
  person: {
    float: "left",
    lineHeight: "2",
    paddingLeft: "20px"
  },
  ageGender: {

    width: "50%",
    padding: "0px 15px"
  },
  tabscolor: {
    background: "#f6f6f6"
  },
  seleted: {
    background: "white"
  },
  variantTab: {
    margin: "0px 15px"
  },
  TEST: {
    height: 'auto',
    width: "15px",
    backgroundColor: "#c6c6c6",
  }
});
const DetailTableTitle = ['Detail Cancer List', 'Gene List', 'SNV List', 'CNV List', 'SV List']
let patientData

@inject("summary", "comm", "home")
@observer
class DataPage extends Component {
  pdfExportComponent;

  state = {
    mainTab: 0,
    detailtab: 0,
    open: false,
    filterTab: 0,
    detailCancerList: [],
    select: {
      cancerList: [],
      detailCancer: [],
      gene: [],
      snv: [],
      sv: [],
      reported: "true",
      gender: "",
      age: "",
      value: "",
    },
    search: "",
    suggestions: [],
    variantType: {},
    Variant: [],
  };

  async componentDidMount() {
    const { home } = this.props;
    home.TotalData('')
    this.reStart()
  }

  reStart = async () => {
    const { comm } = this.props;
    await comm.resetSelectedObj();
    await comm.resetMainFilterObject();
    this.onClickCriteriaFilter()
  };

  mainFilterQuery = () => {
    const { comm: { mainFilterObject } } = this.props;
    let list = []
    let cancer;
    let gene;
    let snv;
    let cnv;
    let sv
    let variantType = [];
    if (mainFilterObject.cancerList) {
      list = []
      for (let i of mainFilterObject.cancerList) {
        list.push(Object.values(i)[0])
      }
      cancer = list.join(",");
    }
    if (mainFilterObject.gene) {
      gene = mainFilterObject.gene.join(",");
    }
    if (mainFilterObject.snv) {
      snv = mainFilterObject.snv.join(",");
    }
    if (mainFilterObject.cnv) {
      cnv = mainFilterObject.cnv.join(",");
    }
    if (mainFilterObject.sv) {
      sv = mainFilterObject.sv.join(",");
    }
    if (mainFilterObject.variantType) {
      variantType = []
      for (let i of Object.values(mainFilterObject.variantType)) {
        variantType = variantType.concat(i)
      }
    }
    let query = {
      majorCancer: cancer,
      majorGene: gene,
      majorSnv: snv,
      majorCnv: cnv,
      majorSv: sv,
      reported: mainFilterObject.reported,
      gender: mainFilterObject.gender,
      startAge: mainFilterObject.startage,
      endAge: mainFilterObject.endage,
      variantType: variantType.join(",")
    };
    return query
  }

  subFilterQuery = () => {
    const { comm: { selectedObj } } = this.props;
    let detailcancer;
    let gene;
    let snv;
    let cnv;
    let sv;
    if (selectedObj.detailcancer) {
      detailcancer = selectedObj.detailcancer.join(",");
    }
    if (selectedObj.gene) {
      gene = selectedObj.gene.join(",");
    }
    if (selectedObj.snv) {
      snv = selectedObj.snv.join(",");
    }
    if (selectedObj.cnv) {
      cnv = selectedObj.cnv.join(",");
    }
    if (selectedObj.sv) {
      sv = selectedObj.sv.join(",");
    }
    let query = {
      detailCancer: detailcancer,
      gene: gene,
      snv: snv,
      cnv: cnv,
      sv: sv
    };
    return query
  }


  onClickMainFilter = async () => {
    const { mainTab, detailtab } = this.state;
    const { comm: { mainFilterObject }, comm, home } = this.props;
    let Type = ''
    const query = this.mainFilterQuery()
    const mainQuery = queryString.stringify(query);
    comm.resetSelectedObj()
    await home.getValueData(`?${mainQuery}`)
    home.getCancerTableData(`?${mainQuery}&sort=subject,desc`)
    switch (mainTab) {
      case 0:
        home.getSubFilterData('cancers', `?${mainQuery}&sort=subject,desc`)
        break;
      case 1:
        home.getSubFilterData('genes', `?${mainQuery}&size=100`)
        home.getreturnGeneDate(`?${mainQuery}&size=10`);
        home.getAllGeneDate(`?${mainQuery}&size=30`);
        break;
      case 2:
        switch (detailtab) {
          case 0:
            if (!(mainFilterObject.variantType && mainFilterObject.variantType.length)) {
              Type = 'variantType=DELETION,INSERTION,SNV,SUBSTITUTION'
            }
            home.getSubFilterData('variants/snvs', `?${mainQuery}&size=100`)
            home.getreturnVariantDate(`?${mainQuery}&size=10`, "snvs");
            home.getAllVarientDate(`?${mainQuery}&size=30`, "snvs");
            break;
          case 1:
            if (!(mainFilterObject.variantType && mainFilterObject.variantType.length)) {
              Type = 'variantType=CNV Deletion,CNV,CNV Amplification'
            }
            home.getSubFilterData('variants/cnvs', `?${mainQuery}&size=100`)
            home.getreturnVariantDate(`?${mainQuery}&size=10`, "cnvs");
            home.getAllVarientDate(`?${mainQuery}&size=30`, "cnvs");
            break;
          case 2:
            if (!(mainFilterObject.variantType && mainFilterObject.variantType.length)) {
              Type = 'variantType=SV'
            }
            home.getSubFilterData('variants/svs', `?${mainQuery}&size=100`)
            home.getreturnVariantDate(`?${mainQuery}&size=10`, "svs");
            home.getAllVarientDate(`?${mainQuery}&size=30`, "svs");
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    home.getDxGraphicsData(`?${Type}&${mainQuery}`)
    home.getPersonGraphicsData(`?${Type}&${mainQuery}`)
    home.getreturnPersonDate(`?${mainQuery}`);
    const { home: { valueData }, } = this.props
    let length = valueData[3].value
    home.getDownloadDate(`?${mainQuery}&size=${length}`);
  }


  onClickCriteriaFilter = async () => {
    const { home, comm: { mainFilterObject } } = this.props;
    let total = { reported: mainFilterObject.reported }
    const Total = queryString.stringify(total);
    home.TotalData(`?${Total}`)
    let tool
    const query = this.mainFilterQuery()
    let toolsquery = query
    this.onClickMainFilter()
    if (toolsquery.majorCancer) {
      toolsquery.majorCancer = null
    }
    if (toolsquery.majorGene) {
      toolsquery.majorGene = null
    }
    if (toolsquery.majorSnv) {
      toolsquery.majorSnv = null
    }
    if (toolsquery.majorCnv) {
      toolsquery.majorCnv = null
    }
    if (toolsquery.majorSv) {
      toolsquery.majorSv = null
    }
    if (toolsquery.variantType) {
      toolsquery.variantType = null
    }
    tool = queryString.stringify(toolsquery);
    home.getCancerTypeData(`?${tool}&sort=subject,desc`);
    home.getGeneTypeData(`?${tool}&size=10`)
    home.getVariantTypeListData(`?${tool}&size=3`)
    home.getVariantTypeData(`?${tool}`)

  }


  ClickSubFilter = async (type, value) => {
    const { mainTab, detailtab } = this.state;
    const { comm: { selectedObj }, home } = this.props;
    let detailcancer;
    let gene;
    let snv;
    let cnv;
    let sv
    let variantType = [];
    let Type
    switch (type) {
      case 'Cancer':
        if (selectedObj.detailcancer) {
          if (selectedObj.detailcancer.indexOf(value) > -1) {
            selectedObj.detailcancer.splice(selectedObj.detailcancer.indexOf(value), 1);
          } else {
            selectedObj.detailcancer.push(value)
          }
        } else {
          selectedObj.detailcancer = [value]
        }
        break;
      case 'Gene':
        if (selectedObj.gene) {
          if (selectedObj.gene.indexOf(value) > -1) {
            selectedObj.gene.splice(selectedObj.gene.indexOf(value), 1);
          } else {
            selectedObj.gene.push(value)
          }
        } else {
          selectedObj.gene = [value]
        }
        break;
      case 'SNV':
        if (selectedObj.snv) {
          if (selectedObj.snv.indexOf(value) > -1) {
            selectedObj.snv.splice(selectedObj.snv.indexOf(value), 1);
          } else {
            selectedObj.snv.push(value)
          }
        } else {
          selectedObj.snv = [value]
        }
        break;
      case 'CNV':
        if (selectedObj.cnv) {
          if (selectedObj.cnv.indexOf(value) > -1) {
            selectedObj.cnv.splice(selectedObj.cnv.indexOf(value), 1);
          } else {
            selectedObj.cnv.push(value)
          }
        } else {
          selectedObj.cnv = [value]
        }
        break;
      case 'SV':
        if (selectedObj.sv) {
          if (selectedObj.sv.indexOf(value) > -1) {
            selectedObj.sv.splice(selectedObj.sv.indexOf(value), 1);
          } else {
            selectedObj.sv.push(value)
          }
        } else {
          selectedObj.sv = [value]
        }
        break;
      default:
        break;
    }
    if (selectedObj.detailcancer) {
      detailcancer = selectedObj.detailcancer.join(",");
    }
    if (selectedObj.gene) {
      gene = selectedObj.gene.join(",");
    }
    if (selectedObj.snv) {
      snv = selectedObj.snv.join(",");
    }
    if (selectedObj.cnv) {
      cnv = selectedObj.cnv.join(",");
    }
    if (selectedObj.sv) {
      sv = selectedObj.sv.join(",");
    }
    if (selectedObj.variantType) {
      variantType = []
      for (let i of Object.values(selectedObj.variantType)) {
        variantType = variantType.concat(i)
      }
    }

    let query = {
      detailCancer: detailcancer,
      gene: gene,
      snv: snv,
      cnv: cnv,
      sv: sv,
    };

    const main = this.mainFilterQuery()
    const mainQuery = queryString.stringify(main);

    const querylist = queryString.stringify(query);
    await home.getValueData(`?${querylist}&${mainQuery}`)
    home.getCancerTableData(`?${querylist}&${mainQuery}&sort=subject,desc`)
    switch (mainTab) {
      case 1:
        home.getreturnGeneDate(`?${querylist}&${mainQuery}&size=10`);
        home.getAllGeneDate(`?${querylist}&${mainQuery}&size=30`);
        break;
      case 2:
        switch (detailtab) {
          case 0:
            if (!variantType.length) {
              Type = 'variantType=DELETION,INSERTION,SNV,SUBSTITUTION'
            }
            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "snvs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "snvs");
            break;
          case 1:
            if (!variantType.length) {
              Type = 'variantType=CNV Deletion,CNV,CNV Amplification'
            }
            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "cnvs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "cnvs");
            break;
          case 2:
            if (!variantType.length) {
              Type = 'variantType=SV'
            }
            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "svs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "svs");
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    home.getDxGraphicsData(`?${Type}&${querylist}&${mainQuery}`)
    home.getPersonGraphicsData(`?${Type}&${querylist}&${mainQuery}`)
    home.getreturnPersonDate(`?${querylist}&${mainQuery}`);
    const { home: { valueData }, } = this.props
    let length = valueData[3].value
    home.getDownloadDate(`?${querylist}&size=${length}&${mainQuery}`);
  }

  onChangeStatusTab = async () => {
    const { mainTab, detailtab } = this.state;
    const { comm: { mainFilterObject }, home } = this.props;
    let Type = ''
    const main = this.mainFilterQuery()
    const mainQuery = queryString.stringify(main);
    let variantType = [];
    if (mainFilterObject.variantType) {
      variantType = []
      for (let i of Object.values(mainFilterObject.variantType)) {
        variantType = variantType.concat(i)
      }
    }

    let query = this.subFilterQuery()
    const querylist = queryString.stringify(query);
    await home.getValueData(`?${querylist}&${mainQuery}`)
    home.getCancerTableData(`?${querylist}&${mainQuery}&sort=subject,desc`)
    switch (mainTab) {
      case 0:
        home.getSubFilterData('cancers', `?${querylist}&${mainQuery}&sort=subject,desc`)
        break;
      case 1:
        home.getSubFilterData('genes', `?${querylist}&${mainQuery}&size=100`)
        home.getreturnGeneDate(`?${querylist}&${mainQuery}&size=10`);
        home.getAllGeneDate(`?${querylist}&${mainQuery}&size=30`);
        break;
      case 2:
        switch (detailtab) {
          case 0:
            if (!variantType.length) {
              Type = 'variantType=DELETION,INSERTION,SNV,SUBSTITUTION'
            }
            home.getSubFilterData('variants/snvs', `?${querylist}&${mainQuery}&size=100`)
            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "snvs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "snvs");
            break;
          case 1:
            if (!variantType.length) {
              Type = 'variantType=CNV Deletion,CNV,CNV Amplification'
            }
            home.getSubFilterData('variants/cnvs', `?${querylist}&${mainQuery}&size=100`)

            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "cnvs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "cnvs");
            break;
          case 2:
            if (!variantType.length) {
              Type = 'variantType=SV'
            }
            home.getSubFilterData('variants/svs', `?${querylist}&${mainQuery}&size=100`)
            home.getreturnVariantDate(`?${querylist}&${mainQuery}&size=10`, "svs");
            home.getAllVarientDate(`?${querylist}&${mainQuery}&size=30`, "svs");
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    home.getDxGraphicsData(`?${Type}&${querylist}&${mainQuery}`)
    home.getPersonGraphicsData(`?${Type}&${querylist}&${mainQuery}`)
    home.getreturnPersonDate(`?${querylist}&${mainQuery}`);
    const { home: { valueData }, } = this.props
    let length = valueData[3].value
    home.getDownloadDate(`?${querylist}&${mainQuery}&size=${length}`);

  }

  moreSubFilter = (size) => {
    const { mainTab, detailtab } = this.state;
    const { home } = this.props;

    const main = this.mainFilterQuery()
    const mainQuery = queryString.stringify(main);
    let setquery = this.subFilterQuery()

    let querylist
    switch (mainTab) {
      case 1:
        setquery.gene = ''
        querylist = queryString.stringify(setquery);
        home.getSubFilterData('genes', `?${querylist}&${mainQuery}&size=${size}`)
        break;
      case 2:
        switch (detailtab) {
          case 0:
            setquery.snv = ''
            querylist = queryString.stringify(setquery);
            home.getSubFilterData('variants/snvs', `?${querylist}&${mainQuery}&size=${size}`)
            break;
          case 1:
            setquery.cnv = ''
            querylist = queryString.stringify(setquery);
            home.getSubFilterData('variants/cnvs', `?${querylist}&${mainQuery}&size=${size}`)
            break;
          case 2:
            setquery.sv = ''
            querylist = queryString.stringify(setquery);
            home.getSubFilterData('variants/svs', `?${querylist}&${mainQuery}&size=${size}`)
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  handlePagination = (s, target) => {
    const { home } = this.props;
    const query = this.mainFilterQuery()
    const mainQuery = queryString.stringify(query);

    let setquery = this.subFilterQuery()

    setquery.page = s.page;
    setquery.size = s.rowsPerPage

    const querylist = queryString.stringify(setquery);
    switch (target) {
      case DetailTableTitle[0]:
        // home.getDetailCancerDate(`?${querylist}`);
        break;
      case DetailTableTitle[1]:
        home.getreturnGeneDate(`?${mainQuery}&${querylist}`);
        break;
      case DetailTableTitle[2]:
        home.getreturnVariantDate(`?${mainQuery}&${querylist}`, 'snvs');
        break;
      case DetailTableTitle[3]:
        home.getreturnVariantDate(`?${mainQuery}&${querylist}`, 'cnvs');
        break;
      case DetailTableTitle[4]:
        home.getreturnVariantDate(`?${mainQuery}&${querylist}`, 'svs');
        break;
      default:
        home.getreturnPersonDate(`?${mainQuery}&${querylist}`);
        break;
    }
  };

  handleSort = ({ order, orderBy }) => {
    const { home } = this.props;
    home.getreturnPersonDate(`?sort=${orderBy},${order}`);
  };

  postSets = (node) => {
    patientData = node
    this.setState({
      open: true,
    });
  }

  onClickOpen = e => {
    e.preventDefault();
    this.setState({
      open: !this.state.open
    });
  };

  onChangeFilterTab = value => {
    this.setState({
      filterTab: value
    });
    this.reStart()
  }

  onChangeTab = async value => {
    await this.setState({
      mainTab: value,
      detailtab: 0
    });
    this.onChangeStatusTab();
  };

  onChangeVariantTab = async value => {
    await this.setState({
      detailtab: value
    });
    this.onChangeStatusTab();
  }

  getKeyword = data => {
    const { home } = this.props;
    home.getKeyWord(`?searchKeyword=${data}`)
  };

  insertFilterKeyword = data => {
    const { comm: { mainFilterObject }, } = this.props;
    let array = []
    let cancer = []
    switch (data.label) {
      case 'gene':
        if (mainFilterObject.gene) {
          array = mainFilterObject.gene
        }
        array.push(data.name)
        mainFilterObject.gene = array
        break;
      case 'cancer':
        if (mainFilterObject.cancerList) {
          cancer = mainFilterObject.cancerList
        }
        cancer.push({ [data.value]: data.name })
        mainFilterObject.cancerList = cancer
        break;
      // case 'detailCancer':
      //   if (selectedObj.detailcancer) {
      //     cancer = selectedObj.detailcancer
      //   }
      //   cancer.push(data.name)
      //   array.push(data.value)
      //   selectedObj.detailcancer = cancer
      //   break;
      case 'hgvsp1digit':
        //hgvsp1digitResults snv
        if (mainFilterObject.snv) {
          array = mainFilterObject.snv
        }
        array.push(data.name)
        mainFilterObject.snv = array
        break;
      case 'cnv':
        if (mainFilterObject.cnv) {
          array = mainFilterObject.cnv
        }
        array.push(data.name)
        mainFilterObject.cnv = array
        break;
      case 'sequenceAlteration':
        if (mainFilterObject.variantType) {
          array = mainFilterObject.variantType
        }
        array.push(data.name)
        mainFilterObject.variantType = array
        break;

      default:
        break;
    }
    this.onClickMainFilter()
  }

  insertSubFilterKeyword = data => {
    const { comm: { selectedObj }, } = this.props;
    let array = []
    let cancer = []
    switch (data.label) {
      case 'gene':
        if (selectedObj.gene) {
          array = selectedObj.gene
        }
        array.push(data.name)
        selectedObj.gene = array
        break;
      case 'detailCancer':
        if (selectedObj.detailcancer) {
          cancer = selectedObj.detailcancer
        }
        cancer.push(data.name)
        array.push(data.value)
        selectedObj.detailcancer = cancer
        break;
      case 'hgvsp1digit':
        //hgvsp1digitResults snv
        if (selectedObj.snv) {
          array = selectedObj.snv
        }
        array.push(data.name)
        selectedObj.snv = array
        break;
      case 'cnv':
        if (selectedObj.cnv) {
          array = selectedObj.cnv
        }
        array.push(data.name)
        selectedObj.cnv = array
        break;
      default:
        break;
    }
    this.ClickSubFilter(null, null)
  }


  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  }

  deleteMainFilter = (value) => {
    const Types = ['SNV', 'CNV', 'SV']
    const { comm: { mainFilterObject } } = this.props;
    if (mainFilterObject.cancerList) {
      // mainFilterObject.cancerList.map((data, i) => {
      //   if (Object.values(data)[0].toString() === value) {
      //     mainFilterObject.cancerList.splice(i, 1)
      //   }
      // })
    }
    if (mainFilterObject.gene && mainFilterObject.gene.indexOf(value) > -1) {
      mainFilterObject.gene.splice(mainFilterObject.gene.indexOf(value), 1);
    }
    if (mainFilterObject.snv && mainFilterObject.snv.indexOf(value) > -1) {
      mainFilterObject.snv.splice(mainFilterObject.snv.indexOf(value), 1);
    }
    if (mainFilterObject.cnv && mainFilterObject.cnv.indexOf(value) > -1) {
      mainFilterObject.cnv.splice(mainFilterObject.cnv.indexOf(value), 1);
    }
    if (mainFilterObject.sv && mainFilterObject.sv.indexOf(value) > -1) {
      mainFilterObject.sv.splice(mainFilterObject.sv.indexOf(value), 1);
    }
    if (mainFilterObject.variantType) {
      for (let i of Types) {
        if (mainFilterObject.variantType[i] && mainFilterObject.variantType[i].indexOf(value) > -1) {
          mainFilterObject.variantType[i].splice(mainFilterObject.variantType[i].indexOf(value), 1);
        }
        if (mainFilterObject.variantType[i] && mainFilterObject.variantType[i].length === 0) {
          delete mainFilterObject.variantType[i]
        }
      }
    }
    if (`${mainFilterObject.startage}^${mainFilterObject.endage}` === value) {
      delete mainFilterObject.startage
      delete mainFilterObject.endage
    }
    if (mainFilterObject.gender === value) {
      delete mainFilterObject.gender
    }
    if (mainFilterObject.reportedFilter === value) {
      delete mainFilterObject.reportedFilter
      delete mainFilterObject.reported
    }
    this.onClickMainFilter()
  }

  deleteEliMainFilter = (value) => {
    this.deleteMainFilter(value)
    this.onClickCriteriaFilter()
  }

  deleteSubFilter = (value) => {
    const { comm: { selectedObj } } = this.props;
    if (selectedObj.detailcancer && selectedObj.detailcancer.indexOf(value) > -1) {
      selectedObj.detailcancer.splice(selectedObj.detailcancer.indexOf(value), 1);
    }
    if (selectedObj.gene && selectedObj.gene.indexOf(value) > -1) {
      selectedObj.gene.splice(selectedObj.gene.indexOf(value), 1);
    }
    if (selectedObj.snv && selectedObj.snv.indexOf(value) > -1) {
      selectedObj.snv.splice(selectedObj.snv.indexOf(value), 1);
    }
    if (selectedObj.cnv && selectedObj.cnv.indexOf(value) > -1) {
      selectedObj.cnv.splice(selectedObj.cnv.indexOf(value), 1);
    }
    if (selectedObj.sv && selectedObj.sv.indexOf(value) > -1) {
      selectedObj.sv.splice(selectedObj.sv.indexOf(value), 1);
    }
    this.ClickSubFilter(null, null)
  }

  render() {
    const { detailtab, filterTab } = this.state;
    const {
      classes,
      home: {
        cancerTableData,
        // backgroundCancerChartData,
        cancerTypeData,
        geneTypeData,
        variantTypeListData,
        variantTypeData,

        // returnDetailCancerData,
        // DemographyData,
        returnPersonData,
        // returnGeneData,
        // returnSnvData,
        // returnCnvData,
        // returnSvData,

        // allGeneData,
        // allSnvData,
        // allCnvData,
        // allSvData,
        totalData,
        valueData,
        downloadData,
        returnKeyWord,
        // SubFilterData,
        // PersonGraphicsData,
        dxAge,
      },
      comm: { originUser, selectedObj, mainFilterObject },
      location: { pathname }
    } = this.props;

    let MainTabList = [
      `Cancer( ${valueData ? valueData[2].value.toLocaleString() : 0} )`,
      `Gene( ${valueData ? valueData[0].value.toLocaleString() : 0} )`,
      `Variant( ${valueData ? valueData[1].value.toLocaleString() : 0} )`];
    // const VariantTabList = [
    //   `SNV(  ${valueData ? valueData[4].value.toLocaleString() : 0} )`,
    //   `CNV(  ${valueData ? valueData[5].value.toLocaleString() : 0} )`,
    //   `SV(  ${valueData ? valueData[6].value.toLocaleString() : 0} )`];
    MainTabList = ['Data Explorer']
    let mainPage;
    let mainDataType = {}
    // let detailType = {}
    // let variantChart
    // let variantName
    // switch (mainTab) {
    //   case 0:
    //     mainDataType = { type: 'Cancer', list: PersonGraphicsData, title: DetailTableTitle[0], columns: detailCancerColumns, data: cancerTableData, chart: cancerTableData }
    //     detailType = { title: DetailTableTitle[0], columns: detailCancerColumns, data: cancerTableData, type: 'Cancer' }
    //     break;
    //   case 1:
    //     mainDataType = { type: 'Gene', list: PersonGraphicsData, title: DetailTableTitle[1], columns: genecolumns, data: returnGeneData, chart: allGeneData }
    //     detailType = { title: DetailTableTitle[1], columns: genecolumns, data: returnGeneData, type: 'Gene' }
    //     break;
    //   case 2:
    //     switch (detailtab) {
    //       case 0:
    //         variantChart = allSnvData
    //         variantName = "SNV"
    //         detailType = { title: DetailTableTitle[2], columns: snvcolumns, data: returnSnvData, type: 'SNV' }
    //         break;
    //       case 1:
    //         variantChart = allCnvData
    //         variantName = "CNV"
    //         detailType = { title: DetailTableTitle[3], columns: cnvcolumns, data: returnCnvData, type: 'CNV' }
    //         break;
    //       case 2:
    //         variantChart = allSvData
    //         variantName = "SV"
    //         detailType = { title: DetailTableTitle[4], columns: svcolumns, data: returnSvData, type: 'SV' }
    //         break;
    //       default:
    //         break;
    //     }
    //     mainDataType = { type: 'variant', list: PersonGraphicsData, isTab: true, title: detailType.title, columns: detailType.columns, data: detailType.data, chart: variantChart }

    //     mainPage = (
    //       <>
    //         <div className={classes.variantTab}>
    //           <TabHeader tabList={VariantTabList}
    //             value={detailtab}
    //             onChangeTab={this.onChangeVariantTab}
    //             tabstyle={classes.tabsize}
    //             tabscolor={classes.tabscolor}
    //             seleted={classes.seleted} />
    //         </div>
    //       </>
    //     );

    //     break;
    //   default:
    //     mainPage = null;
    //     break;
    // }

    return (
      <>
        {/* <button className="k-button" onClick={this.exportPDFWithComponent}>Export with component</button>
        <PDFExport ref={(component) => this.pdfExportComponent = component} > */}

        <StickyContainer>
          <Sticky >
            {({ style }) => (
              <div style={style} className={classes.filterContainer}>
                <div className={classes.marginContainger}>
                  <div className={classes.filters}>
                    <AppliedFilter mainFilterObject={mainFilterObject} selectedObj={selectedObj}
                      deleteMainFilter={this.deleteMainFilter}
                      deleteSubFilter={this.deleteSubFilter}
                      deleteEliMainFilter={this.deleteEliMainFilter} />
                  </div>
                  <SubjectSample list={cancerTableData} totalData={totalData} />
                </div>
              </div>
            )}
          </Sticky>


          <PageTemplate
            pathname={pathname}
            content={
              <div className={classes.container}>
                <LeftMenu
                  pathname={pathname}
                  filterTab={filterTab}
                  match={null}
                  cancerTypeData={cancerTypeData}
                  geneTypeData={geneTypeData}
                  variantTypeListData={variantTypeListData}
                  variantTypeData={variantTypeData}
                  returnKeyWord={returnKeyWord}
                  onChangeFilterTab={this.onChangeFilterTab}
                  onClickReset={this.reStart}
                  getKeyword={this.getKeyword}
                  insertFilterKeyword={this.insertFilterKeyword}
                  mainFilterObject={mainFilterObject}
                  onClickMainFilter={this.onClickMainFilter}
                  onClickCriteriaFilter={this.onClickCriteriaFilter}
                />
                <div className={classes.nav}>
                  <div className={classes.tabhead}>
                    <TypeSubject valueData={valueData} />
                    {/* <SubjectSample list={cancerTableData} totalData={totalData} /> */}
                    <TabHeader
                      tabList={MainTabList}
                      value={this.state.mainTab}
                      onChangeTab={this.onChangeTab}
                      tabstyle={classes.tabsize}
                    />
                  </div>
                  <div className={classes.tabcontainer}>
                    {/* <SubFilter
                      detailType={{ data: SubFilterData, type: detailType.type }}
                      mainFilterObject={mainFilterObject}
                      ClickSubFilter={this.ClickSubFilter}
                      moreSubFilter={this.moreSubFilter}
                      insertFilterKeyword={this.insertSubFilterKeyword}
                      returnKeyWord={returnKeyWord}
                      getKeyword={this.getKeyword}

                    /> */}
                    {mainPage}
                    <div className={classes.barchart}>
                      <div className={classes.chartTitle}>Demography</div>
                      <div className={classes.chartContainer}>
                        <div className={classes.ageGender}>
                          <AgeGenderChart list={mainDataType.list} />
                        </div>
                        <div className={classes.ageGender}>
                          <AgeGenderChart list={dxAge} isAge={true} />
                        </div>
                      </div>
                    </div>
                    <DataPageTable mainDataType={mainDataType} handlePagination={this.handlePagination} dxAge={dxAge}
                      stateList={this.state.detailCancerList} postSets={this.postSets} originUser={originUser} detailtab={detailtab} mainFilterObject={mainFilterObject} />
                    <CheckPermission
                      key={"patient"}
                      permission={"ROLE_KM_USER"}
                    >
                      <div className={classes.board}>
                        <div className={classes.chartTitle}>PATIENT LIST ({returnPersonData ? returnPersonData.totalElements.toLocaleString() : 0}) </div>
                        <div className={classes.chartMargin}>
                          <PatientListTable
                            // ref={this.node}
                            classes={classes}
                            downloadData={downloadData}
                            returnPersonData={returnPersonData}
                            PatientColumns={PatientColumns}
                            handlePagination={this.handlePagination}
                            postSets={this.postSets} />
                        </div>
                      </div>
                    </CheckPermission>
                    <TestChart />

                  </div>
                </div>
                <CustomDialog
                  open={this.state.open}
                  onClickOpen={this.onClickOpen}
                  CustomDialogContent={NewCartSet}
                  type={'Patient'}
                  list={patientData}
                  originUser={originUser}
                />
              </div>
            }
          />
          {/* </PDFExport> */}
        </StickyContainer>

      </>
    );
  }
}

export default withStyles(styles)(DataPage);
