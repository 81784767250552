import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TestTable from "components/atoms/TestTable";
import SearchForm from "components/organisms/SearchForm";
import ApiBtn from "pages/Admin/syncLog/SyncApiButton";

const styles = theme => ({
  chartMargin: {
    // padding: "15px",
    padding: "10px"
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  personTotal: {
    lineHeight: "1.5",
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "11px",
    color: "#3a3a3a"
  },
  person: {
    lineHeight: "normal",
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "14px"
  }
});

const types = [
  {
    value: "type",
    label: "Type"
  },
  {
    value: "title",
    label: "Title"
  },
  {
    value: "email",
    label: "E-mail"
  }
];

class AdminQABoard extends Component {
  state = {
    selected: {},
    open: false
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.parentEl = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    // console.log(this.props.detailType.data, nextProps.detailType.data, this.props.detailType.data.toString() !== nextProps.detailType.data.toString())
    return (
      JSON.stringify(this.props.detailType) !==
        JSON.stringify(nextProps.detailType) ||
      this.state !== nextState ||
      this.props.chartOpen !== nextProps.chartOpen
    );
    // return true;
  }

  handlePagination = s => {
    const { handlePagination, detailType } = this.props;
    handlePagination(s, detailType.title);
  };

  handleSort = ({ order, orderBy }) => {
    console.log(order, orderBy);
  };

  handleClickCheckbox = value => {
    const { tableClick } = this.props;
    tableClick(value.data.id);
  };

  setSelect = selected => {
    this.setState({ selected });
    console.log(this.state.selected);
  };

  render() {
    const {
      detailType,
      classes,
      searchUser,
      isSearch,
      isNotRegistered,
      button
    } = this.props;
    if (detailType.data) {
      return (
        <>
          {isSearch ? (
            <SearchForm
              types={types}
              searchUser={searchUser}
              defaultType={"type"}
            />
          ) : null}
          <div className={classes.chartMargin}>
            <div className={classes.nav}>
              <div className={classes.person}>
                {detailType.title}
                {detailType.isButton && <ApiBtn />}
              </div>

              <div className={classes.person}>{button}</div>
              {isNotRegistered ? null : (
                <div className={classes.personTotal}>
                  ({detailType.data.totalElements} questions registered)
                </div>
              )}
            </div>
            <TestTable
              ref={this.node}
              id={"alllist"}
              columns={detailType.columns}
              data={detailType.data}
              selectRow={{
                mode: "radio",
                handleClickCheckbox: this.handleClickCheckbox,
                hide: true
              }}
              defaultSort={{
                order: "asc",
                orderBy: "geneName",
                handleSort: this.handleSort
              }}
              defaultPagination={{
                page: detailType.search ? detailType.search.page : 0,
                rowsPerPage: detailType.search ? detailType.search.size : 10,
                handlePatination: this.handlePagination
              }}
              headerIcon={true}
              {...this.props}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(AdminQABoard);
