import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "assets/images/checkbox.svg"
import CheckBoxIcon from "assets/images/checkbox_select.svg"
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
const styles = theme => ({
  subject: {
    float: "right"
  },
  checkboxs: {
    padding: "0px!important",
    width: "0.8vw",
    fontSize: "1.2vw !important",
    margin: "2px 5px",
    "& root": {
      fontSize: "1.2vw !important",
    },
  },
  smallCheckboxs: {
    padding: "0px!important",
    width: "0.8vw",
    fontSize: "1.2vw !important",
    margin: "2px 5px 2px 0px",
    "& root": {
      fontSize: "1.2vw !important",
    },
  },
  tableRow: {
    height: "30px",
    '&$hover:hover': {
      backgroundColor: "#efefef"
    }
  },
  checkName: {
    height: "30px",
    backgroundColor: "#ecfffe",
    '&$hover:hover': {
      backgroundColor: "#ecfffe"
    }
  },
  hover: {},
  noboxCell: {
    width: "5%",
    padding: "0px 0px 0px 0px !important",
    opacity: '0.2'
  },
  boxCell: {
    width: "5%",
    padding: "0px 0px 0px 0px !important"
  },
  checkBoxCell: {
    width: "5%",
    padding: "0px 5px 0px 0px !important"
  },
  disablecheckbox: {
    opacity: '0.2'
  },
  textCell: {
    width: "80%",
    padding: "0px",
    fontSize: "12px",
  },
  disabletextCell: {
    width: "80%",
    padding: "0px",
    fontSize: "12px",
    opacity: '0.2'
  },
  subjectCell: {
    width: "10%",
    padding: "0px",
    fontSize: "12px",
    textAlign: 'end',
  },
  subDiv: {
    backgroundColor: '#ececec',
    textAlign: "center",
    padding: '2px',
    fontSize: "10px",
    color: "#434343",
    borderRadius: '10px'
  },
  subjectDiv: {
    backgroundColor: '#ececec',
    textAlign: "center",
    padding: '2px 4px',
    fontSize: "10px",
    color: "#434343",
    borderRadius: '10px',
  },
  disablesubjectCell: {
    width: "10%",
    padding: "0px",
    fontSize: "12px",
    textAlign: 'end',
    opacity: '0.2'
  },
  mainTable: {
    borderTop: "1px solid #dddddd"
  },
  icon: {
    marginLeft: "5px",
    width: '15px',
    height: "15px",
    marginTop: "6px",
    color: "#9d9d9d"
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
  },
  checkboxsize: {
    fontSize: "20px !importnat"
  },
  scrollY: {
    height: "300px",
    overflowY:"scroll",
  },
});

const icon = <img src={CheckBoxOutlineBlankIcon} alt="" />
const checkedIcon = <img src={CheckBoxIcon} alt="" />

class DetailPanel extends Component {

  state = {
    isCheck : false
  }

  // home.cancerTypeData 이 변함에 따라 반영되어야함. delete shouldComponentUpdate by jdy, 2020-01-15
  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false 하면 업데이트를 안함
    
  //   // console.log("DetailPanel : shouldComponentUpdate");

  //   return this.state.isCheck !== nextState.isCheck;

  // }

  static getDerivedStateFromProps(nextProps, prevState) { // props로  받아온 값을 state로 동기화하는 작업 
    const { detail, mainFilterObject } = nextProps;

    // console.log("DetailPanel : getDerivedStateFromProps");
    
    let isCheck = false;

    if (mainFilterObject.detailcancer && mainFilterObject.detailcancer[detail.parentName]) {
      const idx = mainFilterObject.detailcancer[detail.parentName].findIndex(function (item) {
        return item.id === detail.id
      });
      if (idx > -1) {
        isCheck = true;
      }
    }

    if (isCheck != prevState.isCheck) {
      return {
        isCheck: isCheck
      };
    }

    return null;
  }

  cancerCheck = (e) => {
    let { detailClick, detail, parentCheck } = this.props;
    detailClick(e.currentTarget.checked, detail, parentCheck);
    this.handleCheck(!e.currentTarget.checked);
  };

  handleCheck = (isCheck) => {
    this.setState({
      isCheck : isCheck
    });
  };

  render() {
    const { classes, detail, mainFilterObject, disable } = this.props;
    const { isCheck } = this.state;

    // let isCheck = false;

    // if (mainFilterObject.detailcancer && mainFilterObject.detailcancer[detail.parentName]) {
    //   const idx = mainFilterObject.detailcancer[detail.parentName].findIndex(function (item) {
    //     return item.id === detail.id
    //   });
    //   if (idx > -1) {
    //     isCheck = true;
    //   }
    // }

    return (
      <>
        <TableCell className={classes.boxCell} />
        <TableCell className={classes.boxCell} />

        <TableCell padding={"checkbox"} className={disable ? classes.disabletextCell : classes.textCell}>
          <Checkbox
            disabled={disable}
            checked={isCheck}
            className={classes.smallCheckboxs}
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ checked: classes.selectCancerreencolor, disabled: classes.disablecheckbox }}
            value={detail.name}
            onChange={this.cancerCheck}
          />
          {detail.name}
        </TableCell>
        <TableCell className={disable ? classes.disablesubjectCell : classes.subjectCell}>
          <div className={classes.subDiv}>{detail.subject}</div>
        </TableCell>
      </>
    );
  }
}

class CancerPanel extends Component {
  state = {
    isCheck : false,
    disable : false,
    detail: false
  };

  // home.cancerTypeData 이 변함에 따라 반영되어야함. delete shouldComponentUpdate by jdy, 2020-01-15
  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false 하면 업데이트를 안함
    
  //   // console.log("CancerPanel : shouldComponentUpdate");

  //   return this.state.isCheck !== nextState.isCheck 
  //       || this.state.disable !== nextState.disable 
  //       || this.state.detail !== nextState.detail;
  // }

  static getDerivedStateFromProps(nextProps, prevState) { // props로  받아온 값을 state로 동기화하는 작업 
    const { data, state, mainFilterObject } = nextProps;

    // console.log("CancerPanel : getDerivedStateFromProps");

    let isCheck = false;
    let disable = false;

    if (mainFilterObject.cancerList) {
      const idx = mainFilterObject.cancerList.findIndex(function (item) {
        return item.id === data.id
      })
      if (idx > -1) {
        if (mainFilterObject.detailcancer && mainFilterObject.detailcancer[data.name] && mainFilterObject.detailcancer[data.name].length >= data.details.length) {
          isCheck = true;
        }
      }
      disable = (!data.subject || ((state.cancerList ? state.cancerList.length > 0 : false) &&
        (mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length > 0))) && !isCheck;
      
      if (disable && mainFilterObject.detailcancer && mainFilterObject.detailcancer[data.name]) {
        disable = false;
      }

        // isCheck = true;
     
    }

    if (isCheck != prevState.isCheck || disable != prevState.disable) {
      return {
        isCheck: isCheck,
        disable: disable
      };
    }

    return null;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      this.setState({
        detail: false
      });
    }
  }

  onClickArrow = () => {
    const { detail } = this.state;
    this.setState({
      detail: !detail
    });
  };

  cancerCheck = (isCheck, id, name) => {
    let { data, onclickCancer, onclickDetail } = this.props;

    this.handleCheck(!isCheck);
    
    if (isCheck) {
      this.setState({
        detail: false
      });
    }
    for (let i of data.details) {
      onclickDetail(!isCheck, i, false);
    }
    onclickCancer(!isCheck, id, name, true);

  };

  handleCheck = (isCheck) => {
    this.setState({
      isCheck : isCheck
    });
  };

  detailClick = (checked, detail, parentCheck) => {
    const { data, state, onclickDetail, onclickCancer } = this.props;
    onclickDetail(checked, detail, true);
    const idx = state.cancerList.findIndex(function (item) {
      return item.id === data.id
    })
    if (state.detailCancer[data.name] && state.detailCancer[data.name].length) {
      if (idx <= -1) {
        onclickCancer(true, data.id, data.name, false);
      }
    } else {
      onclickCancer(false, data.id, data.name, false);
    }
  };

  render() {
    const { classes, data, state, mainFilterObject } = this.props;
    const { isCheck, disable } = this.state;

    let arrow;
    let detail;

    if (this.state.detail) {
      arrow = < Remove className={classes.icon} />;
      detail = data.details.map((dat, len) => (
        <TableRow key={len} className={isCheck ? classes.checkName : classes.tableRow} hover>
          <DetailPanel
            classes={classes}
            parentCheck={isCheck}
            detail={dat}
            state={state}
            detailClick={this.detailClick}
            mainFilterObject={mainFilterObject}
            disable={disable}
          />
        </TableRow>
      ));
    } else {
      arrow = <Add className={classes.icon} />;
      detail = null;
    }

    return (
      <>
        <TableRow classes={{ hover: classes.hover }}
          className={isCheck ? classes.checkName : classes.tableRow} hover >
          {disable ?
            <TableCell className={classes.noboxCell} >
              {arrow}
            </TableCell> :
            <TableCell className={classes.boxCell} onClick={this.onClickArrow} >
              {arrow}
            </TableCell>
          }
          <TableCell padding={"checkbox"} className={classes.checkBoxCell}>
            <Checkbox
              disabled={disable}
              checked={isCheck}
              className={classes.checkboxs}
              icon={icon}
              checkedIcon={checkedIcon}
              classes={{ checked: classes.selectCancerreencolor, disabled: classes.disablecheckbox }}
              value={data.name}
              onChange={() => this.cancerCheck(isCheck, data.id, data.name)}
            />
          </TableCell>
          <TableCell className={disable ? classes.disabletextCell : classes.textCell}>
            {data.name.replace(" cancer", "").replace(' carcinoma', '').replace(' Cancer', '')}
          </TableCell>
          <TableCell className={disable ? classes.disablesubjectCell : classes.subjectCell}>
            <div className={classes.subjectDiv}>
              {data.subject ? data.subject.toLocaleString() : 0}
            </div>
          </TableCell>

        </TableRow>
        {detail}
      </>
    );
  }
}

class CancerTypeList extends Component {

  onclickCancer = (check, id, name, e) => {
    const { onClickEvent, state, mainFilterObject } = this.props;
    if (check) {
      state.cancerList.push({ id: id, name: name })
    } else {
      const idx = state.cancerList.findIndex(function (item) {
        return item.id === id
      });
      if (idx > -1) {
        state.cancerList.splice(idx, 1);
        delete state.detailCancer[name]
      }
    }
    mainFilterObject.cancerList = state.cancerList
    if (e) {
      onClickEvent();
    }
  };

  onclickDetail = (check, detail, e) => {
    const { mainFilterObject, onClickEvent, state } = this.props;
    if (check) {
      if (state.detailCancer[detail.parentName]) {
        const idx = state.detailCancer[detail.parentName].findIndex(function (item) {
          return item.id === detail.id
        });
        if (idx === -1) {
          state.detailCancer[detail.parentName].push({ id: detail.id, name: detail.name });
        }
      } else {
        state.detailCancer[detail.parentName] = [{ id: detail.id, name: detail.name }];
      }
    } else {
      if (state.detailCancer[detail.parentName]) {
        const idx = state.detailCancer[detail.parentName].findIndex(function (item) {
          return item.id === detail.id
        });
        if (idx > -1) {
          state.detailCancer[detail.parentName].splice(idx, 1);
        }
      }
    }
    if (state.detailCancer[detail.parentName] && !state.detailCancer[detail.parentName].length) {
      delete state.detailCancer[detail.parentName]
    }
    mainFilterObject.detailcancer = state.detailCancer;
    if (e) {
      onClickEvent();
    }
  };

  /* 
	    변경일자:   2020-06-25
	    변경자 이름: 지신복 
	    변경 내용:   화면 display 건수 변경
	    변경 사유:   암종추가에 따른 화면 display 건수 변경                            
	*/      
  render() {
    const { classes, list, state, mainFilterObject } = this.props; // state 는 DataPage의 select 임
    return (
      <div className={classes.scrollY}>
        <Table className={classes.mainTable}>
          <TableBody>
            {list
              ? list.map((data, length) => (
                data.name.length > 0 ? <CancerPanel
                  classes={classes}
                  data={data}
                  key={length}
                  onclickCancer={this.onclickCancer}
                  onclickDetail={this.onclickDetail}
                  state={state}
                  mainFilterObject={mainFilterObject}
                /> : null
              ))
              : null}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(CancerTypeList);
