import React, { Component } from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
// require("highcharts/modules/exporting")(Highcharts);

const basicColorList = [
  "#929db0",
  "#e6e0d1",
  "#869594",
  "#c9ccbf",
  "#84bed3",
  "#dadada",
  "#8d9bab",
  "#bda0a0",
  "#d7d2b1",
  "#bfbfbf",
];

const cancerColorList = {
  "Colon/rectal": "#d7d2b1",
  "Breast": "#bfbfbf",
  "Other": "#929db0",
  "Stomach": "#e6e0d1",
  "Lung": "#869594",
  "Sarcoma": "#c9ccbf",
  "Bile duct": "#84bed3",
  "Ovarian": "#dadada",
  "Head and neck": "#8d9bab",
  "Pancreas": "#bda0a0",

  "Cervix": "#b1d0d7",
  "Endometrial": "#9e9d9d",
  "Liver": "#92b0b0",
  "Melanoma": "#d1d5e6",
  "Multiple primary": "#e7eacb",
  "Prostate": "#cbded9",
  "Renal": "#b3bc9f",
  "Urothelial": "#c6c5c5"
}

class ColumnChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list || this.props.mainFilterObject !== nextProps.mainFilterObject
    // return this.props.TESTDATA !== nextProps.TESTDATA
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }



  render() {
    const { type, list, mainFilterObject } = this.props;
    let returnData = [];
    let name = [];
    let options;
    let titleName;
    let series = [];
    if (list && type === "Cancer") {
      if (mainFilterObject && mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length) {
        for (let data in list.name) {
          series.push({
            name: list.name[data],
            data: list.subject[data],
            color: cancerColorList[list.name[data]]
          })
        }
        name = list.name
        titleName = `Subjects by ${list.id.length} Cancers`;
      } else {
        name = list.name
        series.push({
          name: "cancer",
          data: list.all,
          color: "#c6c6c6"
        });
        /* 
          변경일자:   2020-06-25
          변경자 이름: 지신복 
          변경 내용:   화면 display 건수 변경
          변경 사유:   암종추가에 따른 화면 display 건수 변경                            
        */
        titleName = list.id.length > 17 ? `Subjects by ${list.length} Cancers` : `Subjects by ${list.id.length} Cancers`;
      }
    } else if (list && type === "Gene") {
      if (mainFilterObject && mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length) {
        Object.keys(list.cancers).map((data, len) => {
          series.push(
            {
              name: data,
              data: list.cancers[data],
              color: basicColorList[len]
            }
          )
        });
      } else {
        series = [
          {
            name: "gene",
            data: list.subject,
            color: "#c6c6c6"
          }
        ];
      }
      name = list.id;
      returnData[0] = list.subject[0]
      titleName = list.id.length > 19 ? `Subjects by Top 20 Genes` : `Subjects by ${list.id.length} Genes`;
    } else if (list && (type === "SNV" || type === "CNV" || type === "SV")) {
      if (mainFilterObject && mainFilterObject.detailcancer && Object.keys(mainFilterObject.detailcancer).length) {
        Object.keys(list.cancers).map((data, len) => {
          series.push(
            {
              name: data,
              data: list.cancers[data],
              color: basicColorList[len]
            }
          )
        });
      } else {
        series = [
          {
            name: type,
            data: list.subject,
            color: "#c6c6c6"
          }
        ];
      }
      returnData[0] = list.subject[0]
      switch (type) {
        case 'SNV':
          name = list.snvName
          break;
        case 'CNV':
          name = list.cnvName
          break;
        case 'SV':
          name = list.svName
          break;
        default:
          break;
      }
      titleName = list.id.length > 19 ? `Subjects by Top 20 Variants` : `Subjects by ${list.id.length} Variants`;
    }
    if (list && (list.id.length === 0 || list.totalPages === 0)) {
      titleName = "No Data";
    }

    options = {
      chart: {
        type: "bar",
        marginLeft: 95,
        marginTop: 30,
        height: 200
      },

      title: { text: null },
      subtitle: { text: titleName },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: name,
        labels: {
          autoRotationLimit: 10,
          style: {
            fontSize: "9px"
          }
        },
      },
      yAxis: {
        allowDecimals: false,
        gridLineDashStyle: "longdash",
        gridLineWidth: 0.5,
        tickPixelInterval: 50,
        softMax: returnData[0] || 800,
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          },
          rotation: 0
        },
        title: {
          text: "(Subject)",
          align: "high",
          rotation: 0,
          offset: 0,
          y: -15,
          style: {
            fontSize: "10px",
            fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
            color: "#434343"
          }
        }
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        labelFormatter: function () {
          if (this.name.length > 7) {
            return `${this.name.slice(0, 5)}...`
          }
          else {
            return this.name
          }
        }
      },
      tooltip: {
        shared: true,
        backgroundColor: "#616161",
        formatter: function () {
          if (mainFilterObject && mainFilterObject.cancerList && mainFilterObject.cancerList.length) {
            return this.points.reduce(function (s, point) {
              if (point.y > 0) {
                return s + '<br/><span style="color:' + point.color + '">\u2B24</span><span style="color: #ffffff">' + point.series.name + ': ' +
                  point.y.toLocaleString() + '</span>';
              } else {
                return s;
              }
            }, '<b><div><span style="color: #ffffff;font-weight:bold;font-size:13px !important;">' + this.x + ' (#Subject)</span></div></b>');
          } else {
            return (
              "<b><span style='color: #ffffff;font-weight:bold;font-size:13px !important;'>" +
              this.x +
              "</span></b><br/><span style='color: #ffffff;'>" +
              "Subject: " +
              this.y.toLocaleString() + "</span>"
            )
          }
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        },
        bar: {
          cropThreshold: 1000
        }
      },
      series: series,
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
        key={this.state.fooKey}
      />
    );
  }
}
export default ColumnChart;
