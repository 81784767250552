import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  div: {
    width: "100%"
  },
  tabs: {
    minHeight: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", 
    background: "white",
    width: "100%"
  },
  tab: {
    minWidth: "0",
    minHeight: "0",
    fontSize: "12px",
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    color: "#b4b4b4",
    fontWeight: "400",
    // border: "1px solid #00ff0000",
    borderBottom: "1px solid #c6c6c6",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f4f4f4"
    }
    // "&:nth-last-child(1)": {
    //   // borderRight: "none",
    //   width: "33.4%"
    // }
  },
  label: {
    padding: "0px"
  },
  indicator: {
    display: "none"
  },
  header: {
    boxShadow: "none",
    height: "30px",
    width: "77%"
  },
  selectedTab: {
    background: "#f6f6f6",
    color: "#434343",
    border: "solid 1px #c6c6c6",
    borderBottom: "0px",
    fontWeight: "500"
  },
  spanborder: {
    // minWidth: "100%!important",
    maxWidth: "inherit!important",
    width: "inherit!important",
    height: "30px",
    borderBottom: "1px solid #c6c6c6"
  },
  disabletab: {
    opacity: "0.7!important"
  }
});

class TabHeaderData extends Component {

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.value !== nextProps.value || this.props.tabList !== nextProps.tabList !== this.props.valueData !== nextProps.valueData
    // return true;
  }

  handleChange = (e, value) => {
    const { onChangeTab } = this.props;
    onChangeTab(value);
  };

  render() {
    const {
    
      value,
      classes,
      tabstyle,
      tabscolor,
      seleted,
      valueData,
      POSTQUERY
    } = this.props;

    let {tabList } = this.props;
    let cancerSearch = POSTQUERY.detailCancer;
    let geneSearch = POSTQUERY.majorGene;
    let snvSearch = POSTQUERY.majorSnv;
    let cnvSearch = POSTQUERY.majorCnv;
    let svSearch = POSTQUERY.majorSv;
    let variantTypeSearch = POSTQUERY.variantType;
    let cancerSearchLength = 0;
    let geneSearchLength = 0;
    let variantSearch = 0;
    let variantTypeSearchLength = 0;
    let searchSum = 0;
    if(cancerSearch != undefined){
      cancerSearchLength += cancerSearch.length;
    }
    if(geneSearch != undefined){
      geneSearchLength += geneSearch.length;
    }
    if(snvSearch != undefined){
      variantSearch += snvSearch.length;
    }
    if(cnvSearch != undefined){
      variantSearch += cnvSearch.length;
    }
    if(svSearch != undefined){
      variantSearch += svSearch.length;
    }
    if(variantTypeSearch != undefined){
      variantTypeSearchLength += variantTypeSearch.length;
    }
    searchSum = cancerSearchLength + geneSearchLength + variantSearch + variantTypeSearchLength;
    // console.log("cancer : " + cancerSearchLength);
    // console.log("gene : " + geneSearchLength);
    // console.log("variant : " + variantSearch);
    // console.log("포스트쿼리 : " + JSON.stringify(POSTQUERY));
    
    let valueData10 = valueData && valueData[10] ? valueData[10].value.toLocaleString() : 0;
    let valueData11 = valueData && valueData[11]  ? valueData[11].value.toLocaleString() : 0;
    
    return (
      <>
        <AppBar position="static" className={classes.header}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: `${classes.tabs} ${tabscolor}`,
              flexContainer: classes.div,
              indicator: classes.indicator
            }}
          // variant="fullWidth"
          >
            {/* {tabList.map((t, i) => ( */}
              <Tab
                key='1'
                label={`${tabList[0]} (${valueData ? valueData[2].value : 0})`}
                classes={{
                  root: `${classes.tab} ${tabstyle}`,
                  // labelContainer: classes.label,
                  selected: `${classes.selectedTab} ${seleted}`
                }}
                disableRipple={true}
              />
              <Tab
                key='2'
                label={`${tabList[1]} (${searchSum >  0 ? valueData11 + '/'  : '' } ${valueData ? valueData[0].value.toLocaleString() : 0})`}
                classes={{
                  root: `${classes.tab} ${tabstyle}`,
                  // labelContainer: classes.label,
                  selected: `${classes.selectedTab} ${seleted}`
                }}
                disableRipple={true}
              />
              <Tab
                key='3'
                label={`${tabList[2]} (${ searchSum > 0 ? valueData10 + '/' : '' } ${valueData ? valueData[1].value.toLocaleString() : 0})`}
                classes={{
                  root: `${classes.tab} ${tabstyle}`,
                  // labelContainer: classes.label,
                  selected: `${classes.selectedTab} ${seleted}`
                }}
                disableRipple={true}
              />
            {/* ))} */}
            <Tab
              disabled
              classes={{
                root: `${classes.tab} ${classes.spanborder}`,
                // labelContainer: classes.label,
                selected: classes.selectedTab,
                disabled: classes.disabletab
              }}
              disableRipple={true}
            />
          </Tabs>
        </AppBar>
      </>
    );
  }
}

export default withStyles(styles)(TabHeaderData);
