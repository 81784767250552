import React, { Component } from "react";
import TestTable from "components/atoms/TestTable";
import CartDownLoad from "components/atoms/CartDownLoad";
import TSVDownLoad from "components/atoms/TSVDownLoad";

class PatientListTable extends Component {

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  state = {
    selected: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    return this.props.returnPersonData !== nextProps.returnPersonData || this.state.selected !== nextState.selected
    // return true;
  }

  handleClickCheckbox = value => {
    console.log("handle click check box", value);
  };

  handlePagination = s => {
    const { handlePagination } = this.props
    handlePagination(s, 'patient')
  };

  onClick = e => {
    const { downloadPatient } = this.props
    downloadPatient()
  }

  setSelect = selected => {
    this.setState({ selected: selected });
  };


  onTSVDownload = (tableType) => {

    const { downloadTsvPatient } = this.props
    downloadTsvPatient()
  }
 

  render() {
    const { returnPersonData, PatientColumns, detailType, 
      // , downloadData 
    } = this.props;
    return (
      <>
        {returnPersonData ? (
          <>
            {/* <div style={{ float: 'left', padding: ' 5px 0px', fontSize: "12px" }}>{returnPersonData.totalElements} PATIENT SET</div> */}
            {detailType ? 
            <TSVDownLoad tableType={detailType.type} onClickTSV={this.onTSVDownload} /> 
             : ""} 

            <CartDownLoad onClick={this.onClick} />
            <TestTable
              ref={this.node}
              id={"alllist"}
              columns={PatientColumns}
              data={returnPersonData}
              defaultSort={{
                order: "asc",
                orderBy: "personSourceValue",
                handleSort: this.handleSort
              }}
              defaultPagination={{
                page: 0,
                rowsPerPage: 20,
                handlePatination: this.handlePagination
              }}
              {...this.props}
            />
          </>
        ) : null}
      </>
    )
  }
}


export default PatientListTable;
