import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  layout_border: {
    border: "1px solid #dedede"
  },
  layout: {
    // width: "100%",
    // height: "100%",
    backgroundColor: "#fff"
  },
  head_border: {
    borderBottom: "1px solid #dedede"
  },
  head: {
    height: "1.2rem",
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  body: {
    // width: "100%",
    // height: `100%`,
    // FIXME: style fix
    padding: 10
  },
  title: {
    color: "#3a3a3a",
    fontWeight: 600,
    fontSize: 12
  },
  size: {
    color: "#4572ef"
  }
});

function TableTemplate({
  title,
  size,
  children,
  borderHide,
  bodyPadding,
  bodyStyle,
  layoutStyle,
  headStyle,
  top
}) {
  const classes = useStyles();

  return (
    <div
      style={layoutStyle}
      className={`${classes.layout} ${!borderHide && classes.layout_border}`}
    >
      <div
        className={`${classes.head} ${!borderHide && classes.head_border}`}
        style={headStyle}
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.size}>{size}</div>
      </div>
      {top}
      <div
        style={bodyStyle}
        className={bodyPadding !== "none" ? classes.body : ""}
      >
        {children}
      </div>
    </div>
  );
}

export default TableTemplate;
