import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  td: {
    fontSize: "1.1rem",
    border: "1px solid #c0bebe",
    color: "#6a6a6a",
    padding: "10px 10px",
    background: "#fff",
    wordBreak: "break-all"
  }
});

class CustomCell extends Component {
  render() {
    const {
      data,
      index,
      page,
      rowsPerPage,
      column: { column, key, align },
      classes
    } = this.props;

    const no = page * rowsPerPage + index + 1;

    switch (column) {
      case "No.":
        return <TableCell className={classes.td}>{no}</TableCell>;
      case "Person ID":
        return (
          <TableCell className={classes.td}>
            <a
              href={`http://163.152.147.40/match-portal/patientDetail?sID=${
                data[key]
              }`}
              style={{ cursor: "Pointer" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data[key]}
            </a>
          </TableCell>
        );
      default:
        return (
          <TableCell className={classes.td} align={align}>
            {data[key]}
          </TableCell>
        );
    }
  }
}

export default withStyles(styles)(CustomCell);
