import React, { Component } from "react";
// import CustomSelectBox from "components/atoms/ui/CustomSelectBox";
import CustomBtn from "components/atoms/CustomBtn";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  input: {
    maxWidth: "300px",
    marginRight: "5px",
    fontSize: "12px",
    width: "250px",
    height: "20px"
  },
  selectIcon: {
    position: "absolute",
    background: "linear-gradient(#f1f1f1, #dfdfdf)",
    borderLeft: "1px solid #d4d4d5",
    top: 1,
    right: 1,
    bottom: 1,
    width: "20px",
    height: "20px"
  },
  root: {
    width: "100px"
  }
})

const Input = withStyles(theme => ({
  input: {
    position: "relative",
    border: "1px solid #d4d4d5",
    backgroundColor: "#ffffff",
    fontSize: "11px",
    fontWeight: 400,
    padding: "3px 0px 3px 3px",
    "&:focus": {
      backgroundColor: "#ffffff"
    }
  }
}))(InputBase);


class CustomSelectBox extends Component {

  componentDidMount() {
  }


  render() {
    const { classes, data, onChange, defaultValue } = this.props
    return (
      data.length > 0 && (
        <Select
          native
          onChange={onChange}
          value={defaultValue}
          input={<Input />}
          classes={{
            icon: classes.selectIcon,
            select: classes.select,
            root: classes.root
          }}
        >
          {data.map(({ value, label, url }, i) => {
            return (
              <option key={i} value={url || value} title={label}>
                {label}
              </option>
            );
          })}
        </Select>
      )
    );
  }
}


class SearchUserForm extends Component {

  state = {
    type: "name",
    keyword: ""
  };

  componentDidMount() {
    const { defaultType } = this.props;
    this.setState({
      type: defaultType
    })
  }

  onSubmit = (e) => {
    const { type, keyword } = this.state
    e.preventDefault();
    // console.log(type, keyword);
    this.props.searchUser(type, keyword)
  };

  onChange = (e) => {
    this.setState({
      type: e.currentTarget.value
    })
  };

  onInputChange = (e) => {
    this.setState({
      keyword: e.currentTarget.value
    })
  };


  render() {
    const { classes, types } = this.props
    const { type, keyword } = this.state
    return (
      <form
        onSubmit={this.onSubmit}
        style={{
          display: "flex",
          width: "inherit",
          height: 40,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#d8d8d8",
          margin: "10px"
        }}
      >
        <span style={{ fontSize: "12px" }}>Search</span>
        <span style={{ padding: "0px 5px" }}>
          <CustomSelectBox data={types} onChange={this.onChange} classes={classes} defaultValue={type} />
        </span>
        <input type={"text"} value={keyword} onChange={this.onInputChange} className={classes.input} />
        <CustomBtn text={"Search"} stylebtn={{ width: 150 }} onClick={this.onSubmit} />
      </form>
    )
  }
}

export default withStyles(styles)(SearchUserForm);
