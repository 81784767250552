import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import CustomBtn from "components/atoms/CustomBtn";
import Input from '@material-ui/core/Input';

const styles = theme => ({
  main: {
    height: "calc(100% - 52px)",
    border: "solid 1px #dbdbdb",
    backgroundColor: "#ffffff",
    margin: "10px 5px 0px 10px",
    padding: "10px"
  },
  viewtitle: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#3a3a3a",
    marginTop: "10px",
    paddingLeft: "2px",
    height: "25px"
  },
  titleDiv: {
    height: "35px",
    backgroundColor: "#f5f5f5",
    borderTop: "solid 1px #c8c8c8",
    borderBottom: "solid 1px #c8c8c8",
    marginTop: "10px",
    fontSize: "12px",
    color: "#636262"
  },
  mainView: {
    fontSize: "12px",
    color: "#636262",
    padding: "10px 0px"
  },
  answerDiv: {
    // height: "145px",
    border: "solid 1px #c8c8c8",
    backgroundColor: "#f1f1f1",
    padding: "15px 10px",
    marginTop: "10px"
  },
  description: {
    height: "140px",
    width: "100%",
    border: "solid 1px #c8c8c8",
    backgroundColor: "#ffffff",
  },
  textInput: {
    fontSize: "12px",
    padding: "0px 15px",
    color: "#636262",

  },
  openBtn: {
    width: "120px",
    height: "25px"
  },
  goBtn: {
    width: "105px",
    height: "25px",
    border: "solid 1px #cdcdcd",
    color: "#d3d3d3",
    backgroundColor: "#f1f1f1",
    '&:hover': {
      backgroundColor: "#00c8a3",
      color: "#ffffff"
    }
  }
})

/* 
    변경일자:   2020-06-16
    변경자 이름: 지시복 
    변경 내용:  Input 컴포넌트 추가 
    변경 사유:  질문, 답변에 enter키 표현이 가능한 Input 컴포넌트 추가                               
*/
class QuestionAnswer extends Component {
  render() {
    const { classes,
      answer,
      open,
      main,
      mainChange,
      onClickOpen,
      postCommit } = this.props
    if (open) {
      return (
        <div className={classes.answerDiv}>
          <Input
            className={classes.description}
            type="text"
            disableUnderline={true}
            placeholder="Please fill out the answer."
            value={main}
            onChange={mainChange}
            multiline={true}
            rows={7}
            classes={{ input: classes.textInput }}
          />
          <div style={{ textAlign: "right", paddingTop: "10px" }}>
            <CustomBtn text={"CANCLE"} onClick={onClickOpen} stylebtn={classes.goBtn} />
            <span style={{ paddingLeft: "4px" }}></span>
            <CustomBtn text={"CONFIRM"} onClick={postCommit} stylebtn={classes.goBtn} />
          </div>
        </div>
      )
    } else if (answer.length) {
      return (
        <div style={{ display: "flex", borderBottom: "solid 1px #c8c8c8" }}>
          <div style={{ width: "10%", fontSize: "12px", color: "#636262", padding: "10px 5px 10px 15px", backgroundColor: "#f5f5f5", display: "flex", alignItems: "center" }}>
            Answer
          </div>
          <div style={{ width: "90%", fontSize: "12px", color: "#636262", padding: "10px 0px 10px 10px" }}>
            <Input
              className={classes.description}
              type="text"
              placeholder="Please fill out the answer."
              value={answer[0].comment}
              onChange={mainChange}
              multiline={true}
              disabled = {true}
              rows={7}
              classes={{ input: classes.textInput }}
            />
          </div>
        </div>
      )
    } else {
      return (
        null
      )
    }

  }
}

@inject("system", "comm")
@observer
class Answer extends Component {
  state = {
    open: false,
    main: ""
  }
  async componentDidMount() {
    const { system } = this.props
    await system.getQuestion(`?id=${this.props.match.params.id}`)
    const { system: { details } } = this.props
    if (details) {
      await system.getQuestionAnswer(`?qaId=${details.id}`)
    }
  }

  mainChange = (e) => {
    this.setState({
      main: e.target.value
    });
  }

  onClickOpen = () => {
    const { system: { questionAnswer } } = this.props;
    if (questionAnswer.length) {
      this.setState({
        main: questionAnswer[0].comment
      });
    } else {
      this.setState({
        main: ""
      });
    }
    this.setState({
      open: !this.state.open
    });
  }

  postCommit = async () => {
    const { match, system, system: { questionAnswer } } = this.props
    const { main } = this.state
    let query = {}
    let message
    if (questionAnswer.length) {
      query = {
        comment: main,
        id: questionAnswer[0].id
      }
      if (main !== "") {
        message = await system.putComment(query)
        if (message) {
          alert("Changed.")
        }
      } else {
        alert("You need to write.")
      }

    } else {
      query = {
        comment: main,
        qaId: match.params.id
      }
      if (main !== "") {
        message = await system.postComment(query)
        if (message) {
          alert("Registered.")
        }
      }
      else {
        alert("You need to write.")
      }
    }
    this.setState({
      open: false
    });
    await system.getQuestion(`?id=${match.params.id}`)
    const { system: { details } } = this.props
    if (details) {
      await system.getQuestionAnswer(`?qaId=${details.id}`)
    }
  }

  backPage = (e) => {
    const { history } = this.props
    history.push(`/admin/qaboard`)
  }

  render() {
    const { classes, system: { details, questionAnswer } } = this.props;
    const { open, main } = this.state
    return (
      <div className={classes.main}>
        <div className={classes.viewtitle}>Q&A BOARD
          <div style={{ float: "right" }}><CustomBtn text={"Return to List"} onClick={this.backPage} stylebtn={classes.openBtn} /></div>
        </div>
        <div className={classes.titleDiv}>
          <div style={{ float: "left", padding: "10px" }}>{details.type ? `[ ${details.type} ] ${details.title}` : ""}</div>
          <div style={{ float: "right", padding: "10px" }}>{details.createdDateTime ? details.createdDateTime.slice(0, 10) : ""}</div>
        </div>
        <div className={classes.mainView}> 
            <Input
              className={classes.description}
              type="text"
              disabled
              placeholder="Please fill out the answer."
              value= {details.contents}
              multiline={true}
              rows={7}
              classes={{ input: classes.textInput }}
            />
       </div>
        <div style={{ textAlign: "right", paddingBottom: "10px", borderBottom: "solid 1px #c8c8c8" }}>
          {questionAnswer.length ?
            <div style={{ textAlign: "right" }}>
              <CustomBtn text={"EDIT"} onClick={this.onClickOpen} stylebtn={classes.openBtn} />
            </div>
            : <CustomBtn text={"REPLY"} onClick={this.onClickOpen} stylebtn={classes.openBtn} />}
        </div>

        <QuestionAnswer classes={classes}
          answer={questionAnswer}
          open={open}
          main={main}
          mainChange={this.mainChange}
          onClickOpen={this.onClickOpen}
          postCommit={this.postCommit}
        />

      </div>

    );
  }
}

export default withStyles(styles)(Answer);;
