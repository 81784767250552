import React from "react";
import { computed } from "mobx";
// import EditRegisterUser from "pages/Admin/accountManage/EditRegisterUser";
// import DeleteUser from "pages/Admin/accountManage/DeleteUser";
import StatusSelect from "pages/Admin/accountManage/StatusSelect";
import RoleChange from "pages/Admin/accountManage/RoleChange";
// import DeleteUser from "pages/admin/DeleteUser";
class UserModel {
  constructor(d, i) {
    this.id = d.id
    this.name = `${d.firstName} ${d.lastName}`;
    this.email = d.email;
    this.institute = d.institute;

    // 2021-06-24 delete 1 line, add 5 lines by jdy
    //this.authorities = d.authorities[0].replace("ROLE_", "");
    if (d.authorities.length > 0) {
      this.authorities = d.authorities[0].replace("ROLE_", "");
    } else {
      this.authorities = "";
    }
    
    this.activated = d.activated;
    this.locked = d.locked
    // this.activate = d.activated ? "ACTIVE" : "DEACTIVE";
    this.code = "";
  }

  @computed
  get status() {
    return (
      <RoleChange {...this} />
    );
  }

  @computed
  get activate() {
    return (
      <StatusSelect {...this} />
    );
  }


  // @computed
  // get control() {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <EditRegisterUser {...this}>Edit</EditRegisterUser>
  //       <DeleteUser {...this}>Delete</DeleteUser>
  //     </div>
  //   );
  // }


}

export default UserModel;
