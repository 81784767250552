import React, { useMemo } from "react";
import CustomExcute from "./ui/CustomExcute";
import CustomLink from "./ui/CustomLink";
import CustomInput from "./ui/CustomInput";
import CustomMultiCell from "./ui/CustomMultiCell";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  body: {
    fontSize: "12px",
    wordBreak: "break-all",
    padding: "5px 5px",
    display: "inline-block"
  }
});

const dataFormat = str => {
  let retStr = str;
  // console.log(typeof str)
  if (typeof str === "number") {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return Number(retStr)
      .toString()
      .replace(regexp, ",");
  } else if (String(new Date(str)) !== 'Invalid Date') {
    return String(new Date(str).toISOString()).slice(0, 10)
  }

  if (typeof str === "boolean") {
    return String(retStr);
  }

  if (typeof str === "string" && str.length > 50) {
    return `${str.slice(0, 50)}...`;
  }

  if (!str) {
    return "-";
  }

  return retStr;
};

// TODO: rendering
function CustomCellContent(props) {
  const {
    d,
    detail,
    property,
    options,
    options: { link, isEditable, withCellExcute },
    handleLink
  } = props;

  const value = detail ? detail[property] : d[property];
  let children = value;

  const classes = useStyles();

  const RComponent = useMemo(() => {
    const make = Component => props => {
      if (!Array.isArray(children)) {
        return (
          <Component {...props} {...options} value={children}>
            {dataFormat(children)}
          </Component>
        );
      }

      return children.map((value, i) => (
        <CustomMultiCell lastNode={children.length - 1 === i}>
          <Component key={i} {...props} {...options} value={value}>
            {dataFormat(value)}
          </Component>
        </CustomMultiCell>
      ));
    };

    const defaultCell = ({ children }) => {
      return (
        <div className={classes.body} title={value}>
          {children}
        </div>
      );
    };

    let component = make(defaultCell)();

    if (withCellExcute) {
      component = make(CustomExcute)();
    }

    if (link.isLinked) {
      component = make(CustomLink)(props);
    }

    if (isEditable) component = make(CustomInput)(props);

    return component;
  }, [
      value,
      isEditable,
      link.isLinked,
      withCellExcute,
      classes.body,
      children,
      options
    ]);

  return RComponent;
}

export default React.memo(CustomCellContent);
