import dateFormat from "dateformat";

class UserModel {
  constructor(d, i) {
    this.id = d.id
    this.principal = d.principal;
    this.auditEventDate = dateFormat(d.auditEventDate, "yyyy-mm-dd  HH:MM:ss");
    this.auditEventType = d.auditEventType;
    this.os = d.os;
    this.browser = d.browser;
    this.ip = d.ip;
  }

}

export default UserModel;
