import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import queryString from "query-string";
import AdminQABoard from "components/organisms/AdminQABoard";
import CustomBtn from "components/atoms/CustomBtn";
import Input from '@material-ui/core/Input';

const QAColumns = [
  { column: "No.", property: "idx", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 50 } } },
  { column: "제목", property: "title", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 700 } } },
  { column: "E-MAIL", property: "email", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "시간", property: "createdDateTime", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "STATUS", property: "userstatus", align: "center", sort: false, options: { link: { isLinked: false }, isEditable: false, }, style: { head: { width: 100 } } },
];

const styles = theme => ({
  main: {
    height: "calc(100% - 62px)",
    border: "solid 1px #dbdbdb",
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "20px"
  },
  title: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#3a3a3a"
  },
  tableHeader: {
    border: "solid 1px #dedede",
    backgroundColor: "#f3f3f3",
    fontSize: "11px",
    color: "#3a3a3a"
  },
  headerdiv: {
    padding: "4px 0px",
    textAlign: "center"
  },
  viewtitle: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#3a3a3a",
    marginTop: "10px",
    paddingLeft: "2px"
  },
  titleDiv: {
    height: "35px",
    backgroundColor: "#f5f5f5",
    borderTop: "solid 1px #c8c8c8",
    borderBottom: "solid 1px #c8c8c8",
    marginTop: "10px",
    fontSize: "12px",
    color: "#636262"
  },
  mainView: {
    fontSize: "12px",
    color: "#636262",
    padding: "10px 0px"
  },
  answerDiv: {
    // height: "145px",
    border: "solid 1px #c8c8c8",
    backgroundColor: "#f1f1f1",
    padding: "15px 10px"
  },
  description: {
    height: "140px",
    width: "100%",
    border: "solid 1px #c8c8c8",
    backgroundColor: "#ffffff",
  },
  textInput: {
    fontSize: "12px",
    padding: "0px 15px",
    color: "#636262",

  },
})

/* 
    변경일자:   2020-06-16
    변경자 이름: 지시복 
    변경 내용:  Input 컴포넌트 추가 
    변경 사유:  질문, 답변에 enter키 표현이 가능한 Input 컴포넌트 추가                               
*/
class QuestionAnswer extends Component {
  render() {
    const { classes,
      answer,
      onClickOpen,
      details
    } = this.props
    if (details.contents) {
      return (
        <>
          <div className={classes.viewtitle}>MY Q&A BOARD</div>
          <div className={classes.titleDiv}>
            <div style={{ float: "left", padding: "10px" }}>{`[ ${details.type} ] ${details.title}`}</div>
            {/* <div style={{ float: "right", padding: "10px" }}>{ details.createdDateTime.slice(0, 10)} </div> */}
          </div>
          <div className={classes.mainView}> 
            <Input
              className={classes.description}
              type="text"
              disabled
              placeholder="Please fill out the answer."
              value= {details.contents}
              multiline={true}
              rows={7}
              classes={{ input: classes.textInput }}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <CustomBtn text={"Back"} onClick={onClickOpen} stylebtn={classes.openBtn} />
          </div>
          <div style={{ textAlign: "right", paddingBottom: "10px", borderBottom: "solid 1px #c8c8c8" }} />
          {answer.length ? <div style={{ display: "flex", borderBottom: "solid 1px #c8c8c8" }}>
            <div style={{ width: "10%", fontSize: "12px", color: "#636262", padding: "10px 5px 10px 15px", backgroundColor: "#f5f5f5" }}>
              Admin Answers
          </div>
            <div style={{ width: "90%", fontSize: "12px", color: "#636262", padding: "10px 0px 10px 10px" }}>
              <Input
                className={classes.description}
                type="text"
                placeholder="Please fill out the answer."
                value={answer[0].comment}
                multiline={true}
                disabled = {true}
                rows={7}
                classes={{ input: classes.textInput }}
              />
            </div>
          </div> :
            null}
        </>
      )
    } else {
      return (
        null
      )
    }

  }
}

@inject("system", "comm")
@observer
class QABoard extends Component {

  async componentDidMount() {
    const { system, comm: { user } } = this.props;
    if (user) {
      system.getQAList("/user", `?email=${user.email}&size=10`);
      if (this.props.match.params && this.props.match.params.id) {
        await system.getQuestion(`?id=${this.props.match.params.id}`)
        const { system: { details } } = this.props
        if (details) {
          await system.getQuestionAnswer(`?qaId=${details.id}`)
        }
      }
    }
  }

  componentDidUpdate() {
    const { system, system: { qaList }, comm: { user } } = this.props;
    if (user && !qaList.numberOfElements) {
      if (qaList.numberOfElements !== 0) {
        system.getQAList("/user", `?email=${user.email}&size=10`);
      }
    }
  }

  tableClick = async (e) => {
    const { system, history } = this.props
    history.push(`/my-page/qaboard/${e}`)
    await system.getQuestion(`?id=${e}`)
    const { system: { details } } = this.props
    if (details) {
      await system.getQuestionAnswer(`?qaId=${details.id}`)
    }
  }

  handlePagination = (s, target) => {
    const { system, comm: { user } } = this.props;
    let query = {
      page: s.page,
      size: s.rowsPerPage,
      email: user.email
    };
    const queryList = queryString.stringify(query);
    system.getQAList("/user", `?${queryList}`);
  };

  onClickOpen = (e) => {
    const { history } = this.props
    history.push(`/my-page/qaboard`)

  }

  render() {
    const { classes, system: { qaList, details, questionAnswer } } = this.props;
    let accessLogType = { title: "MY Q&A BOARD", columns: QAColumns, data: qaList }
    if (this.props.match.params && this.props.match.params.id) {
      return (
        <div className={classes.main}>
          <QuestionAnswer
            classes={classes}
            answer={questionAnswer}
            details={details}
            onClickOpen={this.onClickOpen}
          />
        </div>
      )
    } else {
      return (
        <div className={classes.main}>
          {qaList ? <AdminQABoard detailType={accessLogType} handlePagination={this.handlePagination} tableClick={this.tableClick} /> : null}
        </div>
      );
    }
  }
}

export default withStyles(styles)(QABoard);;
