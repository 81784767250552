import React, { Component } from "react";

export default () => {
  const DataContext = React.createContext();

  class DataProvider extends Component {
    state = {
      data: this.props.data.content,
      rowCount: this.props.data.totalElements
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps !== prevState)
        return {
          data: nextProps.data.content || [],
          rowCount: nextProps.data.totalElements || 0
        };

      return null;
    }

    actions = {
      getData: props => {
        // console.log("get data", props);
        return this.state.data;
      },
      getRowCount: props => {
        return this.state.rowCount;
      }
    };

    render() {
      const { state, actions } = this;
      return (
        <DataContext.Provider value={{ state, actions }}>
          {this.props.children}
        </DataContext.Provider>
      );
    }
  }
  return {
    Provider: DataProvider,
    Consumer: DataContext.Consumer,
    Context: DataContext
  };
};
