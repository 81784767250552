import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import { observer, inject } from "mobx-react";
import Test from "./index.js";

const styles = theme => ({
  div: {
    padding: "20px"
  },
  maintitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#4a4a4a",
    display: "flex"
  },
  smalltitle: {
    paddingTop: "8px",
    fontSize: "15px",
    textAlign: "center",
    color: "#4a4a4a",
    paddingBottom: "50px",
    display: "flex"
  },
})
class DataPage extends Component {
  render() {
    const { classes } = this.props
    return (
      <>
        {/* <button onclick={this.removeCleanDonors}>Remove Clean Donors</button>
        <button onclick={this.toggleGridLines}>Grid</button>
        <button onclick={this.toggleCrosshair}>Crosshair Mode</button>
        <input type="text" size="5" id="width-resize" />
        <input type="text" size="5" id="height-resize" />
        <button onclick={this.resize}>Resize</button> */}
        <div className={classes.maintitle}>OncoGrid</div>
        <div className={classes.smalltitle}>
          {/* Set Operation analyzes the relationship between clinical data sets
          selected by the user. */}
        </div>
        <div className={classes.div}>
          <Test />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(DataPage);
