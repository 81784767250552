import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import VennDiagramChart from "components/atoms/VennDiagramChart";
import Link from "@material-ui/core/Link";
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: "15px", paddingBottom: "2px" }} />
const checkedIcon = <CheckBoxIcon style={{ fontSize: "15px", paddingBottom: "2px" }} />

const styles = theme => ({
  vennpadding: {
    padding: "0px 15px"
  },
  vennhead: {
    padding: "15px 0px",
  },
  vennTitle: {
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#4a4a4a'
  },
  vennsubTitle: {
    fontSize: '10px',
    textAlign: 'center',
    color: '#3a3a3a'
  },
  venntable: {
    paddingTop: "15px"
  },
  querytable: {
    width: '100%',
    height: '25px',
    display: "flex",
    border: 'solid 1px #dedede',
    backgroundColor: ' #f3f3f3',
    textAlign: "center",
    fontSize: "12px",
    marginTop: "3px",
    fontWeight: 'bold'
  },
  querylistmain: {
    width: '100%',
    height: '25px',
    display: "flex",
    border: 'solid 1px #dedede',
    backgroundColor: ' #ffffff',
    textAlign: "center",
    fontSize: "12px",
    color: "#3a3a3a",
    cursor: 'pointer',
  },
  querylistmaincheck: {
    width: '100%',
    height: '25px',
    display: "flex",
    border: 'solid 1px #dedede',
    backgroundColor: ' #ecfffe',
    textAlign: "center",
    fontSize: "12px",
    color: "#3a3a3a",
    cursor: 'pointer',
  },
  alias: {
    lineHeight: "2.5",
    width: "20%",
  },
  aliasSubject: {
    lineHeight: "2.5",
    width: "20%",
    paddingRight: "5px"
  },
  numalias: {
    lineHeight: "2.5",
    width: "20%",
    textAlign: "right",
    paddingRight: "5px"
  },
  aliasquery: {
    lineHeight: "2.5",
    width: "60%",
    borderLeft: "solid 1px #dedede",
    borderRight: "solid 1px #dedede"
  },
  setheader: {
    lineHeight: "2.5",
    width: "10%",
    borderRight: "solid 1px #dedede",
  },
  forheader: {
    lineHeight: "2.5",
    width: "60%",
    borderRight: "solid 1px #dedede",
  },
  formula: {
    lineHeight: "2.5",
    width: "60%",
    borderRight: "solid 1px #dedede",
    fontStyle: 'italic'
  },
  set: {
    lineHeight: "2.5",
    width: "10%",
    borderRight: "solid 1px #dedede",
  },
  setsubject: {
    lineHeight: "2.5",
    width: "30%",
  },
  subject: {
    lineHeight: "2.5",
    width: "30%",
    textAlign: "right",
  },
  test: {
    width: "380px",
    margin: "auto"
  },
  subjectPadding: {
    paddingRight: "5px"
  },
  tabletitle: {
    fontSize: "14px"
  },
  absolutName: {
    width: '410px',
    zIndex: '10',
    position: 'absolute',
    paddingTop: "20px"
  },
  allSelect: {
    fontSize: "10px"
  },
  selectLine: {
    textAlign: "center",
    paddingTop: "5px"
  },
  selectCancerreencolor: {
    color: "#26a1a4 !important",
  },
  boxpadding: {
    padding: "3px"
  }
});
const TRIPLE = [{ name: "( S1 ∪ S2 ∪ S3 )", key: "unionAAndBAndC", id: "unionAAndBAndCSerial", set: "U" },
{ name: "( S1 ∩ S2 ∩ S3 )", key: 'intersectionABC', id: "intersectionABCSerial", set: "A" },
{ name: "( S1 ∩ S2 ) - ( S3 )", key: 'intersectionAAndB', id: "intersectionAAndBSerial", set: "B" },
{ name: "( S2 ∩ S3 ) - ( S1 )", key: "intersectionBAndC", id: "intersectionBAndCSerial", set: "C" },
{ name: "( S1 ∩ S3 ) - ( S2 )", key: "intersectionAAndC", id: "intersectionAAndCSerial", set: "D" },
{ name: "( S1 ) - ( S2 ∪ S3 )", key: 'differenceAAndBC', id: "differenceAAndBCSerial", set: "E" },
{ name: "( S2 ) - ( S1 ∪ S3 )", key: "differenceBAndAC", id: "differenceBAndACSerial", set: "F" },
{ name: "( S3 ) - ( S1 ∪ S2 )", key: "differenceCAndAB", id: "differenceCAndABSerial", set: "G" }
]
const DOUBLE = [{ name: "( S1 ∪ S2 )", key: "unionAAndBAndC", id: "unionAAndBAndCSerial", set: "U" },
{ name: "( S1 ∩ S2 )", key: "intersection", id: "intersectionSerial", set: "A" },
{ name: "( S1 - S2 )", key: "differenceBAndA", id: "differenceBAndASerial", set: "B" },
{ name: '( S2 - S1 )', key: "differenceAAndB", id: "differenceAAndBSerial", set: "C" }
]
class VennTable extends Component {

  runSubject = (e) => {
    const { onClick } = this.props
    onClick(e)
  }

  render() {
    const { operationSet, isDouble, setListData, classes, select } = this.props;
    let datalist = isDouble ? ['S1', 'S2'] : ['S1', 'S2', 'S3']
    let queryListArray = []
    let setListArray = []
    for (let i of datalist) {
      let name = setListData[i] ? setListData[i].title : ''
      queryListArray.push(
        <div key={i} className={classes.querylistmain}>
          <div className={classes.alias}>{i}</div>
          {name.length > 25 ?
            <Tooltip title={name} placement="top">
              <div className={classes.aliasquery}>
                {name.slice(0, 25)}...
              </div>
            </Tooltip>
            : <div className={classes.aliasquery}>{name}</div>}
          <div className={classes.numalias}>{setListData[i] ? setListData[i].setsPersonNum.toLocaleString() : 0}</div>
        </div>
      )
    }
    if (isDouble) {
      for (let i of DOUBLE) {
        let IsCheck = i.id === select
        setListArray.push(
          <div key={i.name} className={IsCheck ? classes.querylistmaincheck : classes.querylistmain} onClick={() => this.runSubject({ target: { name: i.id, value: operationSet ? operationSet[i.id] : null } })}>
            <div className={classes.set}>{i.set}</div>
            <div className={classes.formula}>{i.name}</div>
            <div className={classes.subject}>
              <Link className={classes.subjectPadding} component="button" name={i.id} value={operationSet ? operationSet[i.id] : null} onClick={this.runSubject}>
                {operationSet && operationSet[i.key] ? operationSet[i.key].toLocaleString() : 0}
              </Link>
            </div>
          </div>
        )
      }
    } else {
      for (let i of TRIPLE) {
        let IsCheck = i.id === select
        setListArray.push(
          <div key={i.name} className={IsCheck ? classes.querylistmaincheck : classes.querylistmain} onClick={() => this.runSubject({ target: { name: i.id, value: operationSet ? operationSet[i.id] : null } })}>
            <div className={classes.set}>{i.set}</div>
            <div className={classes.formula}>{i.name}</div>
            <div className={classes.subject}>
              <Link className={classes.subjectPadding} component="button" name={i.id} value={operationSet ? operationSet[i.id] : null} onClick={this.runSubject}>
                {operationSet && operationSet[i.key] ? operationSet[i.key].toLocaleString() : 0}
              </Link>
            </div>
          </div>
        )
      }
    }
    return (
      <div className={classes.vennpadding}>
        {/* <div className={classes.vennhead}>
          <div className={classes.vennTitle}>Venn Diagram</div>
          <div className={classes.vennsubTitle}>Click on the areas of the Venn diagram to include them in your result set.</div>
        </div> */}
        <div className={classes.selectLine}>
          <div className={classes.allSelect}>All select<Checkbox
            icon={icon}
            checked={select === "unionAAndBAndCSerial"}
            checkedIcon={checkedIcon}
            classes={{ root: classes.boxpadding, checked: classes.selectCancerreencolor }}
            onClick={() => this.runSubject(
              select === "unionAAndBAndCSerial" ? isDouble ? { target: { name: 'intersectionSerial', value: operationSet ? operationSet['intersectionSerial'] : null } } :
                { target: { name: 'intersectionABCSerial', value: operationSet ? operationSet['intersectionABCSerial'] : null } } :
                { target: { name: 'unionAAndBAndCSerial', value: operationSet ? operationSet['unionAAndBAndCSerial'] : null } }
            )}
          /></div>
        </div>
        <div className={classes.absolutName}>
          <div style={isDouble ? { height: '20px', padding: "0px 40px" } : { height: '20px', padding: "0px 10px" }}>
            <div style={{ float: 'left' }}>
              <span>
                <img src="https://latex.codecogs.com/gif.latex?S_{1}" alt='' title="S_{1}" />
              </span>
            </div>
            <div style={{ float: 'right' }}>
              <span>
                <img src="https://latex.codecogs.com/gif.latex?S_{2}" alt='' title="S_{2}" />
              </span>
            </div>
          </div>
          <div style={isDouble ? { height: '15px', padding: "0px 30px", fontSize: "12px" } : { height: '15px', fontSize: "12px" }}>
            <div style={{ float: 'left' }}><span>({setListData['S1'] ? setListData['S1'].setsPersonNum.toLocaleString() : 0})</span></div>
            <div style={{ float: 'right' }}><span>({setListData['S2'] ? setListData['S2'].setsPersonNum.toLocaleString() : 0})</span></div>
          </div>
        </div>
        <div className={classes.test}>
          <VennDiagramChart operationSet={operationSet} isDouble={isDouble} select={select} runSubject={this.runSubject} />
        </div>
        {isDouble ? null :
          <div style={{ textAlign: 'center', fontSize: "12px" }}>
            <div>
              <img src="https://latex.codecogs.com/gif.latex?S_{3}" alt='' title="S_{3}" />
            </div>
            <div>({setListData['S3'] ? setListData['S3'].setsPersonNum.toLocaleString() : 0})</div>
          </div>}
        <div >
          <div className={classes.tabletitle}>Query Set List</div>
          <div className={classes.querytable}>
            <div className={classes.alias}>Alias</div>
            <div className={classes.aliasquery}>Title</div>
            <div className={classes.aliasSubject}>Subjects</div>
          </div>
          {queryListArray}
        </div>
        <div className={classes.venntable}>
          <div className={classes.tabletitle}>Set List</div>
        </div>
        <div className={classes.querytable}>
          <div className={classes.setheader}>Set</div>
          <div className={classes.forheader}>Formula</div>
          <div className={classes.setsubject}>Subjects</div>
        </div>
        {setListArray}
      </div>
    );
  }
}

export default withStyles(styles)(VennTable);
