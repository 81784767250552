import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import "pages/myPage/myPage.scss";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomBtn from "components/atoms/CustomBtn";
import SystemRepository from "modules/repository/SystemRepository";

const styles = theme => ({

  tokenPad: {
    wordBreak: "brealAll",
    // margin: "2% 2% 2% 2%",
    // border: "solid 1px #c6c6c6",
    // backgroundColor: "#ffffff",
    width: "250px",
    // height: "200px",
    // display: "block"
  }
})

@inject("comm", "auth", "system")
@observer
class BasicInfo extends Component {

  // 2021.09.27 modify by jdy : jwt_token
  state = {
    current: "",
    newpassword: "",
    confirm: "",
    tokenOpen: false,
    tokenId: ""
  }

  componentDidUpdate(nextProps) {
    return true;
  }

  componentDidMount() {
    const {
      auth,
      comm,
      comm: { isUserModified }
    } = this.props;

    if (!isUserModified) {
      auth.loadUser();
    }

    comm.setCurPage("MyPage");
  }

  currentChange = e => {
    this.setState({
      current: e.target.value,
    });
  }

  newChange = e => {
    this.setState({
      newpassword: e.target.value,
    });
  }

  confirmChange = e => {
    this.setState({
      confirm: e.target.value,
    });
  }

  run =async (e) => {
    const {system } = this.props
    const { current, newpassword, confirm } = this.state
    let message
    let query = {
      currentPassword: current,
      newPassword: newpassword
    };
    e.preventDefault()
    if (newpassword === confirm) {
      message = await system.changePasswordUser(query)
      if(message){
      alert("Change Password.");
      }
    } else {
      alert("Please check the password again to change.");
    }
  }

  // 2021.09.27 add 1 function by jdy : jwt_token
  getToken =async (e) => {
 
    // const { system } = this.props;
    const { auth } = this.props;

    let query = auth.getLoginInfo(); //admin

    e.preventDefault();

    console.log("Debug token query : ", query);

    try {
      const {
        data: {
          id_token
        }
      } = await SystemRepository.getToken(query);

      this.setState ({ 
        tokenOpen : true,
        tokenId: id_token
       });

      console.log("Debug token : ", id_token);
    }
    catch (e) {
      return null;
    }
  }

  render() {
    const { comm: { user }, classes } = this.props;
    const { current, newpassword, confirm, tokenOpen, tokenId } = this.state;

    let tokenName;
    if (tokenOpen) {
      tokenName = "This token is effective till 1 year from now. ";
    }
    else {
      tokenName = "";
    }

    return (
      <div className="header">
        <div className='main'>
          <div className='account-table'>
            <div className='table-white'>
              <div className='inform'>
                <div className='account-title'>Basic Information </div>
                <div className='account-main'>
                  <AccountCircleIcon className="account-icon" />
                  <div className='account-inform'>
                    <div className='account-data'>Name : {user ? `${user.firstName} ${user.lastName}` : ""}</div>
                    <div className='account-data'>E-Mail : {user ? user.email : ""}</div>
                    <div className='account-data'>Institute : {user ? user.institute : ""}</div>
                  </div>
                </div>
                <div className='account-change'>
                  <div className='account-change'>Current Password :
                    <input
                      className={"account-change-input"}
                      type={"password"}
                      placeholder={"........"}
                      value={current}
                      onChange={this.currentChange}
                    />
                  </div>
                  <div className='account-change'>Change Password :
                  <input
                      className={"account-change-input"}
                      type={"password"}
                      placeholder={"........"}
                      value={newpassword}
                      onChange={this.newChange}
                    />
                  </div>
                  <div className='account-change'>Confirm Change Password :
                  <input
                      className={"account-change-input"}
                      type={"password"}
                      placeholder={"........"}
                      value={confirm}
                      onChange={this.confirmChange}
                    />
                  </div>
                  <CustomBtn
                    text={"CONFIRM"}
                    disabled={false}
                    stylebtn={"account-confirm-button"}
                    onClick={this.run} />

                </div>

              </div>
              
              <div className='inform'>


                <div className='account-token'>    
                    <div> {tokenId} </div>
                </div>
                <p> </p>
                <div className='account-token-title'>

                    <div> {tokenName} </div>
                </div>
                  {/* 2021.09.17 add by jdy : for api-token */}
                  <CustomBtn
                    text={"Get Token for Api"}
                    disabled={false}
                    stylebtn={"account-confirm-button"}
                    onClick={this.getToken} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BasicInfo);
