import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import BottomNavigation from "@material-ui/core/BottomNavigation";
// import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import Apps from "@material-ui/icons/Apps";
// import Reported from "assets/images/dataexplorer/reported.svg";
// import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  option: {
    padding: "0px 5px",
    display: "flex",
    float: "right",
    fontSize: "10px"
  },
  optionTitle: {
    height: "25px",
    textAlign: 'center',
    lineHeight: 1.5,
    fontWeight: "500"
  },
  optiondetail: {
    height: '25px',
    paddingLeft: "5px",
    textAlign: 'center',
  },
  switch: {
    width: 25,
    height: 16,
    padding: 0,
    margin: "0px 5px"
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 8,
    opacity: "1 !important",
    backgroundColor: "#26a1a4 !important"
  },
  switchBase: {
    padding: 1,
    color: "#f6f6f6"
  },
  selectCancerreencolor: {
    color: "#f6f6f6 !important",
  },
});
class ReportedStatus extends Component {


  render() {
    const { classes, title, left, right, checked, onchange } = this.props;
    return (
      <div className={classes.option}>
        <div className={classes.optionTitle}>{title} : </div>
        <div className={classes.optiondetail}>{left}
          <Switch
            checked={checked}
            onChange={onchange}
            classes={{
              root: classes.switch,
              thumb: classes.thumb,
              switchBase: classes.switchBase,
              track: classes.track,
              checked: classes.selectCancerreencolor
            }}
          />
          {right}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReportedStatus);
