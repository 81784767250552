import { List } from '@material-ui/core';
import {
  axios
} from 'dependencyLibrary';

class HomeRepository {
  URL = "/api/kmaster";

  fetchCategoryTotalCounts = () => {
    return axios.get(`${this.URL}/main/total-count`);
  };

  fetchLastUpdateDate = () => {
    return axios.get(`${this.URL}/cdm/lastUpdateDate`);
  };

  fetchLastUpdateDateHgvs = () => {
    return axios.get(`${this.URL}/cdm/lastUpdateDateHgvs`);
  };

  returnCancerData = data => {
    return axios.post(`${this.URL}/cancers/demographies`, data);
  };

  returnVariantTypesData = data => {
    return axios.post(`${this.URL}/variants/types`, data);
  };

  returnpersonsData = data => {
    return axios.post(`${this.URL}/persons`, data);
  };

  returnpersonsDataAnaly = data => {
    return axios.post(`${this.URL}/personsAnaly`, data);
  };

  returnGeneData = data => {
    return axios.post(`${this.URL}/genes`, data);
  };

  //DrugActionable 표데이터  - ksh 20210128
  returnGeneDataDrugAction = data => {
    return axios.post(`${this.URL}/genes/Action`, data);
  };

  //Hgvs 쿼리 실행 - 권순현
  returnGeneDataHgvs = data => {
    return axios.post(`${this.URL}/genesHgvs`, data);
  };


  returnCancerGeneData = data => {
    return axios.post(`${this.URL}/genes/cancer`, data);
  };
  returnVariantData = (data, variant) => {
    return axios.post(`${this.URL}/variants/${variant}`, data);
  };
  returnVariantDataAnaly = (data, variant) => {
    return axios.post(`${this.URL}/variantsAnaly/${variant}`, data);
  };
  returnVariantCancerData = (data, variant) => {
    return axios.post(`${this.URL}/variants/${variant}/cancer`, data);
  };
  returnKeyWord = (data) => {
    return axios.get(`${this.URL}/search/${data}`);
  };

  returnCancerTypeData = data => {
    return axios.post(`${this.URL}/cancers/summary`, data);
  };

  returnCanceTableData = data => {
    return axios.post(`${this.URL}/cancers`, data);

    
    
  };

  returnValueList = (data) => {
    return axios.post(`${this.URL}/gcdms`, data);
  };

  returnSubFilter = (type, data) => {
    return axios.get(`${this.URL}/${type}${data}`);
  }

  //젠터 그래프 데이터 API
  returnPersonDemoGraphicsData = (data) => {
    return axios.post(`${this.URL}/persons/demographics`, data);
  }


  // VariantCategory API
  returnVariantCategory = (data) => {
    return axios.post(`${this.URL}/genes/variantCategory`, data);
    
  }
  
  // VariantCategory API
  returnGeneActionableData = (data) => {
    return axios.post(`${this.URL}/genes/Actionable`, data);
  }
    
  //Sample 데이터 API
  returnSampleTypeData = (data) => {
    return axios.post(`${this.URL}/persons/sample`, data);
  }
  //Primary 데이터 API
  returnPrimarySiteData = (data) => {
    return axios.post(`${this.URL}/persons/primary`, data);
  }

  //Gene 포지션 별 그래프 데이터 추출
  // returnPersonDemoGraphicsDataH = (data) => {
  //   return axios.post(`${this.URL}/persons/genePosition`, data);
  // }

  returnPersonDemoGraphicsDatadx = (type, data) => {
    return axios.post(`${this.URL}/persons/demographics/dx${type}`, data);
  }

  returnCancerAbsoluteData = (type, data) => {
    return axios.post(`${this.URL}/${type}/absolute/1`, data);
  };

  returnAbsoluteGeneData = (type, data) => {
    return axios.post(`${this.URL}/${type}/absolute/2`, data);
  };

  returnAbsolutevariantData = (type, data) => {
    return axios.post(`${this.URL}/${type}/absolute/3`, data);
  };

  getRowDataSearchPerson(query) {
    return axios.post(`${this.URL}/persons`, query);
    // return axios.get(`${this.URL}/summary/search-person/row-data${query}`);
  }

  // protein by jdy 2010-11-17
  returnProteinData = (query) => {

    return axios.post(`${this.URL}/genes/protein`, query);

    // let data = axios.post(`${this.URL}/genes/protein`, query);
    

    // let dataObj = {}

    // let content = [];

    // content.push({hgvsp1Digit:"C33Y", cnt:2015, hgvspPosition:8033, type:0});
    // content.push({hgvsp1Digit:"R33Y", cnt:2021, hgvspPosition:8033, type:0});   
    // content.push({hgvsp1Digit:"A34Y", cnt:2031, hgvspPosition:8034, type:0});
    // content.push({hgvsp1Digit:"CB34Y", cnt:2011, hgvspPosition:8034, type:0});
    // content.push({hgvsp1Digit:"R39Y", cnt:2041, hgvspPosition:8039, type:1});
    // content.push({hgvsp1Digit:"R45Y", cnt:2021, hgvspPosition:8045, type:0});
    // content.push({hgvsp1Digit:"C65Y", cnt:2015, hgvspPosition:8065, type:2});
    // content.push({hgvsp1Digit:"R653Y", cnt:2021, hgvspPosition:8065, type:0});   
    // content.push({hgvsp1Digit:"A84Y", cnt:2031, hgvspPosition:8084, type:2});
    // content.push({hgvsp1Digit:"CB84Y", cnt:2011, hgvspPosition:8084, type:2});
    // content.push({hgvsp1Digit:"CY94Y", cnt:2011, hgvspPosition:8094, type:2});

    // dataObj.content = content;

    // return {data : dataObj };
  }

  returnAnnotationCategory = (query) => {

    return axios.post(`${this.URL}/genes/annotation`, query);
  }
}

export default new HomeRepository();