import React, { Component } from "react";
import withMenuList from "components/atoms/withMenuList";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// import dataFullIcon from "assets/images/header/data_full.svg";
// import dataIcon from "assets/images/header/data.svg";
import { inject, observer } from "mobx-react";

const menuList = [
  {
    menu: "Data Explorer",
    url: "/data-explorer",
    permission: "ALL",
    only: false,
  },
  {
    menu: "Analysis",
    url: "/analysis",
    permission: "ALL",
    only: false,
  },
  {
    menu: "Admin",
    url: "/admin",
    permission: "ROLE_ADMIN",
    only: true,
  },
  /* {
    menu: "System",
    url: "/system",
    permission: "ROLE_ADMIN",
    only: true,
  }, */
  /* 2021-03-30 add 6 line by jdy */
  {
    menu: "Help",
    url: "/help",
    permission: "ALL",
    only: false,
  },
];

const styles = (theme) => ({
  li: {
    marginTop: "0px",
    padding: "0 26px",
    cursor: "pointer",
    display: "flex",
    // alignItems: "center"
  },
  homeSpan: {
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    fontSize: "14px",
    color: "#bebebe",
    margin: "10px",
    "&:hover": {
      color: "#ffffff",
      textDecoration: "underline",
    },
  },
  span: {
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    fontSize: "14px",
    color: "#b4b4b4",
    margin: "0px 10px",
    padding: "15px 0px 8px 0px",
    "&:hover": {
      color: "#868686",
      textDecoration: "underline",
      // backgroundColor: "#efefef",
    },
  },
  img: {
    margin: "10px",
  },
  fill: {
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
    fontSize: "14px",
    color: "#434343",
    margin: "0px 10px",
    padding: "15px 0px 8px 0px",
    // backgroundColor: "#edecec",
    borderBottom: " 3px solid #26a1a4",
    // textDecoration: 'underline'
  },
});

@inject("comm")
@observer
class TopMenu extends Component {
  _handleClick = (e) => {
    const { onClickMenu } = this.props;

    onClickMenu(e.currentTarget.querySelector("div").innerHTML);
  };

  // _handleHelpClick = (e) => {
  //   const { onClickMenu } = this.props;
  //   onClickMenu(e.currentTarget.querySelector("div").innerHTML);

  //   window.open('/localhost:3000/help'); //, "", '_blank');

  // };

  render() {
    const {
      // comm: { curPage },
      data: { menu, url },
      classes,
      pathname,
    } = this.props;
    if (pathname === "/") {
      return (
        <li onClick={this._handleClick} className={classes.li}>
          <Link to={url}>
            <div className={classes.homeSpan}>{menu}</div>
          </Link>
        </li>
      );
    }
    // else if (pathname === "/help") {
    //   return (
    //     <li onClick={this._handleHelpClick} className={classes.li}>
    //       {/* <Link to={url}> */}
    //         <div className={classes.homeSpan}>{menu}</div>
    //       {/* </Link> */}
    //     </li>
    //   );
    // }  
    else if (pathname.match(url)) {
      return (
        <li onClick={this._handleClick} className={classes.li}>
          <Link to={url}>
            <div className={classes.fill}>{menu}</div>
          </Link>
        </li>
      );
    } 
    else {
      return (
        <li onClick={this._handleClick} className={classes.li}>
          <Link to={url}>
            <div className={classes.span}>{menu}</div>
          </Link>
        </li>
      );
    }
  }
}

export default withMenuList(menuList)(withStyles(styles)(TopMenu));
