import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
drilldow(Highcharts);
// const MaleColorList = [
//   "#236898",
//   "#2775ab",
//   "#2c82be",
//   "#428ec4",
//   "#569bcb",
//   "#6ba7d1",
//   "#80b4d8",
//   "#95c0de",
//   "#aacde5",
//   "#bfd9e6",
// ]
// const FeMaleColorList = [
//   "#cc645f",
//   "#e5716b",
//   "#ff7e77",
//   "#ff8a84",
//   "#ff9792",
//   "#ffa49f",
//   "#ffb1ad",
//   "#ffbebb",
//   "#ffcbc8",
//   "#ffd8d6",
// ]
// const gendercategories = [
//   "0-9",
//   "10-19",
//   "20-29",
//   "30-39",
//   "40-49",
//   "50-59",
//   "60-69",
//   "70-79",
//   "80-89",
//   "90<"
// ];
const categories = ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100<"];
const AgeList = ['age10', 'age20', 'age30', 'age40', 'age50', 'age60', 'age70', 'age80', 'age90', 'age100']
class AgeGenderPieChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list
    // || this.props.list !== undefined
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list && nextProps.list.length) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }


  render() {
    const { list } = this.props;
    let male = 0
    let female = 0
    let options;
    if (list) {
      for (let i in AgeList) {
        male += list[AgeList[i]].male;
        female += list[AgeList[i]].female;
      }
    }
   
    let Sum = [
      {
        name: "Female",
        y: female,
        //color: "#2c82be"
        color: "#ff7e77"
      },
      {
        name: "Male",
        y: male,
        //color: "#ff7e77"
        color: "#2c82be"
      },

    ]


    options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "pie",
        height: 200,
      },
      title: {
        text: null
      },
      subtitle: {
        text: "Gender",
        y: 15,
        zIndex: 10,
      },
      credits: {
        enabled: false
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        labelFormatter: function () {
          if (this.name.length > 7) {
            return `${this.name.slice(0, 5)}...`
          }
          else {
            return this.name
          }
        }
      },
      tooltip: {
        formatter: function () {
          return (
            "<b><span >" +
            this.point.name +
            "</span></b><br/><span>" +
            "Subject: " +
            this.y.toLocaleString() + "</span>"
          )
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            distance: 10,
            style: {
              fontSize: "10px"
            }
          },
          showInLegend: true
        }
      },
      series: [{ name: 'Gender', data: Sum }],
      // drilldown: {
      //   series: [{
      //     name: "Male",
      //     id: "Male",
      //     // innerSize: '20%',
      //     data: Male
      //   }, {
      //     name: "Female",
      //     id: "Female",
      //     // innerSize: '20%',
      //     data: FeMale
      //   }]
      // }
    };


    return <HighchartsReact
      highcharts={Highcharts}
      options={options}
      oneToOne={true}
      key={this.state.fooKey}
    />;
  }
}

export default AgeGenderPieChart;
