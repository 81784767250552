import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import MessageIcon from '@material-ui/icons/Message';
const styles = theme => ({
  main: {
    border: "solid 1px #dbdbdb",
    backgroundColor: "#ffffff",
    margin: "10px 10px 0px 5px",
    // "&:hover": {
    //   borderColor: '#26a1a4'
    // }
  },
  title: {
    display: "flex",
    fontSize: "11px",
    color: "#7d7d7d",
    borderBottom: "solid 1px #dcdbdb",
    height: "35px",
    lineHeight: "3"
  },
  message: {
    minHeight: "800px"
  },
  cardDiv: {
    width: "calc(100% - 20px)",
    minHeight: "50px",
    margin: "10px",
    borderRadius: "1px",
    boxShadow: "0 1px 1px 0 rgba(163, 163, 163, 0.5)",
    backgroundColor: "#ececec",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: '#f7f6f6',
    }
  },
  cardDivTrue: {
    width: "calc(100% - 20px)",
    minHeight: "50px",
    margin: "10px",
    borderRadius: "1px",
    boxShadow: "0 1px 1px 0 rgba(163, 163, 163, 0.5)",
    backgroundColor: "#fbfbfb",
    // cursor: "pointer"
  },
  cardType: {
    width: "20%",
    // height: "16px",
    margin: "5px",
    borderRadius: "1px",
    backgroundColor: "#00c8a3",
    float: "left",
    fontSize: "8px",
    color: "#ffffff",
    textAlign: "center"
  },
  cardTypeTrue: {
    width: "20%",
    margin: "5px",
    borderRadius: "1px",
    backgroundColor: "#d0d0d0",
    float: "left",
    fontSize: "8px",
    color: "#ffffff",
    textAlign: "center"
  },
  cardDate: {
    fontSize: "8px",
    color: "#7d7d7d",
    float: "right",
    margin: "5px",
  },
  cardDateTrue: {
    fontSize: "8px",
    color: "#bfbfbf",
    float: "right",
    margin: "5px",
  },
  cardTitle: {
    fontSize: "11px",
    color: "#7d7d7d",
    // float: "left",
    margin: "5px",
  },
  cardTitleTrue: {
    fontSize: "11px",
    color: "#bfbfbf",
    margin: "5px",
  },
})

class CardList extends Component {
  delete = (e) => {
    const { check } = this.props
    check(e.id, e.title, e.type)
  }
  render() {
    const { classes, notificationsList } = this.props;
    let list = []
    if (notificationsList.length) {
      for (let data of notificationsList) {
        if (data.read) {
          list.push(
            <div className={classes.cardDivTrue} key={data.id} >
              <div style={{ height: "20px" }}>
                <div className={classes.cardTypeTrue}>{data.type}</div>
                <div className={classes.cardDateTrue}>{data.createdDateTime.slice(0, 10)}</div>
              </div>
              <div className={classes.cardTitleTrue}>{data.title}</div>
            </div>
          )
        } else {
          list.push(
            <div className={classes.cardDiv} key={data.id} onClick={() => this.delete(data)}>
              <div style={{ height: "20px" }}>
                <div className={classes.cardType}>{data.type}</div>
                <div className={classes.cardDate}>{data.createdDateTime.slice(0, 10)}</div>
              </div>
              <div className={classes.cardTitle}>{data.title}</div>
            </div>
          )
        }
      }
    }

    return (
      notificationsList.length ? list : null
    );
  }
}

@inject("system", "comm")
@observer
class Notification extends Component {
  state = {
    type: true
  }

  componentDidMount() {
    const { system, comm: { user } } = this.props;
    if (user) {
      system.getNotification("?type=true")
    }
  }

  componentDidUpdate() {
    const { system, system: { notificationsList }, comm: { originUser } } = this.props;
    if (originUser && (!notificationsList)) {
      system.getNotification("?type=true")
    }
  }

  check = async (id, title, type) => {
    const { system } = this.props;
    let query = {
      id: id,
      title: title,
      type: type,
      read: true,
    }
    await system.putNotification(query)
    system.getNotification(`?type=${this.state.type}`)
  }

  setType = () => {
    const { system } = this.props;
    this.setState({
      type: !this.state.type
    })
    system.getNotification(`?type=${!this.state.type}`)
  }

  render() {
    const { classes, system: { notificationsList } } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.title}>

          <div style={{ width: "40%", textAlign: "right" }}><MessageIcon style={{ fontSize: "20px", paddingTop: "5px" }} /></div>
          <div style={{ width: "40%", textAlign: "left", paddingLeft: "5px" }}>NOTIFICATION</div>
          <div style={{ width: "20%", textAlign: "right", cursor: "pointer", paddingRight: "10px", textDecoration: "underline" }} onClick={this.setType}>
            {this.state.type ? "Unchecked" : "All"}
          </div>

        </div>
        <div className={classes.message}>
          <CardList classes={classes} notificationsList={notificationsList.content ? notificationsList.content : []} check={this.check} />
        </div>
      </div>

    );
  }
}

export default withStyles(styles)(Notification);;
