
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CustomBtn from "components/atoms/CustomBtn";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckCircle from "@material-ui/icons/CheckCircle";

const styles = theme => ({
  display: {
    display: "flex",
    marginBottom: "5px"
  },
  goBtn: {
    width: "100%",
    height: "25px"
  },
  title: {
    fontSize: "12px",
    margin: "10px 0px 3px 0px",
    display: "flex",
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  chrBox: {
    width: "20%",
    height: "25px",
    border: "solid 1px #dddddd",
    padding: "0px 3px",
    marginRight: "4px",
    fontSize: '12px',
    backgroundColor: "#ffffff"
  },
  startBox: {
    width: "40%",
    height: "25px",
    border: "solid 1px #dddddd",
    padding: "0px 3px",
    marginRight: "4px",
    fontSize: '12px',
    backgroundColor: "#ffffff"
  },
  endBox: {
    width: "40%",
    height: "25px",
    border: "solid 1px #dddddd",
    padding: "0px 3px",
    fontSize: '12px',
    backgroundColor: "#ffffff"
  },
  iconsize: {
    fontSize: "14px"
  },
  error: {
    fontSize: "10px"
  },
  nonDiv: {
    fontSize: "12px",
    padding: "2px 0px",
    color: "#a9a9a9"
  },
});

class Chromosome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chr: '',
      start: '',
      end: '',
      page: true
    };
  }
  onClickDiv = () => {
    const { page } = this.state;
    this.setState({
      page: !page
    });
  };
  onClick = () => {
    const { start, chr, end } = this.state
    const { mainFilterObject, onClickEvent } = this.props;
    mainFilterObject.start_position = start
    mainFilterObject.end_position = end
    mainFilterObject.chr = chr
    if (start && chr && end) {
      onClickEvent()
    }
    this.setState({
      chr: '',
      start: '',
      end: ''
    });
  }
  chrChange = e => {
    this.setState({
      chr: e.target.value.replace('y', 'Y').replace('x', 'X'),
    });
  }
  startChange = e => {
    this.setState({
      start: e.target.value,
    });
  }
  endChange = e => {
    this.setState({
      end: e.target.value,
    });
  }
  render() {
    const { chr, start, end, page } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div onClick={this.onClickDiv} className={classes.title}>
            <div>Chromosome search</div>
            {page ? (
              <KeyboardArrowUp className={classes.iconsize} />
            ) : (
                <KeyboardArrowDown className={classes.iconsize} />
              )}
          </div>
          {page ? <>
            <div className={classes.display}>
              <input
                type="text"
                placeholder={'chr'}
                onChange={this.chrChange}
                className={classes.chrBox}
                value={chr}
              />
              <input
                type="number"
                placeholder={'start'}
                onChange={this.startChange}
                className={classes.startBox}
                value={start}
              />
              <input
                type="number"
                placeholder={'end'}
                onChange={this.endChange}
                className={classes.endBox}
                value={end}
              />
            </div >
            <div>
              <CustomBtn
                text={"Search"}
                stylebtn={classes.goBtn}
                onClick={this.onClick}
                disabled={!(chr && start && end)}
              />
            </div>
            <div style={{ paddingTop: "5px" }}>
              <div className={classes.nonDiv}><CheckCircle className={classes.error} /> All locations must be entered.</div>
            </div>
          </> : null}

        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Chromosome);
