import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    // padding: "1px",
    // width: "100%",
    margin: "3px 0px",
    border: "solid 1px #dddddd"
  },
  panelroot: {
    minHeight: "30px !important",
    // backgroundColor: '#f0f5f5'
    backgroundColor: '#eaeaea',
    fontWeight: "500",
    lineHeight: '2.5'
  },
  rounded: {
    borderRadius: "0px!important",
    boxShadow: "none !important"
  },
  panel: {
    margin: "0px !important",
    alignItems: 'center'
    // display: "contents"
  },
  heading: {
    fontSize: "12px",
    // paddingTop: '9px'
  },
  expendicon: {
    padding: "0px 5px"
  }
});

class LeftTabPanel extends Component {

  constructor(props) {
    super(props);

    this.bExpand = props.expandedInit;
  }

  state = {
    bExpandState : true
  }
  /* commment by jdy, 2021-01
  props로 넘어온 state는 DataPage component의 state.select 이다.
  따라서 선택에 따라 state 의 property를 설정하면 DataPage component의 state.select가 설정된다.
  */

  /* 
    변경일자:   2020-06-26
    변경자 이름: 지신복 
    변경 내용:   화면 display 건수 변경
    변경 사유:   암종추가에 따른 화면 display 건수 변경                            
  */ 
  // onChange=()=>{
  //   if(this.props.reciveData != null){
  //     this.props.reciveData(this.props.title)
  //   }
  // }

  // modified by jdy, 2021-01
  // props.reciveData 사용 불필요
  // Expanded symbol 선택에 의해 toggle로 re-redering 하도록 변경.

  onChange=()=>{
    this.bExpand = !this.bExpand;

    this.setState({
      bExpandState : this.bExpand
    });
  }

  render() {
    const {
      refresh,
      Detail,
      title,
      classes,
      list,
      onClickEvent,
      mainFilterObject,
      state,
      expandedInit,
      expandedTitle
    } = this.props;


    let bExpandToggle = this.bExpand;
   
    // 삭제 및 변경 by jdy, 2021-01
    //<ExpansionPanel defaultExpanded classes={{ rounded: classes.rounded }} onChange={this.onChange} expanded={this.props.reciveData?expandedTitle==title?true:false:true}></ExpansionPanel>

    return (
      <div className={classes.root}>
        <ExpansionPanel defaultExpanded classes={{ rounded: classes.rounded }} onChange={this.onChange} expanded={bExpandToggle}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
            classes={{
              content: classes.panel,
              root: classes.panelroot,
              expanded: classes.panelroot,
              expandIcon: classes.expendicon
            }}
          >
            <div className={classes.heading}>{title}</div>
          </ExpansionPanelSummary>
          {Detail ? (
            <Detail
              refresh={refresh}
              list={list}
              mainFilterObject={mainFilterObject}
              onClickEvent={onClickEvent}
              state={state}
              title={title}
            />
          ) : null}
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(LeftTabPanel);
