import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TestTable from "components/atoms/TestTable";
// import CustomBtn from "components/atoms/CustomBtn";
// import deleteForever from "@material-ui/icons/DeleteForever";
import SearchForm from "components/organisms/SearchForm"

const styles = theme => ({
  chartMargin: {
    padding: "10px"
  },
  nav: {
    // paddingTop: "15px"
  },
  person: {
    lineHeight: 'normal',
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "14px"
  },
  personTotal: {
    lineHeight: '1.5',
    float: "left",
    paddingLeft: "2px",
    marginBottom: "8px",
    fontSize: "11px",
    color: "#3a3a3a"
  },
  btnmargin: {
    float: 'right',
    width: '120px',
    marginRight: "15px"

  },
})

const types = [
  {
    value: "name",
    label: "Name"
  },
  {
    value: "institution",
    label: "Institution"
  },
  {
    value: "email",
    label: "E-Mail"
  },
  {
    value: "role",
    label: "Role"
  },
  {
    value: "locked",
    label: "Locked"
  }
];

class AdminBoard extends Component {

  state = {
    selected: [],
    open: false,
    select: ''
  };

  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return false 하면 업데이트를 안함
    return JSON.stringify(this.props.detailType) !== JSON.stringify(nextProps.detailType) || this.state !== nextState
    // return true;
  }

  handlePagination = s => {
    const { handlePagination, detailType } = this.props
    handlePagination(s, detailType.title)

  };

  handleSort = ({ order, orderBy }) => {
    console.log(order, orderBy);
  };

  setSelect = selected => {
    this.setState({ selected });
  };

  onClick = e => {
    const { deleteUser } = this.props
    deleteUser(this.state.selected)
  }

  render() {
    const { detailType, classes, handleClickCheckbox, searchUser } = this.props;
    if (detailType.data) {
      return (
        <>
          <SearchForm types={types} searchUser={searchUser} defaultType={"name"} />

          {/* <CustomBtn text={"Delete"} img={deleteForever} stylebtn={classes.btnmargin} onClick={this.onClick} /> */}
          <div className={classes.chartMargin}>
            <div className={classes.nav}>
              <div className={classes.person}>{detailType.title}</div>
              <div className={classes.personTotal}>({detailType.data.totalElements} users registered)</div>
            </div>
            <TestTable
              ref={this.node}
              id={"alllist"}
              columns={detailType.columns}
              data={detailType.data}
              // selectRow={{
              //   mode: "radio",
              //   handleClickCheckbox: handleClickCheckbox,
              //   setSelect: this.setSelect,
              //   selected: this.state.selected
              // }}
              defaultSort={{
                order: "asc",
                orderBy: "geneName",
                handleSort: this.handleSort
              }}
              defaultPagination={{
                page: 0,
                rowsPerPage: 10,
                handlePatination: this.handlePagination
              }}
              headerIcon={<div style={{ width: "35px" }}></div>}
              {...this.props}
            />
          </div>
        </>
      )
    } else {
      return null
    }

  }
}


export default withStyles(styles)(AdminBoard);
