import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import Select from '@material-ui/core/Select';


const styles = theme => ({
  options: {
    fontSize: "10px"
  },
  select: {
    fontSize: "13px",
    border: "solid 1px #dbdbdb"
  }
})

@inject("system")
@observer
class StatusSelect extends Component {
  state = {
    value: false
  }


  componentDidMount() {
    const { locked } = this.props
    if (locked) {
      this.setState({
        value: true
      })
    }
  }

  change = async (e) => {
    const { system, id, email, authorities } = this.props
    let message
    let value = e.target.value === 'true' ? true : false
    let query = {
      id: id,
      locked: value,
      login: email,
      authority: `ROLE_${authorities}`
    }
    message = await system.changeUser(query)
    system.getAdminUser(`?size=10`)
    if (message) {
      alert("Changed.")
    }


  }

  render() {
    const { classes } = this.props
    const { value } = this.state
    return (
      <Select native value={value} disableUnderline={true} displayEmpty onChange={this.change} className={classes.select} >
        <option className={classes.options} value={true}>ACTIVE</option>
        <option className={classes.options} value={false}>DEACTIVE</option>
      </Select>
    )
  }
}

export default withStyles(styles)(StatusSelect);
