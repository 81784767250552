import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import CancerChartPages  from "./ChartPages/CancerChartPages";
import GeneChartPages  from "./ChartPages/GeneChartPages";
import VariantChartPages  from "./ChartPages/VariantChartPages";

// presentTab 에 따라 표시될 Chart가 다르도록 변경하기 위해, 선택된 presentTab에 따라 분기되도록 modify by jdy 2020-11

@inject("home")
@observer
class DataPageTable extends Component {

  render() 
  {
    const { mainDataType, mainFilterObject, onClickMainFilter, PersonGraphicsData, variantCategory, SampleType, primarySite, dxAge, filterTab, chartOpen, checked, absoluteData, absolute, cancerTypeData, posQuery, originUser, presentTab, home } = this.props;

    if (presentTab === 0) 
    {      
      return (
        <CancerChartPages mainDataType={mainDataType} mainFilterObject={mainFilterObject} 
                        chartOpen={chartOpen} absolute={absolute}
                        PersonGraphicsData={PersonGraphicsData} SampleType={SampleType} primarySite={primarySite} dxAge={dxAge} filterTab={filterTab} 
                        presentTab={presentTab} 
                        checked={checked} absoluteData={absoluteData}
                        cancerTypeData={cancerTypeData} posQuery={posQuery} originUser={originUser}
        />
      );
    }
    else if (presentTab === 1) 
    {
      return (
        <GeneChartPages mainDataType={mainDataType} mainFilterObject={mainFilterObject} 
                        onClickMainFilter={onClickMainFilter}
                        chartOpen={chartOpen} absolute={absolute}
                        PersonGraphicsData={PersonGraphicsData} dxAge={dxAge} filterTab={filterTab} 
                        presentTab={presentTab} 
                        checked={checked} absoluteData={absoluteData}
                        cancerTypeData={cancerTypeData} posQuery={posQuery} originUser={originUser}
        />
      );
    }
    else
    {
      return (
        <VariantChartPages mainDataType={mainDataType} mainFilterObject={mainFilterObject} 
                        chartOpen={chartOpen} absolute={absolute} variantCategory={variantCategory}
                        PersonGraphicsData={PersonGraphicsData} dxAge={dxAge} filterTab={filterTab} 
                        presentTab={presentTab} 
                        checked={checked} absoluteData={absoluteData}
                        cancerTypeData={cancerTypeData} posQuery={posQuery} originUser={originUser}
        />
      );
    }
  }
}


export default DataPageTable;
