import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  items: {
    padding: "0px 8px",
    color: "#7d7d7d",
    backgroundColor: "#00ff0000",
    borderBottom: "solid 1px #c6c6c6",
    minHeight: "35px",
    minWidth: "240px",
    "@media (max-width: 1500px)": {
      minWidth: "160px",
    },
  },
  selected: {
    color: "#ffffff",
    backgroundColor: "#00c8a3 !important"
  }
});

class SystemLeftMenu extends Component {
  render() {
    const { classes, list, tab, handleClick } = this.props;
    return (
      <div style={{ backgroundColor: "#494949" }}>
        <MenuList style={{
          paddingTop: "0px",
          backgroundColor: "#494949"
        }}
        >
          {list.map((m, i) => (
            <MenuItem
              key={i}
              onClick={e => handleClick(e, i)}
              selected={i === tab}
              classes={{ selected: classes.selected }}
              className={classes.items}
            >
              {m.icon}
              <span style={{ fontSize: "12px", paddingLeft: "20px" }}>{m.name}</span>
            </MenuItem>
          ))}
        </MenuList>
      </div>
    );
  }
}

export default withStyles(styles)(SystemLeftMenu);
