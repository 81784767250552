import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import { StickyContainer, Sticky } from "react-sticky";
import queryString from "query-string";
import AnalysisChartTable from "components/organisms/AnalysisChartTable";
import DetailAnalysis from "components/organisms/DetailAnalysis";
import CheckPermission from "components/lib/CheckPermission";
import PatientListTable from "components/organisms/PatientListTable";
import TopOperationSetList from "components/atoms/TopOperationSetList";
import VennTable from "components/organisms/VennTable";
import Subject from "assets/images/dataexplorer/subject.svg";
import TypeSubject from "components/atoms/TypeSubject";
import Footer from "components/organisms/FooterSmall";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Run from "assets/images/ajaxloader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

import XLSX from 'xlsx';

const ROLE = ["ROLE_ADMIN", "ROLE_KM_USER"]

// let localStorage = window.localStorage;
const detailCancerColumns = [
  { column: "Cancer", property: "name", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subject", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "Samples", property: "samples", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  // { column: "Genes", property: "gene", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  // { column: "Variants", property: "variant", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "SNV", property: "snv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "CNV", property: "cnv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } },
  { column: "SV", property: "sv", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 200 } } }
];
const PatientColumns = [
  { column: "Person ID", property: "personSourceValue", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 600 } } },
  { column: "Sex", property: "sex", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Age", property: "age", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 250 } } },
  { column: "Cancer", property: "cancer", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Detail Cancer", property: "cancerDetail", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene", property: "geneCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "Variant", property: "variantCount", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const genecolumns = [
  { column: "Gene", property: "geneName", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  { column: "Samples", property: "sampleCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Genes", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 400 } } },
  // { column: "Variants", property: "variantCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SNV", property: "snvCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "CNV", property: "cnvCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } },
  { column: "SV", property: "svCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const snvcolumns = [
  { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Type", property: "sequenceAlteration", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  // { column: "HGVS.c", property: "hgvsC", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  // { column: "HGVS.p", property: "hgvsP", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "HGVS.p", property: "hgvsP1digit", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const cnvcolumns = [
  { column: "Gene", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Type", property: "sequenceAlteration", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const svcolumns = [
  { column: "Gene1", property: "targetGene1Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Gene2", property: "targetGene2Id", align: "left", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 1000 } } },
  { column: "Subjects", property: "subjectCnt", align: "right", sort: false, options: { link: { isLinked: false }, isEditable: false }, style: { head: { width: 300 } } }
];
const DetailTableTitle = ['Detail Cancer List', 'Gene List', 'SNV List', 'CNV List', 'SV List']

let isDouble = true
const styles = theme => ({
  background: {
    width: "100%",
    display: "flex"
  },
  operation: {
    width: "440px",
  },
  line: {
    width: "10px",
    backgroundColor: "#c7c7c7",
    // height: "200px"
  },
  explorer: {
    width: 'calc(100% - 440px)',
  },
  container: {
    display: 'flex'
  },
  header: {
    width: "100%",
    height: '40px',
    backgroundColor: '#919191',
    color: '#ffffff',
    fontSize: "14px",
    // textAlign: "center",
    padding:'8px 5px 1px 10px',
    lineHeight: ' 2.5'
  },
  explorerHeader: {
    width: "100%",
    height: '40px',
    backgroundColor: '#919191',
    color: '#ffffff',
    fontSize: "14px",
    textAlign: "left",
    lineHeight: ' 2',
    zIndex: "100",
    // paddingLeft: "15px"
  },
  mainback: {
    display: "flex",
    margin: "0px 15px",
    padding: "7.5px",
    border: 'solid 1px #dedede',
    backgroundColor: '#f6f6f6',
    flexFlow: "wrap"
  },
  chartTitle: {
    fontSize: "14px",
    borderBottom: "solid 1px #c6c6c6",
    padding: "6.5px 0px 6.5px 12px",
    fontWeight: "500"
  },
  board: {
    width: "100%",
    height: "100%",
    borderRadius: "2px",
    border: "solid 1px #c6c6c6",
    backgroundColor: "#ffffff",
    margin: "7.5px",
  },
  totalTools: {
    display: "flex",
    width: "280px",
    height: "25px",
    float: "right",
    margin: "7px 15px",
    lineHeight: 2,
    fontSize: '12px',
    textAlign: "center",
    "@media (max-width: 1500px)": {
      width: '220px'
    },
  },
  selectTotal: {
    backgroundColor: '#a5a5a5',
    border: 'solid 1px #d4d4d5',
    width: '160px',
    height: "23px",
    "@media (max-width: 1500px)": {
      width: '90px'
    },
  },
  selecticon: {
    width: '25px',
    height: '23px',
    backgroundColor: '#f3f3f3',
    borderTop: "1px solid #d4d4d5",
    borderBottom: "1px solid #d4d4d5",
  },
  selectNum: {
    width: '100px',
    height: '23px',
    border: 'solid 1px #d4d4d5',
    backgroundColor: '#ffffff',
    color: "#434343"
  },
  ICON: {
    paddingTop: "4px"
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  chartMargin: {
    padding: "15px"
  },
  footer: {
    margin: "15px",
    marginBottom: "0px"
  },
  setTable: {
    margin: "15px",
  },
  loadingPaper: {
    backgroundColor: '#00ff0000',
    boxShadow: "none",
    width: "100px"
  },
  circlecolor: {
    color: "#ffffff"
  }
});
let noUserSetList = []
let Alies = ['S1', 'S2', 'S3']

@inject("analysis", "comm", "home")
@observer
class Operation extends Component {
  state = {
    select: "",
    loading: false,
    check: 0,
    reported: true,
    helpOpen: false,
    imageUrl: "help/2_5_2_1.png",
    htmlId: 21
  }

  componentWillUnmount() {
    const { home } = this.props
    home.resetChartData()
  }

  async componentDidUpdate() {
    const { analysis, history, comm: { originUser } } = this.props;
    const { check } = this.state
    let setList = this.props.match.params.id.split('**');
    let query = {}
    if (this.state.select === '') {
      if (setList.length === 3) {
        this.setState({
          select: 'intersectionABCSerial'
        });
      } else if (setList.length === 2) {
        this.setState({
          select: 'intersectionSerial'
        });
      } else {
        history.push(`/analysis`);
      }
    }
    if (originUser && !check) {
      await this.setState({
        check: 1
      })
      if (setList.length > 2) {
        query.setAId = setList[0]
        query.setBId = setList[1]
        query.setCId = setList[2]
        const querylist = queryString.stringify(query);
        await analysis.getOperationData('triple', `?${querylist}`);
        await analysis.getListData('S1', setList[0])
        analysis.getListData('S2', setList[1])
        analysis.getListData('S3', setList[2])
        isDouble = false
        const { analysis: { operationSet } } = this.props;
        this.onfirstClick(operationSet.intersectionABCSerial)
      } else {
        query.setAId = setList[0]
        query.setBId = setList[1]
        const querylist = queryString.stringify(query);
        await analysis.getOperationData('double', `?${querylist}`);
        await analysis.getListData('S1', setList[0])
        analysis.getListData('S2', setList[1])
        isDouble = true
        const { analysis: { operationSet } } = this.props;
        this.onfirstClick(operationSet.intersectionSerial)
      }
      const { analysis: { setListData } } = this.props
      await this.setState({
        reported: setListData['S1'].filter.reported
      })
    }
  }

  async componentDidMount() {
    const { analysis, home, comm: { originUser } } = this.props
    const { check } = this.state
    home.getLastUpdateDate();
    await analysis.resetListData()
    let query = {}
    noUserSetList = []
    let setList = this.props.match.params.id.split('**');
    if (originUser) {
      if (!check) {
        await this.setState({
          check: 1
        })
      }
      if (setList.length > 2) {
        query.setAId = setList[0]
        query.setBId = setList[1]
        query.setCId = setList[2]
        const querylist = queryString.stringify(query);
        await analysis.getOperationData('triple', `?${querylist}`);
        await analysis.getListData('S1', setList[0])
        analysis.getListData('S2', setList[1])
        analysis.getListData('S3', setList[2])
        isDouble = false
        const { analysis: { operationSet } } = this.props;
        this.onfirstClick(operationSet.intersectionABCSerial)
      } else {
        query.setAId = setList[0]
        query.setBId = setList[1]
        const querylist = queryString.stringify(query);
        await analysis.getOperationData('double', `?${querylist}`);
        await analysis.getListData('S1', setList[0])
        analysis.getListData('S2', setList[1])
        isDouble = true
        const { analysis: { operationSet } } = this.props;
        this.onfirstClick(operationSet.intersectionSerial)
      }
      const { analysis: { setListData } } = this.props
      await this.setState({
        reported: setListData['S1'].filter.reported
      })
      // } else {
      //   let querylist = []
      //   for (let i of setList) {
      //     querylist.push(JSON.parse(localStorage.getItem(i)))
      //     noUserSetList.push(i)
      //   }
      //   if (setList.length > 2) {
      //     try {
      //       await analysis.getOperationNoUserData('triple', querylist);
      //       isDouble = false
      //       const { analysis: { operationSet } } = this.props;
      //       if (operationSet) {
      //         this.onfirstClick(operationSet.intersectionABCSerial)
      //       }
      //     } catch (error) {
      //       history.push(`/analysis`);
      //     }
      //   } else {
      //     try {
      //       await analysis.getOperationNoUserData('double', querylist);
      //       isDouble = true
      //       const { analysis: { operationSet } } = this.props;
      //       if (operationSet) {
      //         this.onfirstClick(operationSet.intersectionSerial)
      //       }
      //     } catch (error) {
      //       history.push(`/analysis`);
      //     }
      //   }
    }

  }

  clickMainHelp = (id) => {
    let url;
    let htmlId;

    if (0 == id) {
      url = "/2_5_2_1.png";
      htmlId = 21;
    }
    else if (1 == id) {
      url = "/2_5_2_2.png";
      htmlId = 22;
    }
    else if (2 == id) {
      url = "/2_5_2_2_1.png";
      htmlId = 23;
    }
    else if (3 == id) {
      url = "/2_5_2_2_2.png";
      htmlId = 24;
    }
    else if (4 == id) {
      url = "/2_5_2_2_3.png";
      htmlId = 25;
    }
    else if (5 == id) {
      url = "/2_5_2_2_4.png";
      htmlId = 26;
    }
    else if (6 == id) {
      url = "/2_5_2_2_5.png";
      htmlId = 27;
    }
    else {
      url = "/2_5_2_2_6.png";
      htmlId = 28;
    }

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  runSubject = (e) => {
    this.setState({
      select: e.target.name
    });
    this.onClick(e.target.value)
  }

  handlePagination = (s, target) => {
    const { home, analysis: { operationSet } } = this.props;
    const { select } = this.state
    const query = { person: [operationSet[select]], sort: [`subjectCnt,desc`] }
    query.page = s.page;
    query.size = s.rowsPerPage
    const personQuery = { person: [operationSet[select]] }
    personQuery.page = s.page;
    personQuery.size = s.rowsPerPage
    switch (target) {
      case DetailTableTitle[0]:
        // home.getDetailCancerDate(`?${querylist}`);
        break;
      case DetailTableTitle[1]:
        home.getreturnGeneDate(query);
        break;
      case DetailTableTitle[2]:
        home.getreturnVariantDateAnaly(query, 'snvs');
        break;
      case DetailTableTitle[3]:
        home.getreturnVariantDate(query, 'cnvs');
        break;
      case DetailTableTitle[4]:
        home.getreturnVariantDate(query, 'svs');
        break;
      default:
        home.getreturnPersonDateAnaly(personQuery);
        break;
    }
  };
  onClick = (data) => {
    const { home, analysis, analysis: { operationSet }, comm: { originUser }, } = this.props
    const { reported } = this.state
    home.getValueData({ person: [data], reported: reported })
    home.getDxGraphicsData({ person: [data], reported: reported })
    home.getPersonGraphicsData({ person: [data], reported: reported })
    analysis.getChartData('cancers', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subject,desc`] })
    analysis.getChartData('genes', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] })
    analysis.getChartData('variants/snvs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] })
    analysis.getChartData('variants/cnvs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] })
    analysis.getChartData('variants/svs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] })
    home.getCancerTableData({ person: [data], reported: reported, sort: [`subject,desc`] })
    home.getreturnGeneDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 });
    home.getreturnVariantDateAnaly({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "snvs");
    home.getreturnVariantDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "cnvs");
    home.getreturnVariantDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "svs");
    if (originUser && ROLE.indexOf(originUser.authorities[0]) > -1) {
      home.getreturnPersonDateAnaly({ person: [data], reported: reported });
    }
  }

  onfirstClick = async (data) => {
    const { home, analysis, analysis: { operationSet }, comm: { originUser }, } = this.props
    const { reported } = this.state
    await this.setState({
      loading: true
    });
    let array = [
      home.getValueData({ person: [data], reported: reported }),
      home.getDxGraphicsData({ person: [data], reported: reported }),
      home.getPersonGraphicsData({ person: [data], reported: reported }),
      analysis.getChartData('cancers', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subject,desc`] }),
      analysis.getChartData('genes', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] }),
      analysis.getChartData('variants/snvs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] }),
      analysis.getChartData('variants/cnvs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] }),
      analysis.getChartData('variants/svs', { person: [data], unionPerson: [operationSet.unionAAndBAndCSerial], reported: reported, sort: [`subjectCnt,desc`] }),
      home.getCancerTableData({ person: [data], reported: reported, sort: [`subject,desc`] }),
      home.getreturnGeneDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }),
      home.getreturnVariantDateAnaly({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "snvs"),
      home.getreturnVariantDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "cnvs"),
      home.getreturnVariantDate({ person: [data], reported: reported, sort: [`subjectCnt,desc`], size: 5 }, "svs")
    ]
    if (originUser && ROLE.indexOf(originUser.authorities[0]) > -1) {
      home.getreturnPersonDateAnaly({ person: [data], reported: reported });
    }
    const promises = array.map(item => (item));
    await Promise.all(promises);
    await this.setState({
      loading: false
    });
  }


  downloadPatient = async () => {
    const { home, analysis: { operationSet } } = this.props
    const { reported, select } = this.state
    await this.setState({
      loading: true
    });
    let personSize = { person: [operationSet[select]], reported: reported }
    personSize.size = 10000;
    await home.getRowDataSearchPerson(personSize)
    const { home: { rowDataSearchPerson } } = this.props
    await this.setState({
      loading: false
    });
    let ws = XLSX.utils.json_to_sheet(rowDataSearchPerson.content);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Patient");
    XLSX.writeFile(wb, "PERSON_list.xlsx");
  }


  render() {
    const { classes,
      home: { PersonGraphicsData, dxAge,
        cancerTableData,
        returnGeneData,
        returnSnvData,
        returnCnvData,
        returnSvData,
        valueData,
        returnPersonData,
        lastUpdateDate
      },
      comm: { originUser },
      analysis: { operationSet, setListData, chartData } } = this.props;
    const { select, loading } = this.state
    let mainDataType = {}
    let NouserSetData = {}
    mainDataType.cancer = { type: 'Cancer', title: DetailTableTitle[0], columns: detailCancerColumns, data: cancerTableData, chart: chartData.cancers }
    mainDataType.gene = { type: 'Gene', title: DetailTableTitle[1], columns: genecolumns, data: returnGeneData, chart: chartData.genes }
    mainDataType.snv = { type: 'SNV', title: DetailTableTitle[2], columns: snvcolumns, data: returnSnvData, chart: chartData.snvs }
    mainDataType.cnv = { type: 'CNV', title: DetailTableTitle[3], columns: cnvcolumns, data: returnCnvData, chart: chartData.cnvs }
    mainDataType.sv = { type: 'SV', title: DetailTableTitle[4], columns: svcolumns, data: returnSvData, chart: chartData.svs }
    let selectNum = operationSet && select ? operationSet[select.replace('Serial', '')] : 0
    let totalNum = operationSet ? operationSet.unionAAndBAndC : 0
    // if (selectNum === totalNum) {
    //   selectNum = 0
    // }
    if (!originUser) {
      for (let i in noUserSetList) {
        let dat = noUserSetList[i].split('||')
        NouserSetData[Alies[i]] = {
          setsQuery: dat[2] ? dat[2] : '',
          title: dat[0] ? dat[0] : '',
          setsPersonNum: dat[3] ? dat[3] : 0
        }
      }
    }
    return (
      <div className={classes.background}>
        <div className={classes.operation}>

          <div className={classes.header}>
            
            <div className={classes.container}>  
              <div>SET OPERATIONS RESULT</div>                            
              {/* 20210408 add IconButton, CustomDialog by jdy */}
              <div style={{margin:'0px 2px 2px 20px'}}>
                <IconButton color="primary" key={0} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(0);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </div>
              {/* <div style={{margin:'0px 0px 2px 100px'}}>
                <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(1);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </div> */}
              {/* <div style={{margin:'5px 2px 2px -5px'}}>
                {'==>'}
              </div> */}
              <CustomDialog
                open={this.state.helpOpen}
                onClickOpen={this.onClose}
                imageUrl={this.state.imageUrl}
                htmlId={this.state.htmlId}
                CustomDialogContent={HelpContentList}
              />
            </div>
          </div>

          <VennTable operationSet={operationSet} isDouble={isDouble} setListData={originUser ? setListData : NouserSetData} onClick={this.runSubject} select={select} />
        </div>

        <div className={classes.line}></div>
        <div className={classes.explorer}>
          <StickyContainer>
            <Sticky  >
              {({ style }) => (
                <div style={style} className={classes.explorerHeader}>
                  <span style={{ paddingLeft: "16px", lineHeight: "3" }}>STATUS</span>
                  <div className={classes.totalTools}>
                    <div className={classes.selectTotal}>Select/Total, unit:#Subjects</div>
                    <div className={classes.selecticon}> <img src={Subject} alt="" className={classes.ICON} /></div>
                    <div className={classes.selectNum}>{operationSet && totalNum ? `${selectNum.toLocaleString()}  / ${totalNum.toLocaleString()}` : '0/0'}</div>
                  </div>
                  <TypeSubject valueData={valueData} IsOperation={true} />
                </div>
              )}
            </Sticky>

            <div className={classes.setTable}>
              <TopOperationSetList select={select} originUser={originUser} setListData={originUser ? setListData : NouserSetData} isDouble={isDouble} operationSet={operationSet} />
            </div>

            <div className={classes.mainback}>
              <AnalysisChartTable mainDataType={mainDataType} select={select} PersonGraphicsData={PersonGraphicsData} dxAge={dxAge} operationSet={operationSet} clickMainHelp={this.clickMainHelp}/>
              
              {/* 20210408 add IconButton, CustomDialog by jdy */}
              {/* <div className={classes.container}>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>Demography </div>
                <IconButton color="primary" key={2} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(2);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>Cancer </div>
                <IconButton color="primary" key={3} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(3);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>Gene </div>
                <IconButton color="primary" key={4} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(4);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>SNV </div>
                <IconButton color="primary" key={5} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(5);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>CNV </div>
                <IconButton color="primary" key={6} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(6);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
                <div style={{margin:'5px 2px 2px 20px', fontSize:'0.8em'}}>SV </div>
                <IconButton color="primary" key={7} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(7);}}>
                  <HelpIcon className={classes.helpIcon} />
                </IconButton>
              </div> */}

              <div className={classes.board}>
                <DetailAnalysis detailType={mainDataType.cancer} handlePagination={this.handlePagination}
                  type={mainDataType.cancer.type} />
              </div>
              <div className={classes.board}>
                <DetailAnalysis detailType={mainDataType.gene} handlePagination={this.handlePagination}
                  type={mainDataType.gene.type} />
              </div>
              <div className={classes.board}>
                <DetailAnalysis detailType={mainDataType.snv} handlePagination={this.handlePagination}
                  type={mainDataType.snv.type} />
              </div>
              <div className={classes.board}>
                <DetailAnalysis detailType={mainDataType.cnv} handlePagination={this.handlePagination}
                  type={mainDataType.cnv.type} />
              </div>
              <div className={classes.board}>
                <DetailAnalysis detailType={mainDataType.sv} handlePagination={this.handlePagination}
                  type={mainDataType.sv.type} />
              </div>




              <CheckPermission
                key={"patient"}
                permission={"ROLE_KM_USER"}
              >
                <div className={classes.board}>
                  <div className={classes.chartTitle}>PATIENT LIST ({returnPersonData ? returnPersonData.totalElements.toLocaleString() : 0}) </div>
                  <div className={classes.chartMargin}>
                    <PatientListTable
                      classes={classes}
                      downloadData={null}
                      returnPersonData={returnPersonData}
                      PatientColumns={PatientColumns}
                      handlePagination={this.handlePagination}
                      downloadPatient={this.downloadPatient}
                    />
                  </div>
                </div>
              </CheckPermission>
            </div>
            <div className={classes.footer}>
              <Footer lastUpdateDate={lastUpdateDate} />
            </div>
          </StickyContainer>
        </div>
        <Dialog
          // open={true}
          open={loading}
          classes={{ paper: classes.loadingPaper }}
          maxWidth={false}
        >
          <DialogContent className={classes.loading}>
            <CircularProgress
              classes={{ colorPrimary: classes.circlecolor }}
            />
          </DialogContent>
        </Dialog>

      </div>
    );
  }
}
export default withStyles(styles)(Operation);
