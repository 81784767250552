import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// 2020-03-30 add this file by jdy : for help menu

const MenuItem = ({ item, onClickMenu }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;

  return <Component item={item} onClickMenu={onClickMenu} />;
};

const hasChildren = (item) => {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}


const SingleLevel = ({ item, onClickMenu }) => {

  const handleClick = () => {
    onClickMenu(item.to, item.htmlId, item.title);
  };

  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item, onClickMenu }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
    // onClickMenu(item.to, item.htmlId, item.title);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} onClickMenu={onClickMenu} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default MenuItem;
