import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class BarChart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.series) !== JSON.stringify(this.props.series);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.series.length) {
  //     return {
  //       fooKey: prevState.fooKey + 1
  //     };
  //   }

  //   return null;
  // }

  render() {
    const {
      series: series2,
      pathname,
      chartImage
    } = this.props;
    let cancer = [];
    let data = [];
    let options = {
      chart: {
        backgroundColor: "#00ff0000",
        type: "column",
        marginRight: 30
      },
      title: {
        text: null
      }
    }
    if (pathname === "/" && series2) {
      series2.forEach((v, k) => {
        if (v.name === "null") {
          return true;
        }
        cancer.push(v.name.replace(" cancer", ""));
        data.push(v.subject);
      });

      options = {
        chart: {
          backgroundColor: "#00ff0000",
          type: "column",
          marginRight: 30
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: [
          {
            tickLength: 20,
            opposite: true,
            categories: cancer,
            labels: {
              useHTML: true,
              align: "center",
              /*
              formatter: function () {
                return `<img src=${
                  chartImage[this.value]
                  } style=" width:24px;height:24px;" ></img>`;
              }
              */

            /* 
              변경일자:   2020-06-25
              변경자 이름: 지신복 
              변경 내용:   화면 display 건수 변경
              변경 사유:   암종추가에 따른 화면 display 건수 변경                            
            */        
             formatter: function () {
              return ``;
             }
            }
          },
          {
            linkedTo: 0,
            gridLineDashStyle: "longdash",
            gridLineWidth: 0.5,
            gridLineColor: "#cbcbcb",
            categories: cancer,
            labels: {
              rotation: 45,
              style: {
                fontSize: "10px",
                fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
                color: "#ffffff"
              }
            }
          }
        ],
        yAxis: {
          gridLineDashStyle: "longdash",
          gridLineWidth: 0.5,
          gridLineColor: "#cbcbcb",
          min: 0,
          tickAmount: 8,
          labels: {
            style: {
              fontSize: "10px",
              fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
              color: "#ffffff"
            }
          },
          title: {
            text: "(Subject)",
            align: "high",
            rotation: 0,
            offset: 5,
            y: -15,
            style: {
              fontSize: "10px",
              fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
              color: "#ffffff"
            }
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointWidth: 20,
            borderWidth: 0,
            boxShadow: "0 1px 1px 0 rgba(89, 255, 203, 0.5)"
          }
        },
        series: [
          {
            name: "Subject",
            data: data,
            color: "#67ffc5"
          }
        ]
      };

    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        key={this.state.fooKey}
      />
    );
  }

}
export default BarChart;
