// Variant presentTab의 Gene Protein Chart 상단의 GENE type 선택 창의 list 항목을 생성하기 위한 model, by jdy 2020-12

class SelectedGeneListModel {
  constructor(data, proteinData) {
    this.id = []


    this.geneNames = [];

    this.geneName = proteinData ? proteinData.geneName : "";
    this.nNames = 0;

    this.geneNames.length = 0;

    if (data.length > 0)
    {
      this.nNames = data.length;
      for (let i of data) 
      {
        this.geneName = i.geneName;
        this.geneNames.push(this.geneName);
      }  
    }
    else
    {
      this.geneNames.push(this.geneName);
      this.nNames = 0;
    }

    // for (let i=0; i<this.nNames; i++)
    // {
    //   this.genNames.push(data[i].geneName);
    // }



  } // end constructor
}

export default SelectedGeneListModel;
