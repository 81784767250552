import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
// import CheckPermission from "components/lib/CheckPermission";
import OverView from "./overView"
import SystemStatistics from "./systemStatistics"
import SystemLeftMenu from "components/organisms/SystemLeftMenu";
import TimelineIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import AccessLog from "./accessLog";
import SystemRouter from "shared/SystemRouter";

import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from "components/atoms/CustomDialog";
import HelpContentList from "components/atoms/CustomDialog/HelpContentList";

const styles = theme => ({
  container: {
    display: 'flex'
  },
  display: {
    borderTop: '1px solid #c6c6c6',
    height: "100%",
    padding: "50px 100px 0px 100px"
  },
  maintitle: {
    fontSize: "30px",
    textAlign: "center",
    color: "#4a4a4a",
    display: "flex",
    paddingBottom: "10px",
  },
  mainTable: {
    border: "solid 1px #c6c6c6",
    borderBottom: "0px",
    backgroundColor: "#f6f6f6",
    display: "flex"
  },
  helpIconButton: {
    color: "#3c82be",
    padding: "-4px, 0px, 0px, 5px"
  },
  helpIcon: {
    fontSize: "15px"
  },
  items: {
    padding: "0px 8px",
    color: "#7d7d7d",
    backgroundColor: "#00ff0000",
    borderBottom: "solid 1px #737272",
    minHeight: "40px"
  },
  selected: {
    color: "#ffffff",
    backgroundColor: "#00c8a3 !important"
  }
})

let list = [{ title: "OVERVIEW", icon: <TimelineIcon />, main: <OverView />, name: "OVERVIEW", url: "/system" },
{ title: "SYSTEM STATISTICS", icon: <BarChartIcon />, main: <SystemStatistics />, name: "SYSTEM STATISTICS", url: "/system/static" },
  // { title: "ACCESS LOG", icon: <ListAltIcon />, main: <AccessLog />, name: "ACCESS LOG" },
]

@inject("comm")
@observer
class SystemPage extends Component {
  state = {
    tab: 0,
    helpOpen: false,
    imageUrl: "help/2_7_1.png",
    htmlId: 35
  }
  constructor(props) {
    super(props);
    for (let i in list) {
      if (list[i].url === props.location.pathname) {
        this.state = {
          tab: parseInt(i)
        }
      }
    }
  }

  componentDidUpdate() {
    for (let i in list) {
      if (list[i].url === this.props.location.pathname && this.state.tab !== parseInt(i)) {
        this.setState({
          tab: parseInt(i)
        })
      }
    }
  }

  // 20310409 add help function : clickMainHelp, onClose by jdy
  clickMainHelp = (id) => {
    const { tab } = this.state;

    let url;
    let htmlId;

    if (0 == id) { // Overview
      if (0 == tab) {
        url = "/2_7_1.png";
        htmlId = 35;
      }
      else { // if (1 == tab) 
        url = "/2_7_2.png";
        htmlId = 36;  
      }
    }
    else if (1 == id) { // Statistics
      url = "/2_7_2.png";
      htmlId = 36;
    }
    else if (2 == id) { // Computing Resource
      url = "/2_7_2_1.png";
      htmlId = 37;
    }
    else if (3 == id) { // Storage Resource
      url = "/2_7_2_2.png";
      htmlId = 38;
    }
    else if (4 == id) { // Network Resource
      url = "/2_7_2_3.png";
      htmlId = 39;
    }
    else {
      url = "/2_7_1.png";
      htmlId = 35;
    }

    this.setState({
      helpOpen: true,
      imageUrl: "help" + url,
      htmlId: htmlId
    });
  };

  onClose = () => {
    this.setState({
      helpOpen: false
    });
  };

  handleClick = (e, i) => {
    const { history } = this.props
    this.setState({ tab: i });
    history.push(list[i].url)
  }

  render() {
    const { classes, match, comm: { user } } = this.props
    const { tab } = this.state;

    // 20310409 add help menu by jdy
    let helpList = [];

    helpList.length = 0;

    if (0 == tab) {
      helpList.push(
        <div style={{margin:'15px 2px 2px 20px', fontSize:'0.8em'}}>System Statistics </div> 
      );
      helpList.push(
        <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(1);}}>
            <HelpIcon className={classes.helpIcon} />
        </IconButton> 
      );
    }
    else if (1 == tab) {
      helpList.push(
        <div style={{margin:'15px 2px 2px 20px', fontSize:'0.8em'}}>Computing Resource </div> 
      );
      helpList.push(
        <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(2);}}>
            <HelpIcon className={classes.helpIcon} />
        </IconButton> 
      );
      helpList.push(
        <div style={{margin:'15px 2px 2px 20px', fontSize:'0.8em'}}>Storage Resource </div> 
      );
      helpList.push(
        <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(3);}}>
            <HelpIcon className={classes.helpIcon} />
        </IconButton> 
      );
      helpList.push(
        <div style={{margin:'15px 2px 2px 20px', fontSize:'0.8em'}}>Network Resource </div> 
      );
      helpList.push(
        <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(4);}}>
            <HelpIcon className={classes.helpIcon} />
        </IconButton> 
      );
    }
    else {
      helpList = [];

      helpList.length = 0;
    }
    //

    if (user) {
      return (

        <div className={classes.display}>
          <div className={classes.container}> 
            <div className={classes.maintitle}>
              {list[tab].title}
            </div>

            <div style={{margin:'0px 0px 2px 2px'}}>
              <IconButton color="primary" key={1} className={classes.helpIconButton} onClick={()=>{this.clickMainHelp(0);}}>
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </div>

            {helpList}

            <CustomDialog
              open={this.state.helpOpen}
              onClickOpen={this.onClose}
              imageUrl={this.state.imageUrl}
              htmlId={this.state.htmlId}
              CustomDialogContent={HelpContentList}
            />
          </div>

          <div className={classes.mainTable}>
            <SystemLeftMenu list={list} tab={tab} handleClick={this.handleClick} />
            <div style={{ width: "100%", minHeight: "800px" }}>
              <SystemRouter match={match} />
              {/* {list[tab].main} */}
            </div>
          </div>
        </div>

      );
    } else {
      return (
        null
      )
    }

  }
}

export default withStyles(styles)(SystemPage);
