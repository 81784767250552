import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import OverView from "pages/Admin/overView"
import AccountManage from "pages/Admin/accountManage"
import QATable from "pages/Admin/qAList"
import Answer from "pages/Admin/answer";
import SyncLog from "pages/Admin/syncLog";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

class AdminRouter extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Switch>
          <PrivateRoute path={`${match.url}/useraccount`} component={AccountManage} />
          <PrivateRoute path={`${match.url}/qaboard/:id`} component={Answer} />
          <PrivateRoute path={`${match.url}/qaboard`} component={QATable} />
          <PrivateRoute path={`${match.url}/synclog`} component={SyncLog} />
          <PrivateRoute path={`${match.url}`} component={OverView} />
        </Switch>
      </>
    );
  }
}

export default AdminRouter;
