import React from "react";
import portal from "assets/images/button/kmaster.svg";
import match from "assets/images/button/matchmaster.svg";
import MatchButton from "../MatchButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  loading: {
    padding: "16px 20px"
  },
  loadingPaper: {
    backgroundColor: "#00ff0000",
    boxShadow: "none",
    width: "95px"
  },
  circlecolor: {
    color: "#ffffff"
  }
});

const icon = {
  portal,
  match
};

@inject("system")
class SyncUpdateButton extends React.Component {
  state = {
    loading: false
  };

  onClick = e => {
    e.stopPropagation();
    this.setState({
      loading: true
    });
    this.props.system
      .updateCdm({
        // id: this.props.id,
        portal: true
      })
      .then(value => {
        if (value) {
          this.setState({
            loading: false
          });
          this.props.system.loadSync();
          this.props.system.loadUpdateSync();
        } else {
          this.setState({
            loading: false
          });
        }
      });
  };

  getStyle = () => {
    const opacity = this.props.portal ? 0.7 : 1;
    return {
      background: "none",
      border: "none",
      cursor: "pointer",
      opacity,
      color: "#3e3e3e",
      // border: "0px !important",
      fontSize: "12px",
      boxShadow: "1px 1px 3px 0 rgba(121, 121, 121, 0.8)",
      fontWeight: "normal",
      lineHeight: "normal",
      borderRadius: "1px",
      textTransform: "none",
      backgroundColor: "#fcfcfc",
      padding: "5px 16px"
    };
  };

  render() {
    const { type, portal, classes } = this.props;
    const { loading } = this.state;
    return (
      <>
        <MatchButton
          title={type}
          disabled={portal}
          style={this.getStyle()}
          onClick={this.onClick}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={icon[type]} alt="" style={{ marginRight: 3 }} />
            {portal ? "Updated" : "Update"}
          </div>
        </MatchButton>
        <Dialog
          open={loading}
          classes={{ paper: classes.loadingPaper }}
          maxWidth={false}
        >
          <DialogContent className={classes.loading}>
            <CircularProgress classes={{ colorPrimary: classes.circlecolor }} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(SyncUpdateButton);
