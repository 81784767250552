import React, { Component } from "react";
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";

// const basicColorList = [
//   // "#206e70",
//   // "#26a1a4",
//   // "#67babc",
//   // "#a0d2d3",
//   // "#cae6e6",
//   "#5c4306",
//   "#8a6c1c",
//   "#ca9918",
//   "#ffc429",
//   "#f6de9f",
// ];

// let e
// (function (H) {
//   //internal functions
//   function stopEvent(e) {
//     if (e) {
//       if (e.preventDefault) {
//         e.preventDefault();
//       }
//       if (e.stopPropagation) {
//         e.stopPropagation();
//       }
//       e.cancelBubble = true;
//     }
//   }

//   // the wrap
//   // bug fixed by jdy, 2020-12
//   H.wrap(H.Chart.prototype, 'render', function(proceed) {
//     var chart = this,
//       mapNavigation = chart.options.mapNavigation;

//     proceed.call(chart);

//     // Add the mousewheel event
//     H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'mousewheel', function(event) {

//       var delta, extr, step, newMin, newMax, axis = chart.xAxis[0];
//       var dataMax = chart.xAxis[0].dataMax,
//         dataMin = chart.xAxis[0].dataMin,
//         newExtrMin,
//         newExtrMax;

//       e = chart.pointer.normalize(event);
//       // Firefox uses e.detail, WebKit and IE uses wheelDelta
//       delta = e.detail || -(e.wheelDelta / 120);
//       delta = delta < 0 ? 1 : -1;

//       if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
//         extr = axis.getExtremes();
//         step = (extr.max - extr.min) / 5 * delta;

//         if ((extr.min + step) <= dataMin) {
//           newExtrMin = dataMin;
//           // newExtrMax = extr.max;
//           newExtrMax = newExtrMin + 4.5;
//         } else if ((extr.max + step) >= dataMax) {
//           // newExtrMin = extr.min;
//           newExtrMin = dataMax - 4.5;
//           newExtrMax = dataMax;
//         } else {
//           newExtrMin = extr.min + step;
//           newExtrMax = newExtrMin + 4.5;
//           // newExtrMax = extr.max + step;
//         }

//         if (extr.max > extr.dataMax){
//           newExtrMin = extr.dataMax - 4.5;// - 2.5;
//           newExtrMax = extr.dataMax;// - 2.5;
//         }
//         else if (extr.min < extr.dataMin){
//           newExtrMin = extr.dataMin;// + 3.5;
//           newExtrMax = extr.dataMin + 4.5;// + 3.5;
//         }
//         axis.setExtremes(newExtrMin, newExtrMax, true, false);

//       }

//       stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
//       return false;
//     });
//   });
// }(Highcharts));

// Highcharts.wrap(Highcharts.Axis.prototype, 'getLinePath', function (proceed, lineWidth) {
//   var axis = this,
//     path = proceed.call(this, lineWidth),
//     x = path[1],
//     y = path[2];

//   Highcharts.each(this.breakArray || [], function (brk) {
//     if (axis.horiz) {
//       x = axis.toPixels(brk.from);
//       path.splice(3, 0,
//         'L', x - 4, y, // stop
//         'M', x - 9, y + 5, 'L', x + 1, y - 5, // left slanted line
//         'M', x - 1, y + 5, 'L', x + 9, y - 5, // higher slanted line
//         'M', x + 4, y
//       );
//     } else {
//       y = axis.toPixels(brk.from);
//       path.splice(3, 0,
//         'L', x, y - 4, // stop
//         'M', x + 5, y - 9, 'L', x - 5, y + 1, // lower slanted line
//         'M', x + 5, y - 1, 'L', x - 5, y + 9, // higher slanted line
//         'M', x, y + 4
//       );
//     }
//   });
//   return path;
// });

/**
* On top of each column, draw a zigzag line where the axis break is.
*/

function pointBreakColumn(e) {
  var point = e.point,
    brk = e.brk,
    shapeArgs = point.shapeArgs,
    x = shapeArgs.x,
    y = this.translate(brk.from, 0, 1, 0, 1),
    w = shapeArgs.width,
    key = ['brk', brk.from, brk.to],
    path = ['M', x, y, 'L', x + w * 0.25, y + 4, 'L', x + w * 0.75, y - 4, 'L', x + w, y];

  if (!point[key]) {
    point[key] = this.chart.renderer.path(path)
      .attr({
        'stroke-width': 2,
        stroke: point.series.options.borderColor
      })
      .add(point.graphic.parentGroup);
  } else {
    point[key].attr({
      d: path
    });
  }
}

class Absolute2Chart extends Component {
  state = {
    fooKey: 1
  };

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.list !== nextProps.list || this.props.mainFilterObject !== nextProps.mainFilterObject
    // return true;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list) {
      return {
        fooKey: prevState.fooKey + 1
      };
    }

    return null;
  }



  render() {
    const { type, list } = this.props;
    let returnData = [];
    let name = [];
    let options = {};
    let titleName;
    let series = [];
    if (list) {
      name = list.name
      returnData[0] = list.totalSubject[0]
      series = list.series
    }
    /* 
	    변경일자:   2020-06-25
	    변경자 이름: 지신복 
	    변경 내용:   화면 display 건수 변경
	    변경 사유:   암종추가에 따른 화면 display 건수 변경                            
	  */
    /*
     Drug Actionable Chart 추가로 인한 
     modified by jdy, 2021-01
    */
    if (list && type === "Cancer") {
      titleName = list.id.length > 17 ? `Subjects by ${list.id.length} Cancers` : `Subjects by ${list.id.length} Cancers`;
    } 
    else if (list && type === "Gene") {
      titleName = list.id.length > 19 ? `Subjects by Top 20 Genes` : `Subjects by ${list.id.length} Genes`;
    } 
    else if (list && type === "Drug") {
      titleName = list.id.length > 19 ? `Subjects by Top 20 Drug Actionables` : `Subjects by ${list.id.length} Drug Actionables`;
    } 
    else if (list) {
      titleName = list.id.length > 19 ? `Subjects by Top 20 Variants` : `Subjects by ${list.id.length} Variants`;
    }

    if (list && (list.length === 0 || list.totalPages === 0)) {
      titleName = "No Data";
    }

    options = {
      chart: {
        type: "bar",
        marginLeft: list ? list.marginLeft : 95,
        marginRight : 35,
        marginTop: 30,
        height: 200
      },

      title: { text: null },
      subtitle: { text: titleName },
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          autoRotationLimit: 10,
          formatter: function () {
            return typeof this.value !== 'number' ? this.value : ''
          },
          style: {
            fontSize: "9px"
          }
        },
        scrollbar: {
          enabled: true,
          liveRedraw: true,
          buttonBackgroundColor: '#f2f2f2',
          buttonBorderColor: "#f2f2f2",
          barBorderRadius: 5,
          barBorderWidth: 2,
          barBorderColor: "#f2f2f2",
          rifleColor: "#cccccc",
          trackBorderWidth: 5,
          trackBorderRadius: 2,
          height: 10
        },
        categories: name,
        min: 0,
        max: 4.5,
        tickLength: 0,
        // bug fixed by jdy, 2020-12
        // events: {
        //   afterSetExtremes: function () {
        //     var xAxis = this,
        //       // numberOfPoints = xAxis.series[0].points.length - 1,    // jdy 2020-12-10           
        //       numberOfPoints = xAxis.series[0].xData.length - 1,
        //       minRangeValue = xAxis.getExtremes().min,
        //       maxRangeValue = xAxis.getExtremes().max;

        //     if (minRangeValue < 0) {
        //       xAxis.setExtremes(0.2, xAxis.options.max);
        //     } 
        //     else if (maxRangeValue > numberOfPoints) {
        //       xAxis.setExtremes(
        //         numberOfPoints - xAxis.options.max + 1,
        //         numberOfPoints
        //       );
        //     }
        //   }
        // }
        // visible: false
      },
      yAxis: {
        allowDecimals: false,
        gridLineDashStyle: "longdash",
        gridLineWidth: 0.5,
        // tick display : modified by jdy, 2020-12
        tickPositions: list ? list.tickPositions : [0],
        //tickPixelInterval: list ? list.tickPixelInterval : 20,
        //tickInterval: list ? list.tickInterval : 20,
        // tickAmount: 6,
        // softMax: returnData[0] || 800,
        min: 0,
        max: list ? list.totalSubjectCnt[0] : 3500,
        // lineWidth: 2,
        breaks: [{
          from: list ? list.breaksFrom : 900,
          to: list ? list.breaksTo : 3500          
          // to: 5000
        }],
        events: {
          pointBreak: pointBreakColumn
        },
        labels: {
          formatter: function () {
            return this.value.toLocaleString();
          },
          rotation: 0
        },
        title: {
          text: "(Subject)",
          align: "high",
          rotation: 0,
          offset: 0,
          y: 20,
          style: {
            fontSize: "10px",
            fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
            color: "#434343"
          }
        }
      },
      legend: {
        itemDistance: 5,
        itemStyle: { "fontSize": "10px" },
        labelFormatter: function () {
          if (this.name.length > 7) {
            return `${this.name.slice(0, 5)}...`
          }
          else {
            return this.name
          }
        }
        // enabled: false
      },
      tooltip: {
        shared: true,
        backgroundColor: "#616161",
        formatter: function () {
          return this.points.reduce(function (s, point) {
            if (point.y > 0) {
              return s + '<br/><span style="color:' + point.color + '">\u2B24</span><span style="color: #ffffff">' + point.series.name + ': ' +
                point.y.toLocaleString() + '</span>';
            } else {
              return s;
            }
          }, '<b><div><span style="color: #ffffff;font-weight:bold;font-size:13px !important;">' + this.x + ' (#Subject)</span></div></b>');
        }
      },
      plotOptions: {
        bar: {
          stacking: "normal",
          grouping: false,
          // shadow: false,
          // cropThreshold: 1000 change as below jdy 2020-12
          cropThreshold: 20
        }
      },
      series: series,
    };
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
        key={this.state.fooKey}
      />
    );
  }
}
export default Absolute2Chart;
