import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import DetailTable from "components/organisms/DetailTable";
import queryString from "query-string";
import { withStyles } from "@material-ui/core/styles";
import PageTemplate from "pages/template/pageTemplate";
import LeftMenu from "components/organisms/LeftMenu";

const styles = theme => ({});

let queryObj = {};

@inject("summary", "comm", "home")
@observer
class DetailView extends Component {
  state = {
    value: "",
    isSearch: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Object.entries(nextProps.comm.selectedObj).length > 0 &&
      prevState.isSearch
    ) {
      return {
        value: "",
        isSearch: false
      };
    }
    return null;
  }

  setValue = ({ value, isSearch }) => {
    this.setState({
      value,
      isSearch
    });
  };

  onSearchPerson = () => {
    const {
      summary,
      comm: { reported }
    } = this.props;
    const { value } = this.state;

    const queryObj = { reported };

    if (value.length <= 5 && value.length > 0) {
      const zero = Array(5 - value.length)
        .fill(0)
        .toString()
        .replace(/,/gi, "");

      Object.assign(queryObj, {
        personID: `S${zero}${value}`
      });
    } else if (value.length === 6) {
      if (value.charAt(0).toLowerCase() === "s") {
        Object.assign(queryObj, {
          personID: value
        });
      }
    } else {
      alert("The search term is incorrect.");
      this.setValue({ value: "", isSearch: false });
      return;
    }
    this.setValue({ value: queryObj.personID, isSearch: true });
    summary.loadRowDataSearch(`?${queryString.stringify(queryObj)}`);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.init();
    }
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const {
      summary,
      home,
      comm,
      comm: { reported },
      location: { search }
    } = this.props;

    comm.setCurPage("Detailed View");
    comm.setCurTab(0);
    // comm.resetTableHeadName();
    queryObj = {};

    const paramObj = queryString.parse(search);

    // for (let elem in paramObj) {
    //   if (elem !== "cancerType") comm.setTableHeadName(paramObj[elem], elem);
    // }
    comm.setSelectedObj(paramObj);

    let query = Object.assign(queryObj, paramObj);
    delete query.cancerName;
    if (reported) query.reported = reported;

    summary.loadRowDataSearch(`?${queryString.stringify(query)}`);

    home.getCategoryTotalCounts();
    this.setValue({ value: "", isSearch: false });
  };

  render() {
    const {
      location: { pathname },
      summary: { rowDataSearchMap },
      comm: { selectedObj }
    } = this.props;

    let returnArr = [];
    rowDataSearchMap.forEach((v, k) => {
      returnArr = returnArr.concat(
        <DetailTable
          key={k}
          list={v}
          length={v.length}
          k={k}
          setValue={this.setValue}
          isPagination={true}
          onSearchPerson={this.onSearchPerson}
          selectedObj={selectedObj}
          {...this.state}
        />
      );
    });

    return (
      <PageTemplate
        pathname={pathname}
        LeftNav={LeftMenu}
        isLeftNavi={true}
        content={returnArr}
      />
    );
  }
}

export default withStyles(styles)(DetailView);
