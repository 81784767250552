import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuItem from "./MenuItem";

// 2020-03-30 add this file by jdy : for help menu


// const ListItemBody = ({config}) => {
//   return (<>
//       <ListItemIcon>{config.icon}</ListItemIcon>
//       <ListItemText primary={config.title} />
//   </>);
// };

// const ExpandableMenuItem = ({config, open}) => {

//   return (
//     <div component="nav">
//       <ListItem button onClick={this.handleClick}>
//         <ListItemBody config={config}/>
//         {open ? <ExpandLess /> : <ExpandMore />}
//       </ListItem>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         {/* <Menu items={config.items} /> */}
//         <List>{this.createList(config.items)}</List>
//       </Collapse>
//     </div>
//   );
// };

// const MenuItem = ({config, onClickMenu}) => {

//   return (
//     <ListItem button  onClick={onClickMenu(config.to)}>
//       <ListItemBody config={config}/>
//     </ListItem>
//   );
// };

class HelpMenu extends Component {

//   state = {
//     open : false
//   }


//   Menu({ items }) {
//     return <List>{this.createList(items)}</List>;
//   }



//   handleClick = () => {
//     this.setState({
//       open: !this.state.open
//     });
//   };



//  createList = (items) => {

//     let menu = [];
//     items.map((menuItem) => {
//       if (Array.isArray(menuItem.items) && menuItem.items.length > 0) {
//         menu.push(<ExpandableMenuItem
//           open={false}
//           config={menuItem}
//           key={menuItem.title}
//         />);
//       } else {
//         menu.push(<MenuItem
//           config={menuItem}
//           key={menuItem.title}
//         />);
//       }
//     });
//     return menu.concat();
//   }


  render() {

    const {items, onClickMenu} = this.props;

    return items.map((item, key) => <MenuItem key={key} item={item} onClickMenu={onClickMenu} />);
  }
}

export default HelpMenu;