import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/core/styles";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class NameIconBtn extends Component {
  onButtonClick = e => {
    const { handlePageButtonClick, data } = this.props;
    handlePageButtonClick(e, data - 1);
  };

  render() {
    const { page, data } = this.props;
    return (
      <IconButton disabled={page === data - 1} onClick={this.onButtonClick}>
        {this.props.data}
      </IconButton>
    );
  }
}

class CustomPagination extends Component {
  handleFirstPageButtonClick = e => {
    this.props.onChangePage(e, 0);
  };

  handleLastPageButtonClick = e => {
    const { onChangePage, count, rowsPerPage } = this.props;
    onChangePage(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  handlePageButtonClick = (e, data) => {
    this.props.onChangePage(e, data);
  };

  handleBackButtonClick = event => {
    const { onChangePage, page } = this.props;
    onChangePage(event, page - 1);
  };

  handleNextButtonClick = event => {
    const { onChangePage, page } = this.props;
    onChangePage(event, page + 1);
  };

  renderNumber = () => {
    const { count, page, rowsPerPage } = this.props;

    let totPages = Math.ceil(count / rowsPerPage);
    const pageGroupSize = 5;
    const updatePage = Math.floor(pageGroupSize / 2);

    let endPage = 0,
      startPage = 0;
    if (page < Math.ceil(pageGroupSize / 2)) {
      endPage = pageGroupSize;
      startPage = endPage - pageGroupSize + 1;
    } else if (totPages - updatePage < page + 1) {
      endPage = totPages;
      startPage = totPages - pageGroupSize + 1;
    } else {
      startPage = page - updatePage + 1;
      endPage = page + updatePage + 1;
    }

    if (endPage > totPages) {
      endPage = totPages;
    }

    if (startPage < 1) {
      startPage = 1;
    }

    const numList = [];
    for (let i = startPage; i <= endPage; i++) {
      numList.push(
        <NameIconBtn
          key={i}
          data={i}
          page={page}
          handlePageButtonClick={this.handlePageButtonClick}
        />
      );
    }

    return numList;
  };

  render() {
    const { classes, count, page, rowsPerPage } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          <KeyboardArrowLeft />
        </IconButton>

        {this.renderNumber()}

        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(actionsStyles, {
  withTheme: true
})(CustomPagination);
