
class CancerModel {
  constructor(data) {
    this.id = []
    this.name = []
    this.subject = []
    this.cancers = {}
    this.all = []
    for (let i in data) {

      this.id.push(data[i].id);
      this.name.push(data[i].name.replace(" cancer", "").replace(" carcinoma", ""));
      let detail = Array(data.length).fill(0);
      detail[i] = data[i].subject
      this.subject.push(detail);
      this.all.push(data[i].subject)
      for (let j in data[i].details) {
        let detail = Array(data.length).fill(0);
        let name = data[i].details[j].id
        this.cancers[name] = []
        detail[i] = data[i].details[j].subject
        this.cancers[name].push(detail)
      }
    }
  }
}

export default CancerModel;
