import React, { Component } from "react";
import TabHeader from "components/atoms/TabHeader";
import TabContainer from "components/atoms/TabContainer";
// import CancerTab from "components/molecules/CancerTab";
// import GeneTab from "components/molecules/GeneTab";
// import VariantTab from "components/molecules/VariantTab";
import { withStyles } from "@material-ui/core/styles";

const tabList = ["Cancer", "Gene", "Variant"];
// const contentList = [CancerTab, GeneTab, VariantTab];

const styles = theme => ({
  nav: {
    padding: "15px 15px 0px 100px",
    width: '290px',
    // verticalAlign: 'top',
    // width: 'auto',
    // bottom: '10px',
    // left: '11vw',
    // overflowY: 'auto'
    "@media (max-width: 1500px)": {
      padding: "15px 15px 0px 0px",
      //   margin: 'auto'
    },
  },
  // tabhead: { // tabsize 에 의해 default 설정되는 것 같음.
  //   width: "291px",
  //   height: "30px",
  //   padding: "0px"
  // },
  tabsize: {
    width: "97px",
    height: "30px",
    padding: "0px"
  },
  tabcontainer: {
    width: '288px',
    minHeight: "800px",
    height: 'calc(100% - 30px)',
    background: "#f6f6f6",
    // height: "auto",
    border: "solid 1px #c6c6c6",
    borderTop: "0px",
  }
});

//왼쪽 탭 메뉴 데이터필터
class LeftMenuContainer extends Component {

  render() {
    const {
      classes,
      filterTab,
      onClearReset,
      onChangeFilterTab,
      ...rest
    } = this.props;

    return (
      <div className={classes.nav}>
        <div className={classes.tabhead}>
          <TabHeader
            tabList={tabList}
            value={filterTab}
            onChangeTab={onChangeFilterTab}
            tabstyle={classes.tabsize}
          />
        </div>
        <div className={classes.tabcontainer}>
          <TabContainer
            tab={filterTab}
            clear={onClearReset}
            {...rest}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LeftMenuContainer);
