import LoginRepository from "../repository/LoginRepository";

import { observable, action} from "mobx";
import { axios } from 'dependencyLibrary';

export default class LoginStore {

  // 2021.09.27 add loginInfoObj by jdy : jwt_token
  @observable loginInfoObj = {};

  constructor(root) {
    this.root = root;
  }

  @action
  setLoginInfo(loginInfo) {
    this.loginInfoObj = loginInfo;
  }

  @action
  getLoginInfo() {
    return this.loginInfoObj;
  }

  @action
  async login(loginInfo) {
    try {
      const {
        data: {
          id_token
        }
      } = await LoginRepository.requestLogin(loginInfo);

      //

      //console.log("Debug token : ", id_token);

      this.setAuthInHeader(id_token);
      if (!loginInfo.rememberMe) {
        sessionStorage.setItem("token", id_token);
      } else {
        localStorage.setItem("token", id_token);
      }
      this.root.comm.setLoggedIn(!!id_token);
      this.loadUser();
      return 1
    }
    catch (e) {
      return null
    }
  }

  @action
  logout() {
    this.root.comm.setLoggedIn(false);
    this.root.comm.setUser(null);
    axios.setAuthInHeader(null);

    delete localStorage.token;
    delete sessionStorage.token;
  }

  @action
  async loadUser() {
    const {
      data
    } = await LoginRepository.loadUser();
    this.root.comm.setUser(data);
  }

  @action
  async registerUser(registrationInfo) {
    const data = await LoginRepository.rigisterUser(registrationInfo);
    return data;
  }

  @action
  async activateUser(registrationInfo) {
    const data = await LoginRepository.activateUser(registrationInfo);
    return data;
  }

  @action
  async updateUser(updatedInfo) {
    const {
      firstName,
      lastName,
      institute
    } = updatedInfo;
    const data = await LoginRepository.updateUser({
      firstName,
      lastName,
      institute
    });
    this.root.comm.setUser(updatedInfo);
    return data;
  }

  @action
  setAuthInHeader(id_token) {
    axios.setAuthInHeader(id_token);
  }

  @action
  async postSendEmail(registrationInfo) {
    const data = await LoginRepository.postSendEmail(registrationInfo);
    return data;
  }

  @action
  async postChangePassword(registrationInfo) {
    const data = await LoginRepository.postChangePassword(registrationInfo);
    return data;
  }


}