const basicColorList = [
  "#E7E2BE",
  "#BFBFBF",
  "#2A292B",
  "#646464",
  "#869594",
  "#B5877C",
  "#E6DFD1",
  "#6EA1A5",
  "#5D728A",
  "#929EB0",
  "#E7E2BE",
  "#BFBFBF",
  "#2A292B",
  "#646464",
  "#869594",
  "#B5877C",
  "#E6DFD1",
  "#6EA1A5"
];

class ChartModel {
  constructor(data, i) {
    const {
      name,
      specimenCount,
      value,
      id,
      personCount,
      personTotalCount,
      specimenTotalCount,
      cancerPersonTotalCount,
      cancerSpecimenTotalCount
    } = data;
    this.name = name.replace(" cancer", "");
    this.x = value;
    this.y = specimenCount;
    this.personCount = personCount;
    this.id = id;
    this.color = basicColorList[i];
    this.personTotalCount = personTotalCount;
    this.specimenTotalCount = specimenTotalCount;
    this.cancerPersonTotalCount = cancerPersonTotalCount;
    this.cancerSpecimenTotalCount = cancerSpecimenTotalCount;
  }
}

export default ChartModel;
