import React, { Component } from "react";
import CustomTable from "components/atoms/CustomTable";
import MultiCell from "components/atoms/CustomTable/MultiCell";
// import { teal } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

const columnList = [
  {
    column: "Authority",
    key: "authorities",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "First name",
    key: "firstName",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "Last name",
    key: "lastName",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "Email",
    key: "email",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "Latest access",
    key: "lastModifiedDate",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "Institute",
    key: "institute",
    link: false,
    align: "center",
    scroll: false
  },
  {
    column: "Status",
    key: "activated",
    link: false,
    align: "center",
    scroll: false
  }
];

const styles = theme => ({
  container: {
    padding: "40px 50px",
    background: "#fff",
    margin: "10px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14)",
    border: "2px solid ",
    borderColor: "white",
    "&:hover": {
      borderColor: '#26a1a4'
    }
  },
  tableHeader: {
    display: "flex",
    marginBottom: 5
  },
  leftHeader: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "5px"
  }
});

class AccountTableContainer extends Component {
  render() {
    const { classes, title, subTitle, ...rest } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.tableHeader}>
          <div className={classes.leftHeader}>
            <h3>{title}</h3>
            <p>{subTitle}</p>
          </div>
        </div>
        <CustomTable
          {...rest}
          orderBy={columnList[0].key}
          columnList={columnList}
          Component={MultiCell}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AccountTableContainer);
