class TopFiveVariantModel {
  constructor(data, i) {
    const { gene, hgvsp, hgvsp1digit } = data;
    this.gene = gene;
    this.hgvspl = hgvsp;
    this.hgvsp1digit = hgvsp1digit;
  }
}

export default TopFiveVariantModel;
