import React, { useMemo } from "react";
import CustomExcute from "./ui/CustomExcute";
import CustomLink from "./ui/CustomLink";
import CustomInput from "./ui/CustomInput";
import CustomMultiCell from "./ui/CustomMultiCell";
import { makeStyles } from "@material-ui/core/styles";
import ChooseItemName from "./ChooseItemName";


const useStyles = makeStyles({
  body: {
    fontSize: "12px",
    wordBreak: "break-all",
    padding: "5px 5px",
    display: "inline-block"
  },
  bodyFlex: {
    fontSize: "12px",
    wordBreak: "break-all",
    padding: "15px 15px 15px 15px",
    display: "inline-flex"
  },
  buttonSelect: {
    //background: "#5f06a0",
    textAlign: 'center',
    border: "1px solid silver",
    boderRadius: '5px',
    cursor: "pointer",
    height: '20px',
    width: 80,
    color: '#0f000f',
    backgroundColor: '#afc6c0',
    //padding: '8px 20px'
  },
  button: {
    //background: "#f0f0f0",
    textAlign: 'center',
    border: "1px solid silver",
    boderRadius: '5px',
    cursor: "pointer",
    height: '20px',
    width: 80,
    color: '#f5ff5f6',
    backgroundColor: '#f0f0f0',
    //padding: '8px 20px'
  },

  paper: {
    height: '15px',
    width: 200,
    border: 'none'
  },
  paperSelect: {
    height: '15px',
    width: 200,
    border: 'none'

  },
});

const dataFormat = str => {
  let retStr = str;

  if (typeof str === "number") {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return Number(retStr)
      .toString()
      .replace(regexp, ",");
  }

  if (typeof str === "boolean") {
    return String(retStr);
  }

  if (typeof str === "string" && str.length > 30) {
    return `${str.slice(0, 30)}...`;
  }

  if (!str) {
    return "-";
  }

  return retStr;
};

function handleClick(props) {
  const {
    d,
    detailType,
    detail,
    property,
    options,
    options: { link, isEditable, withCellExcute },
    handleLink,
    isLink,
    index
  } = props;
  alert(detailType.type);
}
// TODO: rendering


function CustomCellContent(props) {
  const {
    onClickEvent,
    d,
    detailType,
    detail,
    property,
    options,
    options: { link, isEditable, withCellExcute },
    handleLink,
    isLink,
    index
  } = props;

  const value = detail ? detail[property] : d[property];
  let children = value;

  // SNV Table 에 최대 5개의 Cancer type을 display 하여 선택할 수 있도록 한다. added by jdy 2020-12
  let snvCancerList = [];
  snvCancerList.length = 0;
  if (detailType && detailType.type == 'SNV' && property == 'selectedCancerLabel') {
    d.cancerList.map((cancerName, i) => {

      // let cancerName = `${cancerObj.name}`
      snvCancerList.push(
        <ChooseItemName key={i.toString()} onClickEvent={onClickEvent} name={cancerName} type={detailType.type} index={1} detail={detail} dataRow={d} id={d.id} />
      );
      snvCancerList.push(
        <span key={i.toString() + 'blank'}>&nbsp;&nbsp;&nbsp;</span>
      );
    });

  }

  const classes = useStyles();

  const RComponent = useMemo(() => {
    const make = Component => props => {
      if (!Array.isArray(children)) {
        return (
          <Component {...props} {...options} value={children}>
            {dataFormat(children)}
          </Component>
        );
      }

      return children.map((value, i) => (
        <CustomMultiCell lastNode={children.length - 1 === i}>
          <Component key={i} {...props} {...options} value={value}>
            {dataFormat(value)}
          </Component>
        </CustomMultiCell>
      ));
    };

    //그리드뷰 데이터 생성
    const defaultCell = ({ children }) => {
      return (
        <div className={classes.body} title={value}>
          {children}
          {/* <a href="http://www.naver.com">{children}</a> */}
        </div>
      );
    };
    const defaultCellTrue = ({ children }) => {
      if (index == 0) {
        return (
          <div className={classes.body} title={value}>
            {/* 첫번째 컬럼의 item 선택시 filterTab의 메뉴에서 선택한 것과 동일한 ACTION을 실행, ChooseItemName added by jdy 2020-12 */}
            <ChooseItemName onClickEvent={onClickEvent} name={children} type={detailType.type} index={index} detail={detail} dataRow={d} id={d.id} />
          </div>
        );
      } else {
        if (detailType && detailType.type == 'SNV' && property == 'selectedCancerLabel' && snvCancerList.length > 0) {
          return (
            <div className={classes.bodyFlex} title={value}>
              {/* SNV Table의 두번째 컬럼의 각 cancer 선택시 filterTab의 메뉴에서 선택한 것과 동일한 ACTION을 실행, added by jdy 2020-12 */}
              {snvCancerList}

            </div>
          );
        }
        else {
          return (
            <div className={classes.body} title={value}>
              {children}
            </div>
          );
        }

      }

    };

    const defaultCellfalse = ({ children }) => {

      return (
        <div className={classes.body} title={value}>
          {children}
        </div>
      );
    };

    let component = make(defaultCell)();

    if (isLink === true) {
      component = make(defaultCellTrue)();
    }

    if (isLink === false) {
      component = make(defaultCellfalse)();
    }

    if (withCellExcute) {
      component = make(CustomExcute)();
    }

    if (link.isLinked) {
      component = make(CustomLink)(props);
    }

    if (isEditable) {
      component = make(CustomInput)(props);
    } 

    return component;
  }, [
    value,
    isEditable,
    link.isLinked,
    withCellExcute,
    classes.body,
    children,
    options
  ]
  );

  return RComponent;
}

export default React.memo(CustomCellContent);
