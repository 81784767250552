import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  contain: {
    // height: "40vh",
    // width: "30vw",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    borderBottom: "solid 2px #cdcccc",
    height: "2vw",
    lineHeight: '2.3',
    fontSize: "14px",
    padding: "0px 8px"
  },
  titlename: {
    float: 'left'
  },
  main: {
    padding: "20px 10px"
  },
  button: {
    width: "49%",
    height: "40px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "2px"
  }
})

class isDelete extends Component {

  onClickEvent = () => {
    this.props.delete()
  }

  render() {
    const { classes, close } = this.props
    return (
      <>
        <DialogContent className={classes.contain}>
          <div className={classes.title}>
            <div className={classes.titlename}>Delete</div>
          </div>
          <div className={classes.main}>
            <Button onClick={close} className={`${classes.button} ${classes.buttonpadding}`} type="button">
              CANCEL
              </Button>
            <Button className={classes.button}
              type="button"
              onClick={this.onClickEvent} >
              Yes
              </Button>
          </div>
        </DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(isDelete);
