import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Badge from '@material-ui/core/Badge';
import { observer, inject } from "mobx-react";

const styles = theme => ({
  btn: {
    borderRadius: "1px",
    backgroundColor: "#fcfcfc",
    boxShadow: "1px 1px 3px 0 rgba(121, 121, 121, 0.8)",
    border: "0px !important",
    color: "#3e3e3e",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "normal",
    "&:hover": {
      backgroundColor: "#efefef"
    }
  },
  icon: {
    fontSize: "15px!important",
    color: "#ababab",
    marginRight: "4px"
  },
  Down: {
    fontSize: "16px!important"
  },
  badge: {
    height: "14px",
    padding: "0px",
    minWidth: "14px",
    fontSize: "0.5rem",
    backgroundColor: "#26a1a4"
  }
});



@inject('analysis')
@observer
class CustomBtn extends Component {

  shouldComponentUpdate(nextProps) {
    // return false 하면 업데이트를 안함
    return this.props.text !== nextProps.text || this.props.disabled !== nextProps.disabled || this.props.stylebtn !== nextProps.stylebtn
    // return true;
  }

  hadleClick = e => {
    const { onClick } = this.props;
    if (onClick) onClick(e);
  };

  render() {
    const { text, img: Icon, classes, styleicon, stylebtn, isDown, isSVG, disabled, isBadge, analysis: { cartSet } } = this.props;
    if (isBadge) {
      return (
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={this.hadleClick}
          className={`${classes.btn} ${stylebtn}`}
        >
          <Badge badgeContent={cartSet} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }} classes={{ badge: classes.badge }}>
            {Icon && !isSVG ? (
              <Icon className={`${classes.icon} ${styleicon}`} />
            ) : (
                <img src={Icon} className={classes.icon} alt="" />
              )}
            {text}
          </Badge>
        </Button>
      )
    } else {
      return (
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={this.hadleClick}
          className={`${classes.btn} ${stylebtn}`}
        >
          {Icon && !isSVG ? (
            <Icon className={`${classes.icon} ${styleicon}`} />
          ) : (
              <img src={Icon} className={`${classes.icon} ${styleicon}`} alt="" />
            )}
          {text}
          {/* {isDown ? <ArrowDropDown className={classes.Down} /> : null} */}
        </Button>
      );
    }

  }
}

export default withStyles(styles)(CustomBtn);
