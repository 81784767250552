import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { observer, inject } from "mobx-react";
import Select from '@material-ui/core/Select';


const styles = theme => ({
  options: {
    fontSize: "10px"
  },
  select: {
    fontSize: "13px",
    border: "solid 1px #dbdbdb"
  }
})

@inject("system")
@observer
class RoleChange extends Component {
  state = {
    authorities: ""
  }


  componentDidMount() {
    const { authorities } = this.props
    if (authorities) {
      this.setState({
        authorities: authorities
      })
    }
  }

  change = async (e) => {
    const { system, id, email, locked } = this.props
    let message
    let value = `ROLE_${e.target.value}`
    let query = {
      id: id,
      locked: locked,
      login: email,
      authority: value
    }
    message = await system.changeUser(query)
    system.getAdminUser(`?size=10`)
    if (message) {
      alert("Changed.")
    }

  }

  render() {
    const { classes } = this.props
    const { authorities } = this.state
    return (
      <Select native value={authorities} disableUnderline={true} displayEmpty onChange={this.change} className={classes.select} >
        <option className={classes.options} value={"ADMIN"}>ADMIN</option>
        <option className={classes.options} value={"KM_USER"}>KM_USER</option>
        <option className={classes.options} value={"DEFAULT_USER"}>DEFAULT_USER</option>
      </Select>
    )
  }
}

export default withStyles(styles)(RoleChange);
