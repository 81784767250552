const basicColorList = [
    '#85b4ef', '#85B4E0', '#d7dfe5', '#d7dfe9', '#c2d6ee'
  ];
  
  class ChartModel {
    constructor(data, i) {
      const { name, specimenCount, value, id } = data;
      this.name = name.replace(" cancer", "");
      this.x = value;
      this.y = specimenCount;
      this.id = id;
      this.color = basicColorList[i];
    }
  }
  
  export default ChartModel;
  