import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = theme => ({
  contain: {
    // width: "300px",
    // width: "30vw",
    boxShadow: "0 2px 5px 0 rgba(99, 99, 99, 0.5)",
    border: "solid 2px #cdcccc",
    backgroundColor: "#ffffff",
    padding: "0px !important"
  },
  title: {
    // borderBottom: "solid 2px #cdcccc",
    // height: "2vw",
    // lineHeight: '2.3',
    fontSize: "15px",
    textAlign: "center",
    padding: "20px 8px"
  },
  main: {
    padding: "0px 20px 20px 20px"
  },
  button: {
    width: "110px",
    height: "30px",
    borderRadius: '0px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #979797',
    color: "#979797",
    fontSize: "13px",
    padding: "5px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff",
      borderColor: "#fff"
    }
  },
  textColor: {
    width: "110px",
    height: "30px",
    borderRadius: '0px',
    backgroundColor: '#26a1a4',
    border: 'solid 1px #979797',
    color: "#fff",
    fontSize: "13px",
    padding: "5px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: '#26a1a4',
      color: "#fff"
    }
  },
  buttonpadding: {
    marginRight: "5px"
  }
})

class GoLogin extends Component {

  render() {
    const { classes } = this.props
    return (
      <DialogContent className={classes.contain}>
        <div className={classes.title}>
          <div className={classes.titlename}>Login or Join is required.</div>
        </div>
        <div className={classes.main}>
          <Link to={{ pathname: "/" }} style={{ textDecoration: "none" }}>
            <Button className={`${classes.button} ${classes.buttonpadding}`} type="button" >
              Home
              </Button>
          </Link>
          <Link to={{ pathname: "/registration" }} style={{ textDecoration: "none" }}>
            <Button className={`${classes.button} ${classes.buttonpadding}`} type="button" >
              JOIN
              </Button>
          </Link>
          <Link to={{ pathname: "/signin" }} style={{ textDecoration: "none" }}>
            <Button className={classes.textColor} type="button">
              LOGIN
              </Button>
          </Link>
        </div>
      </DialogContent>
    );
  }
}
export default withStyles(styles)(GoLogin);
