import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { animateScroll as scroll, scroller } from "react-scroll";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CheckPermission from "components/lib/CheckPermission";
import Cancer from "../../../assets/images/home/cancer.svg";
import Mutation from "../../../assets/images/home/variant.svg";
import Gene from "../../../assets/images/home/gene.svg";
// import Demography from "../../../assets/images/home/demography.svg";
import Subject from "assets/images/dataexplorer/subject.svg";

const styles = theme => ({
  arrowUpward: {
    width: '35px',
    position: "fixed",
    bottom: "100px",
    right: "25px",
    // borderRadius: ' 17.5px',
    borderRadius: ' 0px',
    boxShadow: ' 0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#d8d8d8',
    color: "#898989",
    "@media (max-width: 1500px)": {
      right: "10px",
    },
  },
  oval: {
    width: '33px',
    height: '33px',
    border: "2px solid #f3f2f2",
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f3f2f2',
    minWidth: "0px",
    textTransform: 'none',
    // borderRadius: ' 17.5px',
    borderRadius: ' 0px',
    margin: '5px 0px',
    color: "#898989",
    fontSize: "12px",
    padding: "4px"
  },
  openoval: {
    width: '33px',
    height: '33px',
    border: "2px solid #26a1a4",
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f3f2f2',
    minWidth: "0px",
    textTransform: 'none',
    // borderRadius: ' 17.5px',
    borderRadius: ' 0px',
    margin: '5px 0px',
    color: "#898989",
    fontSize: "12px"
  },
  tooltip: {
    backgroundColor: '#a5a5a5',
  }
});

class ScrollList extends Component {
  scrollToTop = e => {
    scroll.scrollToTop();
  };
  scrollTo = e => {
    const { chartOpen } = this.props
    chartOpen(e.currentTarget.value)
    scroller.scrollTo(e.currentTarget.value, { smooth: true, offset: -120, duration: 1000, });
  };

  render() {
    const { classes, state } = this.props;
    return (
      <div className={classes.arrowUpward}>
        {/* <div style={{ fontSize: "8px" }}> Remote</div> */}
        <Tooltip title="Top" placement="top-start" classes={{ tooltip: classes.tooltip }}>
          <Button className={classes.oval} onClick={this.scrollToTop}>TOP</Button>
        </Tooltip>
        {/* <Tooltip title="Demography" placement="top-start" classes={{ tooltip: classes.tooltip }}>
          <Button className={classes.oval} onClick={this.scrollTo} value={'Demography'}>
            <img src={Demography} alt="" style={{ paddingTop: "3px" }} />
          </Button>
        </Tooltip> */}
        <Tooltip title="Cancer List" placement="top-start" classes={{ tooltip: classes.tooltip }}>
          <Button className={state.cancer ? classes.openoval : classes.oval} onClick={this.scrollTo} value={'cancer'}>
            <img src={Cancer} alt="" style={{ paddingTop: "1px" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Gene List" placement="top-start" classes={{ tooltip: classes.tooltip }}>
          <Button className={state.gene ? classes.openoval : classes.oval} onClick={this.scrollTo} value={'gene'}>
            <img src={Gene} alt="" style={{ paddingTop: "1px" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Variant List" placement="top-start" classes={{ tooltip: classes.tooltip }}>
          <Button className={state.snv ? classes.openoval : classes.oval} style={{ marginBottom: "0px" }} onClick={this.scrollTo} value={'snv'}>
            <img src={Mutation} alt="" style={{ paddingTop: "1px" }} />
          </Button>
        </Tooltip>
        <CheckPermission
          key={"patient"}
          permission={"ROLE_KM_USER"}
        >
          <Tooltip title="Patient List" placement="top-start" classes={{ tooltip: classes.tooltip }}>
            <Button className={classes.oval} style={{ marginTop: "10px" }} onClick={this.scrollTo} value={'Patient'}>
              <img src={Subject} alt="" style={{ paddingTop: "1px" }} />
            </Button>
          </Tooltip>
        </CheckPermission>
      </div>
    );
  }
}

export default withStyles(styles)(ScrollList);
